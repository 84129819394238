import React from 'react';

import { toast } from 'react-toastify';
import { Button, Checkbox, Grid, Input, Modal } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import {
  API_EVENT_UPDATE,
  API_MARKETS_UPDATE,
  HORIZONTAL_POSITION_SIZE,
  MARKET_STATUS_ENUM,
  MINIMAL_MARGIN
} from '../../../constants';
import { ModalStateType, SingleByEventMarketType } from '../../../types';
import {
  calculateMargin,
  isMarketOpeningEnable,
  MarketPricesStateType,
  MarketWithPricesObjectType,
  removeEmptyFields
} from '../../../utils';
import { useEventListener } from '../helpers';
import './ControlPanel.css';

export const ControlPanel = ({
  marketsArray,
  marketsRefresh,
  modalState,
  setModalState,
  scoreboardState,
  setScoreboardState,
  eventMarginState,
  setEventMarginState,
  useMarginState,
  setUseMarginState,
  editedPricesState,
  setEditedPricesState,
  originId,
  eventId,
  eventVersion,
  marketPricesState,
}: {
  marketsArray: Array<SingleByEventMarketType>;
  marketsRefresh: () => void;
  modalState: ModalStateType;
  setModalState: (modalState: ModalStateType) => void;
  scoreboardState: { hide: boolean };
  setScoreboardState: (scoreboardState: { hide: boolean }) => void;
  eventMarginState: number;
  setEventMarginState: (eventMarginState: number) => void;
  useMarginState: boolean;
  setUseMarginState: (useMarginState: boolean) => void;
  editedPricesState?: Array<MarketWithPricesObjectType>;
  setEditedPricesState?: (editedPricesState: Array<MarketWithPricesObjectType>) => void;
  originId: string;
  eventId: string;
  eventVersion: string;
  marketPricesState: MarketPricesStateType;
}) => {
  const [ approvePricesModal, setApprovePricesModal ] = React.useState(false);

  const openMarketsModal = () => {
    setModalState({
      ...modalState,
      showModal: true,
    });
  };

  const hideScoreboard = () => {
    setScoreboardState({ hide: !scoreboardState.hide });
  };

  const { update: marketsStatusesUpdate } = useAxiosRequest(null, {
    onSuccess: marketsRefresh,
  });

  const markets = marketsArray || [];
  const irmMarkets = markets.filter(m => m.showInIRM === true);

  const getHideStatus = () => {
    // If at least one outcome is hidden, button will unhide all events
    // If all outcomes were unhidden, button will hide all outcomes
    return (
      irmMarkets?.filter(market => {
        return (
          market.outcomes?.filter(outcome => outcome.outcome.hideModeTrader === false)?.length !== 0
        );
      })?.length === 0
    );
  };

  const [ hideButtonState, setHideButtonState ] = React.useState({
    hide: getHideStatus(),
  });

  const changeAllMarketsStatus = (params: {status: string}) => {
    let marketsForUpdate = [];
    if (params.status && params.status === MARKET_STATUS_ENUM.OPEN) {
      // We have to exclude markets for update& if they have outcomes with null value
      for (const market of irmMarkets) {
        if (isMarketOpeningEnable(market.outcomes)) {
          marketsForUpdate.push(market);
        }
      }
      if (irmMarkets?.length !== marketsForUpdate?.length) {
        toast.error('Cannot open some markets. Please change null outcome prices');
      }
    } else {
      marketsForUpdate = irmMarkets;
    }

    const dataForRequest = removeEmptyFields({
      originId,
      markets: marketsForUpdate.map(m => ({
        id: m.market.id,
        ...params,
      })),
    });

    marketsStatusesUpdate({
      method: 'PUT',
      url: API_MARKETS_UPDATE,
      data: dataForRequest,
    });
  };

  const { update: hideModeUpdate } = useAxiosRequest(null, {
    onSuccess: () => {
      setHideButtonState({ hide: !hideButtonState.hide });
      marketsRefresh();
    },
  });

  const changeMarketsHideStatus = (params: { hideModeTrader: boolean }) => {
    const dataForRequest = removeEmptyFields({
      originId,
      markets: irmMarkets.map(m => ({
        id: m.market.id,
        outcomes: m.outcomes.map(o => ({
          id: o.outcome.id,
          ...params,
        })),
      })),
    });

    hideModeUpdate({
      method: 'PUT',
      url: API_MARKETS_UPDATE,
      data: dataForRequest,
    });
  };

  const hideIconName = hideButtonState.hide ? 'eye slash' : 'eye';

  React.useEffect(() => {
    setHideButtonState({ hide: getHideStatus() });
    // eslint-disable-next-line
  }, [marketsArray?.length]);

  const onSuccessForPrices = () => {
    setEditedPricesState([]);
    setApprovePricesModal(false);
    toast.success('Market\'s prices were saved');
  };

  const { update: updatePrice } = useAxiosRequest(null, {
    onSuccess: onSuccessForPrices,
  });

  const isReadyToSave = React.useCallback(() => {
    for (const market of editedPricesState) {
      const margin = calculateMargin({
        prices: Object.values(marketPricesState[market.id]),
      });
      if (Number(margin) < MINIMAL_MARGIN) {
        return false;
      }
    }
    return true;
  }, [ marketPricesState, editedPricesState ]);

  const savePricesUpdate = React.useCallback(() => {
    const dataForRequest = removeEmptyFields({
      markets: editedPricesState,
      originId: originId,
    });

    updatePrice({
      method: 'PUT',
      url: API_MARKETS_UPDATE,
      data: dataForRequest,
    });
  }, [ editedPricesState, originId, updatePrice ]);

  const savePrices = React.useCallback(() => {
    if (isReadyToSave()) {
      savePricesUpdate();
    } else {
      setApprovePricesModal(true);
    }
  }, [ isReadyToSave, savePricesUpdate ]);

  const listenerCallback = React.useCallback(
    (e: any) => {
      if ((e.metaKey || e.ctrlKey) && e.code === 'KeyS') {
        e.preventDefault();
        if (editedPricesState?.length !== 0) {
          savePrices();
        }
      }
    },
    [ editedPricesState, savePrices ]
  );

  const getHideButtonName = React.useCallback(() => {
    if (window.innerWidth > HORIZONTAL_POSITION_SIZE) {
      return scoreboardState.hide ? 'arrow right' : 'arrow left';
    } else {
      return scoreboardState.hide ? 'arrow down' : 'arrow up';
    }
  }, [ scoreboardState ]);

  useEventListener('keydown', listenerCallback);

  return (
    <Grid.Row className="control-panel">
      <Modal
        size="tiny"
        open={approvePricesModal}
        onClose={() => {
          setApprovePricesModal(false);
        }}
      >
        <Modal.Header>
          {'Margin in some markets is less, then 101%. Do you want to continue?'}
        </Modal.Header>
        <Modal.Actions>
          <Button
            id="irm--control-panel--button-1"
            content="Cancel"
            icon="checkmark"
            onClick={() => setApprovePricesModal(false)}
            negative
          />
          <Button
            id="irm--control-panel--button-2"
            content="Confirm"
            labelPosition="right"
            icon="checkmark"
            onClick={() => savePricesUpdate()}
            positive
          />
        </Modal.Actions>
      </Modal>
      <Grid.Column width="8" className="panel-buttons-column">
        <Button size="tiny" icon={getHideButtonName()} onClick={hideScoreboard} />
        <Button size="tiny" icon="table" onClick={openMarketsModal} />
        <Button.Group size="tiny" className="market-buttons-group">
          <Button
            id="irm--control-panel--button-3"
            icon="play"
            onClick={() => {
              changeAllMarketsStatus({
                status: MARKET_STATUS_ENUM.OPEN,
              });
            }}
          />
          <Button
            id="irm--control-panel--button-4"
            icon="pause"
            onClick={() =>
              changeAllMarketsStatus({
                status: MARKET_STATUS_ENUM.SUSPENDED,
              })
            }
          />
          <Button
            id="irm--control-panel--button-5"
            icon="save"
            disabled={editedPricesState?.length === 0}
            color={editedPricesState?.length !== 0 ? 'blue' : undefined}
            onClick={savePrices}
          />
        </Button.Group>
        <Button
          id="irm--control-panel--button-6"
          size="tiny"
          disabled={irmMarkets.length === 0}
          onClick={() => {
            changeMarketsHideStatus({
              hideModeTrader: !hideButtonState.hide,
            });
          }}
          icon={hideIconName}
          className="eye_button"
        />
      </Grid.Column>

      <Grid.Column width="8" textAlign="right" className="margin-panel">
        <MarginPanel
          eventMarginState={eventMarginState}
          setEventMarginState={setEventMarginState}
          useMarginState={useMarginState}
          setUseMarginState={setUseMarginState}
          eventId={eventId}
          originId={originId}
          eventVersion={eventVersion}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

const MarginPanel = ({
  eventMarginState,
  setEventMarginState,
  useMarginState,
  setUseMarginState,
  eventId,
  originId,
  eventVersion,
}: {
  eventMarginState: number;
  setEventMarginState: (eventMarginState: number) => void;
  useMarginState: boolean;
  setUseMarginState: (useMarginState: boolean) => void;
  eventId: string;
  originId: string;
  eventVersion: string;
}) => {
  const [ isIncorrectInput, setIsIncorrectInput ] = React.useState(false);

  const { update } = useAxiosRequest(null);

  const setMargin = React.useCallback((e: any) => {
    const margin = e.currentTarget.value;
    if (Number(margin) && margin < 1000 && margin > 0) {
      setEventMarginState(margin);
      const dataForRequest = removeEmptyFields({
        event: {
          id: eventId,
          marginValue: margin,
          version: eventVersion,
          isAutoMargin: useMarginState,
        },
        originId: originId,
      });
      update({
        method: 'PUT',
        url: API_EVENT_UPDATE,
        data: dataForRequest,
      });
      setIsIncorrectInput(false);
    } else {
      setIsIncorrectInput(true);
    }
  },[ setEventMarginState, useMarginState, eventId, eventVersion, originId, update ]);

  const setAutoMargin = React.useCallback(() => {
    update({
      method: 'PUT',
      url: API_EVENT_UPDATE,
      data: {
        event: {
          id: eventId,
          isAutoMargin: !useMarginState,
          version: eventVersion,
          marginValue: eventMarginState,
        },
        originId: originId,
      },
    });
    setUseMarginState(!useMarginState);
  }, [ useMarginState, eventMarginState, setUseMarginState, eventId, eventVersion, originId, update ]);

  return (
    <div>
      <Checkbox
        id="irm--control-panel--markets-margin-checkbox"
        label="Markets margin"
        size="mini"
        checked={useMarginState}
        onChange={setAutoMargin}
      />
      <Input
        id="irm--control-panel--markets-margin-input"
        size="mini"
        error={isIncorrectInput}
        defaultValue={eventMarginState}
        onChange={setMargin}
        labelPosition="right"
        label={{ basic: true, content: '%' }}
        style={{ marginLeft: '15px' }}
      />
    </div>
  );
};
