import React from 'react';

import { BetsHistoryWithLiveMode } from './BetsHistoryWithLiveMode';
import { FILTER_INTERVALS } from '../../constants';
import { BetsColumnLabelEnum, BetsTableFiltersEnum } from '../../types';

const COLUMNS = [
  BetsColumnLabelEnum.Date,
  BetsColumnLabelEnum.Player,
  BetsColumnLabelEnum.Outcomes,
  BetsColumnLabelEnum.Type,
  BetsColumnLabelEnum.Stake,
  BetsColumnLabelEnum.Price,
  BetsColumnLabelEnum.Winning,
  BetsColumnLabelEnum.Status,
  BetsColumnLabelEnum.Actions
];

const FILTERS = [
  BetsTableFiltersEnum.originIds,
  BetsTableFiltersEnum.hideTestAccounts,
  BetsTableFiltersEnum.betId,
  BetsTableFiltersEnum.userId,
  BetsTableFiltersEnum.factorGroupIds,
  BetsTableFiltersEnum.eventId,
  BetsTableFiltersEnum.betType,
  BetsTableFiltersEnum.betAccepted,
  BetsTableFiltersEnum.bookType,
  BetsTableFiltersEnum.betStatuses,
  BetsTableFiltersEnum.betRejectReasons,
  BetsTableFiltersEnum.stake,
  BetsTableFiltersEnum.period,
  BetsTableFiltersEnum.sports,
  BetsTableFiltersEnum.showOnlyOutrights
];

const PARAMS = {
  limit: 50,
};

export const Bets = () => (
  <BetsHistoryWithLiveMode
    params={PARAMS}
    filters={FILTERS}
    columns={COLUMNS}
    showCopyButtons={{
      userId: true,
      userName: true,
      betId: true,
    }}
    parentDomId="bets--"
    defaultPeriod={FILTER_INTERVALS.day.value}
  />
);
