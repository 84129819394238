import React from 'react';

import { Icon } from 'semantic-ui-react';

import { useActualTime } from '../../../../hooks-and-global-states/time-hooks';
import { formatPrice } from '../../../../utils';

type Props = { children: number };

export const Price = ({ children }: Props) => {
  return <>{children === 0 ? '—' : formatPrice(children)}</>;
};

const priceDeltaReducer = (state, action) => {
  switch (action.type) {
    case 'price change':
      return {
        price: action.payload.price,
        delta: action.payload.price - state.price,
        time: action.payload.time,
      };
    case 'reset delta':
      return {
        price: state.price,
        delta: 0,
        time: state.time,
      };
    default:
      return state;
  }
};

export const PriceWithDelta = React.memo<Props>(({ children }: Props) => {
  const time = useActualTime();

  const [ state, dispatch ] = React.useReducer(priceDeltaReducer, {
    price: children,
    delta: 0,
    time,
  });

  if (state.price !== children) {
    dispatch({
      type: 'price change',
      payload: {
        time,
        price: children,
      },
    });
  }

  if (state.delta !== 0 && time - 2999 > state.time) {
    dispatch({
      type: 'reset delta',
    });
  }

  return React.useMemo(
    () => (
      <>
        {state.delta !== 0 && (
          <Icon
            className="animation-flash"
            color={state.delta > 0 ? 'green' : 'red'}
            name={state.delta > 0 ? 'arrow up' : 'arrow down'}
          />
        )}
        <Price>{state.price}</Price>
      </>
    ),
    [ state.delta, state.price ]
  );
});
