import React from 'react';

import {
  API_MARKET,
  CUTOFF_TIME,
  DESCRIPTION,
  MARKET_STATUS_ENUM,
  MARKET_TYPE_GROUP_OUTRIGHT,
  MARKET_TYPE_ID,
  OutcomeResultsEnum,
  OUTCOMES,
  PERIOD_ID,
  STATUS
} from '../../../constants';

export const localOutcomesGetter = outcomes => {
  const obj = {};
  for (const { outcome } of outcomes) {
    obj[outcome.id] = {
      id: outcome.id,
      result: outcome.result,
      voidReason: outcome.voidReason,
    };
  }
  return obj;
};

export const toggleEditFunc = (
  editingResults,
  setLocalOutcomes,
  getLocalOutcomes,
  outcomes,
  setEditingResults
) => {
  const nextEditingResults = !editingResults;
  if (nextEditingResults) {
    setLocalOutcomes(getLocalOutcomes(outcomes));
  }
  setEditingResults(nextEditingResults);
};

export const useHighlightedRowRef = () => {
  const highlightedRowRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (highlightedRowRef.current) {
      highlightedRowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  return highlightedRowRef;
};

export const handleOutcomeResultChangeFunc = ({
  id,
  result,
  voidReason,
  setLocalOutcomes,
  localOutcomes,
}: {
  id: string;
  result: keyof typeof OutcomeResultsEnum;
  voidReason?: string;
  setLocalOutcomes: (string: object) => object;
  localOutcomes: { string: object };
}) => {
  const updatedOutcomes = {};
  Object.keys(localOutcomes).forEach(key => {
    const outcome = localOutcomes[key];

    if (key === id) {
      updatedOutcomes[key] = {
        ...outcome,
        result,
        voidReason,
      };
    } else {
      updatedOutcomes[key] = outcome;
    }
  });
  setLocalOutcomes(updatedOutcomes);
  return updatedOutcomes;
};

export const handleAllOutcomesResultChangeFunc = ({
  result,
  voidReason,
  setLocalOutcomes,
  localOutcomes,
}: {
  result: keyof typeof OutcomeResultsEnum;
  voidReason?: string;
  setLocalOutcomes: (string: object) => object;
  localOutcomes: object;
}) => {
  const updatedOutcomes = {};
  Object.keys(localOutcomes).forEach(key => {
    updatedOutcomes[key] = {
      ...localOutcomes[key],
      result,
      voidReason,
    };
  });
  setLocalOutcomes(updatedOutcomes);
  return updatedOutcomes;
};

export const submitOutcomeResultsFunc = (marketId: string, originId: string, localOutcomes, update) => {
  update({
    url: `${API_MARKET}/${marketId}/update`,
    method: 'PUT',
    data: {
      originId,
      id: marketId,
      outcomes: Object.keys(localOutcomes).reduce((arr, key) => {
        const { id, result, voidReason } = localOutcomes[key];
        arr.push({
          id,
          result,
          voidReason: voidReason === null ? undefined : voidReason,
        });
        return arr;
      }, []),
    },
  });
};

export const isDynamicMarketCheck = outcomes => {
  return outcomes?.some(outcome => outcome.outcome.id.match('-'));
};

export const canEditMarketCheck = market => {
  return (
    market.status === MARKET_STATUS_ENUM.OPEN ||
    market.status === MARKET_STATUS_ENUM.SUSPENDED ||
    market.status === MARKET_STATUS_ENUM.CLOSED
  );
};

export function validateMarketForm(data, periodState, itemsTypeGroup) {
  const {
    description,
    marketTypeId,
    periodId,
    cutoffTime,
    status,
    outcomes
  } = data;
  const errors = {};

  if (!description) errors[DESCRIPTION] = 'description required';
  if (!marketTypeId) errors[MARKET_TYPE_ID] = 'marketTypeId required';
  if (!periodId) errors[PERIOD_ID] = 'periodId required';
  if (!status) errors[STATUS] = 'status required';

  const selectedPeriod = (periodState || []).find(({ id }) => id === periodId);
  if (selectedPeriod && !selectedPeriod.inRunning && !cutoffTime) {
    errors[CUTOFF_TIME] = 'Cutoff time is required for prematch events';
  }

  outcomes.forEach(el => {
    if (el.description === '') {
      errors[OUTCOMES] = 'description outcomes not null';
    }
    if (!el.ordinalPosition && itemsTypeGroup !== MARKET_TYPE_GROUP_OUTRIGHT) {
      errors[OUTCOMES] = 'position outcomes required';
    }
  });

  return errors;
}

export function getDefaultMarketDescription({
  marketTypeId,
  marketTypeOptions,
  periodId,
  periodOptions,
  opponentId,
  opponentOptions,
}) {
  function getTextByValue(value, options) {
    if (!value) return '';
    const option = options.find(item => item.value === value);
    return option ? option.description || option.text : '';
  }

  const market = getTextByValue(marketTypeId, marketTypeOptions);
  const period = getTextByValue(periodId, periodOptions);
  const opponent = getTextByValue(opponentId, opponentOptions);
  const marketToShow = opponent ? market.replace('{OPPONENT}', ` ${opponent}`) : market;
  return `${marketToShow} ${period}`;
}

export function formatMargin (margin) {
  const roundMargin = Number(margin).toFixed(1);
  const resultMargin = Number(roundMargin) + 100;
  return resultMargin.toFixed(1);
}

export function checkNumberCorrect (price) {
  const checkNumberIsComplete = (price: string) => {
    const numberParts = price.split('.');
    return numberParts.length === 1 || (numberParts.length === 2 && !(numberParts[1] === ''));
  };

  return !(isNaN(Number(price)) || price <= 1 || !checkNumberIsComplete(String(price)));
}

export function getNewPrice (collection, index, next = true) {
  if (!collection) return null;

  const collectionIndex = next ? index + 1 : index - 1;
  const newCollection = collection.current[collectionIndex];
  if (!newCollection) return null;

  const priceIndex = next ? 0 : newCollection.length - 1;
  let newPrice = newCollection[priceIndex]?.firstElementChild?.firstElementChild as HTMLInputElement;
  if (!newPrice) return null;

  if (newPrice.disabled) newPrice = getNewPrice(collection, collectionIndex, next);

  return newPrice;
}
