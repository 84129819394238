import { useMemo } from 'react';

import { useOutletContext } from 'react-router-dom';

import {
  INTERVALS_TYPE_1
} from '../../../components/search-and-filters-components/QueryStateFilterByPeriod';
import { FILTER_INTERVALS } from '../../../constants';
import { BetsTableFiltersEnum, EventOutletContextType } from '../../../types';
import { BetsHistoryWithLiveMode } from '../../Bets/BetsHistoryWithLiveMode';

const PARAMS = {
  limit: 50,
};

const FILTERS = [
  BetsTableFiltersEnum.hideTestAccounts,
  BetsTableFiltersEnum.originIds,
  BetsTableFiltersEnum.betId,
  BetsTableFiltersEnum.userId,
  BetsTableFiltersEnum.factorGroupIds,
  BetsTableFiltersEnum.betType,
  BetsTableFiltersEnum.betAccepted,
  BetsTableFiltersEnum.betStatuses,
  BetsTableFiltersEnum.betRejectReasons,
  BetsTableFiltersEnum.bookType,
  BetsTableFiltersEnum.stake,
  BetsTableFiltersEnum.period,
  BetsTableFiltersEnum.marketType
];

export const BetsByEvent = () => {
  const { event } = useOutletContext<EventOutletContextType>();
  const params = useMemo(() => ({
    ...PARAMS,
    eventIds: [ event.id ],
  }),[ event.id ]);

  const filterParams = useMemo(() => ({
    eventId: event.id,
  }),[ event.id ]);

  return (
    <BetsHistoryWithLiveMode
      params={params}
      filters={FILTERS}
      filterParams={filterParams}
      showCopyButtons={{
        userId: true,
        userName: true,
        betId: true,
      }}
      parentDomId="event-edit--bets-tab--"
      defaultPeriod={FILTER_INTERVALS.all.value}
      intervalsOptionsType={INTERVALS_TYPE_1}
    />
  );
};
