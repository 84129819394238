import React from 'react';

import cx from 'classnames';

type Props = React.ComponentProps<'div'> & {
  column?: boolean;
};

interface FlexDivType extends React.FC<Props> {
  Flex: typeof FlexDivFlex;
  Div: any;
}

export const FlexDiv: FlexDivType = ({ className, column, ...props }: Props) => (
  <div className={cx('FlexDiv', column && 'FlexDiv--column', className)} {...props} />
);

const FlexDivFlex = ({ className, ...props }: React.ComponentProps<'div'>) => (
  <div className={cx('FlexDiv--flex', className)} {...props} />
);

FlexDiv.Flex = FlexDivFlex;
FlexDiv.Div = 'div';
