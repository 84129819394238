import React from 'react';

import cx from 'classnames';
import { Button, Table } from 'semantic-ui-react';

import { BetsTableRow } from './BetsTableRow';
import { BETS_COLUMNS } from './Columns';
import { TableSkeleton } from '../../../components/UI/TableSkeleton';
import { ROW_COLOR_FIRST, ROW_COLOR_SECOND } from '../../../constants';
import { useQueryState } from '../../../hooks-and-global-states/history-hooks';
import { BetsColumnLabelEnum, BetsColumnLabelType, BetType } from '../../../types';
import { useTraiderVoidReasons } from '../helpers';
import './BetsTable.scss';

const DEFAULT_COLUMNS = [
  BetsColumnLabelEnum.Date,
  BetsColumnLabelEnum.Player,
  BetsColumnLabelEnum.Outcomes,
  BetsColumnLabelEnum.Stake,
  BetsColumnLabelEnum.Price,
  BetsColumnLabelEnum.Type,
  BetsColumnLabelEnum.Winning,
  BetsColumnLabelEnum.Status,
  BetsColumnLabelEnum.Actions
];

type TBetsTable = {
  bets: Array<BetType>;
  isFetching: boolean;
  columns?: Array<BetsColumnLabelType>;
  pagination: React.ReactNode;
  refresh: (arg0: void) => void;
  sortBy: string;
  sortDir: 'ascending' | 'descending';
  setSort: (str: string) => void;
  limit: number;
  selectedBets?: Array<BetType>;
  setSelectedBets?: (bets: Array<BetType>) => void;
  showCopyButtons?: {
    userId?: boolean;
    betId?: boolean;
    userName?: boolean;
  };
  stopSorting?: boolean;
  parentDomId: string;
}
export const BetsTable = ({
  isFetching,
  columns = DEFAULT_COLUMNS,
  bets,
  pagination,
  refresh,
  sortBy,
  sortDir,
  setSort,
  limit,
  selectedBets = [],
  setSelectedBets,
  showCopyButtons,
  stopSorting = false,
  parentDomId,
}: TBetsTable) => {
  const voidReasonList = useTraiderVoidReasons();
  const { queryState } = useQueryState();
  const liveMode = queryState.liveMode === null ||
    queryState.liveMode === undefined ||
    queryState.liveMode;

  let rowColor: null | 'first' | 'second' = null;

  function selectBets (bet: BetType) {
    let betsToSelect: Array<BetType>;
    if (selectedBets.find(({ id }) => bet.id === id)) {
      betsToSelect = selectedBets.filter(({ id }) => bet.id !== id);
    } else {
      betsToSelect = [ ...selectedBets, { ...bet }];
    }
    setSelectedBets(betsToSelect);
  }

  return (
    <>
      <div className="bets-table__live-mode-wrapper">
        {!liveMode && (
          <Button
            id={parentDomId + 'refresh-btn'}
            circular
            icon="refresh"
            color="violet"
            onClick={() => refresh()}
            disabled={isFetching}
          />
        )}
      </div>

      <Table celled sortable size="small" compact="very">
        <Table.Header>
          <Table.Row>
            {!liveMode && <Table.HeaderCell />}
            {columns.map((column, index) => {
              const { sortByEnum } = BETS_COLUMNS[column];
              function sortedOnClick () {
                if (!sortByEnum || stopSorting) return;
                setSort(sortByEnum);
              }
              return (
                <Table.HeaderCell
                  key={column + index}
                  id={parentDomId + column.toLowerCase() + '-column'}
                  sorted={sortByEnum === sortBy ? sortDir : null}
                  onClick={sortedOnClick}
                >
                  {column}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isFetching &&
            <TableSkeleton
              rows={limit}
              columns={liveMode ? columns?.length : columns?.length + 1}
              rowHeight={3}
            />
          }
          {!isFetching && !!bets?.length &&
            <>
              {bets.map((bet, index) => {
                const { betSystemGroupId } = bet;
                if (betSystemGroupId === '0') {
                  rowColor = null;
                } else {
                  if (!rowColor || index === 0) {
                    rowColor = ROW_COLOR_FIRST;
                  } else {
                    rowColor = betSystemGroupId === bets[index - 1].betSystemGroupId
                      ? rowColor
                      : rowColor === ROW_COLOR_FIRST
                        ? ROW_COLOR_SECOND
                        : ROW_COLOR_FIRST;
                  }
                }

                return (
                  <BetsTableRow
                    key={bet.id + bet.date}
                    columns={columns}
                    bet={bet}
                    refresh={refresh}
                    voidReasonList={voidReasonList}
                    className={cx({
                      'trade-true': !!bet.traderName,
                      'bets-table__row_first': rowColor === ROW_COLOR_FIRST,
                      'bets-table__row_second': rowColor === ROW_COLOR_SECOND,
                    })}
                    isBetSelected={selectedBets?.some(({ id }) => id === bet.id)}
                    selectBets={selectBets}
                    liveMode={liveMode}
                    showCopyButtons={showCopyButtons}
                    parentDomId={parentDomId + 'bets-row-' + (index + 1) + '--'}
                  />
                );
              })}
            </>
          }
          {!isFetching && !bets?.length &&
            <Table.Row>
              <Table.Cell
                colSpan={liveMode ? columns?.length : columns?.length + 1}
                warning
                textAlign="center"
                style={{ fontSize: '1.5rem', padding: '1.6rem' }}
              >
                No data.
              </Table.Cell>
            </Table.Row>

          }
          <Table.Row>
            <Table.Cell
              colSpan={columns?.length}
              disabled={!isFetching && !bets?.length}
            >
              {pagination}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};
