import React, { ChangeEvent } from 'react';

import { toast } from 'react-toastify';
import { Button, Form, Grid, Input, Modal } from 'semantic-ui-react';

import { useBetPlace } from '../../../../react-query/mutations';
import { FieldTypesEnum } from '../../../../types';
import './CreateCustomBetModal.css';

export const CreateCustomBetModal = ({
  openBetModal,
  description,
  marketDescription,
  eventDescription,
  setOpenBetModal,
  outcomeId,
  price,
  parentDomId,
}: {
  openBetModal: boolean;
  description: string;
  marketDescription: string;
  eventDescription: string;
  setOpenBetModal: (openBetModal: boolean) => void;
  outcomeId: string;
  price?: number;
  parentDomId: string;
}) => {

  const [ betState, setBetState ] = React.useState({
    accountId: '',
    stake: '',
    price: price || '',
    outcomeIds: [ outcomeId ],
  });

  const isApplyButtonDisabled = React.useMemo(() => {
    return !(betState.price && betState.stake && betState.accountId);
  }, [ betState ]);

  const mutBetPlace = useBetPlace({
    onSuccess, onError
  });

  function onChange (event: ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value;
    const fieldName = event.target.name;
    setBetState({
      ...betState,
      [fieldName]: newValue
    });
  }

  function onSubmit () {
    if (!(Number(betState.price) > 1)) {
      toast.error('Price must be more, then 1.0');
      return;
    }
    mutBetPlace.mutate({
      accountId: betState.accountId,
      outcomes: [{
        outcomeId: betState.outcomeIds[0],
        price: Number(betState.price)
      }],
      stake: Number(betState.stake)
    });
  }

  function onSuccess () {
    toast.success('Bet was successfully placed');
    setBetState({
      accountId: '',
      stake: '',
      price: price || '',
      outcomeIds: [ outcomeId ],
    });
    setOpenBetModal(false);
  }

  function onError (data: any) {
    toast.error(data.error);
  }

  return (
    <Modal open={openBetModal} size={'small'}>
      <Modal.Header>
        {description}
        <div className="modal-header-description">
          {`${marketDescription} | ${eventDescription}`}
        </div>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column width={8}>
                <Form.Field
                  id={parentDomId + '--account-id-input'}
                  name="accountId"
                  type={FieldTypesEnum.NUMBER}
                  control={Input}
                  label="Account Id"
                  placeholder="Account Id"
                  onChange={onChange}
                  value={betState.accountId}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Field
                  id={parentDomId + '--price-input'}
                  name="price"
                  type={FieldTypesEnum.NUMBER}
                  control={Input}
                  label="Price"
                  placeholder="0.00"
                  onChange={onChange}
                  value={betState.price}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Field
                  id={parentDomId + '--stake-input'}
                  name="stake"
                  type={FieldTypesEnum.NUMBER}
                  control={Input}
                  label="Bet amount"
                  placeholder="100000"
                  value={betState.stake}
                  onChange={onChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id={parentDomId + '--cancel-btn'}
          negative
          onClick={() => setOpenBetModal(false)}
          content="Cancel"
        />
        <Button
          id={parentDomId + '--submit-btn'}
          content="Place bet"
          onClick={onSubmit}
          positive
          disabled={isApplyButtonDisabled}
        />
      </Modal.Actions>
    </Modal>
  );
};
