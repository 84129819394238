import { memo, useMemo } from 'react';

import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, Button } from 'semantic-ui-react';

import { MarketEditForm } from './MarketEditForm';
import { DEFAULT_MARKET } from '../../../constants';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';
import { useBoolean } from '../../../hooks-and-global-states/hooks';
import { useMarketCreate } from '../../../react-query/mutations';
import { EventOutletContextType, MarketCreateType } from '../../../types';
import { removeEmptyFields, removeExtraSpaces } from '../../../utils';

export const MarketAdd = memo(({ id }:{id?: string}) => {
  const { event } = useOutletContext<EventOutletContextType>();
  const { originId } = useGlobalStateContext();
  const { value, setTrue, setFalse } = useBoolean(false);

  const mutMarketCreate = useMarketCreate({ onSuccess });

  const initialValue = useMemo(() => {
    return ({
      ...DEFAULT_MARKET,
      cashoutEnabled: event.cashoutEnabled,
      cashoutInherited: true,
    });
  },[ event.cashoutEnabled ]);

  function submit (values: MarketCreateType) {
    const isPriceEmpty = values.outcomes.map(el => el.price)?.includes('');

    const dataForRequest = removeEmptyFields({
      eventId: event.id,
      hideMode: isPriceEmpty ? true : values.hideMode,
      cashoutInherited: !!values.cashoutInherited,
      cashoutEnabled: values.cashoutEnabled,
      /** Remove extra spaces https://jira.offsidegaming.com/browse/FNX-3790 */
      description: removeExtraSpaces(values.description),
      periodId: values.periodId,
      marketTypeId: values.marketTypeId,
      status: values.status,
      homeSpread: values.homeSpread,
      awaySpread: values.awaySpread,
      cutoffTime: values.cutoffTime,
      outcomes: values.outcomes.map(outcome => ({
        ...outcome, description: removeExtraSpaces(outcome.description)
      })),
      originId,
    });

    mutMarketCreate.mutate(dataForRequest);
  }

  function onSuccess () {
    setFalse();
    toast.success('Market has been created');
  }

  return (
    <Modal
      closeOnDimmerClick={false}
      open={value}
      closeIcon
      onClose={setFalse}
      trigger={
        <Button
          id={id}
          primary
          icon="add"
          content="Add market"
          onClick={setTrue}
          fluid
        />
      }
    >
      <MarketEditForm
        onClose={setFalse}
        initialValues={initialValue}
        submit={submit}
        isMarketCreating={true}
        marketManualMode={true}
        parentDomId="event-edit--"
      />
    </Modal>
  );
});
