import { FC } from 'react';

import { Table } from 'semantic-ui-react';

import { PlayersRow } from './PlayersRow';
import { TotalsRow } from './TotalsRow';
import { TablePagination } from '../../../../components/table-components/TablePagination';
import { TableSkeleton } from '../../../../components/UI/TableSkeleton';
import { useQueryState, useSortQuery } from '../../../../hooks-and-global-states/history-hooks';
import { HEADER_CELLS } from '../../model/constants';
import { IPlayersTable } from '../../model/types';
import '../styles.scss';

export const PlayersTable: FC<IPlayersTable> = ({
  bets,
  performance,
  totalCount,
  page,
  setPage,
  limit,
  isFetching,
}) => {
  const { queryState } = useQueryState();
  const { sortBy, setSort, sortDirForSemanticTable } = useSortQuery({
    sortBy: 'turnover',
  });
  const showLifetime = queryState.showLifetime === 'true';

  return (
    <Table structured celled sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="6">User</Table.HeaderCell>
          {HEADER_CELLS.map(({ name, label, sortable }) => (
            <Table.HeaderCell
              key={name}
              width="2"
              sorted={sortBy === name ? sortDirForSemanticTable : null}
              onClick={
                sortable
                  ? () => setSort(name)
                  : undefined
              }
            >
              {label} {showLifetime && '- Period / Lifetime'}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {isFetching && !bets?.length ? (
          <TableSkeleton
            rows={10}
            columns={11}
            rowHeight={3}
          />
        ) : (
          <>
            <TotalsRow
              totalBets={performance?.totalBets || 0}
              totalRevenue={performance?.totalTurnoverUSD || 0}
              totalProfit={performance?.totalProfitUSD || 0}
              totalPayout={performance?.totalPayoutUSD || 0}
              totalMargin={performance?.totalMargin || 0}
            />
            {bets.map(bet => (
              <PlayersRow
                key={bet.user.id}
                bet={bet}
                showLifetime={showLifetime}
              />
            ))}
            <Table.Row>
              <Table.Cell colSpan={6}>
                <TablePagination
                  parentDomId="settleв-by-players"
                  totalCount={+totalCount}
                  currentCount={bets?.length}
                  page={page}
                  setPage={setPage}
                  isFetching={isFetching}
                  limit={limit}
                />
              </Table.Cell>
            </Table.Row>
          </>
        )}
      </Table.Body>
    </Table>
  );
};


