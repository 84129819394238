import { memo, SyntheticEvent } from 'react';

import moment from 'moment';
import { Dropdown } from 'semantic-ui-react';

import { END_DATE_PARAM, FILTER_INTERVALS, PERIOD_FILTER, START_DATE_PARAM } from '../../constants';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import { getOptionsWithIds } from '../../utils';
import { FilterDatePicker } from '../form-components/FilterDatePicker';

export const DATE_FILTER_INTERVALS_DEFAULT = [
  {
    text: FILTER_INTERVALS.hour.text,
    value: FILTER_INTERVALS.hour.value,
    getter: () => moment().subtract(1, 'hours').format(),
    dateNow: () => moment().format(),
  },
  {
    text: FILTER_INTERVALS.day.text,
    value: FILTER_INTERVALS.day.value,
    getter: () => moment().subtract(24, 'hours').format(),
    dateNow: () => moment().format(),
  },
  {
    text: FILTER_INTERVALS.threeDays.text,
    value: FILTER_INTERVALS.threeDays.value,
    getter: () => moment().subtract(72, 'hours').format(),
    dateNow: () => moment().format(),
  },
  {
    text: FILTER_INTERVALS.week.text,
    value: FILTER_INTERVALS.week.value,
    getter: () => moment().subtract(7, 'days').format(),
    dateNow: () => moment().format(),
  },
  {
    text: FILTER_INTERVALS.month.text,
    value: FILTER_INTERVALS.month.value,
    getter: () => moment().subtract(1, 'months').format(),
    dateNow: () => moment().format(),
  },
  {
    text: FILTER_INTERVALS.threeMonth.text,
    value: FILTER_INTERVALS.threeMonth.value,
    getter: () => moment().subtract(3, 'months').format(),
    dateNow: () => moment().format(),
  },
  {
    text: FILTER_INTERVALS.year.text,
    value: FILTER_INTERVALS.year.value,
    getter: () => moment().subtract(1, 'year').format(),
    dateNow: () => moment().format(),
  },
  {
    text: FILTER_INTERVALS.custom.text,
    value: FILTER_INTERVALS.custom.value,
  }
];

export const DATE_FILTER_INTERVALS_UTC = [
  {
    text: FILTER_INTERVALS.hour.text,
    value: FILTER_INTERVALS.hour.value,
    getter: () => moment().subtract(1, 'hours').utc().format(),
    dateNow: () => moment().utc().format(),
  },
  {
    text: FILTER_INTERVALS.day.text,
    value: FILTER_INTERVALS.day.value,
    getter: () => moment().subtract(24, 'hours').utc().format(),
    dateNow: () => moment().utc().format(),
  },
  {
    text: FILTER_INTERVALS.threeDays.text,
    value: FILTER_INTERVALS.threeDays.value,
    getter: () => moment().subtract(72, 'hours').utc().format(),
    dateNow: () => moment().utc().format(),
  },
  {
    text: FILTER_INTERVALS.week.text,
    value: FILTER_INTERVALS.week.value,
    getter: () => moment().subtract(7, 'days').utc().format(),
    dateNow: () => moment().utc().format(),
  },
  {
    text: FILTER_INTERVALS.month.text,
    value: FILTER_INTERVALS.month.value,
    getter: () => moment().subtract(1, 'months').utc().format(),
    dateNow: () => moment().utc().format(),
  },
  {
    text: FILTER_INTERVALS.threeMonth.text,
    value: FILTER_INTERVALS.threeMonth.value,
    getter: () => moment().subtract(3, 'months').utc().format(),
    dateNow: () => moment().utc().format(),
  },
  {
    text: FILTER_INTERVALS.year.text,
    value: FILTER_INTERVALS.year.value,
    getter: () => moment().subtract(1, 'year').utc().format(),
    dateNow: () => moment().utc().format(),
  },
  {
    text: FILTER_INTERVALS.custom.text,
    value: FILTER_INTERVALS.custom.value,
  }
];

const DATE_FILTERS_INTERVALS_WITH_ALL = [
  {
    text: FILTER_INTERVALS.all.text,
    value: FILTER_INTERVALS.all.value,
  },
  ...DATE_FILTER_INTERVALS_DEFAULT
];

export const DEFAULT_INTERVALS = 'default';
export const INTERVALS_TYPE_1 = 'type1';

const DATE_INTERVALS_TYPE = {
  [DEFAULT_INTERVALS]: DATE_FILTER_INTERVALS_DEFAULT.map(({ text, value }) => ({
    text,
    value,
  })),
  [INTERVALS_TYPE_1]: DATE_FILTERS_INTERVALS_WITH_ALL.map(({ text, value }) => ({
    text,
    value,
  })),
};

export const QueryStateStartAndEndDate = ({
  startDateParam = START_DATE_PARAM,
  endDateParam = END_DATE_PARAM,
  id,
}: {
  startDateParam?: 'startDate' | 'startDateFrom';
  endDateParam?: 'endDate' | 'startDateTo';
  id: string;
}) => {
  const { pendingQueryState, setQueryState } = useQueryState();
  const onChange = (date: Date, start: boolean) => {
    setQueryState(start ? startDateParam : endDateParam, date || '', true);
  };

  const startDateOnChange = (date: Date) => {
    onChange(date, true);
  };

  const endDateOnChange = (date: Date) => {
    onChange(date, false);
  };

  return (
    <div style={{ margin: '10px 0px' }}>
      <FilterDatePicker
        id={id + startDateParam}
        placeholder="Start date"
        selected={pendingQueryState[startDateParam]}
        onChange={startDateOnChange}
        position="right-start"
        showTimeSelect
        shouldCloseOnSelect
      />
      <FilterDatePicker
        id={id + endDateParam}
        placeholder="End date"
        selected={pendingQueryState[endDateParam]}
        onChange={endDateOnChange}
        minDate={pendingQueryState[startDateParam]}
        position="right-start"
        showTimeSelect
        shouldCloseOnSelect
      />
    </div>
  );
};

export const QueryStateFilterByPeriod = memo(({
  id,
  defaultValue = '',
  intervalsOptionsType = DEFAULT_INTERVALS
}: {
  id: string;
  defaultValue?: string;
  intervalsOptionsType?: string;
}) => {
  const { queryState, setQueryStateWithParams } = useQueryState();

  function onChange (
    _e: SyntheticEvent,
    { value }
  ) {
    const newQueryState = {
      [PERIOD_FILTER]: value,
    };

    if (value !== FILTER_INTERVALS.custom.value) {
      newQueryState[START_DATE_PARAM] = '';
      newQueryState[END_DATE_PARAM] = '';
    }

    setQueryStateWithParams(newQueryState);
  }

  return (
    <>
      <Dropdown
        id={id}
        selection
        placeholder="By period"
        clearable={!!queryState.period && queryState.period !== defaultValue}
        fluid
        value={queryState.period || defaultValue}
        options={getOptionsWithIds(DATE_INTERVALS_TYPE[intervalsOptionsType], id)}
        onChange={onChange}
      />

      {queryState.period === FILTER_INTERVALS.custom.value && (
        <QueryStateStartAndEndDate id={id} />
      )}
    </>
  );
});
