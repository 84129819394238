import React from 'react';

import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

import './HeaderMenu.scss';
import {
  EVENT_TREE_PATH,
  LIMIT_GROUPS_PATH,
  MARKET_TYPE_OVERRIDE_PATH,
  MULTIPLES_PATH,
  NEW_LEAGUES_PATH,
  ORIGIN_SETTINGS_PATH,
  SELECTION_PATH,
  SINGLES_PATH
} from '../../constants';

const RISK_ITEMS = [
  [ 'Selection', SELECTION_PATH, 'navbar-selection' ],
  [ 'Singles', SINGLES_PATH, 'navbar-singles' ],
  [ 'Multiples', MULTIPLES_PATH, 'navbar-multiples' ]
];

const UTILITIES_ITEMS = [
  [ 'Origin Settings', ORIGIN_SETTINGS_PATH, 'navbar-origin-settings' ],
  [ 'Limit Groups', LIMIT_GROUPS_PATH, 'navbar-limit-groups' ],
  [ 'Event Paths Tree Editor', EVENT_TREE_PATH, 'navbar-event-paths-tree' ],
  [ 'New Leagues', NEW_LEAGUES_PATH, 'navbar-new-leagues' ],
  [ 'Market type enablement', MARKET_TYPE_OVERRIDE_PATH, 'navbar-market-type-enablement' ]
];

export const HeaderMenu = React.memo(
  ({
    username,
    logout,
    enableUtilities,
  }: {
    username: string;
    logout: () => void;
    enableUtilities: boolean;
  }) => {
    return (
      <Menu fixed="top" inverted className="header-layout-menu">
        <Menu.Item
          id="navbar-home"
          as={Link}
          icon="home"
          to="/"
        />
        <Menu.Item
          id="navbar-events"
          as={Link}
          name="Events"
          to="/events"
        />
        <Menu.Item
          id="navbar-new-bets"
          as={Link}
          name="Bets"
          to="/new-bets"
        />
        {/*TODO In future delete old bets tab and code*/}
        {/*<Menu.Item*/}
        {/*  id="navbar-bets"*/}
        {/*  as={Link}*/}
        {/*  name="Bets"*/}
        {/*  to="/bets"*/}
        {/*/>*/}
        <MenuDropdown
          name="Reports"
          id="navbar-reports"
          items={[[ 'Settled By Players', '/settled-by-players', 'navbar-settled-by-players' ]]}
        />

        <MenuDropdown
          name="Risk"
          id="navbar-risk"
          items={RISK_ITEMS}
        />

        <Menu.Menu position="right">
          {enableUtilities && (
            <MenuDropdown
              name="Utilities"
              id="navbar-utilites"
              items={UTILITIES_ITEMS}
            />
          )}
          <Dropdown item text={`User: ${username}`}>
            <Dropdown.Menu className="header-layout-menu-dropdown">
              <Dropdown.Item onClick={logout} id="navbar-logout">
                <span className="header-layout-menu-dropdown__span">
                  Logout
                </span>
              </Dropdown.Item>
              <Dropdown.Item disabled>
                <span className="header-layout-menu-dropdown__span">
                  Version {process.env.REACT_APP_BUILD}
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }
);

const MenuDropdown = ({
  items,
  name,
  id,
}:{
  items: Array<Array<string>>;
  name: string;
  id: string;
}) => {
  return (
    <Dropdown id={id} item text={name}>
      <Dropdown.Menu className="header-layout-menu-dropdown">
        {items?.map(([ label, to, id ]) => (
          <Dropdown.Item
            key={label}
            as={Link}
            to={to}
            id={id}
          >
            <span className="header-layout-menu-dropdown__span">
              {label}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
