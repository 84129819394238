import { memo, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { isEqual } from 'lodash';
import {
  Accordion,
  Checkbox,
  Icon,
  Menu,
  List,
  Input,
  Button,
  Dropdown
} from 'semantic-ui-react';

import { createOriginsBlock, getOriginsDescriptions } from './utils';
import { ORIGIN_DEFAULT_ID, ORIGIN_IDS_FILTER } from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { useIsQueryStateClear, useQueryState } from '../../hooks-and-global-states/history-hooks';
import { useOutsideClick } from '../../hooks-and-global-states/hooks';
import { OriginType } from '../../types';
import { Loading } from '../UI/Loading';

import './OriginsAccordion.scss';

export const OriginsAccordion = memo(({
  horizontal = false,
  multiChoice = true,
  disable,
  withSubOrigins = true,
  withDefaultOrigin = false,
  className,
  icon,
  parentDomId,
  highlightChangedOrigin,
}:{
  horizontal?: boolean;
  multiChoice?: boolean;
  disable?: boolean;
  withSubOrigins?: boolean;
  withDefaultOrigin?: boolean;
  className?: string;
  icon?: string;
  parentDomId: string;
  highlightChangedOrigin?: boolean,
}) => {
  const { originsList } = useGlobalStateContext();

  if (!originsList?.length) return <Loading />;

  return (
    <OriginsAccordionComponent
      originsData={originsList}
      horizontal={horizontal}
      multiChoice={multiChoice}
      disable={disable}
      withSubOrigins={withSubOrigins}
      withDefaultOrigin={withDefaultOrigin}
      className={className}
      icon={icon}
      parentDomId={parentDomId}
      highlightChangedOrigin={highlightChangedOrigin}
    />
  );
});

const OriginsAccordionComponent = ({
  originsData,
  horizontal,
  multiChoice,
  disable,
  withSubOrigins,
  withDefaultOrigin,
  className,
  icon,
  parentDomId,
  highlightChangedOrigin,
}:{
  originsData: OriginType[];
  horizontal?: boolean;
  multiChoice?: boolean;
  disable?: boolean;
  withSubOrigins?: boolean;
  withDefaultOrigin?: boolean;
  className?: string;
  icon?: string;
  parentDomId: string;
  highlightChangedOrigin?: boolean,
}) => {
  const { queryState, setQueryState } = useQueryState();
  const { setOriginIds, originId, changeOriginFn } = useGlobalStateContext();
  const isQueryStateClear = useIsQueryStateClear();

  const accRef = useRef();
  useOutsideClick(accRef, closeAccordion);

  const filterOrigins = useMemo(() => (
    createOriginsBlock(originsData, withDefaultOrigin)
  ),[ originsData, withDefaultOrigin ]);

  const [ localActiveOrigins, setLocalActiveOrigins ] = useState(getOriginsInitialState);
  const [ isTopAccordionOpen, setIsTopAccordionOpen ] = useState(false);
  const [ accordionActiveIndexes, setAccordionActiveIndexes ] = useState([]);

  const isAllOriginsIncludes = withDefaultOrigin
    ? originsData.every(el => localActiveOrigins?.includes(el.id))
    : originsData
      .filter(origin => Number(origin.id) !== ORIGIN_DEFAULT_ID)
      .every(el => localActiveOrigins?.includes(el.id));

  const originsDescriptions = getOriginsDescriptions(
    localActiveOrigins, originsData, isAllOriginsIncludes);
  const isRequestBtnDisable =
    isEqual(localActiveOrigins, queryState.originIds) || !localActiveOrigins?.length;

  const wrapClasses = cx(
    'origins-accordion-wrapper',
    className,
    horizontal && 'origins-accordion-wrapper_horizontal',
    !multiChoice && 'origins-accordion-wrapper_compact'
  );

  const singleChoiceDropdownOptions = useMemo(() => {
    return filterOrigins.map((collection, index) => ({
      key: index,
      text: `${collection.description || '-'} (${collection?.list[0]?.id || '-'})`,
      value: collection?.list[0]?.id || index,
      content: (
        <AccordionsCollection
          key={collection.description + collection?.list[0]?.id || index}
          parentDomId={parentDomId + 'origin-' + collection?.list[0]?.id + '--'}
          collection={collection}
          originGroupIndex={index}
          localActiveOrigins={localActiveOrigins}
          accordionActiveIndexes={accordionActiveIndexes}
          setAccordionActiveIndexes={setAccordionActiveIndexes}
          setLocalActiveOrigins={setLocalActiveOrigins}
          multiChoice={multiChoice}
          withSubOrigins={withSubOrigins}
          highlightOrigin={highlightChangedOrigin && collection.list[0]?.modifiedLimitGroups}
        />
      ),
    }));
  },[
    accordionActiveIndexes,
    filterOrigins,
    localActiveOrigins,
    multiChoice,
    parentDomId,
    withSubOrigins,
    highlightChangedOrigin
  ]);

  useEffect(() => {
    if (isQueryStateClear) clearAccordion();
    //eslint-disable-next-line
  }, [isQueryStateClear])

  function showOriginsList () {
    setIsTopAccordionOpen(prev => !prev);
  }

  function onChangeShowAllOriginsCheckbox () {
    if (isAllOriginsIncludes) {
      setLocalActiveOrigins([]);
    } else {
      setLocalActiveOrigins(() => originsData.map(el => el.id));
      setQueryState(ORIGIN_IDS_FILTER, '');
      setOriginIds(() => null);
    }
  }

  function saveOriginsToGlobalState () {
    setQueryState(ORIGIN_IDS_FILTER, localActiveOrigins);
    setOriginIds(() => localActiveOrigins);
  }

  function clearAccordion () {
    setIsTopAccordionOpen(false);
    setAccordionActiveIndexes([]);
    setLocalActiveOrigins(() => originsData.map(el => el.id));
  }

  function closeAccordion () {
    setIsTopAccordionOpen(false);
    setAccordionActiveIndexes([]);
  }


  function getOriginsInitialState () {
    if (multiChoice) {
      const updatedList = withDefaultOrigin
        ? originsData.map(el => el.id)
        : originsData
          .map(el => el.id)
          .filter(origin => Number(origin) !== ORIGIN_DEFAULT_ID);

      const queryOrigins = typeof queryState.originIds === 'string'
        ? [ queryState.originIds ]
        : queryState.originIds;
      return queryOrigins ?? updatedList;
    }
    const initialOrigin = queryState.originId ?? originId;
    const changeQueryState = !!queryState.originId;
    if (filterOrigins.find(origin => String(origin.parentId) === initialOrigin)) {
      changeOriginFn(initialOrigin, changeQueryState);
      return [ initialOrigin ];
    } else {
      const newOrigin = String(filterOrigins[0].parentId);
      changeOriginFn(newOrigin, changeQueryState);
      return [ newOrigin ];
    }
  }

  function onChangeSingleChoiceDropdown (_e: any, data: any) {
    const parentOriginId = String(data.value);
    setLocalActiveOrigins(() => [ parentOriginId ]);
    changeOriginFn(parentOriginId, true);
  }

  function onCloseSingleChoiceDropdown () {
    /** Close inside open accordions */
    setAccordionActiveIndexes([]);
  }

  return (
    <div className={wrapClasses}>
      {multiChoice ? (
        <>
          <Input className="origins-accordion__input" disabled value={originsDescriptions} />
          <List horizontal>
            <List.Item>
              <div ref={accRef}>
                <Accordion className="origins-top-accordion" exclusive={false}>
                  <Accordion.Title className="origins-top-accordion__title" active={isTopAccordionOpen}>
                    <Icon
                      id={parentDomId + 'show-origins-list-btn'}
                      name="dropdown"
                      onClick={showOriginsList}
                    />
                    <Checkbox
                      id={parentDomId + 'show-all-origins-checkbox'}
                      label="Show all origins"
                      checked={isAllOriginsIncludes}
                      onChange={onChangeShowAllOriginsCheckbox}
                    />
                    <div className="origins-top-accordion__title-button-wrapper">
                      {!isAllOriginsIncludes && (
                        <Button
                          id={parentDomId + 'save-origins-btn'}
                          icon
                          size="small"
                          disabled={isRequestBtnDisable}
                          onClick={saveOriginsToGlobalState}
                          className="origins-top-accordion__title-button"
                        >
                          <Icon name="save" />
                        </Button>
                      )}
                    </div>
                  </Accordion.Title>

                  <Accordion.Content className="origins-accordion" active={isTopAccordionOpen}>
                    <Accordion.Accordion exclusive={false} as={Menu} vertical>
                      {filterOrigins.map((collection, index) => (
                        <AccordionsCollection
                          key={collection.description + collection?.list[0]?.id || index}
                          parentDomId={parentDomId + 'origin-' + collection?.list[0]?.id + '--'}
                          collection={collection}
                          originGroupIndex={index}
                          localActiveOrigins={localActiveOrigins}
                          accordionActiveIndexes={accordionActiveIndexes}
                          setAccordionActiveIndexes={setAccordionActiveIndexes}
                          setLocalActiveOrigins={setLocalActiveOrigins}
                          multiChoice={multiChoice}
                          withSubOrigins={withSubOrigins}
                          highlightChangedOrigin={highlightChangedOrigin}
                        />
                      ))}
                    </Accordion.Accordion>
                  </Accordion.Content>
                </Accordion>
              </div>
            </List.Item>
          </List>
        </>
      ) : (
        <Dropdown
          id={parentDomId + 'origin-dropdown'}
          className={`origins-accordion__input ${icon ? 'icon' : ''} searching-dropdown`}
          selection
          fluid
          onChange={onChangeSingleChoiceDropdown}
          onClose={onCloseSingleChoiceDropdown}
          value={originId}
          options={singleChoiceDropdownOptions}
          disabled={disable}
          icon={icon}
          labeled={!!icon}
          button={!!icon}
          search
        />
      )}
    </div>
  );
};

const AccordionsCollection = ({
  collection,
  originGroupIndex,
  localActiveOrigins,
  accordionActiveIndexes,
  setAccordionActiveIndexes,
  setLocalActiveOrigins,
  multiChoice,
  withSubOrigins,
  parentDomId,
  highlightOrigin,
}:{
  collection: {
    description: string,
    parentId: string,
    list: OriginType[]
  };
  originGroupIndex: number;
  localActiveOrigins: string[];
  accordionActiveIndexes: number[];
  setAccordionActiveIndexes: (arr: (prev: string[]) => any | string[]) => void;
  setLocalActiveOrigins: (arr: (prev: string[]) => any | string[]) => void;
  multiChoice?: boolean;
  withSubOrigins?: boolean;
  parentDomId: string;
  highlightOrigin?: boolean;
}) => {
  const { queryState } = useQueryState();

  const categoriesIds = collection?.list?.map(origin => origin.id);
  const parentOriginId = collection?.list[0]?.id;
  const collectionLabel = `${collection.description || '-'} (${parentOriginId || '-'})`;

  const isAllCategoriesIdsIncludes = categoriesIds.every(el => localActiveOrigins?.includes(el));
  const isAllCategoriesIdsIncludesInQueryState = categoriesIds.every(el =>
    queryState?.originIds?.includes(el) || !queryState.originIds
  );
  const isParentOriginInclude = localActiveOrigins?.includes(parentOriginId);
  const isOriginsGroupChecked = multiChoice ? isAllCategoriesIdsIncludes : isParentOriginInclude;
  const isOneItemIncludesInQueryState = categoriesIds?.some(el => queryState?.originIds?.includes(el));

  const LayoutComponent = multiChoice ? Menu.Item  : Accordion;

  const className = cx({
    'origins-top-accordion__item_active' : multiChoice && isAllCategoriesIdsIncludesInQueryState,
    'origins-top-accordion__item_active-light' : multiChoice && isOneItemIncludesInQueryState &&
      !isAllCategoriesIdsIncludesInQueryState,
  });

  function onClickAccordionSection (e: any, data: any) {
    e.stopPropagation();
    if (accordionActiveIndexes?.includes(data.index)) {
      setAccordionActiveIndexes(prev => prev.filter(el => el !== data.index));
    } else {
      setAccordionActiveIndexes(prev => [ ...prev, data.index ]);
    }
  }

  function changeOrigin (_e: any, data: any) {
    if (localActiveOrigins?.includes(data.value)) {
      setLocalActiveOrigins(prev => prev.filter(el => el !== data.value).sort());
    } else {
      setLocalActiveOrigins(prev => [ ...prev, data.value ].sort());
    }
  }

  function addOriginsGroup () {
    if (isAllCategoriesIdsIncludes) {
      setLocalActiveOrigins(prev => prev.filter(el => categoriesIds.indexOf(el) < 0).sort());
    } else {
      setLocalActiveOrigins( prev =>
        prev
          .concat(categoriesIds)
          .filter((value, index, array) => array.indexOf(value) === index)
          .sort()
      );
    }
  }

  return (
    <LayoutComponent className={className}>
      <Accordion.Title
        active={accordionActiveIndexes?.includes(originGroupIndex)}
        index={originGroupIndex}
        className="origins-accordion__title"
      >
        {highlightOrigin && (
          <div className="origins-accordion__circle"/>
        )}
        {withSubOrigins && collection?.list?.length > 1 && (
          <Button
            id={parentDomId + 'show-sub-origins-btn'}
            index={originGroupIndex}
            onClick={onClickAccordionSection}
            icon="dropdown"
            compact
            circular
          />
        )}
        {multiChoice ? (
          <Checkbox
            id={parentDomId + 'sub-origins-collection-checkbox'}
            className="dropdown-checkbox"
            label={collectionLabel}
            checked={isOriginsGroupChecked}
            onChange={addOriginsGroup}
          />
        ) : (
          <p className="dropdown-checkbox">{collectionLabel}</p>
        )}
      </Accordion.Title>

      {withSubOrigins && (
        <Accordion.Content active={accordionActiveIndexes?.includes(originGroupIndex)}>
          {!!collection?.list?.length &&
            collection.list.map(origin => {
              const isOriginIncludesInLocalState = localActiveOrigins?.includes(origin.id);
              const checkboxLabel = `${origin.description || '-'} (${origin.id || '-'})`;
              return (
                <div key={origin.id} className="origins-accordion__item">
                  {multiChoice ? (
                    <Checkbox
                      key={origin.id}
                      id={parentDomId + `sub-origin-${origin.id}-checkbox`}
                      label={checkboxLabel}
                      checked={isOriginIncludesInLocalState}
                      value={origin.id}
                      onChange={changeOrigin}
                    />
                  ) : (
                    <p key={origin.id}>{checkboxLabel}</p>
                  )}
                </div>
              );
            })}
        </Accordion.Content>
      )}
    </LayoutComponent>
  );
};
