import {
  HORIZONTAL_POSITION_SIZE, IRM_NULL_PARENT_ID,
  MAX_MARKETS_BLOCK_SIZE,
  MIN_MARKETS_BLOCK_SIZE,
  MIN_SCOREBOARD_SIZE,
  PADDING_SIZE
} from '../../../constants';
import {
  ModalPeriodsTreeNodeType,
  PeriodScoresType,
  PeriodsScoresTreeNodeType,
  PeriodsTreeNodeType,
  PeriodType
} from '../../../types';

export const getActiveRowId = (scores, nodeStatuses, currentActivePeriod) => {
  const activePeriod = scores.periodScores.find(
    period =>
      period.isActive &&
      ((currentActivePeriod && currentActivePeriod.selectedPeriod === period.periodId) ||
        !(period.periodId in nodeStatuses))
  );
  return activePeriod && activePeriod.periodId;
};

export const getHeadPeriodObj = periodList => {
  const inRunningKey = 'inRunning';
  if (inRunningKey in periodList[0]) {
    return periodList.find(period =>
      period.parentId === IRM_NULL_PARENT_ID && period[inRunningKey] === true
    );
  } else {
    return periodList.find(period => period.parentPeriodId === IRM_NULL_PARENT_ID);
  }
};

export const getPeriodsTree = (
  currentPeriodId: string,
  periodsList: Array<PeriodScoresType | PeriodType>,
  prevTreeObj?: PeriodsTreeNodeType | PeriodsScoresTreeNodeType
) => {
  let periodId, children;

  if (periodsList.length === 0) {
    return undefined;
  }

  if ('parentPeriodId' in periodsList[0]) {
    periodId = 'periodId';
    children = periodsList.filter(period => period['parentPeriodId'] === currentPeriodId);
  } else {
    periodId = 'id';
    children = periodsList.filter(
      period => period['parentId'] === currentPeriodId && period['inRunning'] === true
    );
  }

  const treeObj = {
    node: periodsList.find(period => period[periodId] === currentPeriodId),
    children: undefined,
  };

  if (children.length !== 0) {
    treeObj.children = children.map(child =>
      getPeriodsTree(child[periodId], periodsList, prevTreeObj)
    );
  }
  return treeObj;
};

export const getModalPeriodState = (
  nodeObj: ModalPeriodsTreeNodeType,
  periodId: string,
  attrToCheck: string
) => {
  if (nodeObj.node.id === periodId) {
    return nodeObj[attrToCheck];
  } else if (nodeObj.children) {
    for (const child of nodeObj.children) {
      const status = getModalPeriodState(child, periodId, attrToCheck);
      if (status !== undefined) {
        return status;
      }
    }
  } else {
    return;
  }
};

export const getTreeWithPresetValues = (
  periodsList: Array<string>,
  currentTreeNode: ModalPeriodsTreeNodeType
) => {
  currentTreeNode.checked = periodsList?.includes(currentTreeNode.node.id);
  if (currentTreeNode.children) {
    currentTreeNode.children = currentTreeNode.children.map(child =>
      getTreeWithPresetValues(periodsList, child)
    );
    currentTreeNode.opened = !!currentTreeNode.children.find(node => {
      return node.checked || node.opened;
    });
  }
  return currentTreeNode;
};

export const getMarketsForPresetRequest = selectedMarketsState => {
  return selectedMarketsState.markets.map(market => {
    return {
      periodId: market.periodId,
      marketTypeId: market.marketId,
    };
  });
};

export const getMarket = (id, markets) => {
  return markets.find(market => {
    return market.market.id === id || market.outcomes.find(outcome => outcome.outcome.id === id);
  });
};

export const getWidths = scoreboardState => {
  let scoreboardWidth, marketsTableWidth;

  if (window.innerWidth > HORIZONTAL_POSITION_SIZE && !scoreboardState.hide) {
    scoreboardWidth = MIN_SCOREBOARD_SIZE;
    const scoreboardWithPaddingSize = MIN_SCOREBOARD_SIZE + PADDING_SIZE * 8;
    marketsTableWidth =
      window.innerWidth - scoreboardWithPaddingSize < MAX_MARKETS_BLOCK_SIZE
        ? window.innerWidth - scoreboardWithPaddingSize
        : MAX_MARKETS_BLOCK_SIZE;
  } else {
    let blocksWidth;
    if (window.innerWidth > MAX_MARKETS_BLOCK_SIZE + PADDING_SIZE * 2) {
      blocksWidth = MAX_MARKETS_BLOCK_SIZE;
    } else if (window.innerWidth < MIN_MARKETS_BLOCK_SIZE + PADDING_SIZE * 2) {
      blocksWidth = MIN_MARKETS_BLOCK_SIZE;
    } else {
      blocksWidth = window.innerWidth - PADDING_SIZE * 2;
    }
    scoreboardWidth = blocksWidth;
    marketsTableWidth = blocksWidth;
  }

  return [ scoreboardWidth, marketsTableWidth ];
};
