import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import Axios from 'axios';
import Cookies from 'js-cookie';

import { useQueryState } from './history-hooks';
import { FullPageLoading } from '../components/UI/Loading';
import {
  API_ORIGIN_LIST,
  ORIGIN_COOKIE_KEY,
  ORIGIN_ID_DEFAULT,
  ORIGIN_ID_FILTER,
  TRADER_PERM_ALL,
  TRADER_PERM_UTILITIES
} from '../constants';
import { OriginType, UserPermissions } from '../types';

export const GlobalStateContext = createContext<{
  originId: string;
  originIds: string[];
  currentOrigin: OriginType;
  setOriginIds: (arr: (prev) => any | string[]) => void;
  setOriginId: Dispatch<SetStateAction<string>>;
  isAllOrigins: boolean;
  setIsAllOrigins: Dispatch<SetStateAction<boolean>>;
  originIdAllParam: string | undefined;
  isDefaultOrigin: boolean;
  setIsDefaultOrigin: Dispatch<SetStateAction<boolean>>;
  permissions: UserPermissions;
  originsList: Array<OriginType>;
  limitGroups: {
    limitsOrigin: string;
    limits: Array<any>;
    isFetching: boolean;
  };
  setLimitGroups: Dispatch<SetStateAction<{
    limitsOrigin: string;
    limits: any[];
    isFetching: boolean;
  }>>;
  updateOriginsList: () => void;
  changeOriginFn: (origin: number | string, everywhere?: boolean) => void;
}>(null);

const INITIAL_STATE = {
  originId: '',
  originIds: [],
  isAllOrigins: true,
  isDefaultOrigin: false,
  isFetching: true,
  playerFactorGroups: [],
  originList: [],
  limitGroups: {
    limitsOrigin: '',
    limits: [],
    isFetching: false,
  }
};

export const GlobalStateProvider = ({
  children,
  permissions,
}: {
  children: ReactNode;
  permissions: UserPermissions;
}) => {
  const { queryState, setQueryState } = useQueryState();
  const [ isFirstFetching, setIsFirstFetching ] = useState(INITIAL_STATE.isFetching);

  /* =================== STATES =================== */

  const [ originId, setOriginId ] = useState<string>(INITIAL_STATE.originId) ;
  const [ originIds, setOriginIds ] = useState(INITIAL_STATE.originIds);
  const [ isAllOrigins, setIsAllOrigins ] = useState(INITIAL_STATE.isAllOrigins);
  const [ isDefaultOrigin, setIsDefaultOrigin ] = useState(INITIAL_STATE.isDefaultOrigin);
  const [ originList, setOriginList ] = useState(INITIAL_STATE.originList);
  const [ limitGroups, setLimitGroups ] = useState(INITIAL_STATE.limitGroups);

  /* =================== FUNCTIONS =================== */

  const changeOriginFn = useCallback((newOrigin: string | number, everywhere?: boolean) => {
    const newOriginString = String(newOrigin);

    Cookies.set(ORIGIN_COOKIE_KEY, newOriginString, { expires: 365 });
    setOriginId(newOriginString);
    setOriginIds([ newOriginString ]);

    if (everywhere) {
      setQueryState(ORIGIN_ID_FILTER, newOriginString);
    }

  },[ setQueryState ]);

  /* =================== EFFECTS =================== */

  /** UseEffect for fetch origin list. Start when app is start */
  useEffect(() => {
    const fetch = async () => {
      //TODO change request to TSQ
      const originsIdsArr: string[] = await Axios(API_ORIGIN_LIST)
        .then(res => res.data)
        .then(data => data.result)
        .then(res => {
          setOriginList(res);
          return res.map(item => item.id);
        });

      setIsFirstFetching(false);

      const cookieOrigin = Cookies.get(ORIGIN_COOKIE_KEY);

      /** 1. Check origin id in query state */
      if (originsIdsArr.includes(queryState.originId)) {
        setOriginId(queryState.originId);
        setOriginIds([ queryState.originId ]);
        Cookies.set(ORIGIN_COOKIE_KEY, queryState.originId);
        return;
      }

      /** 2. If in origin list no origin from query state, get origin from cookie */
      if (originsIdsArr.includes(cookieOrigin)) {
        setOriginId(cookieOrigin);
        setOriginIds([ cookieOrigin ]);
        return;
      }

      /** 3. If no cookie origin, use default origin id = 1 */
      setOriginId(ORIGIN_ID_DEFAULT);
      setOriginIds([ ORIGIN_ID_DEFAULT ]);
      Cookies.set(ORIGIN_COOKIE_KEY, ORIGIN_ID_DEFAULT);
      return;
    };

    fetch();
  // eslint-disable-next-line
  },[]);

  /** UseEffect for check, default origin in global state or no */
  useEffect(() => {
    for (const origin of originList) {
      const { id, defaultOrigin } = origin;
      if (id === originId) {
        setIsDefaultOrigin(defaultOrigin);
      }
    }
  },[ originId, originList ]);

  /* =================== VALUE =================== */

  const value = useMemo(() => {
    /** Only for developer local build */
    const isDev = false;
    /** ============================== */

    function updateOriginsList ()  {
      Axios(API_ORIGIN_LIST)
        .then(res => res.data)
        .then(data => data.result)
        .then(res => setOriginList(res));
    }

    const allPermissions = {
      ...permissions,
      allowPermissions: [ TRADER_PERM_ALL, TRADER_PERM_UTILITIES ],
    };

    return {
      originId,
      currentOrigin: originList.find(item => item.id === originId),
      originIdAllParam: isAllOrigins ? undefined : originId,
      setOriginId,
      isAllOrigins,
      setIsAllOrigins,
      isDefaultOrigin,
      setIsDefaultOrigin,
      permissions: isDev ? allPermissions : permissions,
      originIds,
      setOriginIds,
      originsList: originList,
      limitGroups,
      setLimitGroups,
      updateOriginsList,
      changeOriginFn,
    };
  }, [
    originId,
    isAllOrigins,
    isDefaultOrigin,
    permissions,
    originIds,
    originList,
    limitGroups,
    setLimitGroups,
    changeOriginFn
  ]);

  if (isFirstFetching) {
    return <FullPageLoading />;
  }

  return <GlobalStateContext.Provider value={value}>{children}</GlobalStateContext.Provider>;
};

export const useGlobalStateContext = () => useContext(GlobalStateContext);
