import { FC } from 'react';

import { Dropdown } from 'semantic-ui-react';

import { useSportsFind } from '../../react-query/query';
import { replaceId } from '../../utils';

interface ISportDropdown {
  value: string | string[];
  onChange: (arg0: string | string[]) => void;
  fluid?: boolean;
  placeholder?: string;
  id: string;
  multiple?: boolean;
}

export const SportsDropdown: FC<ISportDropdown> = ({
  value,
  onChange,
  fluid,
  placeholder = 'Sport',
  id,
  multiple = false,
}) => {
  const { data, isFetching } = useSportsFind();

  const options = data?.map((sport: any) => ({
    text: sport.description,
    value: sport.id,
    key: sport.id,
    id: id + '--' + replaceId(sport.id) + '-option'
  })) || [];

  function dropdownChange (_e: any, { value }) {
    const newValue = multiple ? [ ...value ] : String(value);
    onChange(newValue);
  }

  return (
    <Dropdown
      id={id || 'sport-dropdown'}
      placeholder={placeholder}
      search
      selection
      clearable
      scrolling
      fluid={fluid}
      options={options}
      onChange={dropdownChange}
      value={value || ''}
      selectOnBlur={false}
      loading={isFetching}
      multiple={multiple}
    />
  );
};
