import { ReactNode, useMemo, useState } from 'react';

import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid, List, Dropdown, Header, Checkbox } from 'semantic-ui-react';

import { MarketAdd } from './MarketAdd';
import { MarketsTable } from './MarketsTable/MarketsTable';
import { OriginsAccordion } from '../../../components/form-components/OriginsAccordion';
import {
  MARKET_STATUSES_UPDATE_DATA,
  MARKET_STATUSES_FILTER,
  BOOK_TYPES_ENUM,
  OutcomeResultsEnum,
  TRADER_PERM_EVENT_EDITOR,
  TRADER_PERM_ALL,
  ORIGIN_ID_FILTER
} from '../../../constants';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';
import { useQueryState } from '../../../hooks-and-global-states/history-hooks';
import { useMarketsUpdate } from '../../../react-query/mutations';
import { useMarkets, useVoidReasonList } from '../../../react-query/query';
import { EventOutletContextType, EventType, SingleByEventMarketType, VoidReason } from '../../../types';
import { getFiltersToRender, getOptionsForMarketFilters } from '../../../utils';
import { MarketsFilters } from '../MarketsFilters';
import '../../Risk/RiskManager.css';

const MarketsComponent = ({
  event,
  openSidebar,
  markets,
}: {
  event: EventType;
  openSidebar: (arg0: ReactNode) => void;
  markets: Array<SingleByEventMarketType>;
}) => {
  const { queryState, setQueryState } = useQueryState();
  const { originId, permissions, setIsAllOrigins, isAllOrigins } = useGlobalStateContext();
  const { data: voidReasons } = useVoidReasonList();

  const [ selectedMarkets, setSelectedMarkets ] = useState([]);

  const filtersToRender = getFiltersToRender(event);
  const enableEditing =
    !permissions?.denyPermissions?.includes(TRADER_PERM_EVENT_EDITOR) &&
    [ TRADER_PERM_EVENT_EDITOR, TRADER_PERM_ALL ].some(permission =>
      permissions?.allowPermissions?.includes(permission)
    );

  const queryStateMarketStatusesFilter = queryState[MARKET_STATUSES_FILTER];

  const changeStatusList = useMemo(() => {
    return MARKET_STATUSES_UPDATE_DATA.filter(([ status ]) => status !== queryStateMarketStatusesFilter);
  }, [ queryStateMarketStatusesFilter ]);

  const options = useMemo(() => {
    return getOptionsForMarketFilters(markets, filtersToRender);
  }, [ markets, filtersToRender ]);

  const mutMarketsUpdate = useMarketsUpdate({
    onSuccess: () => {
      setSelectedMarkets([]);
      toast.success('Update successful');
    },
  });
  const actionOnClick = (value: string) => {
    mutMarketsUpdate.mutate({
      originId,
      markets: selectedMarkets.map(m => ({
        id: m.market.id,
        manualMode: true,
        status: value,
      })),
    });
  };

  const hideMarketsOnClick = (hideMode: boolean) => {
    mutMarketsUpdate.mutate({
      originId,
      markets: selectedMarkets.map(m => ({ id: m.market.id, hideMode })),
    });
  };

  const irmActiveOnClick = (showInIRM: boolean) => {
    mutMarketsUpdate.mutate({
      originId,
      markets: selectedMarkets
        .filter(m => m.market.bookType !== BOOK_TYPES_ENUM.BOOKTYPE_PRE_MATCH)
        .map(m => ({ id: m.market.id, showInIRM })),
    });
  };

  const voidReasonOnClick = (value: VoidReason) => {
    mutMarketsUpdate.mutate({
      originId,
      markets: selectedMarkets.map(m => ({
        id: m.market.id,
        manualMode: true,
        outcomes: m.outcomes.map(({ outcome }) => ({
          id: outcome.id,
          manualMode: true,
          result: OutcomeResultsEnum.VOID,
          voidReason: value,
        })),
      })),
    });
  };

  const disableManualModeOnClick = () => {
    mutMarketsUpdate.mutate({
      originId,
      markets: selectedMarkets.map(m => ({
        id: m.market.id,
        manualMode: false,
      })),
    });
  };

  function showAllOrigins () {
    const newOrigin = !isAllOrigins ? undefined : originId;
    setQueryState(ORIGIN_ID_FILTER, newOrigin);
    setIsAllOrigins(v => !v);
  }

  return (
    <Grid columns="equal">
      <Grid.Column width="3">
        <List>
          <List.Item>
            <Checkbox
              id="event-edit-markets-tab-show-all-origins"
              label="Show all origins"
              checked={isAllOrigins}
              onChange={showAllOrigins}
              style={{ marginBottom: 10 }}
            />
            <OriginsAccordion
              parentDomId="event-edit-markets-tab-origins-filter"
              multiChoice={false}
              withSubOrigins={false}
              disable={isAllOrigins}
              icon="filter"
            />
          </List.Item>
          {filtersToRender.map(name => (
            <List.Item key={name}>
              <Header as="h5">{MarketsFilters[name].title}</Header>
              {MarketsFilters[name].render(options[name], 'event-edit-markets-tab-')}
            </List.Item>
          ))}
          {enableEditing && (
            <>
              <List.Item>
                <Dropdown
                  id="event-edit-markets-tab-actions-dropdown"
                  labeled
                  fluid
                  button
                  className="icon"
                  disabled={!selectedMarkets.length}
                  text={`Actions (${selectedMarkets.length} selected)`}
                >
                  <Dropdown.Menu>
                    {changeStatusList.map(([ value, , , action ]) => {
                      return (
                        <Dropdown.Item
                          key={String(value)}
                          id={
                            `event-edit-markets-tab-actions-dropdown-${
                              String(action).toLowerCase()
                            }-action`
                          }
                          onClick={() => actionOnClick(value as string)}
                          content={String(action)}
                        />
                      );
                    })}
                    <Dropdown.Item
                      id="event-edit-markets-tab-actions-dropdown-disable-manual-mode-action"
                      onClick={disableManualModeOnClick}
                      content="Disable manual mode"
                    />
                    <Dropdown.Item
                      id="event-edit-markets-tab-actions-dropdown-hide-markets-action"
                      onClick={() => hideMarketsOnClick(true)}
                      content="Hide markets"
                    />
                    <Dropdown.Item
                      id="event-edit-markets-tab-actions-dropdown-show-markets-action"
                      onClick={() => hideMarketsOnClick(false)}
                      content="Show markets"
                    />
                    {event.manualCreated &&
                      <>
                        <Dropdown.Item
                          id="event-edit-markets-tab-actions-dropdown-display-in-irm-action"
                          onClick={() => irmActiveOnClick(true)}
                          content="Display in IRM"
                        />
                        <Dropdown.Item
                          id="event-edit-markets-tab-actions-dropdown-remove-from-irm-action"
                          onClick={() => irmActiveOnClick(false)}
                          content="Remove from IRM"
                        />
                      </>
                    }
                    <Dropdown.Divider />
                    <Dropdown.Header content="Void" />
                    {voidReasons?.map(({ description, value }) => {
                      return (
                        <Dropdown.Item
                          key={value}
                          id={
                            `event-edit-markets-tab-actions-dropdown-${description.toLowerCase()}-action`
                          }
                          onClick={() => voidReasonOnClick(value)}
                          content={description}
                        />
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </List.Item>
              <List.Item>
                <MarketAdd id="event-edit-markets-tab-add-market-button"/>
              </List.Item>
            </>
          )}
        </List>
      </Grid.Column>
      <Grid.Column width="13">
        <MarketsTable
          openSidebar={openSidebar}
          selectedMarkets={selectedMarkets}
          setSelectedMarkets={setSelectedMarkets}
          marketsArray={markets}
        />
      </Grid.Column>
    </Grid>
  );
};

export const Markets = ({ openSidebar }: { openSidebar: (arg0: ReactNode) => void }) => {
  const { event } = useOutletContext<EventOutletContextType>();
  const { originIdAllParam } = useGlobalStateContext();
  const { data: markets } = useMarkets({
    params: { eventId: event.id, originId: originIdAllParam },
    refetchInterval: 5000,
    enabled: !!event?.id
  });

  return (
    <MarketsComponent
      key={event.id}
      openSidebar={openSidebar}
      markets={markets}
      event={event}
    />
  );
};
