import React from 'react';

import Axios from 'axios';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import { object, string, date, number, boolean } from 'yup';

import { EventCreateForm } from './EventCreateForm';
import { API_EVENT_CREATE, EVENT_EDIT_PATH, EVENT_TYPES_OPTIONS } from '../../constants';
import { isSportWithSetsCheck, isSportWithTiebreak, removeEmptyFields } from '../../utils';

const EventCreateSchema = object().shape({
  description: string().required(),
  sportId: string().required(),
  regionId: string().required(),
  leagueId: string().required(),
  type: string().required(),
  opponentHomeId: string()
    .nullable()
    .when('type', {
      is: eventType => eventType === 'GAME',
      then: string().required(),
    }),
  opponentAwayId: string()
    .nullable()
    .when('type', {
      is: eventType => eventType === 'GAME',
      then: string().required(),
    }),
  startDate: date().required(),
  tieBreak: string().when('sportId', {
    is: sportId => isSportWithTiebreak(sportId),
    then: string().required(),
  }),
  bestOfSets: number().when('sportId', {
    is: sportId => isSportWithSetsCheck(sportId),
    then: number().required(),
  }),
  noAdScoring: boolean(),
});

const DEFAULT_EVENT = {
  description: '',
  sportId: '',
  regionId: '',
  leagueId: '',
  opponentHomeId: '',
  opponentAwayId: '',
  type: EVENT_TYPES_OPTIONS[0].value,
  startDate: new Date(),
  tieBreak: undefined,
  bestOfSets: undefined,
  noAdScoring: false,
};

export const EventCreate = () => {
  const navigate = useNavigate();

  async function onSubmit (values, { setSubmitting, setFieldError }) {
    setSubmitting(true);
    try {
      const dataForRequest = removeEmptyFields({
        event: values,
      });
      const { data } = await Axios.post(API_EVENT_CREATE, dataForRequest);
      navigate(`${EVENT_EDIT_PATH}/${data.result.result.id}`);
    } catch (error) {
      setFieldError('submit', error.message);
      setSubmitting(false);
    }
  }

  return (
    <Segment basic>
      <Formik
        validationSchema={EventCreateSchema}
        onSubmit={onSubmit}
        initialValues={DEFAULT_EVENT}
      >
        {({
          setFieldValue,
          isValid,
          isSubmitting,
          values,
          handleSubmit,
          errors
        }) => (
          <EventCreateForm
            setFieldValue={setFieldValue}
            isValid={isValid}
            isSubmitting={isSubmitting}
            values={values}
            handleSubmit={handleSubmit}
            errors={errors}
          />
        )}
      </Formik>
    </Segment>
  );
};
