import React from 'react';

import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import { IrmEditableDescriptionComponent } from './IrmEditableDescriptionComponent';
import { IrmOutcomeResultSelector } from './IrmOutcomeResultSelector';
import { IrmPriceComponent } from './IrmPriceComponent';
import { FlexDiv } from '../../../components/FlexDiv';
import { Money } from '../../../components/table-components/Money';
import { OutcomeResultsEnum } from '../../../constants';
import { OutcomeSinglesByEventType } from '../../../types';
import { MarketPricesStateType, MarketWithPricesObjectType } from '../../../utils';
import { isNumberHelp } from '../helpers';
import { HideModeOutcomeIcon } from '../Markets/MarketsTable/HideModeOutcomeIcon';

import './IrmOutcomeRow.css';

export const IrmOutcomeRow = ({
  marketId,
  id,
  description,
  reload,
  canEditPrice,
  result,
  voidReason,
  handleOutcomeResultChange,
  hideModeFeed,
  hideModeTrader,
  editedPricesState,
  setEditedPricesState,
  eventMarginState,
  useMarginState,
  selectedOutcomeState,
  selectOutcome,
  marketOutcomes,
  marketPricesState,
  setMarketPricesState,
  setLocalOutcomes,
  localOutcomes,
  setActiveStatusState,
  liability,
  liabilityRiskSingle,
  betsCount,
  betsCountSingle,
  betsTotal,
  handleBetsClick,
  isSpecialMarket,
  sportId,
  parentDomId,
}: {
  marketId: string;
  id: string;
  description: string;
  reload: () => void;
  canEditPrice: boolean;
  result: keyof typeof OutcomeResultsEnum;
  voidReason: string;
  handleOutcomeResultChange: (payload: {
    id: string;
    result: keyof typeof OutcomeResultsEnum;
    voidReason?: string;
    setLocalOutcomes: (string: object) => void;
    localOutcomes: object;
  }) => object;
  hideModeFeed: boolean;
  hideModeTrader: boolean;
  editedPricesState?: Array<MarketWithPricesObjectType>;
  setEditedPricesState?: (editedPricesState: Array<MarketWithPricesObjectType>) => void;
  eventMarginState: number;
  useMarginState: boolean;
  selectedOutcomeState?: string;
  selectOutcome: (string) => void;
  marketOutcomes: Array<OutcomeSinglesByEventType>;
  marketPricesState?: MarketPricesStateType;
  setMarketPricesState?: (MarketPricesStateType) => void;
  setLocalOutcomes: (string: object) => void;
  localOutcomes: object;
  setActiveStatusState: (boolean) => void;
  liability: number;
  liabilityRiskSingle: number;
  betsCount: number;
  betsCountSingle: number;
  betsTotal: number;
  handleBetsClick: () => void;
  isSpecialMarket: boolean;
  sportId: string;
  parentDomId: string;
}) => {
  const [ outcomeDescription, setOutcomeDescription ] = React.useState(description);

  const outcomesLength = Object.keys(localOutcomes).length;

  const handleOutcomeResultChangeWithId = React.useCallback(({
    result,
    voidReason,
    localOutcomes,
    setLocalOutcomes,
  }) => {
    return handleOutcomeResultChange({
      id,
      result,
      voidReason,
      setLocalOutcomes,
      localOutcomes,
    });
  },[ id, handleOutcomeResultChange ]);

  let liabilityClassname = 'null-liability';
  if (liability < 0) {
    liabilityClassname = 'negative-liability';
  } else if (liability > 0) {
    liabilityClassname = 'positive-liability';
  }

  let liabilitySingleClassname = 'null-liability';
  if (+liabilityRiskSingle < 0) {
    liabilitySingleClassname = 'negative-liability';
  } else if (+liabilityRiskSingle > 0) {
    liabilitySingleClassname = 'positive-liability';
  }

  function descriptionOnChange (value) {
    const editingMarket = editedPricesState?.find(el => el.id === marketId) || { id: marketId };
    const outcomesList = editingMarket?.outcomes || [];

    const editingOutcome = outcomesList.find(outcome => outcome.id === id) || { id };
    editingOutcome.description = value;

    const updatedOutcomes = outcomesList.filter(el => el.id !== id);
    updatedOutcomes.push(editingOutcome);

    const updatedList = editedPricesState.filter(marketObj => marketObj.id !== marketId);
    updatedList.push({ ...editingMarket, outcomes: updatedOutcomes });

    setEditedPricesState(updatedList);
  }

  return (
    <Grid.Row className="outcome-row">
      <Grid.Column width={4}>
        <FlexDiv>
          {isSpecialMarket ? (
            <FlexDiv.Flex>
              <IrmEditableDescriptionComponent
                className="outcome-name-input"
                description={outcomeDescription}
                setDescription={setOutcomeDescription}
                onBlur={reload}
                descriptionOnChange={descriptionOnChange}
                marketId={marketId}
                parentDomId={parentDomId}
              />
            </FlexDiv.Flex>
          ) : (
            <FlexDiv.Flex>{description}</FlexDiv.Flex>
          )}
          <HideModeOutcomeIcon
            hideModeTrader={hideModeTrader}
            hideModeFeed={hideModeFeed}
            marketId={marketId}
            outcomeId={id}
            reload={reload}
            parentDomId={parentDomId}
          />
        </FlexDiv>
      </Grid.Column>
      <Grid.Column width={3} className="outcome-input-wrap">
        <IrmPriceComponent
          outcomeId={id}
          marketId={marketId}
          editedPricesState={editedPricesState}
          setEditedPricesState={setEditedPricesState}
          eventMarginState={eventMarginState}
          useMarginState={useMarginState}
          selectedOutcomeState={selectedOutcomeState}
          selectOutcome={selectOutcome}
          marketOutcomes={marketOutcomes}
          marketPricesState={marketPricesState}
          setMarketPricesState={setMarketPricesState}
          canEditPrice={canEditPrice}
          parentDomId={parentDomId}
        />
      </Grid.Column>
      <Grid.Column width={1} className={liabilityClassname} verticalAlign="middle">
        <Money>{liability}</Money>
      </Grid.Column>
      <Grid.Column
        width={1}
        className={liabilitySingleClassname}
        verticalAlign={'middle'}
      >
        <Money>{liabilityRiskSingle}</Money>
      </Grid.Column>
      <Grid.Column width={2} verticalAlign={'middle'} className={'bets_block'}>
        {betsCount === 0 ? (
          <div> No bets </div>
        ) : (
          <Link to={'#'} onClick={handleBetsClick}>
            <Money>{betsTotal}</Money> / {betsCount} / {' '}
            <Money>{isNumberHelp((+betsTotal / +betsCountSingle), 0)}</Money>
          </Link>
        )}
      </Grid.Column>
      <Grid.Column width={5} textAlign={'right'}>
        <IrmOutcomeResultSelector
          handleClick={handleOutcomeResultChangeWithId}
          value={result}
          voidReason={voidReason}
          setLocalOutcomes={setLocalOutcomes}
          localOutcomes={localOutcomes}
          marketId={marketId}
          setActiveStatusState={setActiveStatusState}
          refreshMarkets={reload}
          sportId={sportId}
          outcomesLength={outcomesLength}
          parentDomId={parentDomId}
        />
      </Grid.Column>
    </Grid.Row>
  );
};
