import React from 'react';

import qs from 'qs';
import { Dropdown, Pagination, Table } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { EventTableRow } from './EventTableRow';
import { TableSkeleton } from '../../components/UI/TableSkeleton';
import {
  API_EVENTS_FIND,
  ASC,
  NO_DATA,
  ROWS_PER_PAGE_OPTIONS,
  START_DATE_PARAM
} from '../../constants';
import { useQueryState, useSortQuery } from '../../hooks-and-global-states/history-hooks';
import { EventListTablRowType } from '../../types';

const defaultQueryState = {
  limit: 10,
};

const EvenListColumns = [
  {
    id: START_DATE_PARAM,
    label: 'Start date',
    sortable: true,
  },

  {
    id: 'eventInfo',
    label: 'Event Info',
  },
  {
    id: 'marketStatus',
    label: 'Market status',
    sortable: false, //TODO: until backend fix the error while sorting by this column
  }
];

export const EventTable = () => {
  const { queryState, setQueryState } = useQueryState();

  const { sortDir, sortBy, setSort, sortDirForSemanticTable } = useSortQuery({
    sortBy: START_DATE_PARAM,
    sortDir: ASC,
  });

  const { data, isFetching } = useAxiosRequest<any>(
    React.useMemo(() => {
      const params: any = {
        ...defaultQueryState,
        sortBy,
        sortDir,
      };

      if (queryState.limit) {
        params.limit = queryState.limit;
      }

      if (queryState.page) {
        const limit = +(queryState.limit || defaultQueryState.limit);
        params.offset = limit * (+queryState.page - 1) || 0;
      }

      if (queryState.search) {
        params.searchLine = queryState.search;
      }

      if (queryState.startDateFrom) {
        params.startDateFrom = queryState.startDateFrom;
      }

      if (queryState.startDateTo) {
        params.startDateTo = queryState.startDateTo;
      }

      if (queryState.sportIds) {
        params.sportIds = queryState.sportIds;
      }

      if (queryState.regionIds) {
        params.regionIds = queryState.regionIds;
      }

      if (queryState.leagueIds) {
        params.leagueIds = queryState.leagueIds;
      }

      if (queryState.marketStatuses) {
        const marketStatus = queryState.marketStatuses;
        params.marketStatus = typeof marketStatus === 'string' ? marketStatus.split(',') : marketStatus;
      }

      if (queryState.manualMode) {
        params.manualMode =
          typeof queryState.manualMode === 'string'
            ? queryState.manualMode.split(',')
            : queryState.manualMode;
      }

      if (queryState.liveOnly) {
        params.liveOnly = queryState.liveOnly;
      }

      if (queryState.withBetsOnly) {
        params.withBetsOnly = !!queryState.withBetsOnly;
      }

      return {
        url: API_EVENTS_FIND,
        params,
        paramsSerializer: v => qs.stringify(v, { arrayFormat: 'repeat' }),
      };
    }, [
      queryState.marketStatuses,
      queryState.manualMode,
      sortBy,
      sortDir,
      queryState.sportIds,
      queryState.leagueIds,
      queryState.regionIds,
      queryState.search,
      queryState.page,
      queryState.limit,
      queryState.startDateTo,
      queryState.startDateFrom,
      queryState.liveOnly,
      queryState.withBetsOnly
    ])
  );

  const handleChangeRowsPerPage = React.useCallback((value: string) => {
    setQueryState('limit', value || 10);
  }, [ setQueryState ]);

  const limit = +queryState.limit || defaultQueryState.limit;
  const totalPages = data && Math.ceil(data.totalCount / limit);
  const totalRows = data?.totalCount || '-';

  return (
    <Table striped compact="very" sortable>
      <Table.Header>
        <Table.Row>
          {EvenListColumns.map(column => (
            <Table.HeaderCell
              key={column.id}
              sorted={column.id === sortBy ? sortDirForSemanticTable : null}
              onClick={() => column.sortable && setSort(column.id)}
            >
              {column.label}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {isFetching && (
          <TableSkeleton
            rows={10}
            columns={EvenListColumns.length}
            rowHeight={2}
          />
        )}
        {!isFetching &&
          data?.result?.length > 0 &&
          data.result.map((row: EventListTablRowType) => <EventTableRow key={row.id} {...row} />)}
        {!isFetching && !data?.result.length && (
          <tr>
            <td
              colSpan={9}
              style={{
                textAlign: 'center',
                paddingTop: '28px',
                paddingBottom: '29px',
                fontWeight: 600,
              }}
            >
              {NO_DATA}
            </td>
          </tr>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={EvenListColumns.length + 1}>
            <div style={{ float: 'right' }}>
              <span
                style={{
                  paddingRight: '30px',
                  alignItems: 'center',
                  display: 'inline-flex',
                  height: '28.28px',
                }}
              >
                Total rows: {totalRows}
              </span>
              {totalPages > 1 && (
                <>
                  Rows per page: {' '}
                  <Dropdown
                    style={{
                      marginRight: '20px',
                    }}
                    options={ROWS_PER_PAGE_OPTIONS}
                    upward
                    value={String(limit)}
                    onChange={(_e, data) => handleChangeRowsPerPage(data.value as string)}
                  />
                  <Pagination
                    size="mini"
                    activePage={queryState.page || 1}
                    totalPages={totalPages}
                    onPageChange={(_e, data) => {
                      setQueryState('page', data.activePage);
                    }}
                  />
                </>
              )}
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};
