import React from 'react';

import { Dimmer, Loader } from 'semantic-ui-react';

export const Loading = () => {
  return (
    <div className="loading-component-wrapper">
      <Loader active inline="centered" size="large" />
    </div>
  );
};

export const FullPageLoading = () => {
  return (
    <div className="full-page-loading">
      <Dimmer active inverted>
        <Loader
          inverted
          size="large"
        >Loading</Loader>
      </Dimmer>
    </div>
  );
};
