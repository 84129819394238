import { Link, useMatch } from 'react-router-dom';

import { COLOR_RED } from '../../../constants';

export const EventPathTreeTitle = ({
  description,
  eventPathId,
  path,
  linkPrefix,
  id,
}: {
  description: string;
  eventPathId: string;
  path: string;
  linkPrefix: string;
  id: string;
}) => {
  const url = `${path}/${linkPrefix}/${eventPathId}`;
  const match = useMatch(url);
  return (
    <>
      <Link
        id={id}
        to={url}
      >
        {description}
      </Link>
      &nbsp;
      {match && <span style={{ color: COLOR_RED }}>•</span>}
    </>
  );
};
