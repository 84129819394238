import React from 'react';

import cx from 'classnames';
import { Table } from 'semantic-ui-react';

import './TableSkeleton.scss';

type Props = {
  rows: number;
  columns: number;
  rowHeight?: string | number;
};

export const TableSkeleton = React.memo<Props>(({
  rows,
  columns,
  rowHeight,
}: Props) => {
  const classes = cx(
    'table-skeleton__cell',
    `table-skeleton__cell_${rowHeight}-height`,
    rowHeight && 'table-skeleton__cell_height'
  );

  let rowItems = 1;
  if (!isNaN(Number(rowHeight))) {
    rowItems = Number(rowHeight) - 1;
  }

  return (
    <>
      {[ ...new Array(rows) ]
        .map((_e, i) => i)
        .map(i => (
          <Table.Row key={i}>
            <Table.Cell colSpan={columns} className={classes}>
              <div>
                {' '}
                {[ ...new Array(Number(rowItems)) ]
                  .map((_e, i) => i)
                  .map(i => (
                    <span key={i}>
                      <br/> <span>...</span>
                    </span>
                  ))
                }
              </div>
            </Table.Cell>
          </Table.Row>
        ))
      }
    </>
  );
});
