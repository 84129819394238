import moment from 'moment';

export const getDateString = (startDate: string | number | Date, now: string | number | Date) => {
  const nowMoment = moment(now);
  const thenMoment = moment(startDate);

  const days = nowMoment.diff(thenMoment, 'days');
  const hours = nowMoment.subtract(days, 'days').diff(thenMoment, 'hours');
  const minutes = nowMoment.subtract(hours, 'hours').diff(thenMoment, 'minutes');

  if (days > 1) {
    return `${days}d ${hours}h`;
  } else if (hours > 1) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${minutes}m`;
  }
};
