import React from 'react';

import qs from 'qs';
import { Checkbox, List } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import {
  getHeadPeriodObj,
  getModalPeriodState
} from './helpers';
import { API_MARKET_TYPE_TEMPLATE_FIND } from '../../../constants';
import {
  MarketsForCreationType,
  ModalPeriodsTreeNodeType,
  PeriodType,
  SelectedMarketsStateType,
  SelectedPeriodsStateType
} from '../../../types';


export const ModalPeriodsTree = ({
  selectedPeriodsState,
  setSelectedPeriodsState,
  eventId,
  periodsList,
  periodsTreeState,
  setPeriodsTreeState,
  onSuccessForSelectedPeriod,
  selectedMarketsState,
  setSelectedMarketsState,
}: {
  selectedPeriodsState: SelectedPeriodsStateType;
  setSelectedPeriodsState: (selectedPeriodsState: SelectedPeriodsStateType) => void;
  onSuccessForSelectedPeriod: (data: { objList: { result: Array<MarketsForCreationType> } }) => void;
  eventId: string;
  periodsList: Array<PeriodType>;
  periodsTreeState: ModalPeriodsTreeNodeType;
  setPeriodsTreeState: (periodsTreeState: ModalPeriodsTreeNodeType) => void;
  selectedMarketsState: SelectedMarketsStateType;
  setSelectedMarketsState: (selectedMarketsState: SelectedMarketsStateType) => void;
}) => {
  let headPeriodId;
  if (periodsList.length !== 0) headPeriodId = getHeadPeriodObj(periodsList)?.id;

  const getModalPeriodsTree = (
    currentPeriodId: string,
    isHead: boolean,
    periodsList: Array<PeriodType>,
    prevTreeObj?: ModalPeriodsTreeNodeType
  ) => {
    if (periodsList.length === 0) {
      return undefined;
    }

    const children = periodsList.filter(
      period => period.parentId === currentPeriodId && period.inRunning === true
    );

    const treeObj = {
      node: periodsList.find(period => period.id === currentPeriodId),
      opened: prevTreeObj ? getModalPeriodState(prevTreeObj, currentPeriodId, 'opened') : isHead,
      /** Cause by default we open only head tree level */
      children: undefined,
      checked: prevTreeObj ? getModalPeriodState(prevTreeObj, currentPeriodId, 'checked') : false,
    };

    if (children.length !== 0) {
      treeObj.children = children.map(child =>
        getModalPeriodsTree(child.id, false, periodsList, prevTreeObj)
      );
    }
    return treeObj;
  };

  // TODO refactoring
  // time fix for quick updating periods tree
  const [ refresh, setRefresh ] = React.useState(0);

  const setPeriodsTreeStateCallback = React.useCallback(tree => {
    setPeriodsTreeState(tree);
  },[ setPeriodsTreeState ]);

  React.useEffect(() => {
    setPeriodsTreeState(getModalPeriodsTree(headPeriodId, true, periodsList));
    // eslint-disable-next-line
  }, [headPeriodId, periodsList, setPeriodsTreeState]);

  const getTreePeriodsList = (treeNode: ModalPeriodsTreeNodeType) => {
    let childrenTags = undefined;
    if (treeNode.children && treeNode.opened && treeNode) {
      childrenTags = treeNode.children.map(child => getTreePeriodsList(child));
    }

    return (
      <PeriodRaw
        currentTreeNode={treeNode}
        childrenTags={childrenTags}
        periodsTreeState={periodsTreeState}
        setPeriodsTreeState={setPeriodsTreeStateCallback}
        selectedPeriodsState={selectedPeriodsState}
        setSelectedPeriodsState={setSelectedPeriodsState}
        onSuccessForSelectedPeriod={onSuccessForSelectedPeriod}
        eventId={eventId}
        refresh={refresh}
        setRefresh={setRefresh}
        key={treeNode?.node?.id}
        selectedMarketsState={selectedMarketsState}
        setSelectedMarketsState={setSelectedMarketsState}
      />
    );
  };
  return <List>{getTreePeriodsList(periodsTreeState)}</List>;
};

const PeriodRaw = ({
  currentTreeNode,
  childrenTags,
  periodsTreeState,
  setPeriodsTreeState,
  selectedPeriodsState,
  setSelectedPeriodsState,
  onSuccessForSelectedPeriod,
  eventId,
  setRefresh,
  refresh,
  selectedMarketsState,
  setSelectedMarketsState,
}: {
  currentTreeNode: ModalPeriodsTreeNodeType;
  childrenTags: string;
  periodsTreeState: ModalPeriodsTreeNodeType;
  setPeriodsTreeState: (periodsTreeState: ModalPeriodsTreeNodeType) => void;
  selectedPeriodsState: SelectedPeriodsStateType;
  setSelectedPeriodsState: (selectedPeriodsState: SelectedPeriodsStateType) => void;
  onSuccessForSelectedPeriod: (data: { objList: { result: Array<MarketsForCreationType> } }) => void;
  eventId: string;
  setRefresh: (refresh: number) => void;
  refresh: number;
  selectedMarketsState: SelectedMarketsStateType;
  setSelectedMarketsState: (selectedMarketsState: SelectedMarketsStateType) => void;
}) => {
  const iconName = currentTreeNode.opened ? 'angle down' : 'angle right';

  const marketsConfig = React.useMemo(() => {
    return {
      url: API_MARKET_TYPE_TEMPLATE_FIND,
      params: {
        eventId: eventId,
        onlyNew: true,
        periodIds: selectedPeriodsState.periods
      },
      paramsSerializer: v => qs.stringify(v, { arrayFormat: 'repeat' }),
    };
  }, [ selectedPeriodsState, eventId ]);

  const { update } = useAxiosRequest(null, {
    onSuccess: onSuccessForSelectedPeriod,
  });

  function getUpdatedModalPeriodsTree (periodId, nodeObj, attrToChange) {
    if (nodeObj.node.id === periodId) {
      nodeObj[attrToChange] = !nodeObj[attrToChange];
    } else if (nodeObj.children) {
      nodeObj.children = nodeObj.children.map(child =>
        getUpdatedModalPeriodsTree(periodId, child, attrToChange)
      );
    }
    return nodeObj;
  }

  function selectPeriodsCheckbox (periodId) {
    const selectedItems = selectedPeriodsState.periods;

    const updatedTree = getUpdatedModalPeriodsTree(periodId, periodsTreeState, 'checked');
    setPeriodsTreeState(updatedTree);
    setRefresh(refresh + 1);

    if (selectedItems.indexOf(periodId) === -1) {
      selectedItems.push(periodId);
    } else {
      selectedItems.splice(selectedItems.indexOf(periodId), 1);
      setSelectedMarketsState({
        markets: selectedMarketsState.markets.filter(market => market.periodId !== periodId),
      });
    }

    setSelectedPeriodsState({
      periods: selectedItems,
    });

    update(marketsConfig);
  }

  function openTreeNodes () {
    const updatedTree = getUpdatedModalPeriodsTree(currentTreeNode.node.id, periodsTreeState, 'opened');
    setPeriodsTreeState(updatedTree);
    setRefresh(refresh + 1);
  }

  function isChecked (periodId) {
    return getModalPeriodState(periodsTreeState, periodId, 'checked');
  }

  return (
    currentTreeNode.node && (
      <List.Item>
        {currentTreeNode.children !== undefined ? (
          <List.Icon
            name={iconName}
            link
            onClick={openTreeNodes}
          />
        ) : (
          <List.Icon />
        )}
        <List.Content>
          <Checkbox
            key={currentTreeNode.node.id}
            label={currentTreeNode.node.description}
            checked={isChecked(currentTreeNode.node.id)}
            onChange={() => {
              selectPeriodsCheckbox(currentTreeNode.node.id);
            }}
          />
          {childrenTags
            ? <List.List>{childrenTags}</List.List>
            : <></>
          }
        </List.Content>
      </List.Item>
    )
  );
};
