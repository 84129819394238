import React from 'react';

import {
  QueryStateDropdown
} from '../../components/search-and-filters-components/QueryStateInputs';
import { BOOK_TYPE_FILTER, BOOK_TYPES_OPTIONS } from '../../constants';
import { getOptionsWithIds } from '../../utils';

export const BookTypeDropdown = props => {
  return (
    <QueryStateDropdown
      options={getOptionsWithIds(BOOK_TYPES_OPTIONS, props.id)}
      name={BOOK_TYPE_FILTER}
      placeholder="Book type"
      {...props}
    />
  );
};
