import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Header, Form } from 'semantic-ui-react';

import {
  EventPathSubmitButton,
  PhoenixField,
  PhoenixFieldMultipleRadioType
} from '../../components/form-components/FormComponents';
import { API_EVENT_PATH_UPDATE } from '../../constants';
import { useLimitGroups } from '../../hooks-and-global-states/hooks';
import { FieldTypesEnum } from '../../types';
import { removeEmptyFields, replaceId } from '../../utils';

export const LimitGroupsEdit = ({
  limitGroupId,
  limitGroupInherited,
  id,
  version,
  parentDomId,
  originId,
  revalidationParentKey,
}: {
  limitGroupInherited: boolean;
  limitGroupId: string;
  id: string;
  version: string;
  parentDomId: string;
  originId: string;
  revalidationParentKey?: string;
}) => {
  const queryClient = useQueryClient();

  const { limits, isFetching: isFetchingLimits } = useLimitGroups({ originId });

  const initialValues = {
    limitGroupId,
    limitGroupInherited,
  };

  function submit (values: any, { setSubmitting, resetForm }) {
    const dataForRequest = removeEmptyFields({
      id,
      version,
      originId,
      ...values,
    });

    axios
      .put(API_EVENT_PATH_UPDATE, dataForRequest)
      .then(response => {
        toast.success('Limit Group has been updated');

        resetForm({
          ...response.data.result,
          values,
        });

        setSubmitting(false);
      })
      .then(async () => {
        if (revalidationParentKey) {
          await queryClient.invalidateQueries({
            queryKey: [ revalidationParentKey ]
          });
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.error(error);
        setSubmitting(false);
      });
  }

  return (
    <>
      <Header>Limit Groups</Header>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submit}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <PhoenixFieldMultipleRadioType
                name="limitGroupId"
                disabled={values.limitGroupInherited}
                horizontal
                options={
                  !isFetchingLimits
                    ? limits.map(option => ({
                      text: option.description,
                      value: option.id,
                      id: parentDomId + 'limit-group--id-radio' + replaceId(option.id)
                    }))
                    : []
                }
              />
            </Form.Field>
            <Form.Field>
              <PhoenixField
                id={parentDomId + 'limit-group--inherited-checkbox'}
                label="Inherited"
                type={FieldTypesEnum.CHECKBOX}
                name="limitGroupInherited"
              />
            </Form.Field>
            <EventPathSubmitButton id={parentDomId + 'limit-group--submit-btn'} />
          </Form>
        )}
      </Formik>
    </>
  );
};
