import { useState } from 'react';

import { Header } from 'semantic-ui-react';

import { Accordion, OpenAccordion } from './Accordion';
import { EventPathTreeTitle } from './EventPathTreeTitle';
import { Filters } from './Filters';
import { EVENT_TREE_PATH } from '../../../constants';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';
import {
  useEventPathTree,
  useLeaguesFind,
  useRegionsFind,
  useSportsFind
} from '../../../react-query/query';
import './EventPathTree.scss';

interface IFilters {
  withPL: boolean,
  withAPL: boolean,
  originId: string
}

const PL_FILTERS: IFilters = {
  withPL: false,
  withAPL: false,
  originId: ''
};

const LeagueTree = ({
  sportEventPathId,
  regionEventPathId,
  selectedNodes,
  setSelectedNodes,
  enabledFetch,
}:{
  sportEventPathId: string;
  regionEventPathId: string;
  selectedNodes: string[];
  setSelectedNodes: (selectedNodes: Array<string>) => void;
  enabledFetch: boolean;
}) => {
  const {
    data: leagues,
    isPending: leaguesPending,
  } = useLeaguesFind({
    regionId: regionEventPathId,
    enabled: enabledFetch
  });

  return (
    <Accordion
      parentId="path-tree--league-list--"
      noContent
      path={EVENT_TREE_PATH}
      label="League"
      linkPrefix="league"
      parentSportId={sportEventPathId}
      parentRegionId={regionEventPathId}
      selectedNodes={selectedNodes}
      setSelectedNodes={setSelectedNodes}
      isFetching={leaguesPending}
    >
      {leagues?.map(({
        id,
        description
      }, index: number) => ({
        key: id,
        title: (
          <EventPathTreeTitle
            id={`path-tree--league-list--item-${index + 1}--link`}
            description={description}
            eventPathId={id}
            path={EVENT_TREE_PATH}
            linkPrefix="league"
          />
        ),
      }))}
    </Accordion>
  );
};

const RegionTree = ({
  sportEventPathId,
  selectedNodes,
  setSelectedNodes,
  enabledFetch,
}:{
  sportEventPathId: string;
  selectedNodes: string[];
  setSelectedNodes: (selectedNodes: Array<string>) => void;
  enabledFetch: boolean;
}) => {
  const {
    data: regions,
    isPending: regionsPending,
  } = useRegionsFind({
    sportId: sportEventPathId,
    enabled: enabledFetch
  });

  return (
    <Accordion
      parentId="path-tree--region-list--"
      path={EVENT_TREE_PATH}
      label="Region"
      linkPrefix="region"
      parentSportId={sportEventPathId}
      selectedNodes={selectedNodes}
      setSelectedNodes={setSelectedNodes}
      isFetching={regionsPending}
    >
      {regions?.map(({
        id: regionEventPathId,
        description,
      }, index: number) => ({
        key: regionEventPathId,
        title: (
          <EventPathTreeTitle
            id={`path-tree--region-list--item-${index + 1}--link`}
            description={description}
            eventPathId={regionEventPathId}
            path={EVENT_TREE_PATH}
            linkPrefix="region"
          />
        ),
        render: () => (
          <LeagueTree
            sportEventPathId={sportEventPathId}
            regionEventPathId={regionEventPathId}
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            enabledFetch={selectedNodes?.includes(regionEventPathId)}
          />
        ),
      }))}
    </Accordion>
  );
};

const SportTree = () => {
  const [
    selectedNodes,
    setSelectedNodes
  ] = useState([]);

  const {
    data: sports,
    isPending: sportsPending,
  } = useSportsFind();

  return (
    <Accordion
      parentId="path-tree--sport-list--"
      path={EVENT_TREE_PATH}
      label="Sport"
      linkPrefix="sport"
      selectedNodes={selectedNodes}
      setSelectedNodes={setSelectedNodes}
      isFetching={sportsPending}
    >
      {sports?.map(({
        id: sportEventPathId,
        description
      }, sportIndex: number) => ({
        key: sportEventPathId,
        title: (
          <EventPathTreeTitle
            id={`path-tree--region-list--item-${sportIndex + 1}--link`}
            description={description}
            eventPathId={sportEventPathId}
            path={EVENT_TREE_PATH}
            linkPrefix="sport"
          />
        ),
        render: () => (
          <RegionTree
            sportEventPathId={sportEventPathId}
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            enabledFetch={selectedNodes.includes(sportEventPathId)}
          />
        )
      }))}
    </Accordion>
  );
};

const PriceLineTree = ({
  pricelineFilters,
}:{
  pricelineFilters: IFilters,
}) => {
  const {
    data: pricelineTree,
    isPending: priceLineTreePending,
  } = useEventPathTree({
    onlyActiveOriginMargins: pricelineFilters.withAPL,
    originId: pricelineFilters.originId
  });

  return (
    <OpenAccordion
      parentId="path-tree--sport-list--"
      label="Sport"
      linkPrefix="sport"
      isFetching={priceLineTreePending}
    >
      {pricelineTree?.map(({
        id: sportEventPathId,
        name: description,
        regions
      }, sportIndex: number) => ({
        key: sportEventPathId,
        title: (
          <EventPathTreeTitle
            id={`path-tree--region-list--item-${sportIndex + 1}--link`}
            description={description}
            eventPathId={sportEventPathId}
            path={EVENT_TREE_PATH}
            linkPrefix="sport"
          />
        ),
        render: () => (
          <OpenAccordion
            parentId="path-tree--region-list--"
            path={EVENT_TREE_PATH}
            label="Region"
            linkPrefix="region"
          >
            {regions.map(({
              id: regionEventPathId,
              name: description,
              leagues,
            }, regionIndex: number) => ({
              key: regionEventPathId,
              title: (
                <EventPathTreeTitle
                  id={`path-tree--region-list--item-${regionIndex + 1}--link`}
                  description={description}
                  eventPathId={regionEventPathId}
                  path={EVENT_TREE_PATH}
                  linkPrefix="region"
                />
              ),
              render: () => (
                <OpenAccordion
                  parentId="path-tree--league-list--"
                  noContent
                  label="League"
                  linkPrefix="league"
                >
                  {leagues.map(({
                    id,
                    name: description,
                  }, index: number) => ({
                    key: id,
                    title: (
                      <EventPathTreeTitle
                        id={`path-tree--league-list--item-${index + 1}--link`}
                        description={description}
                        eventPathId={id}
                        path={EVENT_TREE_PATH}
                        linkPrefix="league"
                      />
                    ),
                  }))}
                </OpenAccordion>
              ),
            }))}
          </OpenAccordion>
        )
      }))}
    </OpenAccordion>
  );
};

export const EventPathTree = () => {
  const { originId } = useGlobalStateContext();

  const [
    pricelineFilters,
    setPricelineFilters
  ] = useState<IFilters>({ ...PL_FILTERS, originId });

  const isPriceLinesTree = !!pricelineFilters.withAPL || !!pricelineFilters.withPL;

  return (
    <>
      <Header size="large">
        Event Path Tree
      </Header>
      <Filters
        parentId="path-tree--sport-list--"
        pricelineFilters={pricelineFilters}
        setPricelineFilters={setPricelineFilters}
      />
      {isPriceLinesTree ? (
        <PriceLineTree pricelineFilters={pricelineFilters} />
      ) : (
        <SportTree />
      )}
    </>
  );
};
