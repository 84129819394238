import { memo } from 'react';

import { Link } from 'react-router-dom';

import { OriginLabel } from './OriginLabel';
import { PlayerFactorGroupLabel } from './PlayerFactorGroups';
import { TextWithCopyButton } from './TextWithCopyButton';
import { USER_PATH } from '../../constants';
import { FlexDiv } from '../FlexDiv';

const DEFAULT_NAME = 'Empty';

type Props = {
  firstName: string;
  lastName: string;
  username: string;
  id: string;
  origin: string;
  userFactorGroupId: string;
  noLink?: boolean;
  floatedLabels?: boolean;
  showCopyButtons?: {
    userId?: boolean;
    betId?: boolean;
    userName?: boolean;
  };
  parentDomId: string;
};

export const TableCellPlayerInfo = memo<Props>(({
  firstName,
  lastName,
  username,
  id,
  origin,
  userFactorGroupId,
  noLink,
  floatedLabels,
  showCopyButtons,
  parentDomId,
}: Props) => {
  const userInfo = (
    <div>
      {firstName || DEFAULT_NAME} {lastName || DEFAULT_NAME}
      <br/>
      {!showCopyButtons?.userName && `${username} `}
      {!showCopyButtons?.userId && `(${id})`}
    </div>
  );

  return (
    <FlexDiv column={!floatedLabels}>
      <FlexDiv.Flex>
        {noLink ? (
          userInfo
        ) : (
          <Link
            id={parentDomId + 'user-link'}
            to={`${USER_PATH}/${id}`}
            className="as-link"
          >
            {userInfo}
          </Link>
        )}
      </FlexDiv.Flex>
      {showCopyButtons?.userName && (
        <TextWithCopyButton
          value={username}
          type="userName"
        />
      )}
      {showCopyButtons?.userId && (
        <TextWithCopyButton
          value={id}
          type="id"
        />
      )}
      <div>
        <OriginLabel originId={origin} />
        <PlayerFactorGroupLabel userFactorGroupId={userFactorGroupId} />
      </div>
    </FlexDiv>
  );
});

// TODO change folder, check where compnents use
export const TableCellPlayerInfoNew = ({
  firstName,
  lastName,
  username,
  id,
  showCopyButtons,
  parentDomId,
}: {
  firstName: string;
  lastName: string;
  username: string;
  id: string;
  showCopyButtons?: {
    userId?: boolean;
    betId?: boolean;
    userName?: boolean;
  };
  parentDomId: string;
}) => {
  return (
    <div>
      <Link
        id={parentDomId + 'user-link'}
        to={`${USER_PATH}/${id}`}
        className="as-link"
      >
        {firstName || DEFAULT_NAME} {lastName || DEFAULT_NAME}
      </Link>
      {showCopyButtons?.userName && (
        <TextWithCopyButton
          value={username}
          type="userName"
        />
      )}
      {showCopyButtons?.userId && (
        <TextWithCopyButton
          value={id}
          type="id"
        />
      )}
    </div>
  );
};

export const TableCellGroupInfoNew = ({
  origin,
  userFactorGroupId,
}: {
  origin: string;
  userFactorGroupId: string;
}) => {
  return (
    <div>
      <OriginLabel simple originId={origin}/>
      <PlayerFactorGroupLabel
        userFactorGroupId={userFactorGroupId}
      />
    </div>
  );
};
