import React from 'react';

import {
  Input,
  Dropdown,
  Checkbox,
  List,
  Label,
  DropdownProps,
  SemanticCOLORS
} from 'semantic-ui-react';
import { $Diff } from 'utility-types';

import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import { getOptionsWithIds } from '../../utils';

type QueryStateInputProps = React.ComponentProps<typeof Input> & {
  name: string;
};

export const QueryStateInput = React.memo<QueryStateInputProps>(
  ({ name, ...props }: QueryStateInputProps) => {
    const { pendingQueryState, setQueryState } = useQueryState();

    return (
      <Input
        value={pendingQueryState[name] || ''}
        {...props}
        onChange={e => {
          setQueryState(name, e.currentTarget.value, true);
        }}
      />
    );
  }
);

export type QueryStateDropdownProps = $Diff<
  DropdownProps,
  {
    value?: DropdownProps['value'];
    onChange?: DropdownProps['onChange'];
    name?: DropdownProps['name'];
  }
> & {
  onChange?: (name: string, value: DropdownProps['value']) => void;
  name: string;
};

export const QueryStateDropdown = React.memo<QueryStateDropdownProps>(
  ({ name, options, onChange, defaultValue, ...props }: QueryStateDropdownProps) => {
    const { queryState, setQueryState } = useQueryState();
    function dropdownOnChange (_e: any, data: any) {
      if (onChange) {
        onChange(name, data.value);
      } else {
        setQueryState(name, data.value);
      }
    }
    return (
      <Dropdown
        selection
        options={getOptionsWithIds(options, props.id)}
        clearable
        closeOnChange
        value={queryState[name] || defaultValue || (props.multiple ? [] : '')}
        onChange={dropdownOnChange}
        {...props}
      />
    );
  }
);

export const QueryStateDropdownMultipleSelection = React.memo<QueryStateDropdownProps>(
  ({ name, options, onChange, defaultValue, multiply, ...props }: QueryStateDropdownProps) => {
    const { queryState, setQueryState } = useQueryState();

    let value: Array<any>;
    if (queryState[name]) {
      if (Array.isArray(queryState[name])) {
        value = queryState[name];
      } else {
        value = [ queryState[name] ];
      }
    } else {
      value = defaultValue || [];
    }

    function onChangeFunc (_e: any, data: any) {
      if (onChange) {
        onChange(name, data.value);
      } else {
        setQueryState(name, data.value);
      }
    }

    return (
      <Dropdown
        fluid
        multiple={multiply}
        selection
        options={options}
        clearable
        closeOnChange
        value={value}
        onChange={onChangeFunc}
        {...props}
      />
    );
  }
);

type QueryStateCheckboxType = React.ComponentProps<typeof Checkbox> & {
  name: string;
};

export const QueryStateCheckbox = React.memo<QueryStateCheckboxType>(
  ({ name, ...props }: QueryStateCheckboxType) => {
    const { queryState, setQueryState } = useQueryState();
    //TODO change string to boolean
    const checked = queryState[name] === 'true';

    return (
      <Checkbox
        {...props}
        onChange={(_e, { checked }) => {
          setQueryState(name, checked.toString());
        }}
        checked={checked}
      />
    );
  }
);

export type QueryStateMultipleCheckboxType = {
  name: string;
  withLabelColor?: boolean;
  options: Array<{
    disabled?: boolean;
    labelColor?: SemanticCOLORS;
    value: string;
    text: string;
  }>;
  id: string;
};

export const QueryStateMultipleCheckbox = React.memo<QueryStateMultipleCheckboxType>(
  ({ name, options, withLabelColor, id }) => {
    const { queryState, setQueryState } = useQueryState();
    const queryStateValues = React.useMemo(() => {
      const values = queryState[name];
      return values ? (Array.isArray(values) ? values : values.split(',')) : [];
    }, [ name, queryState ]);

    const onChange = React.useCallback((_e: any, data: any) => {
      if (!data) return;

      queryStateValues?.includes(data.value)
        ? setQueryState(
            name,
            queryStateValues.filter((queryStateValue: any) => queryStateValue !== data.value).join(',')
          )
        : setQueryState(name, [ ...queryStateValues, data.value ].join(','));
    },[ queryStateValues, setQueryState, name ]);

    return (
      <List relaxed>
        {options.map(({ value, text, disabled, labelColor }) => {
          const checked = queryStateValues?.includes(value);
          const couldBeDisabled = !checked && disabled;
          return (
            <List.Item key={value} disabled={couldBeDisabled}>
              <Checkbox
                id={id + value.toLowerCase()}
                onChange={onChange}
                value={value}
                checked={checked}
                label={withLabelColor ? null : text}
                disabled={couldBeDisabled}
                style={
                  withLabelColor
                    ? { verticalAlign: 'bottom' }
                    : null
                }
              />
              {withLabelColor && (
                <span>
                  <Label
                    circular
                    color={labelColor}
                    style={{
                      minHeight: '0px',
                      minWidth: '0px',
                      marginLeft: '5px',
                    }}
                  />{' '}
                  {text}
                </span>
              )}
            </List.Item>
          );
        })}
      </List>
    );
  }
);
