import React from 'react';

import { Button, Card, Grid } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { IrmOutcomeResultSelector } from './IrmOutcomeResultSelector';
import { IrmOutcomeRow } from './IrmOutcomeRow';
import { MarketCardHeaderRow } from './MarketCardHeaderRow';
import { OutcomeBets } from '../../../components/sidebar-components/OutcomeBets';
import { Money } from '../../../components/table-components/Money';
import { API_MARKET, BetStatusesEnum } from '../../../constants';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';
import { EventType, Market, OutcomeSinglesByEventType } from '../../../types';
import { calculateMargin, MarketPricesStateType, MarketWithPricesObjectType } from '../../../utils';
import { isNumberHelp } from '../helpers';
import {
  canEditMarketCheck,
  handleAllOutcomesResultChangeFunc,
  handleOutcomeResultChangeFunc,
  localOutcomesGetter
} from '../Markets/helpers';
import './IrmMarketCard.css';

export const IrmMarketCard = ({
  market,
  outcomes,
  marketManualCreated,
  reload,
  event,
  editedPricesState,
  setEditedPricesState,
  eventMarginState,
  useMarginState,
  selectedOutcomeState,
  selectOutcome,
  marketPricesState,
  setMarketPricesState,
  openSidebar,
  isSpecialMarket,
}: {
  outcomes: Array<OutcomeSinglesByEventType>;
  marketManualCreated: boolean;
  event: EventType;
  market: Market;
  reload: () => void;
  editedPricesState?: Array<MarketWithPricesObjectType>;
  setEditedPricesState?: (editedPricesState: Array<MarketWithPricesObjectType>) => void;
  eventMarginState: number;
  useMarginState: boolean;
  selectedOutcomeState?: string;
  selectOutcome: (string) => void;
  marketPricesState?: MarketPricesStateType;
  setMarketPricesState?: (MarketPricesStateType) => void;
  openSidebar: (node: React.ReactNode) => void;
  isSpecialMarket: boolean;
}) => {
  const { originId } = useGlobalStateContext();
  const getLocalOutcomes = React.useCallback(localOutcomesGetter, []);

  const [ outcomesState, setOutcomesState ] = React.useState(outcomes);
  const [ localOutcomes, setLocalOutcomes ] = React.useState(getLocalOutcomes(outcomesState));

  React.useEffect(() => {
    setOutcomesState(outcomes);
    setLocalOutcomes(getLocalOutcomes(outcomes));
  }, [ outcomes, getLocalOutcomes ]);

  const [ activeStatusState, setActiveStatusState ] = React.useState(market.status);

  React.useEffect(() => {
    setActiveStatusState(market.status);
  }, [ market ]);

  const handleOutcomeResultChange = React.useCallback(handleOutcomeResultChangeFunc, [
    setLocalOutcomes,
    localOutcomes
  ]);

  const handleAllOutcomesResultChange = React.useCallback(handleAllOutcomesResultChangeFunc, [
    setLocalOutcomes,
    localOutcomes
  ]);

  const canEditMarket = canEditMarketCheck(market);

  const [ marginCurrentPrice ] = React.useMemo(() => {
    const currentPrices = [];
    for (const outcomeId in marketPricesState[market.id]) {
      currentPrices.push(marketPricesState[market.id][outcomeId]);
    }
    return [
      calculateMargin({
        prices: currentPrices,
      })
    ];
  }, [ marketPricesState, market.id ]);

  const marketBetsCount = outcomes.reduce((prev, next) => prev + next.betsCount, 0);
  const marketBetsCountSingles = outcomes.reduce((prev, next) => prev + next.betsCountSingle, 0);
  const marketBetsTotal = outcomes.reduce((prev, next) => prev + next.betsTotal, 0);

  const { update: addOutcomeUpdate } = useAxiosRequest(null, {
    onSuccess: () => setTimeout(() => reload(), 500),
  });

  // At first, we send request for adding new outcome
  // Then we update marketsContext with all markets for their quick displaying
  // on the board
  const addOutcome = () => {
    addOutcomeUpdate({
      method: 'PUT',
      url: `${API_MARKET}/${market.id}/update`,
      data: {
        originId,
        id: market.id,
        outcomes: [{ description: 'Outcome' }],
      },
    });
  };

  return (
    <Card style={{ width: '100%' }}>
      <Card.Content>
        <Grid>
          <MarketCardHeaderRow
            market={market}
            activeStatusState={activeStatusState}
            outcomes={outcomes}
            reload={reload}
            canEditMarket={canEditMarket}
            marketManualCreated={marketManualCreated}
            setActiveStatusState={setActiveStatusState}
            isSpecialMarket={isSpecialMarket}
            editedPricesState={editedPricesState}
            setEditedPricesState={setEditedPricesState}
            outcomesState={outcomesState}
          />
          {outcomesState.map(
            ({
              outcome: { id, description, hideModeFeed, hideModeTrader },
              liabilityRisk,
              liabilityRiskSingle,
              betsCount,
              betsTotal,
              betsCountSingle,
            }) => {
              const localOutcome = localOutcomes[id];
              const parentDomId = `irm--markets--market-${market.id}--outcome-${id}--`;
              return (
                <IrmOutcomeRow
                  marketId={market.id}
                  hideModeFeed={hideModeFeed}
                  hideModeTrader={hideModeTrader}
                  key={id}
                  id={id}
                  parentDomId={parentDomId}
                  description={description}
                  reload={reload}
                  editedPricesState={editedPricesState}
                  setEditedPricesState={setEditedPricesState}
                  canEditPrice={canEditMarket}
                  handleOutcomeResultChange={handleOutcomeResultChange}
                  result={localOutcome.result}
                  voidReason={localOutcome.voidReason}
                  eventMarginState={eventMarginState}
                  useMarginState={useMarginState}
                  selectedOutcomeState={selectedOutcomeState}
                  selectOutcome={selectOutcome}
                  marketOutcomes={outcomes}
                  marketPricesState={marketPricesState}
                  setMarketPricesState={setMarketPricesState}
                  setLocalOutcomes={setLocalOutcomes}
                  localOutcomes={localOutcomes}
                  setActiveStatusState={setActiveStatusState}
                  liability={liabilityRisk}
                  liabilityRiskSingle={liabilityRiskSingle}
                  betsCount={+betsCount}
                  betsCountSingle={betsCountSingle}
                  betsTotal={+betsTotal}
                  sportId={event.sportId}
                  isSpecialMarket={isSpecialMarket}
                  handleBetsClick={() => {
                    openSidebar(
                      <OutcomeBets
                        parentDomId={parentDomId}
                        outcomeId={id}
                        marketDescription={market.description}
                        outcomeDescription={description}
                        marketStatus={market.status}
                        liabilityRisk={liabilityRisk}
                        betStatuses={[
                          BetStatusesEnum.BET_STATUS_PENDING,
                          BetStatusesEnum.BET_STATUS_WIN,
                          BetStatusesEnum.BET_STATUS_LOSE
                        ]}
                      />
                    );
                  }}
                />
              );
            }
          )}
          <Grid.Row style={{ padding: '5px' }}>
            <Grid.Column width={4}>
              {isSpecialMarket && <Button icon={'plus'} size={'tiny'} onClick={addOutcome} />}
            </Grid.Column>
            <Grid.Column width={2} verticalAlign={'middle'}>
              <strong className={Number(marginCurrentPrice) < 1 ? 'bad-margin-price' : 'margin-price'}>
                {marginCurrentPrice ? Math.round(Number(marginCurrentPrice) * 10) / 10 + 100 + '%' : ''}
              </strong>
            </Grid.Column>
            <Grid.Column width={3} />
            <Grid.Column width={2} verticalAlign={'middle'} className={'bets_block'}>
              {marketBetsCount === 0 ? (
                <div> No bets </div>
              ) : (
                <div>
                  <Money>{marketBetsTotal}</Money>
                  / {marketBetsCount} /{' '}
                  <Money>{isNumberHelp((marketBetsTotal / marketBetsCountSingles), 0)}</Money>
                </div>
              )}
            </Grid.Column>
            <Grid.Column width={5} textAlign={'right'}>
              <strong className={'outcomes_all_text'}>All: </strong>
              <IrmOutcomeResultSelector
                handleClick={handleAllOutcomesResultChange}
                value={null}
                voidReason={null}
                setLocalOutcomes={setLocalOutcomes}
                localOutcomes={localOutcomes}
                marketId={market.id}
                setActiveStatusState={setActiveStatusState}
                refreshMarkets={reload}
                sportId={event.sportId}
                outcomesLength={0}
                parentDomId={`irm--markets--market-${market.id}`}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
};
