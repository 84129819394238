import { Dispatch, SetStateAction, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { Table, Header, Segment, Button, Modal, Icon } from 'semantic-ui-react';

import { LimitGroupRow } from './LimitGroupRow';
import { OriginsAccordion } from '../../components/form-components/OriginsAccordion';
import { Loading } from '../../components/UI/Loading';
import { ORIGIN_ID_DEFAULT } from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { useOriginLimitGroupMutation } from '../../react-query/mutations';
import { KEY_ORIGIN_LIMIT_GROUP, useOriginLimitGroup } from '../../react-query/query';
import { LeagueLimitGroup } from '../../types';

import './LimitGroups.scss';


export const LimitGroups = () => {
  const { originId } = useGlobalStateContext();

  const {
    data,
    isFetching,
    error
  } = useOriginLimitGroup({ originId });

  const {
    data: defaultOriginLimits,
    isFetching: fetchingDefaultOrigin
  } = useOriginLimitGroup({ originId: '1' });

  if (isFetching || fetchingDefaultOrigin) {
    return <Loading/>;
  }

  return (
    <LimitGroupsComponent
      limitGroups={data}
      originId={originId}
      isFetching={isFetching}
      error={error}
      defaultOriginLimits={defaultOriginLimits}
    />
  );
};

const LimitGroupsComponent = ({
  limitGroups,
  originId,
  error,
  defaultOriginLimits,
  isFetching,
}: {
  limitGroups: Array<LeagueLimitGroup>;
  originId: string;
  error?: Error;
  defaultOriginLimits: LeagueLimitGroup[];
  isFetching: boolean;
}) => {
  const { updateOriginsList } = useGlobalStateContext();
  const queryClient = useQueryClient();

  const [ limitState, setLimitState ] = useState(limitGroups);

  const formattedDefaultOriginLimits = defaultOriginLimits.map((defaultLimit, index) => {
    const originLimits = limitGroups[index];

    return ({
      ...originLimits,
      limits: defaultLimit.limits
    });
  });

  const { mutate } = useOriginLimitGroupMutation({
    onSuccess: async () => {
      toast.success('Limits has been updated.');
      await queryClient.invalidateQueries({ queryKey: [ KEY_ORIGIN_LIMIT_GROUP, originId ] });
      updateOriginsList();
    },
    onError: error => {
      toast.error([ 'There was an error while updating limits.', error.message ].join('\n'));
    },
  });

  function submit () {
    mutate({
      originId,
      limitGroups: limitState,
    });
  }

  function resetToDefault () {
    mutate({
      originId,
      limitGroups: formattedDefaultOriginLimits,
    });
  }

  return (
    <div className="limit-group__container">
      <Header>Limit Groups</Header>
      <div className="limit-group__origin-dropdown">
        <OriginsAccordion
          parentDomId="limits-page--"
          multiChoice={false}
          withSubOrigins={false}
          withDefaultOrigin
          highlightChangedOrigin
          icon="filter"
        />
      </div>
      {error ? (
        <Segment inverted color="red">
          {error.message}
        </Segment>
      ) : (
        <LimitGroupsEditor
          originId={originId}
          limitState={limitState}
          setLimitState={setLimitState}
          defaultOriginLimits={formattedDefaultOriginLimits}
        />
      )}
      <div>
        <SaveButtons
          originId={originId}
          submit={submit}
          disabled={isEqual(limitState, limitGroups) || isFetching}
        />
        {originId !== ORIGIN_ID_DEFAULT && (
          <ResetButton
            onClick={resetToDefault}
            disabled={isEqual(formattedDefaultOriginLimits, limitGroups) || isFetching}
          />
        )}
      </div>
    </div>
  );
};

const LimitGroupsEditor = ({
  limitState,
  setLimitState,
  defaultOriginLimits,
}:{
  limitState: Array<LeagueLimitGroup>;
  setLimitState:  Dispatch<SetStateAction<LeagueLimitGroup[]>>;
  defaultOriginLimits: LeagueLimitGroup[];
}) => {
  function updateLimit(
    index: number,
    type:
      | 'maxPayoutLiveMultiple'
      | 'maxPayoutLiveSingle'
      | 'maxPayoutMultiple'
      | 'maxPayoutSingle'
      | 'maxStakeLiveMultiple'
      | 'maxStakeLiveSingle'
      | 'maxStakeMultiple'
      | 'maxStakeSingle',
    value: number
  ) {
    setLimitState(prevState => prevState.map((limitGroup, i) => {
      if (index === i) {
        return {
          ...limitGroup,
          limits: {
            ...limitGroup.limits,
            [type]: value,
          },
        };
      }
      return limitGroup;
    }));
  }

  return (
    <Table structured celled fixed unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell rowSpan="3">Category</Table.HeaderCell>
          <Table.HeaderCell colSpan="4">Max Payout</Table.HeaderCell>
          <Table.HeaderCell colSpan="4">Max Bet</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell colSpan="2">Pre-match</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">Live</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">Pre-match</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">Live</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Single</Table.HeaderCell>
          <Table.HeaderCell>Multi</Table.HeaderCell>
          <Table.HeaderCell>Single</Table.HeaderCell>
          <Table.HeaderCell>Multi</Table.HeaderCell>
          <Table.HeaderCell>Single</Table.HeaderCell>
          <Table.HeaderCell>Multi</Table.HeaderCell>
          <Table.HeaderCell>Single</Table.HeaderCell>
          <Table.HeaderCell>Multi</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {limitState?.map(({
          id,
          description,
          limits: {
            maxPayoutLiveMultiple,
            maxPayoutLiveSingle,
            maxPayoutMultiple,
            maxPayoutSingle,
            maxStakeLiveMultiple,
            maxStakeLiveSingle,
            maxStakeMultiple,
            maxStakeSingle,
          },
        }, i) => {
          return (
            <LimitGroupRow
              key={id}
              maxPayoutLiveMultiple={maxPayoutLiveMultiple}
              maxPayoutLiveSingle={maxPayoutLiveSingle}
              maxPayoutMultiple={maxPayoutMultiple}
              maxPayoutSingle={maxPayoutSingle}
              maxStakeLiveMultiple={maxStakeLiveMultiple}
              maxStakeLiveSingle={maxStakeLiveSingle}
              maxStakeMultiple={maxStakeMultiple}
              maxStakeSingle={maxStakeSingle}
              description={description}
              updateLimit={updateLimit}
              index={i}
              defaultOriginLimit={defaultOriginLimits?.[i]?.limits}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
};

const ResetButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <>
      <Button
        id="limit-page--reset-btn"
        disabled={disabled}
        onClick={() => setIsOpen(true)}
        content="Reset to default"
      />
      <Modal closeIcon open={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Content>
          <p>Do you really want to reset to default?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="limit-page--reset-cancel-btn"
            color="red"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Icon name="remove" /> No
          </Button>
          <Button
            id="limit-page--reset-accept-btn"
            color="green"
            onClick={onClick}
          >
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const SaveButtons = ({
  originId,
  submit,
  disabled
}:{
  originId: string;
  submit: () => void;
  disabled: boolean;
}) => {
  const [ open, setOpen ] = useState(false);


  if (originId !== ORIGIN_ID_DEFAULT) {
    return (
      <Button
        id="limits-page--save-btn"
        positive
        disabled={disabled}
        onClick={submit}
        content="Save"
      />
    );
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        <Button
          id="limits-page--save-btn"
          positive
          disabled={disabled}
          content="Submit for all origins"
        />
      }
    >
      <Header icon>
        <Icon name="attention" />
        Attention!
      </Header>
      <Modal.Content>
        <p>Do you really want to submit changes for all origins?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="limit-page--submit-all-cancel-btn"
          basic
          onClick={() => setOpen(false)}
        >
          <Icon name="remove" /> No
        </Button>
        <Button
          id="limit-page--submit-all-accept-btn"
          color="green"
          onClick={submit}
        >
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
