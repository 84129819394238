import { FC, SyntheticEvent, useEffect, useState } from 'react';

import { Checkbox, Dropdown, DropdownProps, Input } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { FilterDatePicker } from '../../../../components/form-components/FilterDatePicker';
import { ICheckbox, IDatePicker, IDropdown, IInput } from '../../model/types';

export const DropdownComponent: FC<IDropdown> =   ({
  name,
  options,
  multiply,
  value,
  action,
  ...props
}) => {

  function onChangeFunc(_e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) {
    action(name, data.value);
  }

  return (
    <Dropdown
      fluid
      multiple={multiply}
      selection
      options={options}
      closeOnChange
      value={value}
      onChange={onChangeFunc}
      {...props}
    />
  );
};

export const InputComponent: FC<IInput> = ({
  value,
  action,
  name,
  ...props
}) => {
  return (
    <Input
      value={value}
      {...props}
      onChange={e => {
        action(name, e.currentTarget.value);
      }}
    />
  );
};

export const CheckboxComponent: FC<ICheckbox> = ({
  value,
  action,
  name,
  ...props
}) => {
  return (
    <Checkbox
      {...props}
      checked={value}
      onChange={(_e, { checked }) => {
        action(name, checked);
      }}
    />
  );
};

export const DatePickerComponent: FC<IDatePicker> = ({
  value,
  action,
  name,
  ...props
}) => {

  function onChange (date: Date) {
    action(name, date);
  }

  return (
    <FilterDatePicker
      selected={value}
      onChange={onChange}
      showTimeSelect
      shouldCloseOnSelect
      {...props}
    />
  );
};

export const DropdownWithAxiosComponent = ({
 name,
 value,
 action,
                                             axiosConfig,
                                             multiply,
                                             isShown,
 ...props
}) => {

  function onChangeFunc(_e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) {
    action(name, data.value);
  }

  const [ optionsState, setOptionsState ] = useState([])

  const { update } = useAxiosRequest<any>(null, {
    onSuccess: data =>
      setOptionsState(data?.result?.map(({ id, description }) => ({
        value: id,
        text: description || '',
      })) || [])
  });

  useEffect(() => {
    !isShown && action(name, '');
    isShown && update(axiosConfig);
  }, [ isShown ]);

  return isShown ? (
    <Dropdown
      fluid
      multiple={multiply}
      selection
      options={optionsState}
      closeOnChange
      value={value}
      onChange={onChangeFunc}
      clearable
      search
      {...props}
    />
  ) : <></>;
};
