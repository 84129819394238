import { useEffect, useState } from 'react';

import axios from 'axios';
import { URLSearchParamsInit, useLocation, useSearchParams } from 'react-router-dom';

import { useGlobalStateContext } from './global-context';
import { API_ORIGIN } from '../constants';
import { parseQuery } from '../utils';

export const useBoolean = (defaultValue: boolean | (() => boolean)) => {
  const [ value, setValue ] = useState<boolean>(defaultValue);
  return {
    value,
    setValue,
    setTrue: () => setValue(true),
    setToggle: () => setValue(v => !v),
    setFalse: () => setValue(false),
  };
};

export const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export function useClickOutside (ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ callback, ref ]);
}

export function useLimitGroups ({ originId }:{ originId: string; }) {
  const { limitGroups, setLimitGroups } = useGlobalStateContext();

  const isLimitGroupsActual = !!limitGroups.limitsOrigin &&
    limitGroups.limitsOrigin === originId &&
    limitGroups.limits.length !== 0;

  useEffect(() => {
    if (!isLimitGroupsActual) {
      setLimitGroups(prev => ({ ...prev, isFetching: true }));

      axios(`${API_ORIGIN}/${originId}/limitGroups`)
        .then(res => res.data)
        .then(data => data.result)
        .then(result => {
          setLimitGroups(prev => ({
            ...prev,
            limits: [ ...result ],
            limitsOrigin: originId,
          }));
        })
        .catch(error => {
          setLimitGroups(prev => ({ ...prev, limits: [] }));
          // eslint-disable-next-line
          console.log(error);
        })
        .finally(() => setLimitGroups(prev => ({ ...prev, isFetching: false })));
    }
    // eslint-disable-next-line
  },[ isLimitGroupsActual ]);

  return ({
    limits: limitGroups.limits,
    isFetching: limitGroups.isFetching,
  });
}

// TODO save
export const useLocationParams = () => {
  const location = useLocation();
  const [ , setSearchParams ] = useSearchParams();
  const queryObj = parseQuery(location.search);
  const isQueryClear = !location.search.length;

  function changeQuery (newQuery: object) {
    const valuesForRemove = [ null, undefined, '' ];

    const updatedQuery = {
      ...queryObj,
      ...newQuery,
    };

    const filteredUpdatedQuery = Object.keys(updatedQuery)
      // @ts-ignore
      .filter(k => !valuesForRemove.includes(updatedQuery[k]))
      .reduce((a, k) => ({ ...a, [k]: updatedQuery[k] }), {});

    setSearchParams(filteredUpdatedQuery as URLSearchParamsInit);
  }

  function clearQuery () {
    setSearchParams('');
  }

  return {
    queryObj,
    changeQuery,
    clearQuery,
    isQueryClear,
  };
};
