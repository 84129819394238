import { isEqual, isNull, pickBy } from 'lodash';

import {
  DATE_FILTER_INTERVALS_DEFAULT
} from '../../../components/search-and-filters-components/QueryStateFilterByPeriod';
import {
  ASC, ASCENDING,
  BetsSortByEnum,
  DESC, DESCENDING,
  FILTER_INTERVALS,
  MAX_STAKE_FILTER,
  MIN_STAKE_FILTER,
  SORT_DIC
} from '../../../constants';
import { IBetRequest } from '../../../react-query/types';
import { BetBookType } from '../../../types';
import { TFilters } from '../model/types';

const REJECTED_REASONS_FOR_REQUEST = {
  'Success': [ 'BET_REASON_SUCCESS' ],
  'Failure': [
    'BET_REASON_UNKNOWN',
    'BET_REASON_OWALLET',
    'BET_REASON_LIMITS',
    'BET_REASON_RESTRICTIONS',
    'BET_REASON_PRICE_CHANGED',
    'BET_REASON_OTHER'
  ]
};

export const getBetParams = (
  filters: Partial<TFilters>,
  limit: number,
  liveMode: boolean,
  sortBy: BetsSortByEnum | null,
  sortDir: typeof ASCENDING | typeof DESCENDING
) => {
  const {
    betId,
    hideTestAccounts,
    userId,
    factorGroupIds,
    eventId,
    betType,
    betAccepted,
    bookType,
    betStatuses,
    stake,
    period,
    startDate,
    endDate,
    sports,
    showOnlyOutrights,
    betRejectReasons,
    originIds,
    marketType,
  } = filters;

  const paramsForRequest = {
    sortBy: sortBy || BetsSortByEnum.BET_SORT_DATE,
    sortDir: SORT_DIC[sortDir] as typeof ASC | typeof DESC,
    limit,
  } as IBetRequest;

  if (originIds) {
    paramsForRequest.originIds = originIds;
  }

  if (betId) {
    paramsForRequest.betId = betId || null;
  }

  if (Array.isArray(betRejectReasons) && betRejectReasons.length !== 0) {
    paramsForRequest.betRejectReasons = betRejectReasons;
  }

  if (hideTestAccounts) {
    paramsForRequest.hideTestAccounts = hideTestAccounts || null;
  }

  if (userId) {
    paramsForRequest.userIds = [ userId ];
  }

  if (factorGroupIds) {
    paramsForRequest.factorGroupIds = factorGroupIds || null;
  }

  if (eventId) {
    paramsForRequest.eventIds = [ eventId ];
  }

  if (betType) {
    paramsForRequest.betTypes = betType || null;
  }

  if (betAccepted) {
    paramsForRequest.betRejectReasons = REJECTED_REASONS_FOR_REQUEST[betAccepted];
  }

  if (bookType) {
    paramsForRequest.bookTypes = [ bookType as BetBookType ];
  }

  if (betStatuses) {
    paramsForRequest.betStatuses = betStatuses || null;
  }

  if (stake?.[MIN_STAKE_FILTER]) {
    paramsForRequest.minStake = Number(stake[MIN_STAKE_FILTER]) || null;
  }

  if (stake?.[MAX_STAKE_FILTER]) {
    paramsForRequest.maxStake = Number(stake[MAX_STAKE_FILTER]) || null;
  }

  if (marketType) {
    paramsForRequest.marketTypeId = marketType as string;
  }

  const periodConfig = DATE_FILTER_INTERVALS_DEFAULT
    .find(({ value }) => value === period);

  const withPeriod = !liveMode && period && periodConfig;

  if (withPeriod) {
    if (
      periodConfig.value === FILTER_INTERVALS.custom.value ||
      periodConfig.value === FILTER_INTERVALS.all.value
    ) {
      paramsForRequest.startDate = startDate || null;
      paramsForRequest.endDate = endDate || null;
    } else {
      paramsForRequest.startDate = periodConfig.getter();
      paramsForRequest.endDate = periodConfig.dateNow();
    }

  } else if (liveMode) {
    paramsForRequest.startDate = null;
    paramsForRequest.endDate = null;

  }

  if (Array.isArray(sports) && sports.length !== 0) {
    paramsForRequest.sportIds = sports;
  }

  if (showOnlyOutrights) {
    paramsForRequest.isOutright = showOnlyOutrights;
  }

  return pickBy(paramsForRequest, v => !isNull(v)) as IBetRequest;
};

export const filterObject = (obj: object, filters: Array<any>) => {
  const excludeArrays = filters.some(f => Array.isArray(f));
  const excludeObjects = filters.some(f => isObject(f));

  function isObject (o: any) {
    return typeof o === 'object' &&
    !Array.isArray(o) &&
    o !== null;
  }

  return (
    Object.keys(obj)
      .filter(k => {
        const currentItem = obj[k];
        const isArr = Array.isArray(currentItem);
        const isObj = isObject(currentItem);

        const objIf = excludeObjects && isObj
          ? !isEqual(currentItem, {})
          : true;
        const arrayIf = excludeArrays && isArr
          ? !isEqual(currentItem, [])
          : true;
        const plainIf = !isArr && !isObj
          ? !filters.includes(currentItem)
          : true;


        return objIf && arrayIf && plainIf;
      })
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {})
  );
};
