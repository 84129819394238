import { Dispatch, SetStateAction, useState } from 'react';

import { Button, Checkbox } from 'semantic-ui-react';

import { OriginsAccordion } from '../../../components/form-components/OriginsAccordionNew';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';

export const Filters = ({
  parentId,
  pricelineFilters,
  setPricelineFilters,
}:{
  parentId: string;
  pricelineFilters: {
    withPL: boolean;
    withAPL: boolean;
    originId: string;
  };
  setPricelineFilters: Dispatch<SetStateAction<{
    withPL: boolean;
    withAPL: boolean;
    originId: string;
  }>>
}) => {
  const { changeOriginFn } = useGlobalStateContext();

  const [ isFull, setIsFull ] = useState(false);

  const onChangeWithPL = (_e, { checked }) => {
    setPricelineFilters(prev => ({
      ...prev,
      withPL: checked,
      withAPL: prev.withAPL && checked ? false : prev.withAPL
    }));
  };

  const onChangeWithAPL = (_e, { checked }) => {
    setPricelineFilters(prev => ({
      ...prev,
      withAPL: checked,
      withPL: prev.withPL && checked ? false : prev.withPL
    }));
  };

  const onChangeOrigin = (newOrigins: string[]) => {
    const [ newOrigin ] = newOrigins;

    setPricelineFilters(prev => ({
      ...prev,
      originId: newOrigin,
    }));
    changeOriginFn(newOrigin);
  };

  const toggleFilters = () => {
    if (isFull) {
      setPricelineFilters(prev => ({
        ...prev,
        withPL: false,
        withAPL: false,
      }));
    }
    setIsFull(!isFull);
  };

  return (
    <>
      <Button
        content="Priceline filters"
        fluid
        icon={isFull ? 'chevron up' : 'chevron down'}
        labelPosition="right"
        onClick={toggleFilters}
      />
      {isFull && (
        <div className="event-path-tree__filters-wrap">
          <Checkbox
            label="Show leagues with pricelines"
            checked={pricelineFilters.withPL}
            onChange={onChangeWithPL}
          />
          <Checkbox
            label="Show leagues with active pricelines"
            checked={pricelineFilters.withAPL}
            onChange={onChangeWithAPL}
          />
          <OriginsAccordion
            parentDomId={parentId}
            originAction={onChangeOrigin}
            originsParentState={pricelineFilters.originId}
            type="simple-dropdown"
            icon="filter"
            fullWidth
          />
        </div>
      )}
    </>
  );
};
