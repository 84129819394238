import React from 'react';

import { Button, Input } from 'semantic-ui-react';

export const IrmEditableDescriptionComponent = ({
  description,
  setDescription,
  className,
  periodDescription,
  onBlur,
  descriptionOnChange,
  marketId,
  parentDomId,
}: {
  description: string;
  setDescription: (outcomeDescription: string) => void;
  className: string;
  periodDescription?: string;
  onBlur: () => void;
  descriptionOnChange: (value: string) => void;
  marketId: string;
  parentDomId: string;
}) => {
  const handleFocus = event => event.target.select();

  function inputOnChange (e) {
    setDescription(e.target.value);
    descriptionOnChange(e.target.value);
  }

  return (
    <>
      <Input
        id={`${parentDomId}--market-${marketId}--input-1`}
        className={className}
        label={<Button icon="minus" disabled={true} />}
        size="small"
        value={description}
        onChange={inputOnChange}
        onFocus={handleFocus}
        onBlur={onBlur}
      />
      {periodDescription}
    </>
  );
};
