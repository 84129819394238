import React, { ReactElement } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Icon, Popup } from 'semantic-ui-react';

import { useGlobalStateContext } from '../../../../hooks-and-global-states/global-context';
import { useMarketUpdate } from '../../../../react-query/mutations';
import { KEY_MARKETS } from '../../../../react-query/query';

export const HideModeOutcomeIcon = ({
  hideModeTrader,
  hideModeFeed,
  disabled,
  marketId,
  outcomeId,
  parentDomId,
}: {
  hideModeTrader: boolean;
  hideModeFeed: boolean;
  disabled?: boolean;
  marketId: string;
  outcomeId: string;
  parentDomId: string;
}) => {
  const queryClient = useQueryClient();
  const mutMarketUpdate = useMarketUpdate({
    onSuccess: onSuccess,
    onError: onSuccess,
  });

  const { originId } = useGlobalStateContext();

  const options: any = React.useMemo(() => {
    const result: {[key: string]: string | boolean | ReactElement } = {};

    if (hideModeTrader) {
      result.color = 'violet';
      result.iconName = 'eye slash';
      result.content = (
        <>
          This outcome has been hidden by a trader. Click the icon to return to the feed hide status (
          <strong>{hideModeFeed ? 'hidden' : 'visible'}</strong>
          ).
        </>
      );
      result.clickable = true;
    } else {
      result.color = 'grey';

      if (hideModeFeed) {
        result.iconName = 'eye slash';
        result.content = 'This outcome has been hidden feed.';
        result.clickable = false;
      } else {
        result.iconName = 'eye';
        result.content = 'This outcome is currenly visible. Click on the icon to hide the outcome.';
        result.clickable = true;
      }
    }

    return result;
  }, [ hideModeFeed, hideModeTrader ]);

  function handleClick () {
    if (options.clickable) {
      mutMarketUpdate.mutate({
        id: marketId,
        originId: originId,
        outcomes: [{
          id: outcomeId,
          hideModeTrader: !hideModeTrader,
        }],
      });
    }
  }

  async function onSuccess () {
    await queryClient.invalidateQueries({ queryKey: [ KEY_MARKETS ] });
  }

  return (
    <Popup
      content={options.content}
      disabled={disabled}
      trigger={
        <Icon
          id={parentDomId + 'hide-mode-btn'}
          style={{
            cursor: options.clickable ? 'pointer' : undefined,
          }}
          onClick={handleClick}
          color={options.color}
          name={options.iconName}
          disabled={disabled}
        />
      }
    />
  );
};
