import { ReactNode } from 'react';

import { ManualEvents } from './ManualEvents';
import { UnsettledMarkets } from './UnsettledMarkets';
import { RiskManager } from '../Risk/RiskManager';

import './Dashboard.scss';

export const Dashboard = ({ openSidebar }: { openSidebar: (node: ReactNode) => void }) => {
  const dashboardsElements = [
    <RiskManager
      key="RiskManager"
      openSidebar={openSidebar}
      compactView
    />,
    <UnsettledMarkets key="UnsettledMarkets" />,
    <ManualEvents key="ManualEvents" />
  ];
  return (
    <div className="dashboard-root">
      {dashboardsElements.map(children => (
        <div key={children.key} className="dashboard-widget">
          {children}
        </div>
      ))}
    </div>
  );
};
