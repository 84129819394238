import React from 'react';

import { QueryStateCheckbox } from './QueryStateInputs';
import { HIDE_TEST_ACCOUNTS_FILTER } from '../../constants';

export const QueryStateHideTestAccountsFilter = ({ parentDomId }:{parentDomId: string}) => {
  return (
    <QueryStateCheckbox
      id={parentDomId + 'hide-test-account-checkbox'}
      label="Hide test accounts"
      name={HIDE_TEST_ACCOUNTS_FILTER}
    />
  );
};
