import React from 'react';

import {
  QueryStateDropdownProps,
  QueryStateDropdownMultipleSelection
} from '../../components/search-and-filters-components/QueryStateInputs';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import { BetTypeTypeEnum } from '../../types';
import { getOptionsWithIds } from '../../utils';

export const BetTypeDropdown = React.memo<QueryStateDropdownProps>(
  ({ placeholder, name, options, id }: QueryStateDropdownProps) => {
    const { setQueryState } = useQueryState();

    const betTypeFilterOnChange = (name: string, newValues: any) => {
      const all_types = [
        BetTypeTypeEnum.Bet_Single,
        BetTypeTypeEnum.Bet_Multiple,
        BetTypeTypeEnum.Bet_System,
        BetTypeTypeEnum.Bet_BetBuilder
      ];

      if (newValues?.includes(BetTypeTypeEnum.Bet_Any) || all_types.every(v => newValues.includes(v))) {
        setQueryState(name, [ BetTypeTypeEnum.Bet_Any ]);
      } else {
        setQueryState(name, newValues);
      }
    };

    return (
      <QueryStateDropdownMultipleSelection
        id={id}
        placeholder={placeholder}
        fluid
        options={getOptionsWithIds(options, id)}
        name={name}
        onChange={betTypeFilterOnChange}
        multiply
      />
    );
  }
);
