import { Dispatch, SetStateAction, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { isEqual, uniq, isNumber, toNumber } from 'lodash';
import { toast } from 'react-toastify';
import { Button, Checkbox, Dropdown, Header, Input, Loader, Segment } from 'semantic-ui-react';

import { ORIGIN_ID_DEFAULT } from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { useOriginMarginsUpdate } from '../../react-query/mutations';
import {
  KEY_EVENT_PATH_TREE_FIND,
  KEY_ORIGINS_MARGIN,
  useMarketType,
  useOriginsMargin,
  usePeriod
} from '../../react-query/query';
import { IOriginMargin } from '../../types';

const DEFAULT_MARGIN: IOriginMargin = {
  enable: true,
  marketTypeId: '',
  periodId: '',
  margin: '',
  eventPathId: '',
};

export const PriceLine = ({
  sportId,
  eventPathId,
  originId,
}:{
  sportId: string;
  eventPathId: string;
  originId: string;
}) => {
  const queryClient = useQueryClient();

  const { originsList } = useGlobalStateContext();

  const [ error, setError ] = useState<string>('');

  const currentOrigin = originsList.find(item => item.id === originId);
  const currentPriceLineId = currentOrigin.priceLineId;

  const { data: originMargins, isFetching: originMarginsFetching } = useOriginsMargin({
    params: {
      eventPathId: eventPathId,
      priceLineId: currentPriceLineId
    }
  });

  const { mutate: originMarginUpdateMutation } = useOriginMarginsUpdate({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ KEY_ORIGINS_MARGIN, eventPathId, currentPriceLineId ]
      });
      await queryClient.invalidateQueries({
        queryKey: [ KEY_EVENT_PATH_TREE_FIND ]
      });
      toast.success('Successfully changed pricelines');
    },
    onError: err => {
      // eslint-disable-next-line
      console.log(err);
      toast.error('Some problems');
    }
  });

  const enabledMargins = originMargins?.some(margin => margin.enable);

  if (currentOrigin.id === ORIGIN_ID_DEFAULT) {
    return <></>;
  }

  return (
    <div className="priceline-container">
      <Header>Priceline</Header>
      {originMarginsFetching ? (
        <Loader active inline="centered"/>
      ) : (
        <PricelineForm
          initialOriginMargins={originMargins}
          originMarginsFetching={originMarginsFetching}
          error={error}
          setError={setError}
          sportId={sportId}
          currentPriceLineId={currentPriceLineId}
          eventPathId={eventPathId}
          originMarginUpdateMutation={originMarginUpdateMutation}
          enabledMargins={enabledMargins}
        />
      )}
    </div>
  );
};

const useOriginMarginOptions = ({ sportId }:{ sportId: string }) => {
  const {
    data: marketTypes,
    isFetching: marketTypeOptionsFetching,
  } = useMarketType({
    params: { sportEventPathId: sportId },
    enabled: !!sportId,
  });

  const marketTypeOptions = marketTypes
    ?.filter(type => type.typeGroup === '2' && type.id === '1')
    ?.map(({ id, description, typeGroup }) => ({
      value: id,
      text: description,
      type: typeGroup,
    }));

  const {
    data: periods,
    isFetching: periodOptionsFetching,
  } = usePeriod({
    params: { sportEventPathId: sportId },
    enabled: !!sportId,
  });

  const periodOptions = periods
    ?.filter(({ inRunning, id }) => !inRunning && id === '100')
    ?.map(({ description, id }) => ({
      value: id,
      text: `${description} (Prematch)`,
    })) || [];

  return {
    marketTypeOptions,
    marketTypeOptionsFetching,
    periodOptions,
    periodOptionsFetching
  };
};

const PricelineForm = ({
  initialOriginMargins = null,
  originMarginsFetching,
  error,
  setError,
  sportId,
  currentPriceLineId,
  eventPathId,
  originMarginUpdateMutation,
  enabledMargins,
}:{
  initialOriginMargins: IOriginMargin[],
  originMarginsFetching: boolean,
  error: string,
  setError: (err: string) => void,
  sportId: string,
  currentPriceLineId: string,
  eventPathId: string,
  originMarginUpdateMutation: any;
  enabledMargins: boolean;
}) => {
  const [ originMargins, setOriginMargins ] = useState<IOriginMargin[]>(initialOriginMargins);

  const {
    marketTypeOptions,
    marketTypeOptionsFetching,
    periodOptions,
    periodOptionsFetching,
  } = useOriginMarginOptions({ sportId });

  const isDirty = !isEqual(initialOriginMargins, originMargins);

  function addPriceline () {
    setOriginMargins(p =>
      Array.isArray(p) ? [ ...p, DEFAULT_MARGIN ] : [ DEFAULT_MARGIN ]
    );
    setError('');
  }

  async function submit () {
    let marginError = false;
    let marketTypeError = false;
    let periodError = false;

    const combinations = [];

    for (let i = 0; i < originMargins.length; i++) {
      const currentPriceLine = originMargins[i];
      const { margin, marketTypeId, periodId } = currentPriceLine;

      if (!margin || Number(margin) < 101) {
        marginError = true;
      }
      if (!marketTypeId) {
        marketTypeError = true;
      }
      if (!periodId) {
        periodError = true;
      }
      combinations.push(`${marketTypeId}-${periodId}`);
    }

    if (marginError) {
      setError('Margin must be more than 101%');
      return;
    } else if (marketTypeError) {
      setError('Market can\'t be empty');
      return;
    } else if (periodError) {
      setError('Period can\'t be empty');
      return;
    } else if (combinations.length > 1 && !isEqual(combinations, uniq(combinations))) {
      setError('Priceline with such parameters already exist!');
      return;
    }

    const dataForRequest = {
      originMargins: originMargins.map(margin => ({
        ...margin,
        enable: enabledMargins,
      })),
      priceLineId: currentPriceLineId,
      eventPathId,
    };

    originMarginUpdateMutation(dataForRequest);
  }

  const marginsLength = originMargins?.length > 0;
  function changeMode () {
    const dataForRequest = {
      priceLineId: currentPriceLineId,
      eventPathId,
      originMargins: originMargins.map(margin => ({
        ...margin,
        enable: !margin.enable,
      })),
    };
    originMarginUpdateMutation(dataForRequest);
    setError('');
  }

  return (
    <>
      <div className="priceline__prices-wrapper">
        {originMarginsFetching && (
          <div className="priceline__loader-wrap">
            <Loader active />
          </div>
        )}
        {!originMarginsFetching && !originMargins?.length && <div>No margins</div>}
        {!originMarginsFetching &&
          originMargins?.length > 0 &&
          originMargins?.map((margin, index) => (
            <PricelineRow
              key={index}
              originMargin={margin}
              index={index}
              marketTypeOptions={marketTypeOptions}
              marketTypeOptionsFetching={marketTypeOptionsFetching}
              periodOptions={periodOptions}
              periodOptionsFetching={periodOptionsFetching}
              setOriginMargins={setOriginMargins}
              setError={setError}
            />
          ))}
      </div>
      {!!error && (
        <div>
          <Segment inverted color="red" tertiary>
            {error}
          </Segment>
        </div>
      )}
      <div className="priceline__buttons">
        <Button
          icon="plus"
          labelPosition="left"
          content="Add margin"
          onClick={addPriceline}
        />
        <Button
          positive
          content="Submit"
          onClick={submit}
          disabled={!!error || !isDirty}
        />
        <Checkbox
          label="Margin overrides"
          toggle
          checked={enabledMargins}
          onChange={changeMode}
          disabled={!marginsLength}
          className={'priceline__checkbox'}
        />
      </div>
    </>
  );
};

const PricelineRow = ({
  index,
  setError,
  originMargin,
  setOriginMargins,
  marketTypeOptions,
  marketTypeOptionsFetching,
  periodOptions,
  periodOptionsFetching,
}: {
  index: number;
  setError: (n: string) => void;
  originMargin: IOriginMargin;
  setOriginMargins: Dispatch<SetStateAction<IOriginMargin[]>>;
  marketTypeOptions: any[];
  marketTypeOptionsFetching: boolean;
  periodOptions: any[];
  periodOptionsFetching: boolean;
}) => {
  const marginError = Number(originMargin.margin) < 101 && originMargin.margin !== '';

  function onChange(_e, data) {
    const value = data.value;
    const name = data.name;

    setError('');
    setOriginMargins(prev => {
      const result = [ ...prev ];
      result[index] = {
        ...prev[index],
        [name]: value,
      };

      return result;
    });
  }

  function inputOnChange (_e, data) {
    const value = data.value;
    const name = data.name;

    if (
      !isNumber(toNumber(value)) ||
      !/^[0-9]*[.,]?[0-9]*$/.test(value)
    ) {
      return;
    }

    setError('');
    setOriginMargins(prev => {
      const result = [ ...prev ];
      result[index] = {
        ...prev[index],
        [name]: value.replace(/,/,'.'),
      };

      return result;
    });
  }

  function deleteRow() {
    setOriginMargins(prev => {
      const result = [ ...prev ];

      result[index] = null;
      setError('');

      return result.filter(p => p);
    });
  }

  return (
    <div className="priceline__row">
      <div className="priceline__filter">
        {!index && <label>Market type</label>}
        <Dropdown
          name="marketTypeId"
          value={originMargin.marketTypeId}
          placeholder="Choose one"
          selection
          fluid
          search
          options={marketTypeOptions}
          loading={marketTypeOptionsFetching}
          disabled={marketTypeOptionsFetching}
          onChange={onChange}
        />
      </div>
      <div className="priceline__filter">
        {!index && <label>Period</label>}
        <Dropdown
          name="periodId"
          value={originMargin.periodId}
          placeholder="Choose one"
          selection
          fluid
          search
          options={periodOptions}
          loading={periodOptionsFetching}
          disabled={periodOptionsFetching}
          onChange={onChange}
        />
      </div>
      <div className="priceline__filter">
        {!index && <label>Margin %</label>}
        <Input
          name="margin"
          value={originMargin.margin}
          onChange={inputOnChange}
          placeholder="ex. 108.4"
          error={marginError}
        />
      </div>
      <Button
        icon="delete"
        onClick={deleteRow}
      />
    </div>
  );
};
