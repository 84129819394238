import React from 'react';

import { Button } from 'semantic-ui-react';

import { useQueryState } from '../../hooks-and-global-states/history-hooks';

export const ClearFiltersButton = ({
  resetStartDateToToday,
  id,
}: {
  resetStartDateToToday?: boolean;
  id: string;
}) => {
  const { queryState, setQueryStateWithParams } = useQueryState();

  const onClick = React.useCallback(() => {
    setQueryStateWithParams(
      Object.keys(queryState).reduce((obj, key) => {
        if (key === 'startDateFrom' && resetStartDateToToday) {
          obj[key] = new Date();
        } else {
          obj[key] = '';
        }
        return obj;
      }, {})
    );
  }, [ queryState, resetStartDateToToday, setQueryStateWithParams ]);

  return (
    <Button
      id={id}
      compact
      disabled={Object.keys(queryState).length === 0}
      floated="right"
      icon="delete"
      content="Clear"
      onClick={onClick}
    />
  );
};
