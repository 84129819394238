import React from 'react';

import { RegionsDropdown } from './RegionsDropdown';
import { SportsDropdown } from './SportsDropdown';

export const SportRegionSelector = ({
  value,
  onChange,
  sportId,
  id,
}: {
  value: string;
  onChange: (arg1, arg2?) => void;
  sportId: string;
  id: string;
}) => {
  const [ sport, setSport ] = React.useState(sportId);

  const handleSportChange = (value: string) => {
    onChange('');
    setSport(value);
  };

  return (
    <>
      <div className="field">
        <label>Sport</label>
        <SportsDropdown
          id={id + '--sport-dropdown'}
          value={sport}
          onChange={handleSportChange}
        />
      </div>
      <div className="field">
        <label>Region</label>
        <RegionsDropdown
          id={id + '--region-dropdown'}
          sportIds={sport}
          onChange={onChange}
          value={value}
        />
      </div>
    </>
  );
};
