import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';

import { CashoutSettingsEdit } from './CashoutSettingsEdit';
import { LeagueForm } from './EventPathForms';
import { InRunningDelayComponent } from './InRunningDelayComponent';
import { LimitGroupsEdit } from './LimitGroupsEdit';
import { OpponentsList } from './OpponentsList';
import { PriceLine } from './Priceline';
import { ResultReasonSettings } from './ResultReasonSettings';
import { OriginsAccordion } from '../../components/form-components/OriginsAccordionNew';
import { Loading } from '../../components/UI/Loading';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { KEY_LEAGUE_FIND_BY_ID, useLeagueFindById } from '../../react-query/query';
import './styles/style.scss';


const FOOTBALL_ID = '240';

export const EditLeague = () => {
  const { leagueId } = useParams();

  const oldLeagueId = useRef(leagueId);

  const {
    originId: globalOrigin,
    changeOriginFn,
  } = useGlobalStateContext();

  const [
    originId,
    setOriginId
  ] = useState(globalOrigin);

  const {
    data,
    isPending
  } = useLeagueFindById({
    originId,
    eventPathIds: leagueId
  });

  useEffect(() => {
    if (oldLeagueId.current !== leagueId) {
      setOriginId(globalOrigin);
      oldLeagueId.current = leagueId;
    }
  // eslint-disable-next-line
  }, [ leagueId ]);

  function originAction (newOrigins: string[]) {
    const [ newOrigin ] = newOrigins;

    setOriginId(newOrigin);
    changeOriginFn(newOrigin);
  }

  if (isPending) {
    return (
      <Loading/>
    );
  }

  const {
    description,
    sportId,
    version,
    cashoutEnabled,
    cashoutInherited,
    id,
    regionId,
    limitGroupId,
    limitGroupInherited,
    resultReasons,
  } = data;

  return (
    <>
      <Header size="large">Edit League</Header>
      <Segment color="orange">
        <LeagueForm
          id={id}
          description={description}
          parentEventPathId={regionId}
          sportId={sportId}
          version={version}
        />
      </Segment>
      <Segment color="orange">
        <OriginsAccordion
          parentDomId="path-tree--edit-league--"
          originAction={originAction}
          originsParentState={originId}
          type="simple-dropdown"
          icon="filter"
          withDefaultOrigin
          fullWidth={false}
        />
        {/*<ResultReasonSettings*/}
        {/*  parentDomId="path-tree--edit-league--"*/}
        {/*  eventPathId={id}*/}
        {/*  sportId={sportId}*/}
        {/*  version={version}*/}
        {/*  originId={originId}*/}
        {/*  revalidationParentKey={KEY_LEAGUE_FIND_BY_ID}*/}
        {/*  resultReasons={resultReasons}*/}
        {/*/>*/}
        <CashoutSettingsEdit
          parentDomId="path-tree--edit-league--"
          eventPathId={id}
          version={version}
          enabled={cashoutEnabled}
          inherited={cashoutInherited}
          originId={originId}
          revalidationParentKey={KEY_LEAGUE_FIND_BY_ID}
        />
        <LimitGroupsEdit
          parentDomId="path-tree--edit-league--"
          id={id}
          limitGroupId={limitGroupId}
          limitGroupInherited={limitGroupInherited}
          version={version}
          originId={originId}
          revalidationParentKey={KEY_LEAGUE_FIND_BY_ID}
        />
        <InRunningDelayComponent
          parentDomId="path-tree--edit-league--"
          eventPathId={leagueId}
          originId={originId}
          revalidationParentKey={KEY_LEAGUE_FIND_BY_ID}
        />
        {sportId === FOOTBALL_ID && (
          <PriceLine
            eventPathId={leagueId}
            sportId={sportId}
            originId={originId}
          />
        )}
      </Segment>
      <OpponentsList leagueId={leagueId} />
    </>
  );
};
