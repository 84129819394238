import React from 'react';

import { Label, Table } from 'semantic-ui-react';

import { MarketStatusStats } from './MarketStatusStats';
import { FlexDiv } from '../../components/FlexDiv';
import { EventOutcomeInfo } from '../../components/table-components/EventOutcomeInfo';
import { MANUAL_MODE_ARRAY } from '../../constants';
import { EventListTablRowType } from '../../types';
import { convertNumbersToPercents, formatDateInTable, formatMarketStatus } from '../../utils';

export const EventTableRow = ({
  startDate,
  id,
  description,
  leagueDescription,
  sportDescription,
  manualMode,
  feedCode,
  marketStatusCount,
  marketStatus,
} : EventListTablRowType) => {
  const formattedMarketStatus = formatMarketStatus(marketStatus);
  const marketStatusCountPercentage = convertNumbersToPercents(marketStatusCount);

  return (
    <Table.Row>
      <Table.Cell>{formatDateInTable(startDate)}</Table.Cell>
      <Table.Cell>
        <FlexDiv>
          <FlexDiv.Flex>
            <EventOutcomeInfo
              parentDomId={`events-page--event-${id}--`}
              sportDescription={sportDescription}
              leagueDescription={leagueDescription}
              eventId={id}
              eventDescription={description}
              feedCode={feedCode}
            />
          </FlexDiv.Flex>
          {manualMode.map(m => {
            const mode = MANUAL_MODE_ARRAY.find(({ id }) => id === m);
            return (
              mode && (
                <FlexDiv.Div key={m}>
                  <Label size="tiny">{mode.description}</Label>
                </FlexDiv.Div>
              )
            );
          })}
        </FlexDiv>
      </Table.Cell>
      <Table.Cell>
        <div>
          {formattedMarketStatus}
          <MarketStatusStats stats={marketStatusCountPercentage} />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};
