import React from 'react';

import cx from 'classnames';

import { IdValue } from '../../types';
import './MarketStatusStats.scss';

export const MarketStatusStats = ({ stats }: { stats: Array<IdValue> }) => (
  <div className="market-status-stats-wrapper">
    {stats.map(item => {
      const divStyles = { width: item.percent + '%' };
      const cls = cx('market-status', `market-status_${item.id}`);
      const spanCls = cx('market-status__span', {
        'market-status__span_white': item.id !== 'suspended',
        'market-status__span_black': item.id === 'suspended',
      });
      return (
        <div
          key={item.id}
          style={divStyles}
          className={cls}
        >
          <span className={spanCls}>
            {item.value}
          </span>
        </div>
      );
    })}
  </div>
);
