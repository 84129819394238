import React from 'react';

import moment from 'moment';
import qs from 'qs';
import { Table } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { getDateString } from './helpers';
import { EventOutcomeInfo } from '../../components/table-components/EventOutcomeInfo';
import { TablePagination } from '../../components/table-components/TablePagination';
import { ErrorMessage } from '../../components/UI/ErrorMessage';
import { TableSkeleton } from '../../components/UI/TableSkeleton';
import { API_EVENTS_FIND } from '../../constants';
import { useActualTime } from '../../hooks-and-global-states/time-hooks';
import { EventListTablRowType } from '../../types';

const LIMIT = 10;

export const UnsettledMarkets = React.memo(() => {
  const [ markets, setMarkets ] = React.useState<Array<EventListTablRowType>>(null);
  const [ page, setPage ] = React.useState(1);

  const config = React.useMemo(() => ({
    url: API_EVENTS_FIND,
    params: {
      startDateTo: moment().subtract(120, 'minutes').toISOString(),
      limit: LIMIT * page,
      marketStatus: [ 'open', 'suspended', 'resulted', 'closed' ],
    },
    paramsSerializer: (v: any) => qs.stringify(v, { arrayFormat: 'repeat' }),
  }),[ page ]);

  const { isFetching, error } = useAxiosRequest<any>(
    config, {
      onSuccess: data => {
        setMarkets(data?.result || []);
      }
    });

  return (
    <div className="content-container unsettled-markets">
      <div className="content-header">
        <h2>Unsettled Markets</h2>
      </div>
      {error ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Event Info</Table.HeaderCell>
              <Table.HeaderCell># Markets Unsettled</Table.HeaderCell>
              <Table.HeaderCell>Time since event started</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!markets ? (
              <TableSkeleton
                rows={LIMIT * page}
                columns={3}
                rowHeight={3}
              />
            ) : (
              markets
                .sort((a, b) => +moment(b.startDate) - +moment(a.startDate))
                .map(
                  ({
                    id,
                    sportDescription,
                    regionDescription,
                    leagueDescription,
                    description,
                    marketStatusCount: { open, suspended, resulted, closed },
                    startDate,
                    feedCode,
                  }) => (
                    <UnsettledMarketsRow
                      id={id}
                      sportDescription={sportDescription}
                      regionDescription={regionDescription}
                      leagueDescription={leagueDescription}
                      description={description}
                      marketStatusCount={open + suspended + resulted + closed}
                      startDate={startDate}
                      key={id}
                      feedCode={feedCode}
                    />
                  )
                )
            )}
            <Table.Row>
              <Table.Cell colSpan={3}>
                <TablePagination
                  parentDomId="unsettled-markets"
                  currentCount={markets?.length || 0}
                  page={page}
                  setPage={setPage}
                  isFetching={isFetching}
                  limit={LIMIT}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </div>
  );
});

const UnsettledMarketsRow = React.memo<any>(
  ({ sportDescription, leagueDescription, id, description, marketStatusCount, startDate, feedCode }) => {
    const now = useActualTime();

    const dateString = React.useMemo(() => {
      return getDateString(startDate, now);
    }, [ startDate, now ]);

    return (
      <Table.Row>
        <Table.Cell>
          <EventOutcomeInfo
            parentDomId={`unsettled-markets--event-${id}--`}
            sportDescription={sportDescription}
            leagueDescription={leagueDescription}
            eventId={id}
            eventDescription={description}
            startDate={startDate}
            feedCode={feedCode}
          />
        </Table.Cell>
        <Table.Cell>{marketStatusCount}</Table.Cell>
        <Table.Cell>{dateString}</Table.Cell>
      </Table.Row>
    );
  }
);
