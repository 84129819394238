import React from 'react';

import { Checkbox, Divider, Grid, List } from 'semantic-ui-react';

import { MarketCheckboxRow } from './MarketCheckboxRow';
import {
  MarketsForCreationType,
  SelectedMarketsStateType,
  SingleByEventMarketType
} from '../../../types';

export const MarketsModalList = ({
  markets,
  modalMarketsState,
  selectedMarketsState,
  setSelectedMarketsState,
  allMarketsStatus,
  setAllMarketsStatus,
  updateNumberOfSpecials,
  setNumberOfMarkets,
}: {
  markets: Array<SingleByEventMarketType>;
  modalMarketsState: { objList: Array<MarketsForCreationType> };
  selectedMarketsState: SelectedMarketsStateType;
  setSelectedMarketsState: (selectedMarketsState: SelectedMarketsStateType) => void;
  allMarketsStatus: { checked: boolean };
  setAllMarketsStatus: (allMarketsStatus: { checked: boolean }) => void;
  updateNumberOfSpecials: (counter: number, marketId: string, periodId: string) => void;
  setNumberOfMarkets: (marketId: string, periodId: string, newNumberOfMarkets) => void;
}) => {
  if (allMarketsStatus.checked) {
    if (
      modalMarketsState.objList.length !== selectedMarketsState.markets.length ||
      modalMarketsState.objList.length === 0
    ) {
      setAllMarketsStatus({ checked: false });
    }
  } else {
    if (
      modalMarketsState.objList.length === selectedMarketsState.markets.length &&
      modalMarketsState.objList.length !== 0
    ) {
      setAllMarketsStatus({ checked: true });
    }
  }

  const sortedMarkets = modalMarketsState.objList.sort((market1, market2) => {
    if (market1.allowMultiMarkets && !market2.allowMultiMarkets) {
      return 1;
    } else if (!market1.allowMultiMarkets && market2.allowMultiMarkets) {
      return -1;
    } else {
      return 0;
    }
  });

  function selectAllMarkets () {
    if (allMarketsStatus.checked) {
      setSelectedMarketsState({ markets: [] });
      setAllMarketsStatus({ checked: false });
    } else {
      setSelectedMarketsState({
        markets: modalMarketsState.objList.map(market => {
          return {
            marketId: market.id,
            periodId: market.periodId,
            ...(market.allowMultiMarkets && {
              numberOfMarkets: market.numberOfMarkets,
            }),
          };
        }),
      });
      setAllMarketsStatus({ checked: true });
    }
  }

  return (
    <div>
      <Grid.Row>
        <Checkbox
          label="Select all"
          onChange={selectAllMarkets}
          checked={allMarketsStatus.checked}
        />
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <List>
          {sortedMarkets.map((market, index) => (
            <MarketCheckboxRow
              markets={markets}
              market={market}
              selectedMarketsState={selectedMarketsState}
              setSelectedMarketsState={setSelectedMarketsState}
              key={index}
              updateNumberOfSpecials={updateNumberOfSpecials}
              setNumberOfMarkets={setNumberOfMarkets}
            />
          ))}
        </List>
      </Grid.Row>
    </div>
  );
};
