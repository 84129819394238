import React from 'react';

import Axios from 'axios';
import Raven from 'raven-js';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { AxiosRequest } from './components/fetch-components/AxiosRequest';
import { LoginForm } from './components/UI/LoginForm';
import { NoopComponent } from './components/UI/NoopComponent';
import {
  API_PHOENIX_LOGOUT,
  API_PHOENIX_TOKEN_VALIDATE
} from './constants';
import { GlobalStateProvider } from './hooks-and-global-states/global-context';
import { QueryState } from './hooks-and-global-states/history-hooks';
import { TitleProvider } from './hooks-and-global-states/title-context';
import { UserType } from './types';
import { registerAxiosInterseptors } from './utils';
import 'react-datepicker/dist/react-datepicker.css';
import 'semantic-ui-css/semantic.min.css';
import './styles/index.scss';

if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN_CLIENT) {
  Raven.config(process.env.SENTRY_DSN_CLIENT).install();
}

registerAxiosInterseptors();

const getCookie = (name: string) => {
  const value = '; ' + window.document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const setCookie = (name: string, value: string) => {
  window.document.cookie = `${name}=${value}; path=/`;
};

const deleteCookie = (name: string) => {
  window.document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

const setAxiosDefaultAuthorizationHeader = (jwt: string) => {
  Axios.defaults.headers.common['X-Authorization'] = jwt;
};

const deleteAxiosDefaultAuthorizationHeader = () => {
  delete Axios.defaults.headers.common['X-Authorization'];
};

const COOKIE_PHOENIX_TOKEN = 'phoenix-jwt';
const cookie = getCookie(COOKIE_PHOENIX_TOKEN);
if (typeof cookie === 'string') {
  setAxiosDefaultAuthorizationHeader(cookie);
}

class Main extends React.Component<{ user: UserType | null }> {
  state = {
    user: this.props.user,
  };

  onLogin = (user: UserType) => {
    setCookie(COOKIE_PHOENIX_TOKEN, user.jwt);
    setAxiosDefaultAuthorizationHeader(user.jwt);
    this.setState({
      user: user,
    });
  };

  logout = () => {
    const { user } = this.state;
    if (!user) {
      return;
    }
    Axios.post(API_PHOENIX_LOGOUT, {
      jwt: user.jwt,
    });
    deleteCookie(COOKIE_PHOENIX_TOKEN);
    deleteAxiosDefaultAuthorizationHeader();
    this.setState({
      user: null,
    });
  };

  render() {
    const { user } = this.state;
    return user ? (
      <GlobalStateProvider permissions={user.permissions}>
        <App
          username={user.username}
          logout={this.logout}
        />
      </GlobalStateProvider>
    ) : (
      <LoginForm onLogin={this.onLogin} />
    );
  }
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <QueryState>
      <TitleProvider>
        <AxiosRequest
          noError
          Loading={NoopComponent}
          config={cookie ? API_PHOENIX_TOKEN_VALIDATE : null}
        >
          {({ data, error }) => <Main user={data && !error ? data.result : null} />}
        </AxiosRequest>
      </TitleProvider>
    </QueryState>
  </BrowserRouter>
);

// * HMR
// TODO check ignore
//@ts-ignore
if (module.hot) {
  //@ts-ignore
  module.hot.accept();
}
