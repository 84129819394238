import { FC, memo, SyntheticEvent, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';
import { toast } from 'react-toastify';
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItemProps,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'semantic-ui-react';

import { BET_CELL_CREATOR } from './Columns';
import { FILTERS_COMPONENTS } from './filters/Filters';
import cl from './styles.module.scss';
import { BetsTablePagination } from '../../../components/table-components/TablePagination';
import { TableSkeleton } from '../../../components/UI/TableSkeleton';
import {
  ROW_COLOR_FIRST,
  ROW_COLOR_SECOND
} from '../../../constants';
import { useBetsSettle, useBetsVoid } from '../../../react-query/mutations';
import { BetsTableFiltersEnumNew, TBetsTableRow } from '../../../types';
import { getOptionWithId, removeEmptyFields } from '../../../utils';
import { useTraiderVoidReasons } from '../../Bets/helpers';
import { BetsSlice, useBetsActions, useBetsStore } from '../lib/reducer';
import { BetsColumnEnum, COLUMNS, NAME_BY_COLUMN } from '../model/constants';
import './styles.scss';

const DOM_ID = 'bets--';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let timeout = null;

export const NewBets = () => {
  return (
    <BetsSlice>
      <BetPageComponent/>
    </BetsSlice>
  );
};

const BetPageComponent = () => {
  const { liveMode, simpleFetch } = useBetsStore();
  const { liveModeCheckboxOnChange, refetch } = useBetsActions();

  return (
    <div className={cl.container}>
      <div className={cl.header}>
        <div className={cl.left}>
          <h1 className={cl.title}>Bets</h1>
          <Checkbox
            id={DOM_ID + 'live-mode-checkbox'}
            checked={liveMode}
            label="Live mode"
            onChange={liveModeCheckboxOnChange}
          />
        </div>
        <div className={cl.right}>
          {!liveMode && (
            <Button
              id={DOM_ID + 'refresh-btn'}
              circular
              icon="refresh"
              color="violet"
              onClick={refetch}
              disabled={simpleFetch}
            />
          )}
        </div>
      </div>
      <TopPanel />
      <div className={cl.main}>
        <Filters />
        <MainTable />
      </div>
    </div>
  );
};

const TopPanel = () => {
  const {
    activeColumns,
    withFilters,
    isFiltersEmpty
  } = useBetsStore();

  const {
    toggleFilters,
    changeColumn,
    clearFilters,
  } = useBetsActions();

  const [ open, setOpen ] = useState(false);
  const [ show, setShow ] = useState(false);

  function columnsFiltersChange () {
    timeout = null;
    if (open) {
      setShow(false);

      timeout = setTimeout(() => {
        setOpen(false);
      }, 250);
      return;
    }

    setOpen(true);
    timeout = setTimeout(() => {
      setShow(true);
    }, 250);
  }

  useEffect(() => () => timeout = null, []);

  return (
    <div className={cl.topPanel}>
      <div className={cx(cl.topPanelLeft, withFilters && cl.topPanelLeft_active)}>
        <Button
          id={DOM_ID + 'bets-filters-clear-btn'}
          className={cl.clearBtn}
          icon="delete"
          disabled={isFiltersEmpty}
          labelPosition="left"
          onClick={clearFilters}
          content="Clear"
        />
        <Button
          id={DOM_ID + 'bets-filters-filters'}
          className={cx(cl.filterBtn, withFilters && cl.filterBtn_active)}
          icon={withFilters ? 'angle left' : 'angle right'}
          labelPosition="left"
          content="Filters"
          onClick={toggleFilters}
        />
        <VoidAction />
      </div>
      <div className={cl.topPanelRight}>
        {open && (
          <div className={cx(cl.columnsCheckboxes, cl.columnsCheckboxes_light, show && cl.show)}>
            {COLUMNS.map(column => {
              return (
                <Checkbox
                  key={column}
                  id={DOM_ID + column + '-checkbox'}
                  label={NAME_BY_COLUMN[column]}
                  checked={activeColumns[column]}
                  onChange={() => changeColumn(column)}
                />
              );
            })}
          </div>
        )}
        <Button
          content="Edit columns"
          className={cl.btnShowColumnsFilter}
          onClick={columnsFiltersChange}
          icon={open ? 'angle right' : 'angle left'}
          labelPosition="left"
        />
        {!open && (
          <div className={cx(cl.columnsCheckboxes, cl.columnsCheckboxes_full)}>
            {COLUMNS.map(column => {
              return (
                <Checkbox
                  key={column}
                  id={DOM_ID + column + '-checkbox'}
                  label={NAME_BY_COLUMN[column]}
                  checked={activeColumns[column]}
                  onChange={() => changeColumn(column)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const VoidAction = () => {
  const voidReasonList = useTraiderVoidReasons();
  const queryClient = useQueryClient();
  const { selectedBets, liveMode, betsParams } = useBetsStore();
  const { changeSelectedBets } = useBetsActions();

  const mutBetsSettle = useBetsSettle({
    onSuccess: onSuccess
  });

  const mutVoidBets = useBetsVoid({
    onSuccess: onSuccess,
    onError: () => toast.error('Request error')
  });

  function voidBets (
    _e: SyntheticEvent,
    { value }: DropdownItemProps
  ) {
    const dataForRequest = removeEmptyFields({
      bets: [ ...selectedBets.map(({ id }) => ({ id: id, reason: value })) ],
    });
    mutVoidBets.mutate(dataForRequest);
  }

  async function onSuccess () {
    toast.success('Success');
    changeSelectedBets([]);
    await queryClient.invalidateQueries({ queryKey: [ ...betsParams ] });
  }

  function settleBets () {
    const idsForRequest = selectedBets.map(({ id }) => id);
    mutBetsSettle.mutate({ ids: idsForRequest });
  }

  return (
    <>
      <Dropdown
        id={DOM_ID + 'void-reason-dropdown'}
        labeled
        button
        className={cx('icon', 'bets__main-void-button')}
        loading={mutVoidBets.isPending || mutBetsSettle.isPending}
        disabled={liveMode || !selectedBets?.length}
        text="Action"
      >
        <Dropdown.Menu>
          <Dropdown.Item id={DOM_ID + 'void-reason-dropdown--settle'} onClick={settleBets}>
            Settle
          </Dropdown.Item>
          <Dropdown.Divider />
          {voidReasonList.map((item: Record<string, string>) => (
            <Dropdown.Item
              key={item.id}
              onClick={voidBets}
              value={item.value}
              id={getOptionWithId(item, DOM_ID + 'void-reason-dropdown')}
            >
              {item.description}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const Filters = () => {
  const {
    withFilters,
    filtersToShow,
    filters
  } = useBetsStore();

  const { filterOnChange } = useBetsActions();
  return (
    <div className={cx(cl.filters, withFilters && cl.filters_active)}>
      {filtersToShow.map(filter => (
        <div key={filter} className={cl.filter}>
          {FILTERS_COMPONENTS[filter].render({
            parentDomId: DOM_ID,
            value: filters[filter],
            action: filterOnChange,
            sportIds: filters[BetsTableFiltersEnumNew.sports]
            // defaultPeriod: DEF_PERIOD,
          })}
        </div>
      ))}
    </div>
  );
};

const MainTable = () => {
  const voidReasonList = useTraiderVoidReasons();
  const {
    liveMode,
    withFilters,
    activeColumnsList,
    betsFetching,
    limit,
    bets,
    selectedBets,
    sorting: {
      sortBy,
      sortDir,
    },
    simpleFetch
  } = useBetsStore();

  const {
    changeSelectedBets,
    changeSort,
    addLimit,
  } = useBetsActions();

  let rowColor: null | 'first' | 'second' = null;

  return (
    <div className={cx(cl.tableWrap, withFilters && cl.tableWrap_active)}>
      <Table
        size="small"
        sortable
        selectable={!betsFetching && !betsFetching && !!bets?.length}
      >
        <TableHeader>
          {/* =========== FIRST ROW =========== */}
          <TableRow className={cl.headerRow}>
            {!liveMode && <TableHeaderCell />}
            {[ ...activeColumnsList, BetsColumnEnum.Actions ].map((column, index) => {
              const { columnSortBy, classes } = BET_CELL_CREATOR[column];

              function sortedOnClick() {
                if (!columnSortBy || liveMode) return;
                changeSort(columnSortBy);
              }

              return (
                <TableHeaderCell
                  key={column + index}
                  id={DOM_ID + column.toLowerCase() + '-column'}
                  sorted={!!columnSortBy && columnSortBy === sortBy ? sortDir : null}
                  onClick={sortedOnClick}
                  className={classes}
                >
                  {NAME_BY_COLUMN[column]}
                </TableHeaderCell>
              );
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {betsFetching && !bets?.length && (
            <TableSkeleton
              rows={limit}
              columns={liveMode ? activeColumnsList?.length + 1 : activeColumnsList?.length + 2}
              rowHeight={3}
            />
          )}
          {!!bets?.length && (
            <>
              {bets?.map((bet, index) => {
                const { betSystemGroupId } = bet;
                if (betSystemGroupId === '0') {
                  rowColor = null;
                } else {
                  if (!rowColor || index === 0) {
                    rowColor = ROW_COLOR_FIRST;
                  } else {
                    rowColor =
                      betSystemGroupId === bets[index - 1].betSystemGroupId
                        ? rowColor
                        : rowColor === ROW_COLOR_FIRST
                        ? ROW_COLOR_SECOND
                        : ROW_COLOR_FIRST;
                  }
                }

                return (
                  <BetsTableRow
                    key={bet.id + bet.date}
                    columns={activeColumnsList}
                    bet={bet}
                    voidReasonList={voidReasonList}
                    className={cx({
                      'bets__row': true,
                      'trade-true': !!bet.traderName,
                      'bets-table__row_first': rowColor === ROW_COLOR_FIRST,
                      'bets-table__row_second': rowColor === ROW_COLOR_SECOND,
                    })}
                    isBetSelected={selectedBets?.some(({ id }) => id === bet.id)}
                    selectBets={() => changeSelectedBets(bet)}
                    showCopyButtons={{
                      userId: true,
                      userName: true,
                      betId: true,
                    }}
                    parentDomId={DOM_ID + 'bets-row-' + (index + 1) + '--'}
                    activeColumnsList={activeColumnsList}
                    liveMode={liveMode}
                    withFilters={withFilters}
                  />
                );
              })}
            </>
          )}
          {!betsFetching && !bets?.length && (
            <TableRow>
              <TableCell
                colSpan={liveMode ? activeColumnsList?.length + 1 : activeColumnsList?.length + 2}
                warning
                textAlign="center"
                style={{ fontSize: '1.5rem', padding: '1.6rem' }}
              >
                No data.
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell
              colSpan={activeColumnsList?.length + 2}
              disabled={!betsFetching && !bets?.length}
            >
              <BetsTablePagination
                currentCount={bets?.length}
                isFetching={simpleFetch}
                limit={limit}
                setLimitState={addLimit}
                showButton={!!bets?.length}
                parentDomId={DOM_ID}
                withLoader={false}
                loaderInside
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export const BetsTableRow: FC<TBetsTableRow> = memo(({
  bet,
  columns,
  voidReasonList,
  className,
  isBetSelected,
  selectBets,
  showCopyButtons,
  parentDomId,
  liveMode,
  withFilters,
  activeColumnsList,
}) => {
  return (
    <TableRow active={isBetSelected} className={cx(
      className,
      {'firstBetRow': bet.firstBets}
    )}>
      {!liveMode && (
        <TableCell className={cl.cell} verticalAlign="top">
          <Checkbox
            id={parentDomId + 'bet-outcome-checkbox'}

            checked={isBetSelected}
            onChange={() => selectBets(bet)}
          />
        </TableCell>
      )}
      {columns.map(column => {
        return (
          <TableCell
            key={column + bet.id}
            className={cx(cl.cell, BET_CELL_CREATOR[column].classes)}
            verticalAlign="top"
          >
            {BET_CELL_CREATOR[column].render(bet, {
              voidReasonList,
              showCopyButtons,
              parentDomId,
              withFilters,
              activeColumnsList
            })}
          </TableCell>
        );
      })}
      <TableCell
        className={cl.cell}
        verticalAlign="top"
      >
        {BET_CELL_CREATOR[BetsColumnEnum.Actions].render(bet, {
          voidReasonList,
          showCopyButtons,
          parentDomId,
          withFilters,
          activeColumnsList
        })}
      </TableCell>
    </TableRow>
  );
});
