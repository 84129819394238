import React from 'react';

import { Button, Dropdown, Form, Message } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import {
  LeaguesPhoenixFieldDropdownWithAxios,
  RegionsPhoenixFieldDropdownWithAxios,
  SportsPhoenixFieldDropdownWithAxios
} from '../../components/form-components/DropdownWithAxios';
import { FilterDatePicker } from '../../components/form-components/FilterDatePicker';
import {
  PhoenixFieldCheckboxType,
  PhoenixFieldDropdown,
  PhoenixFieldTextWithDefaultOrManually
} from '../../components/form-components/FormComponents';
import {
  API_OPPONENT_FIND,
  BEST_OF_SETS_OPTIONS,
  EVENT_TYPES_OPTIONS,
  START_DATE_PARAM,
  TIEBREAK_OPTIONS
} from '../../constants';
import {
  getTextByIdFromDropdownOptions,
  isSportWithAddScoring,
  isSportWithSetsCheck,
  isSportWithTiebreak
} from '../../utils';

const VERY_BIG_LIMIT = {
  limit: 99999,
};

export const EventCreateForm = ({
  setFieldValue,
  isValid,
  isSubmitting,
  values,
  handleSubmit,
  errors,
}) => {
  const onChange = React.useCallback((_e: any, data: any) => {
    if (data) {
      setFieldValue(data.name, data.value);
    }
  },[ setFieldValue ]);

  const onChangeStartDate = React.useCallback((data: any) => (
    setFieldValue(START_DATE_PARAM, data)
  ),[ setFieldValue ]);

  const onChangeSport = React.useCallback(() => {
    setFieldValue('regionId', '');
    setFieldValue('leagueId', '');
    setFieldValue('opponentHomeId', '');
    setFieldValue('tieBreak', undefined);
    setFieldValue('bestOfSets', undefined);
    setFieldValue('noAdScoring', false);
  },[ setFieldValue ]);

  const opponentsConfig = React.useMemo(() => {
    if (!values.leagueId) return null;
    return ({
      url: API_OPPONENT_FIND,
      params: {
        eventPathId: values.leagueId,
        ...VERY_BIG_LIMIT,
      }
    });
  },[ values.leagueId ]);

  const { data: opponentsData, isFetching: opponentsIsFetching } = useAxiosRequest<{
    result: Array<{ id: string; description: string }>;
  }>(opponentsConfig);

  const opponents = React.useMemo(() => {
    if (values.leagueId && opponentsData) {
      return opponentsData.result.map(({ id, description }) => ({
        value: id,
        text: description,
      }));
    }
    return [];
  }, [ opponentsData, values.leagueId ]);

  const opponentsAway = React.useMemo(() => {
    return values.opponentHomeId
      ? opponents.filter(({ value }) => value !== values.opponentHomeId)
      : opponents;
  }, [ opponents, values.opponentHomeId ]);

  const opponentsDynamicDefaultValue = React.useMemo(() => {
    const opponentHome = getTextByIdFromDropdownOptions(opponents, values.opponentHomeId);
    const opponentAway = getTextByIdFromDropdownOptions(opponents, values.opponentAwayId);
    if (opponentHome || opponentAway) {
      return `${opponentHome} - ${opponentAway}`;
    }

    return '';
  }, [ opponents, values.opponentHomeId, values.opponentAwayId ]);

  return (
    <Form
      className="content-container"
      onSubmit={handleSubmit}
    >
      <Form.Group widths="equal">
        <Form.Field required>
          <label>Start date</label>
          <FilterDatePicker
            id="event-create-field-start-date"
            name="startDate"
            selected={values.startDate}
            onChange={onChangeStartDate}
            showTimeSelect
          />
        </Form.Field>
        <Form.Field required>
          <label>Event type</label>
          <Dropdown
            id="event-create-field-type"
            name="type"
            value={values.type}
            options={EVENT_TYPES_OPTIONS}
            onChange={onChange}
            selection
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <SportsPhoenixFieldDropdownWithAxios
          id="event-create-field-sport-id"
          name="sportId"
          label="Sport"
          required
          customOnChange={onChangeSport}
        />
        <RegionsPhoenixFieldDropdownWithAxios
          id="event-create-field-region-id"
          name="regionId"
          label="Region"
          sports={values.sportId}
          required
        />
        <LeaguesPhoenixFieldDropdownWithAxios
          id="event-create-field-league-id"
          name="leagueId"
          label="League"
          regions={values.regionId}
          required
        />
      </Form.Group>

      <Form.Group widths="equal">
        <PhoenixFieldDropdown
          id="event-create-field-opponent-home-id"
          required={values.type === 'GAME'}
          label="Home opponent"
          name="opponentHomeId"
          options={opponents}
          disabled={!values.leagueId}
          loading={opponentsIsFetching}
        />
        <PhoenixFieldDropdown
          id="event-create-field-opponent-away-id"
          required={values.type === 'GAME'}
          label="Away opponent"
          name="opponentAwayId"
          options={opponentsAway}
          disabled={!values.leagueId || !values.opponentHomeId}
          loading={opponentsIsFetching}
        />
      </Form.Group>

      {isSportWithSetsCheck(values.sportId) && (
        <Form.Group widths="4">
          <PhoenixFieldDropdown
            id="event-create-field-bets-of-sets"
            name="bestOfSets"
            label="Best of sets"
            options={BEST_OF_SETS_OPTIONS}
            disabled={!isSportWithSetsCheck(values.sportId)}
            required={isSportWithSetsCheck(values.sportId)}
          />
        </Form.Group>
      )}
      <Form.Group widths="4">
        {isSportWithTiebreak(values.sportId) && (
          <PhoenixFieldDropdown
            id="event-create-field-tie-break"
            name="tieBreak"
            label="Tiebreak"
            options={TIEBREAK_OPTIONS}
            disabled={!isSportWithSetsCheck(values.sportId)}
            required={isSportWithSetsCheck(values.sportId)}
          />
        )}
        {isSportWithAddScoring(values.sportId) && (
          <PhoenixFieldCheckboxType
            id="event-create-field-no-ad-scoring"
            name="noAdScoring"
            label="No-Ad scoring"
            value={false}
          />
        )}
      </Form.Group>

      <PhoenixFieldTextWithDefaultOrManually
        id="event-create-field-description"
        name="description"
        label="Description"
        required
        dynamicDefaultValue={opponentsDynamicDefaultValue}
      />

      {errors.submit && <Message negative>{errors.submit}</Message>}
      <Button
        id="event-create-submit"
        type="submit"
        className="ui positive button"
        disabled={!isValid}
        loading={isSubmitting}
        content="Save Event"
      />
    </Form>
  );
};
