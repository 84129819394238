import React from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './FilterDatePicker.css';

const convertToDate = (v: string | Date) => (v instanceof Date ? v : moment(v).toDate());

export const FilterDatePicker = ({
  placeholder,
  onChange,
  selected,
  handleSevenDaysClick,
  minDate,
  disabled,
  showTimeSelect = false,
  shouldCloseOnSelect = false,
  onBlur,
  position = 'top-end',
  id,
}: {
  placeholder?: string;
  onChange?: (data: Date) => void;
  selected: string | null | undefined | Date;
  handleSevenDaysClick?: (arg0) => void;
  minDate?: Date | string;
  disabled?: boolean;
  showTimeSelect?: boolean;
  shouldCloseOnSelect?: boolean;
  onBlur?: () => void;
  position?: string;
  id: string;
}): React.ReactElement<'div'> => {
  const isSameMinDate = minDate && moment(selected).isSame(minDate, 'day');
  const minTime = isSameMinDate ? convertToDate(minDate) : null;
  const maxTime = isSameMinDate ? moment(minDate).set({ hour: 23, minute: 50 }).toDate() : null;

  return (
    <div>
      <DatePicker
        id={id}
        placeholderText={placeholder}
        minDate={minDate ? convertToDate(minDate) : null}
        maxTime={maxTime}
        minTime={minTime}
        selected={selected ? convertToDate(selected) : null}
        onChange={onChange}
        showTimeSelect={showTimeSelect}
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat={showTimeSelect ? 'dd MMM yyyy HH:mm' : 'dd MMM yyyy'}
        isClearable={!disabled}
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        disabled={disabled}
        shouldCloseOnSelect={shouldCloseOnSelect}
        onBlur={onBlur}
        popperPlacement={position}
      >
        {handleSevenDaysClick && (
          <div className="react-datepicker__handleSevenDaysClick" onClick={handleSevenDaysClick}>
            Select next 7 days
          </div>
        )}
      </DatePicker>
    </div>
  );
};
