import React from 'react';

import qs from 'qs';
import { useAxiosRequest } from 'use-axios-request';

import { BetsTable } from './BetsTable/BetsTable';
import { BetsTablePagination } from '../../components/table-components/TablePagination';
import {
  API_BETS,
  BetsSortByEnum,
  BetsTablePageType
} from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { useSortQuery } from '../../hooks-and-global-states/history-hooks';
import { BetsColumnLabelType, BetsRequest } from '../../types';

type TBetsTableSimple = {
  params: BetsRequest;
  pageType: string;
  columns?: Array<BetsColumnLabelType>;
  pollInterval?: number;
  parentDomId: string;
}
export const BetsTableSimple = ({
  params,
  pageType,
  columns,
  pollInterval,
  parentDomId,
}: TBetsTableSimple) => {
  const { sortDir, sortBy, setSort, sortDirForSemanticTable } = useSortQuery({
    sortBy: [ BetsTablePageType.LARGE_SINGLE, BetsTablePageType.LARGE_MULTIPLE ].includes(pageType)
      ? BetsSortByEnum.BET_SORT_STAKE
      : BetsSortByEnum.BET_SORT_DATE,
  });

  const [ limitState, setLimitState ] = React.useState(params.limit);
  const { originIdAllParam } = useGlobalStateContext();

  const config = React.useMemo(() => {
    return {
      url: API_BETS,
      params: {
        ...params,
        sortBy: [ sortBy ],
        sortDir: sortDir as 'ASC' | 'DESC',
        limit: limitState,
        originId: originIdAllParam,
      },
      paramsSerializer: (v: any) => qs.stringify(v, { arrayFormat: 'repeat' }),
    };
  }, [ params, sortBy, sortDir, limitState, originIdAllParam ]);

  const { isFetching, data, refresh } = useAxiosRequest<any>(
    config,
    { pollInterval: pollInterval }
  );
  const bets = Array.isArray(data?.result) ? data.result : [];

  return (
    <BetsTable
      columns={columns}
      isFetching={isFetching}
      bets={bets}
      refresh={refresh}
      sortDir={sortDirForSemanticTable}
      setSort={setSort}
      sortBy={sortBy}
      limit={limitState}
      pagination={
        <BetsTablePagination
          currentCount={bets.length}
          isFetching={isFetching}
          limit={limitState}
          setLimitState={setLimitState}
          parentDomId={parentDomId}
        />
      }
      parentDomId={parentDomId}
    />
  );
};
