import React from 'react';

import { toast } from 'react-toastify';
import { Checkbox, Divider, Grid, Header, List } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { API_MARKETS_UPDATE } from '../../../constants';
import { SingleByEventMarketType } from '../../../types';

export const IrmBoardTable = ({
  originId,
  markets,
}: {
  markets: Array<SingleByEventMarketType>;
  originId: string;
}) => {
  return (
    <Grid.Column width={7} style={{ backgroundColor: '#e0e1e2' }}>
      <Header as="h3">IRM Board</Header>
      <Divider />
      <div
        style={{
          marginTop: '0px',
          fontSize: '9pt',
          color: 'grey',
        }}
      >
        Displayed
      </div>
      <List>
        {markets.map((market, index) => (
          <MarketRowIrmBoard market={market} originId={originId} key={index} />
        ))}
      </List>
    </Grid.Column>
  );
};

const MarketRowIrmBoard = ({
  market,
  originId,
}: {
  market: SingleByEventMarketType;
  originId: string;
}) => {
  const { update } = useAxiosRequest(null, {
    onSuccess: data => {
      const action = data['result'][0]['showInIRM'] ? 'displayed' : 'hidden';
      toast.success(`Market will be ${action} on IRM board`);
    },
  });

  function changeMarketIrmStatus (marketId, showInIRM) {
    update({
      method: 'put',
      url: API_MARKETS_UPDATE,
      data: {
        originId: originId,
        markets: [
          {
            id: marketId,
            showInIRM: !showInIRM,
          }
        ],
      },
    });
  }

  return (
    <List.Item>
      <Checkbox
        slider
        key={market.market.id}
        label={market.market.description + ' (' + market.market.periodDescription + ')'}
        onChange={() => {
          changeMarketIrmStatus(market.market.id, market.showInIRM);
        }}
        checked={market.showInIRM}
      />
    </List.Item>
  );
};
