import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Form, Header } from 'semantic-ui-react';
import { number, object } from 'yup';

import { AxiosRequest } from '../../components/fetch-components/AxiosRequest';
import { EventPathSubmitButton, PhoenixField } from '../../components/form-components/FormComponents';
import { API_EVENT_PATH_UPDATE, API_IN_RUNNING_DELAY } from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { FieldTypesEnum } from '../../types';
import { prepareDataForInRunningDelay } from '../../utils';

type InRunningDelaySettings = {
  inRunningDelay: number;
  toleranceRate: number;
  isInRunningDelayInherited: number;
  isToleranceRateInherited: number;
};

const InRunningSchema = object().shape({
  toleranceRate: number().min(0, ' '),
  inRunningDelay: number().min(0, ' '),
});

export const InRunningDelayComponent = ({
  eventPathId,
  parentDomId,
  originId,
  revalidationParentKey,
}: {
  eventPathId: string;
  parentDomId: string;
  originId: string;
  revalidationParentKey?: string;
}) => (
  <>
    <Header>InRunning Delay</Header>
    <InRunningDelayEventPath
      parentDomId={parentDomId}
      eventPathId={eventPathId}
      originId={originId}
      revalidationParentKey={revalidationParentKey}
    />
  </>
);

const InRunningDelayEventPath = ({
  eventPathId,
  parentDomId,
  originId,
  revalidationParentKey,
}: {
  eventPathId: string;
  parentDomId: string;
  originId: string;
  revalidationParentKey: string;
}) => {
  return (
    <AxiosRequest
      config={{
        url: API_IN_RUNNING_DELAY,
        params: {
          eventPathId,
          originId,
        },
      }}
    >
      {({ data }) => (
        <InRunningDelayForm
          parentId={parentDomId}
          id={eventPathId}
          initialValues={data.result}
          params={{ eventPathId, originId }}
          revalidationParentKey={revalidationParentKey}
        />
      )}
    </AxiosRequest>
  );
};

const InRunningDelayForm = ({
  initialValues,
  id,
  params,
  parentId,
  revalidationParentKey,
}: {
  initialValues: InRunningDelaySettings;
  id: string;
  params: Record<string, string>;
  parentId: string;
  revalidationParentKey: string;
}) => {
  const queryClient = useQueryClient();

  const { originId } = useGlobalStateContext();

  async function omSubmit (values, { setSubmitting, resetForm }) {
    try {
      const dataForRequest = {
        id,
        inrunningSettings: prepareDataForInRunningDelay(values),
        version: Date.now() + 1,
        originId,
      };

      await axios.put(API_EVENT_PATH_UPDATE, dataForRequest);

      const { data: updatedData } = await axios(API_IN_RUNNING_DELAY, { params });

      resetForm({ values: { ...updatedData.result }});

      toast.success('InRunning Delay Settings have been updated');

      if (revalidationParentKey) {
        await queryClient.invalidateQueries({
          queryKey: [ revalidationParentKey ]
        });
      }
    } catch (error) {
      toast.error(error);
    }

    setSubmitting(false);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={omSubmit}
      validationSchema={InRunningSchema}
    >
      {({ handleSubmit, values }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <PhoenixField
              id={parentId + 'inrunning-delay--tolerance-rate-input'}
              name="toleranceRate"
              label="Tolerance Rate"
              readOnly={values.isToleranceRateInherited}
              disabled={values.isToleranceRateInherited}
              type={FieldTypesEnum.NUMBER}
            />
            <PhoenixField
              id={parentId + 'inrunning-delay--in-running-delay-input'}
              name="inRunningDelay"
              label="InRunning Delay"
              readOnly={values.isInRunningDelayInherited}
              disabled={values.isInRunningDelayInherited}
              type={FieldTypesEnum.NUMBER}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <PhoenixField
              id={parentId + 'inrunning-delay--tolerance-rate-checkbox'}
              name="isToleranceRateInherited"
              label="Inherited"
              type={FieldTypesEnum.CHECKBOX}
            />

            <PhoenixField
              id={parentId + 'inrunning-delay--in-running-delay-checkbox'}
              name="isInRunningDelayInherited"
              label="Inherited"
              type={FieldTypesEnum.CHECKBOX}
            />
          </Form.Group>

          <EventPathSubmitButton id={parentId + 'inrunning-delay--submit-btn'} />
        </Form>
      )}
    </Formik>
  );
};
