import React from 'react';

import { QueryStateDropdown } from '../../components/search-and-filters-components/QueryStateInputs';

const RiskDropdownOptions = [
  {
    text: 'Top 100',
    value: 'top-100',
  },
  {
    text: 'Over $25,000',
    value: 'over-25000',
  },
  {
    text: '$10,000 - $25,000',
    value: '10000-25000',
  },
  {
    text: '$5,000 - $10,000',
    value: '5000-10000',
  },
  {
    text: '$1,000 - $5,000',
    value: '1000-5000',
  },
  {
    text: '$500 - $1000',
    value: '500-1000',
  },
  {
    text: 'below $500',
    value: '0-500',
  }
];

export const RiskSelector = () => (
  <QueryStateDropdown
    id="risk-manager--risk-range-dropdown"
    name="risk"
    options={RiskDropdownOptions}
    defaultValue={RiskDropdownOptions[0].value}
    clearable={false}
  />
);
