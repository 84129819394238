import { memo, useMemo } from 'react';

import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { $Keys } from 'utility-types';

import { MarketEditForm } from './MarketEditForm';
import { BOOK_TYPES } from '../../../constants';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';
import { useMarketUpdate } from '../../../react-query/mutations';
import { useMarket } from '../../../react-query/query';
import { EventOutletContextType, MarketType, TOS } from '../../../types';
import { prepareDataForMarketEditModal, removeEmptyFields, removeExtraSpaces } from '../../../utils';

const INITIAL_STATE = {
  id: null,
  marketTypeId: null,
  periodId: null,
  status: null,
  hideMode: null,
  homeSpread: null,
  awaySpread: null,
  description: null,
  cutoffTime: null,
  cashoutInherited: null,
  cashoutEnabled: null,
  opponentId: null,
  outcomes: [
    { description: '', price: '', opponentId: null },
    { description: '', price: '', opponentId: null }
  ],
  isBetBuilderMarket: null,
};

export const MarketEdit = memo<{
  marketId: string;
  marketBookType: $Keys<typeof BOOK_TYPES>;
  marketManualCreated: boolean;
  closeModal: () => void;
  marketManualMode?: boolean;
  parentDomId: string;
}>(({
  marketId,
  marketManualCreated,
  closeModal,
  marketManualMode,
  marketBookType,
  parentDomId,
}) => {
  const { originId } = useGlobalStateContext();
  const { event } = useOutletContext<EventOutletContextType>();

  const mutMarketUpdate = useMarketUpdate({
    onSuccess
  });

  const { data, isFetching } = useMarket({
    params: {
      eventId: event.id,
      originId: originId
    },
  });

  const initialFormValue = useMemo(() => {
    const markets = data && Array.isArray(data) ? data : [];
    const market = markets.find(({ id }) => id === marketId);

    if (!market) {
      return INITIAL_STATE;
    }

    return {
      id: market.id,
      marketTypeId: market.type,
      periodId: market.periodId,
      status: market.status,
      hideMode: market.hideMode,
      homeSpread: market.homeSpread,
      awaySpread: market.awaySpread,
      description: market.description,
      cutoffTime: market.cutoffTime,
      cashoutInherited: market.cashoutInherited,
      cashoutEnabled: market.cashoutEnabled,
      opponentId: market.opponentId === '0' ? null : market.opponentId,
      outcomes: market.outcomes.map((outcome: TOS) => {
        const initOutcome: TOS = { ...outcome, price: String(outcome.price) };
        if (outcome.opponentId === '0') initOutcome.opponentId = null;

        return initOutcome;
      }),
      isBetBuilderMarket: market.isBetBuilderMarket,
    };
  }, [ data, marketId ]);

  function submit (values: MarketType) {
    let isPriceEmpty = false;

    const copiedValues = structuredClone(values);

    const valuesWithoutSpaces = {
      ... copiedValues,
      description: removeExtraSpaces(copiedValues.description),
      outcomes: copiedValues.outcomes.map(outcome => {
        if (outcome.price === '') {
          isPriceEmpty = true;
        }
        return {
          ...outcome, description: removeExtraSpaces(outcome.description)
        };
      })
    };

    const touchedFields = prepareDataForMarketEditModal(initialFormValue, valuesWithoutSpaces);

    if (!touchedFields) {
      closeModal();
    }

    const dataForRequest = removeEmptyFields({
      originId,
      id: valuesWithoutSpaces.id,
      cashoutInherited: valuesWithoutSpaces.cashoutInherited,
      cashoutEnabled: valuesWithoutSpaces.cashoutEnabled,
      /** Remove extra spaces https://jira.offsidegaming.com/browse/FNX-3790 */
      description: touchedFields.description,
      hideMode: isPriceEmpty ? true : touchedFields.hideMode,
      status: touchedFields.status,
      homeSpread: touchedFields.homeSpread,
      awaySpread: touchedFields.awaySpread,
      opponentId: touchedFields.opponentId,
      cutoffTime: touchedFields.cutoffTime,
      outcomes: touchedFields.outcomes?.map((outcome: TOS) => {
        const outcomeToRequest = {
          id: outcome.id,
          description: outcome.description,
          price: !outcome.price || !Number(outcome.price) ? null : outcome.price,
          opponentId: outcome.opponentId,
          hideModeTrader: outcome.hideModeTrader,
          result: outcome.result === 'NO_RESULT' ? null : outcome.result,
          voidReason: outcome.voidReason,
          ordinalPosition: outcome.ordinalPosition,
        };
        if (!outcomeToRequest.price) delete outcomeToRequest.price;

        return outcomeToRequest;
      }),
    });

    mutMarketUpdate.mutate(dataForRequest);
  }

  async function onSuccess () {
    toast.success('Market has been updated');
    closeModal();
  }

  return (
    <MarketEditForm
      onClose={closeModal}
      submit={submit}
      manuallyCreated={marketManualCreated}
      marketManualMode={marketManualMode}
      editing
      initialValues={initialFormValue}
      originId={originId}
      marketBookType={marketBookType}
      fetchingValues={isFetching}
      parentDomId={parentDomId}
    />
  );
});
