import React from 'react';

import moment from 'moment/moment';
import { Button, Checkbox, Header, Message } from 'semantic-ui-react';

import { EventPeriodStatusBlock } from './EventPeriodStatusBlock';
import {
  DATE_FORMAT_3_DIGIT_MONTH_TIME,
  SPORT_ID_GREYHOUNDS,
  SPORT_ID_HORSE_RASING,
  TRADER_PERM_ALL,
  TRADER_PERM_EVENT_EDITOR
} from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { EventType } from '../../types';
import './EventCard.scss';

export const EventCard = ({
  setModalStatus,
  setModalDescription,
  event,
}:{
  setModalStatus: (arg: object) => void,
  setModalDescription: (arg: string) => void,
  event: EventType,
}) => {
  const {
    id,
    sportDescription,
    regionDescription,
    leagueDescription,
    startDate,
    feedCode,
    eachWayTerms,
    sportId,
    description,
    hideMode,
    mainEventId,
    manualCreated,
  } = event;

  const { permissions } = useGlobalStateContext();
  const enableEditing =
    !permissions?.denyPermissions?.includes(TRADER_PERM_EVENT_EDITOR) &&
    [ TRADER_PERM_EVENT_EDITOR, TRADER_PERM_ALL ].some(permission =>
      permissions?.allowPermissions?.includes(permission)
    );

  const copyEvent = () => {
    setModalStatus({
      isOpen: true,
      copying: true,
    });
    setModalDescription('This operation will create a copy of this event');
  };

  const showEventToggleOnChange = () => {
    setModalStatus({
      isOpen: true,
      copying: false,
    });
    setModalDescription(
      event.hideMode
        ? 'Do you want to show event on the site?'
        : 'After applying event will be hidden from the site'
    );
  };

  return (
    <Message className="event-card__message">
      <div className="event-card__message-wrapper">
        <Header as="h5"># {id}</Header>
        <i>{moment(startDate).format(DATE_FORMAT_3_DIGIT_MONTH_TIME)}</i>
        <br />
        {sportDescription} / {regionDescription} / {leagueDescription}
        <Header as="h4">
          <div>
            {description} ({feedCode})
          </div>
        </Header>
        {sportId === (SPORT_ID_HORSE_RASING || SPORT_ID_GREYHOUNDS) && (
          <div>{'Each way ' + eachWayTerms}</div>
        )}
      </div>
      {enableEditing && (
        <div>
          <Checkbox
            id="event-edit-show-event-checkbox"
            toggle
            label="Show event on site"
            checked={!hideMode}
            onChange={showEventToggleOnChange}
          />
          {!(mainEventId !== '0' || manualCreated) && (
            <div className="event-card__copy-button">
              <Button
                id="event-edit-copy-event-button"
                size="tiny"
                onClick={copyEvent}
                content="Copy Event"
              />
            </div>
          )}
          <EventPeriodStatusBlock event={event} />
        </div>
      )}
    </Message>
  );
};
