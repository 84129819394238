import { useCallback, useMemo, useState } from 'react';

import axios, { AxiosRequestConfig } from 'axios';
import cx from 'classnames';
import { isEqual } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { Button, Icon, Segment, Message, Portal, Table } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { QueryDropdownWithAxios } from '../../components/form-components/DropdownWithAxios';
import { TableSkeleton } from '../../components/UI/TableSkeleton';
import {
  API_MARKET_TYPE_OVERRIDE_LIST,
  API_MARKET_TYPE_OVERRIDE_RESET,
  API_MARKET_TYPE_OVERRIDE_UPDATE,
  API_SPORTS_FIND
} from '../../constants';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import { MarketOverrideType } from '../../types';
import './MarketTypeOverride.scss';

export const MarketTypeOverride = () => {
  const { queryState, setQueryStateWithParams } = useQueryState();
  const [ list, setList ] = useState([]);
  const [ initialState, setInitialState ] = useState([]);
  const [ showPortal, setShowPortal ] = useState(false);

  const sportConfig: AxiosRequestConfig = useMemo(() => ({
    url: API_SPORTS_FIND,
    params: {
      limit: 99999,
    },
  }),[]);

  const listConfig: AxiosRequestConfig = useMemo(() => {
    if (!queryState.sportIds) return null;
    return ({
      url: API_MARKET_TYPE_OVERRIDE_LIST,
      params: { sportId: queryState.sportIds },
    });
  },[ queryState.sportIds ]);

  const updateConfig: AxiosRequestConfig = useMemo(() => {
    const updateList = [ ...list ].map(item => {
      // eslint-disable-next-line
      const { dndId, ...other } = item;
      return other;
    });
    return ({
      method: 'PUT',
      url: API_MARKET_TYPE_OVERRIDE_UPDATE,
      data: {
        sportId: queryState.sportIds,
        overrides: updateList
      },
    });
  },[ list, queryState.sportIds ]);

  const { isFetching: isListFetch, refresh } = useAxiosRequest<any>(listConfig, {
    onSuccess: data => {
      const sortList = data.result.map((item: object, index: number) => ({ ...item, dndId: index }));
      setList([ ...sortList ]);
      setInitialState([ ...sortList ]);
    },
    onError: error => {
      toast.error(error.message);
      setList([]);
    }
  });

  const { update, isFetching: isSaveFetch } = useAxiosRequest(null, {
    onSuccess: () => {
      setInitialState([ ...list ]);
      toast.success('Market\'s order was successfully changed');
    }
  });

  const isArraysEqual = isEqual( initialState, list );

  const changeSport = useCallback((_name: string, value: string | number) => {
    setQueryStateWithParams({ sportIds: String(value) });
  }, [ setQueryStateWithParams ]);

  function saveChanges () {
    update(updateConfig);
  }

  function onClickReset () {
    setShowPortal(true);
  }

  async function resetChanges () {
    await axios
      .delete(
        API_MARKET_TYPE_OVERRIDE_RESET, {
          params: { sportId: queryState.sportIds }
        }
      )
      .then(() => {
        refresh();
        setShowPortal(false);
      });
  }

  function cancelReset () {
    setShowPortal(false);
  }

  function handleOnDragEnd (result: any) {
    if (!result.destination) return;

    const items = reorder(
      list,
      result.source.index,
      result.destination.index
    );
    const itemsWithUpdatedOrder = items.map((item, index) => ({
      ...item,
      ordinalPosition: index + 1
    }));

    setList(itemsWithUpdatedOrder);
  }

  function changeDown (index: number) {
    if (index === list.length) return;

    setList([
      ...list.slice(0, index),
      { ...list[ index + 1 ], ordinalPosition: list[ index ].ordinalPosition },
      { ...list[ index ], ordinalPosition: list[ index + 1 ].ordinalPosition },
      ...list.slice(index + 2)
    ]);
  }

  function changeUp (index: number) {
    if (index === 0) return;

    setList([
      ...list.slice(0, index - 1),
      { ...list[ index ], ordinalPosition: list[ index -1 ].ordinalPosition },
      { ...list[ index - 1 ], ordinalPosition: list[ index ].ordinalPosition },
      ...list.slice(index + 1)
    ]);
  }

  function reorder (list: MarketOverrideType[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [ removed ] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  return (
    <>
      <Portal open={showPortal}>
        <Segment className="market-override__reset-portal">
          <p>
            Are you sure you want to reset the sorting of markets to default?
            This will affect all events of the selected sport
          </p>
          <div>
            <Button
              id="market-override--reset-sorting--reset-btn"
              content="Reset"
              positive
              onClick={resetChanges}
            />
            <Button
              id="market-override--reset-sorting--cancel-btn"
              content="Cancel"
              negative
              onClick={cancelReset}
            />
          </div>
        </Segment>
      </Portal>

      <div className="market-override-container">

        <h1>Market Type</h1>

        <div className="market-override-filters">
          <QueryDropdownWithAxios
            id="market-override--sport-id-input"
            value={queryState.sportIds || ''}
            axiosConfig={sportConfig}
            placeholder="Sports"
            name="sportIds"
            onChange={changeSport}
          />

          {queryState.sportIds &&
            <Button
              id="market-override--reset-sorting-open-popup-btn"
              content="Reset to default"
              disabled={showPortal}
              onClick={onClickReset}
            />
          }

          <Button
            id="market-override--save-changes-btn"
            content="Save"
            disabled={isArraysEqual || isSaveFetch}
            onClick={saveChanges}
            loading={isSaveFetch}
          />
        </div>

        <Segment className="market-override-segment market-override-segment_header">
          <div className="market-override-segment__item"></div>
          <div className="market-override-segment__item"></div>
          <div className="market-override-segment__item"></div>
          <div className="market-override-segment__item">Order</div>
          <div className="market-override-segment__item">Name</div>
          <div className="market-override-segment__item">Id</div>
        </Segment>

        {isListFetch &&
          <Table style={{ margin: 0 }}>
            <Table.Body>
              <TableSkeleton
                rows={5}
                columns={3}
                rowHeight={2}
              />
            </Table.Body>
          </Table>
        }

        {!isListFetch && !list.length &&
          <Message warning className="market-override-message">
            <Message.Header>No data</Message.Header>
          </Message>
        }

        {!isListFetch && !!list.length &&
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {provided => (
                <div
                  className="market-override-segment-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {list.map(({ ordinalPosition, description, marketTypeId, dndId }, index) => {
                    return (
                      <Draggable
                        key={String(dndId)}
                        draggableId={String(dndId)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Segment
                              className={cx(
                                'market-override-segment',
                                snapshot.isDragging && 'market-override-segment_drag'
                              )}
                            >
                              <div className="market-override-segment__item">
                                <Button
                                  id={`market-override--change-position-up-btn--id-${marketTypeId}`}
                                  compact
                                  icon="arrow up"
                                  onClick={() => changeUp(index)}
                                  disabled={index === 0}
                                />
                              </div>
                              <div className="market-override-segment__item">
                                <Button
                                  id={`market-override--change-position-down-btn--id-${marketTypeId}`}
                                  compact
                                  icon="arrow down"
                                  onClick={() => changeDown(index)}
                                  disabled={index + 1 === list.length}
                                />
                              </div>
                              <div className="market-override-segment__item">
                                <Icon className="drag-icon" name="bars" />
                              </div>
                              <div className="market-override-segment__item">{ordinalPosition}</div>
                              <div className="market-override-segment__item">{description}</div>
                              <div className="market-override-segment__item">{marketTypeId}</div>
                            </Segment>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        }

      </div>
    </>
  );
};
