import { ReactNode, useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';
import qs from 'qs';
import { toast } from 'react-toastify';
import { Header, Grid } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { BetsTable } from './BetsTable/BetsTable';
import { FiltersForBetsTable } from './FiltersForBetsTable';
import { getParamsWithFilters } from './helpers';
import { BetsTablePagination } from '../../components/table-components/TablePagination';
import {
  BetsTablePageType,
  BetsSortByEnum,
  API_BETS,
  SHORT_POLL_INTERVAL,
  LONG_POLL_INTERVAL,
  MIN_STAKE_FILTER,
  MAX_STAKE_FILTER,
  ORIGIN_IDS_FILTER,
  BET_REJECTED_REASON_FILTER,
  EVENT_ID_FILTER,
  BET_ACCEPTED_FILTER,
  BET_ACCEPTED_STATUS_DROPDOWN_OPTIONS,
  SORT_BY_FILTER_QUERY
} from '../../constants';
import { useQueryState, useSortQuery } from '../../hooks-and-global-states/history-hooks';
import { BetsRequest, BetsColumnLabelEnum, BetsTableFiltersEnum, BetType } from '../../types';

const FILTERS_FOR_LONG_POLLING: string[] = [
  MIN_STAKE_FILTER,
  MAX_STAKE_FILTER,
  ORIGIN_IDS_FILTER,
  BET_REJECTED_REASON_FILTER,
  EVENT_ID_FILTER
];

type TBetsTableWithFilters = {
  pageType: string;
  columns: Array<keyof typeof BetsColumnLabelEnum>;
  filters?: Array<keyof typeof BetsTableFiltersEnum>;
  tableName?: string | ReactNode;
  initialParams: BetsRequest;
  enablePollInterval?: boolean;
  startPollInterval?: number;
  filterParams?: {
    sportId?: string;
    eventId?: string;
    period?: string;
  };
  showCopyButtons?: {
    userId?: boolean;
    betId?: boolean;
    userName?: boolean;
  };
  stopSorting?: boolean;
  parentDomId: string;
  defaultPeriod: string;
  intervalsOptionsType?: string;
}

export const BetsTableWithFilters = ({
  pageType,
  columns,
  filters,
  tableName,
  initialParams,
  enablePollInterval,
  startPollInterval = SHORT_POLL_INTERVAL,
  filterParams,
  showCopyButtons,
  stopSorting = false,
  parentDomId,
  defaultPeriod,
  intervalsOptionsType,
}: TBetsTableWithFilters) => {
  const {
    sortDir,
    sortBy,
    setSort,
    sortDirForSemanticTable
  } = useSortQuery({
    [SORT_BY_FILTER_QUERY]:
      [ BetsTablePageType.LARGE_SINGLE, BetsTablePageType.LARGE_MULTIPLE ].includes(pageType)
        ? BetsSortByEnum.BET_SORT_STAKE
        : BetsSortByEnum.BET_SORT_DATE,
  });
  const { queryState } = useQueryState();

  const [ bets, setBets ] = useState<BetType[]>(null);
  const [ selectedBets, setSelectedBets ] = useState<BetType[]>([]);
  const [ limitState, setLimitState ] = useState(initialParams.limit);
  const [ oldQueryState, setOldQueryState ] = useState(queryState);
  const [ pollIntervalState, setPollIntervalState ] = useState(startPollInterval);

  const liveMode = queryState.liveMode === null ||
    queryState.liveMode === undefined ||
    queryState.liveMode;

  const betsConfig = useMemo(() => {
    const defaultParams = {
      period: !liveMode ? defaultPeriod : '',
      ...queryState
    };
    const params = getParamsWithFilters(initialParams, defaultParams);

    return {
      url: API_BETS,
      params: {
        ...params,
        sortBy: sortBy,
        sortDir: sortDir as 'ASC' | 'DESC',
        limit: limitState,
      },
      paramsSerializer: (v: any) => qs.stringify(v, { arrayFormat: 'repeat' }),
    };
  }, [ defaultPeriod, initialParams, limitState, liveMode, queryState, sortBy, sortDir ]);

  const { isFetching: isFetchBets, refresh } = useAxiosRequest<any>(
    betsConfig,
    {
      pollInterval: enablePollInterval && pollIntervalState,
      onSuccess: data => {
        if (!isEqual(data.result, bets)) {
          setBets(data?.result || []);
        }
        setOldQueryState(queryState);
      },
      onError: error => toast.error(error.message)
    }
  );

  const isFetchingTable = useMemo(() => {
    return isFetchBets && (!isEqual(queryState, oldQueryState) || bets === null);
  }, [ bets, isFetchBets, oldQueryState, queryState ]);

  useEffect(() => {
    if (liveMode) {
      setSelectedBets([]);
    }
  }, [ liveMode ]);

  /** Effect for change polling period */
  useEffect(() => {
    const currentFilters = Object.keys(queryState);

    const sortingIf = currentFilters.includes(SORT_BY_FILTER_QUERY) &&
      queryState[SORT_BY_FILTER_QUERY] !== BetsSortByEnum.BET_SORT_DATE;

    const betAcceptedIf = currentFilters.includes(BET_ACCEPTED_FILTER) &&
      queryState[BET_ACCEPTED_FILTER] === BET_ACCEPTED_STATUS_DROPDOWN_OPTIONS[1].value ;

    if (currentFilters.some(f => FILTERS_FOR_LONG_POLLING.includes(f))) {
      setPollIntervalState(LONG_POLL_INTERVAL);
    } else if (sortingIf || betAcceptedIf) {
      setPollIntervalState(LONG_POLL_INTERVAL);
    } else {
      setPollIntervalState(startPollInterval);
    }
  // eslint-disable-next-line
  }, [ queryState ]);

  const Pagination =
    <BetsTablePagination
      currentCount={bets?.length}
      isFetching={isFetchBets}
      limit={limitState}
      setLimitState={setLimitState}
      showButton={!!bets?.length}
      parentDomId={parentDomId}
    />;

  return (
    <Grid columns="equal" className="singles-table">
      {!!filters?.length && (
        <FiltersForBetsTable
          refreshBets={refresh}
          selectedBets={selectedBets}
          setSelectedBets={setSelectedBets}
          filters={filters}
          filterParams={filterParams}
          parentDomId={parentDomId}
          defaultPeriod={defaultPeriod}
          intervalsOptionsType={intervalsOptionsType}
        />
      )}
      <Grid.Column columns={13}>
        {tableName &&
          <Header className="bets-table__header">
            {tableName}
          </Header>
        }
        <BetsTable
          columns={columns}
          isFetching={isFetchingTable}
          bets={bets || []}
          refresh={refresh}
          sortDir={sortDirForSemanticTable}
          setSort={setSort}
          sortBy={sortBy}
          limit={limitState}
          pagination={Pagination}
          selectedBets={selectedBets}
          setSelectedBets={setSelectedBets}
          showCopyButtons={showCopyButtons}
          stopSorting={liveMode && stopSorting}
          parentDomId={parentDomId}
        />
      </Grid.Column>
    </Grid>
  );
};
