import { useMemo } from 'react';

import { Checkbox } from 'semantic-ui-react';

import { BetsTableWithFilters } from './BetsTableWithFilters';
import {
  BetsTablePageType,
  BetStatusesEnum,
  LONG_POLL_INTERVAL
} from '../../constants';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import { BetsColumnLabelEnum, BetsRequest, BetsTableFiltersEnum } from '../../types';

const COLUMNS = [
  BetsColumnLabelEnum.Date,
  BetsColumnLabelEnum.Player,
  BetsColumnLabelEnum.Outcomes,
  BetsColumnLabelEnum.Type,
  BetsColumnLabelEnum.Stake,
  BetsColumnLabelEnum.Price,
  BetsColumnLabelEnum.Winning,
  BetsColumnLabelEnum.Status,
  BetsColumnLabelEnum.Actions
];

type TBetsHistoryWithLiveMode = {
  params: BetsRequest;
  filters: Array<keyof typeof BetsTableFiltersEnum>;
  filterParams?: {
    eventId?: string;
  };
  columns?: typeof COLUMNS;
  showCopyButtons?: {
    userId?: boolean;
    betId?: boolean;
    userName?: boolean;
  };
  parentDomId: string;
  defaultPeriod: string;
  intervalsOptionsType?: string;
}

export const BetsHistoryWithLiveMode = ({
  params,
  filters,
  filterParams,
  columns = COLUMNS,
  showCopyButtons,
  parentDomId,
  defaultPeriod,
  intervalsOptionsType,
}: TBetsHistoryWithLiveMode) => {
  const { queryState, setQueryStateWithParams } = useQueryState();

  const liveMode = queryState.liveMode === null ||
    queryState.liveMode === undefined ||
    queryState.liveMode;

  const filtersToShow = useMemo(() => {
    let resultFilters = [ ...filters ];

    const statusRejected = queryState?.betStatuses?.includes(BetStatusesEnum.BET_STATUS_FAIL);

    resultFilters = statusRejected
      ? resultFilters
      : resultFilters.filter(v => v !== BetsTableFiltersEnum.betRejectReasons);

    if (liveMode) {
      resultFilters = resultFilters.filter(v => v !== BetsTableFiltersEnum.period);
    }

    return resultFilters;
  }, [ filters, liveMode, queryState.betStatuses ]);

  function liveModeCheckboxOnChange () {
    const nextQueryState = {
      ...queryState,
      liveMode: !liveMode,
      period: liveMode ? defaultPeriod : undefined,
    };

    if (!liveMode && nextQueryState.sortBy) {
      nextQueryState.sortBy = '';
    }

    if (!liveMode && nextQueryState.sortDir) {
      nextQueryState.sortDir = '';
    }

    if (!liveMode) {
      nextQueryState.startDate = undefined;
      nextQueryState.endDate = undefined;
    }

    setQueryStateWithParams(nextQueryState);
  }

  const LiveCheckbox =
    <Checkbox
      id={parentDomId + 'live-mode-checkbox'}
      checked={liveMode}
      label="Live mode"
      onChange={liveModeCheckboxOnChange}
    />;

  return (
    <div className="content-container bets-container">
      <BetsTableWithFilters
        columns={columns}
        tableName={LiveCheckbox}
        enablePollInterval={liveMode}
        startPollInterval={filterParams?.eventId && LONG_POLL_INTERVAL}
        initialParams={params}
        filters={filtersToShow}
        filterParams={filterParams}
        pageType={BetsTablePageType.BETS_HISTORY_WITH_LIVE_MODE}
        showCopyButtons={showCopyButtons}
        stopSorting={liveMode}
        parentDomId={parentDomId}
        defaultPeriod={defaultPeriod}
        intervalsOptionsType={intervalsOptionsType}
      />
    </div>
  );
};
