import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Header, Segment } from 'semantic-ui-react';
import { string, object, boolean } from 'yup';

import { SportRegionSelector } from './SportRegionSelector';
import { SportsDropdown } from './SportsDropdown';
import { PhoenixField, PhoenixSubmitButton } from '../../components/form-components/FormComponents';
import {
  API_EVENT_PATH_CREATE,
  API_EVENT_PATH_UPDATE,
  API_SPORT_UPDATE,
  EVENT_TREE_PATH
} from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import {
  KEY_EVENT_PATH_TREE_FIND,
  KEY_LEAGUES_FIND,
  KEY_REGIONS_FIND,
  KEY_SPORTS_FIND
} from '../../react-query/query';
import { FieldTypesEnum } from '../../types';
import { removeEmptyFields } from '../../utils';

const SportFormSchema = object().shape({
  description: string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
  sportCode: string().required('Required'),
  countDown: boolean().required('Required'),
});

export const SportForm = ({
  initialValues,
}: {
  initialValues?: {
    description: string;
    sportCode: string;
    countDown: boolean;
    id: string;
    inheritedSportId?: boolean;
    version: string;
  };
}) => {
  const queryClient = useQueryClient();

  function submit (values: any, { setSubmitting, setFieldValue }) {
    const dataForRequest = removeEmptyFields({ ...values });

    axios
      .put(API_SPORT_UPDATE, dataForRequest)
      .then(async response => {
        toast.success('Sport has been updated');

        setFieldValue('version', response.data.result.version);

        setSubmitting(false);

        await queryClient.invalidateQueries({
          queryKey: [ KEY_SPORTS_FIND ]
        });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.error(error);
        setSubmitting(false);
      });
  }

  return (
    <Formik
      validationSchema={SportFormSchema}
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <PhoenixField
            id="path-tree--edit-sport--description-input"
            name="description"
            label="Description"
          />
          <PhoenixField
            id="path-tree--edit-sport--sport-code-input"
            name="sportCode"
            label="SportCode"
            disabled
          />
          <PhoenixField
            id="path-tree--edit-sport--count-down-checkbox"
            name="countDown"
            label="Countdown"
            type={FieldTypesEnum.CHECKBOX}
          />

          <PhoenixSubmitButton id="path-tree--edit-sport--submit-sport-form-btn" />
        </Form>
      )}
    </Formik>
  );
};

SportForm.defaultProps = {
  initialValues: {
    description: '',
    sportCode: '',
    countDown: false,
    id: '',
  },
};

const RegionFormSchema = object().shape({
  description: string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
  parentEventPathId: string().required('Required'),
});

export const RegionForm = ({
  description = '',
  parentEventPathId = '',
  id,
  version,
}: {
  parentEventPathId: string;
  description?: string;
  id?: string;
  version?: string;
}) => {
  const navigate = useNavigate();

  const { originIdAllParam } = useGlobalStateContext();

  return (
    <EventPathTreeForm
      parentId={`event-path--region-form-${id ? 'update' : 'create'}--`}
      initialValues={{
        description,
        parentEventPathId,
        id,
        version,
        originId: originIdAllParam,
      }}
      onSubmit={response => navigate(`${EVENT_TREE_PATH}/region/${response.data.result.id}`)}
      renderParentSelector={({ onChange, value }) => (
        <div className="field">
          <label>Sport</label>
          <SportsDropdown
            id={`path-tree--region-form-${id ? 'update' : 'create'}--sport-dropdown`}
            onChange={onChange}
            value={value}
          />
        </div>
      )}
      validationSchema={RegionFormSchema}
      type="region"
    />
  );
};

export const RegionFormCreate = () => {
  const { state } = useLocation();
  const { sportId } = state;

  return (
    <Segment color="pink">
      <Header size="large">New Region</Header>
      <RegionForm parentEventPathId={sportId} />
    </Segment>
  );
};

export const LeagueForm = ({
  description = '',
  parentEventPathId,
  id,
  sportId = '',
  version,
}: {
  description?: string;
  parentEventPathId: string;
  id?: string;
  sportId?: string;
  version?: string;
}) => {
  const navigate = useNavigate();

  const { originId } = useGlobalStateContext();

  return (
    <EventPathTreeForm
      parentId={`event-path--league-form-${id ? 'update' : 'create'}--`}
      initialValues={{
        description,
        parentEventPathId,
        id,
        version,
        originId,
      }}
      onSubmit={response => {
        if (response.data) {
          navigate(`${EVENT_TREE_PATH}/league/${response.data.result.id}`);
        }
      }}
      renderParentSelector={({ onChange, value }) => (
        <SportRegionSelector
          onChange={onChange}
          value={value}
          sportId={sportId}
          id={`path-tree--region-form-${id ? 'update' : 'create'}--sport-dropdown`}
        />
      )}
      validationSchema={RegionFormSchema}
      type="league"
    />
  );
};

export const LeagueFormCreate = () => {
  const { state } = useLocation();
  const { regionId, sportId } = state;

  return (
    <Segment color="orange">
      <Header size="large">New League</Header>
      <LeagueForm
        key={regionId + sportId}
        parentEventPathId={regionId}
        sportId={sportId}
      />
    </Segment>
  );
};

const EventPathTreeForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  renderParentSelector,
  parentId,
  type,
}: {
  initialValues: {
    description: string;
    parentEventPathId: string;
    id?: string;
  };
  validationSchema: object;
  onSubmit?: (arg: any) => void;
  renderParentSelector?: (arg: any) => void;
  parentId: string;
  type: 'league' | 'region'
}) => {
  const queryClient = useQueryClient();

  const { originId } = useGlobalStateContext();

  function submit (values, { setSubmitting, setFieldValue }) {
    const edit = !!values.id;

    const dataForRequest = removeEmptyFields({ ...values, originId });
    const request = edit
      ? axios.put(API_EVENT_PATH_UPDATE, dataForRequest)
      : axios.post(API_EVENT_PATH_CREATE, dataForRequest);

    request
      .then(async response => {
        /** Revalidate event path tree if there were changes */
        queryClient.invalidateQueries({
          queryKey: [ KEY_EVENT_PATH_TREE_FIND ]
        });

        if (type === 'region') {
          queryClient.invalidateQueries({
            queryKey: [ KEY_REGIONS_FIND ]
          });
        } else if (type === 'league') {
          queryClient.invalidateQueries({
            queryKey: [ KEY_LEAGUES_FIND ]
          });
        }

        toast.success(`Event path has been ${edit ? 'updated' : 'created'}`);

        if (edit) {
          setFieldValue('version', response.data.result.version);
        }

        if (typeof onSubmit === 'function' && !edit) {
          onSubmit(response);
        }

        setSubmitting(false);
      })
      .catch(error => {
        // eslint-disable-next-line
        console.error(error);
        setSubmitting(false);
      });
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form onSubmit={handleSubmit}>
          <>
            {typeof values.parentEventPathId === 'string' ? (
              typeof renderParentSelector === 'function' ? (
                renderParentSelector({
                  value: values.parentEventPathId,
                  onChange: (value: string | number) => {
                    setFieldValue('parentEventPathId', value);
                  },
                })
              ) : (
                <PhoenixField
                  id={parentId + 'parent-event-path-id-input'}
                  name="parentEventPathId"
                  label="Parent ID"
                />
              )
            ) : null}
            <PhoenixField
              id={parentId + 'description-input'}
              name="description"
              label="Description"
            />
            <PhoenixSubmitButton id={parentId + 'submit-btn'} />
          </>
        </Form>
      )}
    </Formik>
  );
};
