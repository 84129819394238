import { useOutletContext, useParams } from 'react-router-dom';
import { Dimmer, Header, Loader, Segment } from 'semantic-ui-react';

import { CashoutSettingsEdit } from './CashoutSettingsEdit';
import { EditEventPathTreeRequest } from './EditEventPathTreeRequest';
import { SportForm } from './EventPathForms';
import { InRunningDelayComponent } from './InRunningDelayComponent';
import { LimitGroupsEdit } from './LimitGroupsEdit';
import { OriginsAccordion } from '../../components/form-components/OriginsAccordion';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { EventPathOutletContextType } from '../../types';

export const EditSport = () => {
  const { sportId } = useParams();
  const { originId } = useGlobalStateContext();
  const { reload } = useOutletContext<EventPathOutletContextType>();

  if (!sportId) return null;
  return (
    <>
      <Header size="large">Edit Sport</Header>
      <EditEventPathTreeRequest
        url="sports"
        id={sportId}
      >
        {({ eventPath, isFetching }) => {
          const {
            description,
            sportCode,
            countDown,
            version,
            cashoutEnabled,
            id,
            limitGroupId,
            limitGroupInherited,
          } = eventPath || ({} as any);
          return (
            <>
              <Segment color="green">
                <Dimmer active={isFetching} inverted>
                  <Loader />
                </Dimmer>
                <SportForm
                  key={sportId + sportCode}
                  initialValues={{
                    description,
                    sportCode,
                    countDown,
                    id: sportId,
                    version
                  }}
                  reload={reload}
                />
              </Segment>
              <Segment color="green">
                <OriginsAccordion
                  parentDomId="path-tree--edit-sport--"
                  multiChoice={false}
                  withSubOrigins={false}
                  withDefaultOrigin
                  icon="filter"
                />

                <CashoutSettingsEdit
                  parentDomId="path-tree--edit-sport--"
                  eventPathId={sportId}
                  version={version}
                  enabled={cashoutEnabled}
                  originId={originId}
                />

                <LimitGroupsEdit
                  parentDomId="path-tree--edit-sport--"
                  id={id}
                  limitGroupId={limitGroupId}
                  limitGroupInherited={limitGroupInherited}
                  version={version}
                  originId={originId}
                />

                <InRunningDelayComponent
                  parentDomId="path-tree--edit-sport--"
                  eventPathId={sportId}
                  originId={originId}
                />
              </Segment>
            </>
          );
        }}
      </EditEventPathTreeRequest>
    </>
  );
};
