import React from 'react';

import Axios from 'axios';
import { FormikState } from 'formik';
import { toast } from 'react-toastify';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import { API_ORIGIN_UPDATE } from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { OriginType } from '../../types';
import { removeEqualsFields } from '../../utils';

export const DefaultSubmitModal = ({
  values,
  resetForm,
  setSubmitting,
  origin,
  dirty,
}:{
  values: OriginType;
  resetForm: (nextState?: Partial<FormikState<any>>) => void
  setSubmitting: (isSubmitting: boolean) => void
  origin: object;
  dirty: boolean;
}) => {
  const { updateOriginsList } = useGlobalStateContext();

  const [ open, setOpen ] = React.useState(false);
  const [ isFetching, setIsFetching ] = React.useState(false);

  function submitClick () {
    setOpen(false);
    setIsFetching(true);

    const changes = removeEqualsFields(origin, values);
    const dataForRequest = {
      id: values.id,
      version: values.version,
      ...changes
    };

    Axios.put(API_ORIGIN_UPDATE, dataForRequest)
      .then(response => {
        toast.success('Origin settings have been updated');
        const nextFormState = {
          values: {
            ...values,
            ...response.data.result,
          }
        };
        resetForm(nextFormState);
        updateOriginsList();
      })
      .catch(error => {
        // eslint-disable-next-line
        console.error(error);
        setSubmitting(false);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        <Button
          id="origin-page--submit-all-origins-btn"
          positive
          size="big"
          loading={isFetching}
          disabled={isFetching || !dirty}
          content="Submit for all origins"
        />
      }
    >
      <Header icon>
        <Icon name="attention" />
        Attention!
      </Header>
      <Modal.Content>
        <p>Do you really want to submit changes for all origins?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="origin-page--submit-all-origins-cancel-btn"
          basic
          onClick={() => setOpen(false)}
        >
          <Icon name="remove" /> No
        </Button>
        <Button
          id="origin-page--submitall-origins-accept-btn"
          color="green"
          onClick={submitClick}
        >
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
