import { Form, Checkbox } from 'semantic-ui-react';

import { PhoenixFieldRender } from './FormComponents';

export const CashoutSettingsForm = ({
  showInherit,
  enabled,
  cashoutInheritedName,
  cashoutEnabledName,
  disableAll,
  id,
}: {
  showInherit: boolean;
  enabled: boolean;
  cashoutInheritedName: string;
  cashoutEnabledName: string;
  disableAll?: boolean;
  id: string;
}) => (
  <Form.Group widths="equal">
    {showInherit && (
      <PhoenixFieldRender name={cashoutInheritedName}>
        {({ form: { setFieldValue }, field }) => (
          <Checkbox
            id={id + 'cashout-settings--inherited-checkbox'}
            label="Inherited"
            {...field}
            value={undefined}
            checked={field.value}
            onChange={(_e, { checked }) => {
              setFieldValue(cashoutInheritedName, checked);
              setFieldValue(cashoutEnabledName, enabled);
            }}
            disabled={disableAll}
          />
        )}
      </PhoenixFieldRender>
    )}
    <PhoenixFieldRender name={cashoutEnabledName}>
      {({ form: { values, setFieldValue }, field }) => (
        <Checkbox
          id={id + 'cashout-settings--enabled-checkbox'}
          label="Enabled"
          {...field}
          value={undefined}
          checked={field.value}
          disabled={(showInherit && values.cashoutInherited) || disableAll}
          onChange={(_e, { checked }) => {
            setFieldValue(cashoutEnabledName, checked);
          }}
        />
      )}
    </PhoenixFieldRender>
  </Form.Group>
);
