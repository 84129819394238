import React from 'react';

import { IrmMarketCard } from './IrmMarketCard';
import { EventType, SingleByEventMarketType } from '../../../types';
import { MarketPricesStateType, MarketWithPricesObjectType } from '../../../utils';
import './IrmMarkets.css';

export const IrmMarkets = ({
  event,
  editedPricesState,
  setEditedPricesState,
  eventMarginState,
  useMarginState,
  selectedOutcomeState,
  selectOutcome,
  marketsArray,
  marketsRefresh,
  marketPricesState,
  setMarketPricesState,
  openSidebar,
}: {
  event: EventType;
  editedPricesState?: Array<MarketWithPricesObjectType>;
  setEditedPricesState?: (editedPricesState: Array<MarketWithPricesObjectType>) => void;
  eventMarginState?: number;
  useMarginState?: boolean;
  selectedOutcomeState?: string;
  selectOutcome?: (string) => void;
  marketsArray: Array<SingleByEventMarketType>;
  marketsRefresh: () => void;
  marketPricesState?: MarketPricesStateType;
  setMarketPricesState?: (MarketPricesStateType) => void;
  openSidebar: (node: React.ReactNode) => void;
}) => {
  const markets = React.useMemo(() => {
    if (marketsArray == null) return [];
    let markets = marketsArray.slice();
    markets = markets.filter(market => market.showInIRM);
    markets.sort(market => {
      if (market.market.status === 'SETTLED') {
        return -1;
      } else {
        return 0;
      }
    });
    const settled = markets.filter(market => market.market.status === 'SETTLED');
    markets = markets.filter(market => market.market.status !== 'SETTLED').concat(settled);
    return markets;
  }, [ marketsArray ]);

  return (
    <div className="market-card-div">
      {markets.map(({
        market,
        outcomes,
        marketManualCreated,
        isSpecialMarket
      }) => (
        <IrmMarketCard
          key={market.id}
          outcomes={outcomes}
          market={market}
          marketManualCreated={marketManualCreated}
          reload={marketsRefresh}
          event={event}
          editedPricesState={editedPricesState}
          setEditedPricesState={setEditedPricesState}
          eventMarginState={eventMarginState}
          useMarginState={useMarginState}
          selectedOutcomeState={selectedOutcomeState}
          selectOutcome={selectOutcome}
          marketPricesState={marketPricesState}
          setMarketPricesState={setMarketPricesState}
          openSidebar={openSidebar}
          isSpecialMarket={isSpecialMarket}
        />
      ))}
    </div>
  );
};
