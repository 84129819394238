import React from 'react';

import {
  MarketStatusesFilter
} from '../../components/search-and-filters-components/MarketStatusesFilter';
import {
  QueryStateMultipleCheckbox
} from '../../components/search-and-filters-components/QueryStateInputs';
import {
  BETBUILDER_TYPE_FILTER,
  BETBUILDER_TYPE_OPTIONS,
  BOOK_TYPES_FILTER,
  BOOK_TYPES_OPTIONS,
  IRM_STATUS_FILTER,
  IRM_STATUSES_ENUM,
  IRM_STATUSES_OPTIONS,
  MANUAL_MODE_ENUM,
  MANUAL_MODE_OPTIONS,
  MANUAL_MODES_FILTER,
  MARKET_STATUSES_FILTER,
  MARKET_STATUSES_OPTIONS,
  MARKET_BETS_FILTER,
  MARKET_BETS_OPTIONS
} from '../../constants';

export const MarketsFilters = {
  [MARKET_BETS_FILTER]: {
    title: '',
    render: (options, id) =>
      <QueryStateMultipleCheckbox
        name={MARKET_BETS_FILTER}
        options={options}
        id={id + 'bets-filter-'}
      />,
    getOptions: counts =>
      MARKET_BETS_OPTIONS.map(({ value, text }) => ({
        value,
        text: `${text} (${counts[MARKET_BETS_FILTER][value]})`,
        disabled: counts[MARKET_BETS_FILTER][value] === 0,
      })),
    getter: markets =>
      markets.filter(market =>
        market.outcomes?.some(outcome => {
          return outcome.betsCount > 0;
        })
      ),
  },
  [MARKET_STATUSES_FILTER]: {
    title: 'Status',
    render: (options, id) =>
      <MarketStatusesFilter
        options={options}
        id={id + 'status-filter-'}
      />,
    getOptions: counts =>
      MARKET_STATUSES_OPTIONS.map(({ value, text, labelColor }) => ({
        value,
        text: `${text} (${counts[MARKET_STATUSES_FILTER][value]})`,
        labelColor,
        disabled: counts[MARKET_STATUSES_FILTER][value] === 0,
      })),
    getter: (markets, queryState) =>
      markets.filter(market =>
        queryState[MARKET_STATUSES_FILTER]?.split(',')?.includes(market?.market?.status)
      ),
  },
  [BETBUILDER_TYPE_FILTER]: {
    title: 'Market Type',
    render: (options, id) =>
      <QueryStateMultipleCheckbox
        id={id + 'market-type-filter-'}
        name={BETBUILDER_TYPE_FILTER}
        options={options}
      />,
    getOptions: counts =>
      BETBUILDER_TYPE_OPTIONS.map(({ value, text }) => ({
        value,
        text: `${text} (${counts[BETBUILDER_TYPE_FILTER][value]})`,
        disabled: counts[BETBUILDER_TYPE_FILTER][value] === 0,
      })),
    getter: markets => markets.filter(market => market.isBetBuilderMarket),
  },
  [BOOK_TYPES_FILTER]: {
    title: 'Book Type',
    render: (options, id) =>
      <QueryStateMultipleCheckbox
        id={id + 'book-type-filter-'}
        name={BOOK_TYPES_FILTER}
        options={options}
      />,
    getOptions: counts =>
      BOOK_TYPES_OPTIONS.map(({ value, text }) => ({
        value,
        text: `${text} (${counts[BOOK_TYPES_FILTER][value]})`,
        disabled: counts[BOOK_TYPES_FILTER][value] === 0,
      })),
    getter: (markets, queryState) =>
      markets.filter(market =>
        queryState[BOOK_TYPES_FILTER]?.split(',')?.includes(market?.market?.bookType)
      ),
  },
  [MANUAL_MODES_FILTER]: {
    title: 'Manual Mode',
    render: (options, id) =>
      <QueryStateMultipleCheckbox
        id={id + 'manual-mode-filter-'}
        name={MANUAL_MODES_FILTER}
        options={options}
      />,
    getOptions: count =>
      MANUAL_MODE_OPTIONS.map(({ value, text }) => ({
        value,
        text: `${text} (${count[MANUAL_MODES_FILTER][value]})`,
        disabled: count[MANUAL_MODES_FILTER][value] === 0,
      })),
    getter: (markets, queryState) => {
      const manualModeValues = queryState[MANUAL_MODES_FILTER]?.split(',');
      if (
        manualModeValues?.includes(MANUAL_MODE_ENUM.manualTrading) &&
        manualModeValues?.includes(MANUAL_MODE_ENUM.manualCreated)
      ) {
        markets = markets.filter(
          market => market.marketManualMode || market?.outcomes?.some(outcome => outcome.manualMode)
        );
      } else {
        if (manualModeValues?.includes(MANUAL_MODE_ENUM.manualTrading)) {
          markets = markets.filter(
            market =>
              !market.marketManualCreated &&
              (market.marketManualMode || market?.outcomes?.some(outcome => outcome.manualMode))
          );
        }

        if (manualModeValues?.includes(MANUAL_MODE_ENUM.manualCreated)) {
          markets = markets.filter(market => market.marketManualCreated);
        }
      }
      return markets;
    },
  },
  [IRM_STATUS_FILTER]: {
    title: 'IRM',
    render: (options, id) =>
      <QueryStateMultipleCheckbox
        id={id + 'irm-filter-'}
        name={IRM_STATUS_FILTER}
        options={options}
      />,
    getOptions: counts =>
      IRM_STATUSES_OPTIONS.map(({ value, text, labelColor }) => ({
        value,
        text: `${text} (${counts[IRM_STATUS_FILTER][value]})`,
        labelColor,
        disabled: counts[IRM_STATUS_FILTER][value] === 0,
      })),
    getter: (markets, queryState) => {
      const status = queryState[IRM_STATUS_FILTER] === IRM_STATUSES_ENUM.showInIrm;
      return markets.filter(market => market.showInIRM === status);
    },
  },
};
