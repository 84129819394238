import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import {
  fnEventCopy,
  fnStatusHide,
  fnMarketsUpdate,
  fnMarketUpdate,
  fnPriceCreate,
  fnPricesCreate,
  fnBetsSettle,
  fnBetPlace,
  fnBetsVoid,
  fnMarketCreate,
  fnOriginLimitGroupUpdate,
  fnOriginMarginUpdate
} from './mutation-functions';

type TOutcomesForUpdate = {
  [key: string]: string | boolean
}
export const useStatusHide = ({
  onSuccess,
  onError,
}:{
  onSuccess?: () => void,
  onError?: () => void,
}) => {
  return useMutation({
    mutationFn: fnStatusHide,
    onSuccess,
    onError,
  });
};

export const useEventCopy = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: () => void,
}) => {
  return useMutation({
    mutationFn: fnEventCopy,
    onSuccess,
    onError,
  });
};


export const useMarketsUpdate = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: () => void,
}) => {
  return useMutation({
    mutationFn: fnMarketsUpdate,
    onSuccess,
    onError,
  });
};

export const useMarketUpdate = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: () => void,
}) => {
  return useMutation({
    mutationFn: (data: {id: string, originId: string, outcomes: TOutcomesForUpdate[]}) => {
      const { id } = data;
      return fnMarketUpdate(id, data);
    },
    onSuccess,
    onError,
  });
};

export const useMarketCreate = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: () => void,
}) => {
  return useMutation({
    mutationFn: fnMarketCreate,
    onSuccess,
    onError,
  });
};

export const usePricesCreate = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: () => void,
}) => {
  return useMutation({
    mutationFn: ({ pricesForRequest }: {pricesForRequest: Array<any>}) => {
      const dataForRequest = {
        outcomesPrice: []
      };
      pricesForRequest.forEach(el => {
        if (el.localPrice !== el.currentPrice)
          dataForRequest.outcomesPrice.push({
            outcomeId: el.outcomeId,
            price: el.localPrice,
          });
      });

      return fnPricesCreate(dataForRequest);
    },
    onSuccess,
    onError,
  });
};

export const usePriceCreate = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: () => void,
}) => {
  return useMutation({
    mutationFn: fnPriceCreate,
    onSuccess,
    onError,
  });
};

export const useOriginMarginsUpdate = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: (err: Error) => void,
}) => {
  return useMutation({
    mutationFn: fnOriginMarginUpdate,
    onSuccess,
    onError,
  });
};

export const useBetPlace = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: (data: any) => void,
}) => {
  return useMutation({
    mutationFn: fnBetPlace,
    onSuccess,
    onError,
  });
};

export const useBetsSettle = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: () => void,
}) => {
  return useMutation({
    mutationFn: fnBetsSettle,
    onSuccess,
    onError,
  });
};

export const useBetsVoid = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: () => void,
}) => {
  return useMutation({
    mutationFn: fnBetsVoid,
    onSuccess,
    onError,
  });
};

export const useOriginLimitGroupMutation = ({
  onSuccess,
  onError,
}:{
  onSuccess?: (res: AxiosResponse) => void,
  onError?: (err: Error) => void,
}) => {
  return useMutation({
    mutationFn: fnOriginLimitGroupUpdate,
    onSuccess,
    onError,
  });
};
