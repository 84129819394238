import { memo, ReactNode, useMemo, useState } from 'react';

import moment from 'moment';
import qs from 'qs';
import { toast } from 'react-toastify';
import { Label, Table, Button, Icon, List, Checkbox } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { EventClock } from './EventClock';
import { getIsClockDisplayed, getIsClockPaused, getRiskValuesFromRisk } from './helpers';
import { RiskSelector } from './RIskSelector';
import { FlexDiv } from '../../components/FlexDiv';
import { OriginsAccordion } from '../../components/form-components/OriginsAccordion';
import { OutcomeBets } from '../../components/sidebar-components/OutcomeBets';
import { EventOutcomeInfo } from '../../components/table-components/EventOutcomeInfo';
import { Money } from '../../components/table-components/Money';
import { TablePagination } from '../../components/table-components/TablePagination';
import { TableSkeleton } from '../../components/UI/TableSkeleton';
import { API_SINGLES_RISK } from '../../constants';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import { MarketStatusLabel } from '../EventEdit/Markets/MarketsTable/MarketStatusLabel';
import './RiskManager.css';

export const RiskManager = ({
  openSidebar,
  compactView,
}: {
  openSidebar: (arg0: ReactNode) => void;
  compactView?: boolean;
}) => {
  const { queryState } = useQueryState();

  const [ riskFrom, riskTo ] = getRiskValuesFromRisk(queryState.risk);
  const [ page, setPage ] = useState(1);
  const [ hideOutrights, setHideOutrights ] = useState(true);

  const limit = compactView ? 10 : 100;

  const config = useMemo(() => {
    const confLimit = limit * page;
    const ids = compactView ? undefined : queryState.originIds;
    return {
      url: API_SINGLES_RISK,
      params: {
        riskFrom: riskFrom,
        riskTo: riskTo,
        limit: confLimit,
        originIds: ids,
        hideOutrights,
      },
      paramsSerializer: (v: any) => qs.stringify(v, { arrayFormat: 'repeat' }),
    };
  }, [ compactView, hideOutrights, limit, page, queryState.originIds, riskFrom, riskTo ]);

  const { isFetching, data } = useAxiosRequest<any>(
    config, {
      pollInterval: 3000,
      onError: error => toast.error(error.message)
    }
  );

  const risks = Array.isArray(data?.result) ? data?.result : [];

  const HideOutrightsCheckbox =
    <Checkbox
      checked={hideOutrights}
      onChange={() => setHideOutrights(p => !p)}
      label="Hide outrights"
      id="hide-outrights-checkbox"
    />;

  return (
    <div className="content-container selection-container">
      <div className="content-header selection__header">
        <h2>Selection</h2>
        {compactView && HideOutrightsCheckbox}
        {!compactView && (
          <List horizontal>
            <List.Item>
              {HideOutrightsCheckbox}
            </List.Item>
            <List.Item>
              <OriginsAccordion
                parentDomId="risk-manager--"
                horizontal
              />
            </List.Item>
            <List.Item>Risk Range</List.Item>
            <List.Item>
              <RiskSelector />
            </List.Item>
          </List>
        )}
      </div>
      <Table className="selection__table">
        <Table.Body>
          {isFetching && !risks?.length &&
            <TableSkeleton
              rows={10}
              columns={4}
              rowHeight={4}
            />
          }
          {!!risks.length && risks?.map(risk => (
            <SingleRiskRow
              key={risk.outcome.id}
              sportDescription={risk.sport.description}
              eventId={risk.event.id}
              eventDescription={risk.event.description}
              eventStartDate={risk.event.startDate}
              leagueDescription={risk.league.description}
              marketDescription={risk.market.description}
              periodDescription={risk.market.periodDescription}
              marketStatus={risk.market.status}
              outcomeDescription={risk.outcome.description}
              outcomeId={risk.outcome.id}
              outcomePrice={risk.outcome.price}
              liabilityRisk={risk.liabilityRisk}
              betsCount={risk.betsCount}
              betsTotal={risk.betsTotal}
              totalScore={risk.totalScore}
              clockOffset={~~(+risk.clock.offset / 1000)}
              clockPeriod={risk.clock.period.description}
              clockStatus={risk.clock.status}
              countDown={risk.sport.countDown}
              handleBetsClick={openSidebar}
              feedCode={risk.event.feedCode}
            />
          ))}
          <Table.Row>
            <Table.Cell colSpan={4}>
              <TablePagination
                currentCount={data?.result?.length}
                page={page}
                setPage={setPage}
                isFetching={isFetching}
                limit={limit}
                parentDomId="risk-manager--"
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

const SingleRiskRow = memo(
  ({
    sportDescription,
    eventId,
    eventDescription,
    leagueDescription,
    marketDescription,
    marketStatus,
    outcomeDescription,
    outcomeId,
    periodDescription,
    outcomePrice,
    liabilityRisk,
    betsCount,
    betsTotal,
    totalScore,
    eventStartDate,
    clockPeriod,
    clockStatus,
    countDown,
    clockOffset,
    handleBetsClick,
    feedCode,
  }: {
    sportDescription: string;
    eventId: string;
    eventDescription: string;
    leagueDescription: string;
    marketDescription: string;
    marketStatus: string;
    outcomeDescription: string;
    outcomeId: string;
    periodDescription: string;
    outcomePrice: number;
    liabilityRisk: number;
    betsCount: number;
    betsTotal: number;
    totalScore: string;
    eventStartDate: string;
    clockPeriod: string;
    clockStatus: string;
    countDown: boolean;
    clockOffset: number;
    handleBetsClick: (arg0: ReactNode) => void;
    feedCode: string;
  }) => {
    const showClocks = getIsClockDisplayed(clockStatus);
    const isClockPaused = getIsClockPaused(clockStatus);

    return (
      <Table.Row>
        <Table.Cell>
          <Money>{liabilityRisk * -1}</Money>
        </Table.Cell>
        <Table.Cell>
          <FlexDiv>
            <FlexDiv.Flex>
              <EventOutcomeInfo
                sportDescription={sportDescription}
                leagueDescription={leagueDescription}
                eventId={eventId}
                eventDescription={eventDescription}
                outcomeMarketDescription={marketDescription}
                outcomeMarketPeriodDescription={periodDescription || 'period'}
                outcomeDescription={outcomeDescription}
                outcomePrice={outcomePrice}
                outcomeId={outcomeId}
                startDate={eventStartDate}
                feedCode={feedCode}
                parentDomId={`risk-manager--event-${eventId}--`}
              />
            </FlexDiv.Flex>
          </FlexDiv>
        </Table.Cell>
        <Table.Cell>
          <List>
            {totalScore !== '' && (
              <List.Item>
                <Label size="tiny">Score: {totalScore}</Label>
              </List.Item>
            )}
            <List.Item>
              <Label size="tiny">
                {showClocks ? (
                  <>
                    <Icon name="clock" />
                    {clockPeriod}{' '}
                    <EventClock seconds={clockOffset} countDown={countDown} paused={isClockPaused} />
                  </>
                ) : (
                  `Starts ${moment(eventStartDate).fromNow()}`
                )}
              </Label>
            </List.Item>
            <List.Item>
              <MarketStatusLabel status={marketStatus} size="tiny" />
            </List.Item>
          </List>
        </Table.Cell>
        <Table.Cell className="nowrap">
          <Button
            id={`risk-manager--event-${eventId}--bets-btn`}
            basic
            onClick={() => {
              handleBetsClick(
                <OutcomeBets
                  outcomeId={outcomeId}
                  isClockPaused={isClockPaused}
                  showClocks={showClocks}
                  clockOffset={clockOffset}
                  clockPeriod={clockPeriod}
                  eventDescription={eventDescription}
                  sportDescription={sportDescription}
                  leagueDescription={leagueDescription}
                  eventStartDate={eventStartDate}
                  liabilityRisk={liabilityRisk}
                  marketDescription={marketDescription}
                  marketStatus={marketStatus}
                  outcomeDescription={outcomeDescription}
                  countDown={countDown}
                  totalScore={totalScore}
                  betStatuses={[ 'BET_STATUS_PENDING' ]}
                  feedCode={feedCode}
                  parentDomId={`risk-manager--event-${eventId}--sidebar--`}
                />
              );
            }}
          >
            <Money>{betsTotal}</Money>/ {betsCount} /<Money>{betsTotal / betsCount}</Money>
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
);
