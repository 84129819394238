import React from 'react';

import { QueryStateMultipleCheckboxType, QueryStateMultipleCheckbox } from './QueryStateInputs';
import { MARKET_STATUSES_FILTER, MARKET_STATUSES_OPTIONS } from '../../constants';

export const MarketStatusesFilter = ({
  options = MARKET_STATUSES_OPTIONS,
  ...props
}: Partial<QueryStateMultipleCheckboxType>) => (
  <QueryStateMultipleCheckbox
    options={options}
    name={MARKET_STATUSES_FILTER}
    withLabelColor
    id={props.id}
    {...props}
  />
);
