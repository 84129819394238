import { FILTER_INTERVALS } from '../../../constants';

export enum BetsColumnEnum {
  Type = 'Type',
  Date = 'Date',
  Player = 'Player',
  Group = 'Group',
  Outcomes = 'Outcomes',
  StakeUSD = 'StakeUSD',
  Stake = 'Stake',
  Price = 'Price',
  WinningUSD = 'WinningUSD',
  Winning = 'Winning',
  Status = 'Status',
  Actions = 'Actions',
}

export const COLUMNS = [
  BetsColumnEnum.Type,
  BetsColumnEnum.Date,
  BetsColumnEnum.Player,
  BetsColumnEnum.Group,
  BetsColumnEnum.Outcomes,
  BetsColumnEnum.StakeUSD,
  BetsColumnEnum.Stake,
  BetsColumnEnum.Price,
  BetsColumnEnum.WinningUSD,
  BetsColumnEnum.Winning,
  BetsColumnEnum.Status
];

export const INIT_COLUMNS: Record<BetsColumnEnum, boolean> = COLUMNS
  .reduce((acc, val) => ({
    ...acc,
    [val]: true
  }), {} as Record<BetsColumnEnum, boolean>);

export const NAME_BY_COLUMN = {
  [BetsColumnEnum.Type]: 'Type',
  [BetsColumnEnum.Date]: 'Date',
  [BetsColumnEnum.Player]: 'Player',
  [BetsColumnEnum.Group]: 'Group',
  [BetsColumnEnum.Outcomes]: 'Outcomes',
  [BetsColumnEnum.StakeUSD]: 'Stake, $',
  [BetsColumnEnum.Stake]: 'Stake',
  [BetsColumnEnum.Price]: 'Price',
  [BetsColumnEnum.WinningUSD]: 'Winning, $',
  [BetsColumnEnum.Winning]: 'Winning',
  [BetsColumnEnum.Status]: 'Status',
  [BetsColumnEnum.Actions]: '',
};

export const DEFAULT_PERIOD = FILTER_INTERVALS.day.value;
