import React from 'react';

import { Statistic } from 'semantic-ui-react';

import { MoneyWithUserCurrency } from '../../components/table-components/Money';

type UserPerformanceProps = {
  totalBets: number;
  totalTurnover: number;
  totalProfit: number;
  totalPayout: number;
  totalTurnoverUSD: number;
  totalProfitUSD: number;
  totalPayoutUSD: number;
  totalMargin: number;
  size?: string;
  currency: string;
};

export const UserPerformance = React.memo<UserPerformanceProps>(
  ({
    totalBets,
    totalTurnover,
    totalPayout,
    totalProfit,
    totalTurnoverUSD,
    totalPayoutUSD,
    totalProfitUSD,
    currency,
    totalMargin,
  }: UserPerformanceProps) => {
    return (
      <Statistic.Group widths={5} size="mini">
        <Statistic>
          <Statistic.Label>Total Bets</Statistic.Label>
          <Statistic.Value>{totalBets}</Statistic.Value>
        </Statistic>
        <Statistic>
          <Statistic.Label>Total Turnover</Statistic.Label>
          <Statistic.Value>
            <MoneyWithUserCurrency currency={currency} usd={totalTurnoverUSD}>
              {totalTurnover}
            </MoneyWithUserCurrency>
          </Statistic.Value>
        </Statistic>
        <Statistic>
          <Statistic.Label>Total Payout</Statistic.Label>
          <Statistic.Value>
            <MoneyWithUserCurrency currency={currency} usd={totalPayoutUSD}>
              {totalPayout}
            </MoneyWithUserCurrency>
          </Statistic.Value>
        </Statistic>
        <Statistic color={totalProfit > 0 ? 'green' : 'red'}>
          <Statistic.Label>Total Revenue</Statistic.Label>
          <Statistic.Value>
            <MoneyWithUserCurrency currency={currency} usd={totalProfitUSD}>
              {totalProfit}
            </MoneyWithUserCurrency>
          </Statistic.Value>
        </Statistic>
        <Statistic color={totalMargin > 0 ? 'green' : 'red'}>
          <Statistic.Label>Total Margin</Statistic.Label>
          <Statistic.Value>{totalMargin}%</Statistic.Value>
        </Statistic>
      </Statistic.Group>
    );
  }
);
