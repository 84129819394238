import cx from 'classnames';
import { Button, Label, Icon, Dropdown, SemanticCOLORS } from 'semantic-ui-react';

import { OutcomeResultLabel } from '../../../../components/table-components/OutcomeResultLabel';
import {
  OUTCOME_RESULT_OPTIONS,
  OutcomeResultsEnum,
  SPORT_ID_GREYHOUNDS,
  SPORT_ID_HORSE_RASING
} from '../../../../constants';
import { useVoidReasonList } from '../../../../react-query/query';

export const OutcomeResultSelector = ({
  value,
  voidReason,
  handleClick,
  disabled,
  setLocalOutcomes,
  localOutcomes,
  outcomesQuantity,
  sportId,
  localId = '0',
  parentDomId,
}: {
  value: string;
  voidReason: string;
  handleClick: (arg0: {
    result: keyof typeof OutcomeResultsEnum | string;
    voidReason?: string;
    setLocalOutcomes?: (string: object) => void;
    localOutcomes?: object;
  }) => void;
  disabled: boolean;
  setLocalOutcomes: (string: object) => void;
  localOutcomes: object;
  outcomesQuantity: number;
  sportId: string;
  localId?: string;
  parentDomId: string;
}) => {
  const { data: voidReasons } = useVoidReasonList();

  const voidReasonLabel = voidReasons?.find(v => v.value === voidReason);

  if (disabled) {
    return (
      <OutcomeResultLabel
        value={value}
        size="tiny"
        voidReasonLabel={voidReasonLabel}
      />
    );
  }

  return (
    <>
      {OUTCOME_RESULT_OPTIONS.map(([ id, label, color ]) => {
        if (id === OutcomeResultsEnum.SET_PLACE) {
          let i = 2;
          const placesList = [];
          while (i <= outcomesQuantity) {
            placesList.push({
              id: i - 1,
              description: `Place ${i}`,
              value: `PLACE_${i}`,
            });
            i++;
          }

          if (
            !([ SPORT_ID_GREYHOUNDS, SPORT_ID_HORSE_RASING ].includes(sportId) &&
            !!placesList.length)
          )
            return null;

          return(
            <Dropdown
              key={localId + id}
              id={parentDomId + 'set-place-dropdown'}
              text="Set Place"
              button
              className={cx('mini', color, value && !value?.includes('PLACE') && 'basic')}
              icon={<Icon style={{ margin: 0 }} name="angle down" />}
            >
              <Dropdown.Menu>
                {placesList.map(({ value, description }) => (
                  <Dropdown.Item
                    key={localId + value}
                    id={parentDomId + 'set-place-dropdown--' + value?.toLowerCase() + '-item'}
                    onClick={() =>
                      handleClick({
                        result: value,
                        setLocalOutcomes: setLocalOutcomes,
                        localOutcomes: localOutcomes,
                      })
                    }
                  >
                    {description}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          );
        } else if (id === OutcomeResultsEnum.VOID) {
          return (
            <Dropdown
              key={localId + id}
              id={parentDomId + 'void-reason-dropdown'}
              text="Void"
              button
              className={cx('mini', color, value !== OutcomeResultsEnum.VOID && 'basic')}
              icon={<Icon style={{ margin: 0 }} name="angle down" />}
            >
              <Dropdown.Menu>
                {voidReasons.map(({ value: voidReasonValue, description }) => (
                  <Dropdown.Item
                    key={localId + voidReasonValue}
                    id={
                      parentDomId + 'void-reason-dropdown--' + voidReasonValue?.toLowerCase() + '-item'
                    }
                    onClick={() =>
                      handleClick({
                        result: OutcomeResultsEnum.VOID,
                        voidReason: voidReasonValue,
                        setLocalOutcomes: setLocalOutcomes,
                        localOutcomes: localOutcomes,
                      })
                    }
                  >
                    {voidReasonValue === voidReason ? (
                      <Label horizontal color={color as SemanticCOLORS}>
                        {description}
                      </Label>
                    ) : (
                      description
                    )}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          );
        } else {
          return (
            <Button
              key={localId + id}
              id={parentDomId + label?.replace(/\s/, '-').toLowerCase() + '-button'}
              size="mini"
              onClick={() =>
                handleClick({
                  result: id,
                  setLocalOutcomes: setLocalOutcomes,
                  localOutcomes: localOutcomes,
                })
              }
              basic={value !== id}
              color={color as SemanticCOLORS}
            >
              {label}
            </Button>
          );
        }
      })}
    </>
  );
};
