import React from 'react';

const interval = (() => {
  let intervalId = null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  const listeners: Set<Function> = new Set();

  // eslint-disable-next-line @typescript-eslint/ban-types
  return (cb: Function) => {
    listeners.add(cb);
    if (listeners.size === 1 && intervalId === null) {
      // console.log('interval sub');
      intervalId = setInterval(() => {
        // console.log('interval tick');
        listeners.forEach(cb => cb());
      }, 1000);
    }

    return () => {
      if (listeners.has(cb)) {
        listeners.delete(cb);
        if (listeners.size === 0 && intervalId !== null) {
          // console.log('interval tick');
          clearInterval(intervalId);
          intervalId = null;
        }
      }
    };
  };
})();

// const usePrevious = value => {
//   const ref = React.useRef(value);
//   React.useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// };

export const useTimer = (
  seconds: number,
  countDown: boolean | null | undefined,
  paused: boolean | null | undefined
) => {
  const [ currentSeconds, setCurrentSeconds ] = React.useState(seconds);
  const [ lastSecondsFromProps, setLastSecondsFromProps ] = React.useState(seconds);

  if (seconds !== lastSecondsFromProps) {
    setCurrentSeconds(seconds);
    setLastSecondsFromProps(seconds);
  }

  React.useEffect(() => {
    if (!paused) {
      const unsubscribe = interval(() => {
        setCurrentSeconds(prevCurrentSeconds => {
          const nextValue = Math.max(prevCurrentSeconds + (countDown ? -1 : 1), 0);
          if (nextValue === 0) {
            unsubscribe();
          }
          return nextValue;
        });
      });

      return unsubscribe;
    }
  }, [ seconds, paused, countDown ]);

  return currentSeconds;
};

const padNumber = (n: number) => n.toString().padStart(2, '0');

const renderTimer = (seconds: number) => {
  const minutesTimer = ~~(seconds / 60);
  const secondsTimer = seconds % 60;
  return `${padNumber(minutesTimer)}:${padNumber(secondsTimer)}`;
};

export const EventClock = ({
  seconds,
  countDown,
  paused,
}: {
  seconds: number;
  countDown?: boolean;
  paused?: boolean;
}) => {
  const currentSeconds = useTimer(seconds, countDown, paused);

  return <>{renderTimer(currentSeconds)}</>;
};
