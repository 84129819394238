import React from 'react';

import { Button, Grid, Input, Modal } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { getMarketsForPresetRequest } from './helpers';
import {
  API_MARKET_PRESET_CREATE,
  API_MARKET_PRESET_DELETE,
  API_MARKET_PRESET_EDIT
} from '../../../constants';
import { PresetsType, SelectedMarketsStateType } from '../../../types';

export const PresetsActionPanel = ({
  selectedMarketsState,
  onSuccessForPresetsCreating,
  sportId,
  selectedPreset,
  setSelectedPreset,
  onSuccessForPresetsDeleting,
  onSuccessForPresetsUpdating,
}: {
  selectedMarketsState: SelectedMarketsStateType;
  onSuccessForPresetsCreating: () => void;
  closeModal: () => void;
  sportId: string;
  selectedPreset: { preset: PresetsType };
  setSelectedPreset: (selectedPreset: { preset: PresetsType }) => void;
  onSuccessForPresetsDeleting: () => void;
  onSuccessForPresetsUpdating: () => void;
}) => {
  const [ presetNameModalState, setPresetNameModalState ] = React.useState({ show: false });
  const [ newPresetNameState, setNewPresetNameState ] = React.useState({ name: '' });

  const { update: updateForPresetCreating } = useAxiosRequest(null, {
    onSuccess: () => {
      setNewPresetNameState({ name: '' });
      setPresetNameModalState({ show: false });
      onSuccessForPresetsCreating();
    },
  });

  const { update: updateForPresetDeleting } = useAxiosRequest(null, {
    onSuccess: onSuccessForPresetsDeleting,
  });

  const { update: updateForPresetUpdating } = useAxiosRequest(null, {
    onSuccess: onSuccessForPresetsUpdating,
  });

  const saveNewPreset = React.useCallback(() => {
    updateForPresetCreating({
      method: 'POST',
      url: API_MARKET_PRESET_CREATE,
      data: {
        sportId: sportId,
        description: newPresetNameState.name,
        items: getMarketsForPresetRequest(selectedMarketsState),
      },
    });
  }, [ selectedMarketsState, sportId, newPresetNameState, updateForPresetCreating ]);

  const deletePreset = React.useCallback(() => {
    if (selectedPreset.preset) {
      setSelectedPreset({ preset: undefined });
      updateForPresetDeleting({
        method: 'DELETE',
        url: API_MARKET_PRESET_DELETE,
        params: {
          id: selectedPreset.preset.id,
        },
      });
    }
  }, [ selectedPreset, setSelectedPreset, updateForPresetDeleting ]);

  const updatePreset = React.useCallback(() => {
    if (selectedPreset.preset) {
      updateForPresetUpdating({
        method: 'PUT',
        url: API_MARKET_PRESET_EDIT,
        data: {
          id: selectedPreset.preset.id,
          description: selectedPreset.preset.description,
          items: getMarketsForPresetRequest(selectedMarketsState),
        },
      });
    }
  }, [ selectedPreset, selectedMarketsState, updateForPresetUpdating ]);

  function saveNewPresetsModalOpen () {
    setPresetNameModalState({ show: true });
  }

  function closeWindow () {
    setPresetNameModalState({ show: false });
  }

  function modalOnClose () {
    setNewPresetNameState({ name: '' });
    setPresetNameModalState({ show: false });
  }


  return (
    <Grid.Column width={9}>
      <Grid.Row>
        <Button
          positive
          floated="left"
          labelPosition="right"
          icon="checkmark"
          content="Save new preset"
          onClick={saveNewPresetsModalOpen}
        />
        <Button
          color="orange"
          floated="left"
          labelPosition="right"
          icon="redo alternate"
          content="Update preset"
          onClick={updatePreset}
          disabled={!selectedPreset.preset && selectedMarketsState.markets.length === 0}
        />
        <Button
          color="red"
          floated="left"
          labelPosition="right"
          icon="window close"
          content="Delete preset"
          onClick={deletePreset}
          disabled={!selectedPreset.preset && selectedMarketsState.markets.length === 0}
        />
      </Grid.Row>
      <Modal
        size="mini"
        open={presetNameModalState.show}
        onClose={modalOnClose}
      >
        <Modal.Header>
          Enter new preset name
        </Modal.Header>
        <Modal.Content>
          <Input onChange={input => setNewPresetNameState({ name: input.target.value })} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            content="Close window"
            onClick={closeWindow}
          />
          <Button
            positive
            onClick={saveNewPreset}
            content="Save new preset"
          />
        </Modal.Actions>
      </Modal>
    </Grid.Column>
  );
};
