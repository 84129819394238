import { useMemo, useState } from 'react';

import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import { equals } from 'ramda';
import { useOutletContext } from 'react-router-dom';
import { Header, Input, Grid, Button, List, Checkbox, Message } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { Loading } from '../../../components/UI/Loading';
import {
  DATE_FORMAT_DATE_TIME,
  API_EVENTS_FIND_BY_ID,
  API_EVENTS_FIND,
  API_EVENT_BET_RESTRICTIONS,
  API_EVENT_BET_RESTRICTIONS_UPDATE
} from '../../../constants';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';
import { EventOutletContextType, EventType } from '../../../types';
import { removeEmptyFields } from '../../../utils';

import './TabBetRestrictions.css';

export const TabBetRestrictions = () => {
  const { eventId } = useOutletContext<EventOutletContextType>();

  const config = useMemo(() => {
    return ({
      url: API_EVENT_BET_RESTRICTIONS,
      params: {
        eventId: eventId,
      }
    });
  },[ eventId ]);

  const {
    data: restrictedEvents,
    isFetching,
  } = useAxiosRequest<{
    allow: boolean,
    allowLeague: boolean,
    eventDisallowIds: Array<string>;
    marketDisallowIds: Array<string>;
  }>(config);

  if (isFetching) {
    return <Loading />;
  }


  return (
    <TabBetRestrictionsUI
      initRestrictedEvents={restrictedEvents ? restrictedEvents.eventDisallowIds : []}
      allowsFetched={{
        allow: restrictedEvents ? restrictedEvents.allow : true,
        allowLeague: restrictedEvents ? restrictedEvents.allowLeague : true
      }}
    />
  );
};

const TabBetRestrictionsUI = ({
  initRestrictedEvents,
  allowsFetched,
}: {
  initRestrictedEvents: Array<string>;
  allowsFetched: {
    allow: boolean;
    allowLeague: boolean;
  }
}) => {
  const { originId } = useGlobalStateContext();
  const { eventId, event: { type: eventType }} = useOutletContext<EventOutletContextType>();
  const isRank = eventType === 'RANK';

  //TODO change many states to one reducer

  const [ searchEvent, setSearchEvent ] = useState('');
  const [ selectedEvents, setSelectedEvents ] = useState(initRestrictedEvents);
  const [ restrictedEvents, setRestrictedEvents ] = useState(initRestrictedEvents);
  const [ isFetching, setIsFetching ] = useState({ update: false });
  const [ error, setError ] = useState(null);
  const [ initAllows, setInitAllows ] = useState(allowsFetched);
  const [ allows, setAllows ] = useState(initAllows);
  const [ eventsWithDescription, setEventsWithDescription ] = useState<{
    [key: string]: EventType;
  }>({});

  const updateIsDisabled = equals(restrictedEvents.sort(), selectedEvents.sort()) &&
    equals(allows, initAllows);
  const restrictedEventsWithDescription = useMemo(() => {
    return restrictedEvents
      ? restrictedEvents.map(id => eventsWithDescription[id]).filter(v => v)
      : [];
  }, [ restrictedEvents, eventsWithDescription ]);

  const config = useMemo(() => {
    if (restrictedEvents?.length > 0)
      return {
        url: API_EVENTS_FIND_BY_ID,
        params: {
          eventIds: restrictedEvents,
          originId,
        },
        paramsSerializer: (v: string) => qs.stringify(v, { arrayFormat: 'repeat' }),
      };
    return null;
  }, [ restrictedEvents, originId ]);

  const { isFetching: eventsWithDescriptionIsFetching } = useAxiosRequest<{
    result: Array<EventType>;
  }> (config,
    {
      onSuccess: fetchEventsSuccess,
    }
  );

  const searchConfig = useMemo(() => {
    if (searchEvent?.length < 3) return null;
    return {
      url: API_EVENTS_FIND,
      params: {
        searchLine: searchEvent,
        startDateFrom: moment().subtract(12, 'hours').format(),
      }
    };
  }, [ searchEvent ]);

  const { data: eventsSearchData, isFetching: eventsSearchDataIsFetching } = useAxiosRequest<{
    result: Array<EventType>;
  }>(searchConfig);

  function fetchEventsSuccess (data) {
    setEventsWithDescription(prevData =>
      data.result.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      },
      { ...prevData })
    );
  }

  function updateOnClick () {
    setIsFetching(prev => ({ ...prev, update: true }));
    const dataForRequest = removeEmptyFields({
      betRestriction: {
        eventDisallowIds: selectedEvents,
        allowLeague: allows.allowLeague,
        allow: allows.allow
      },
      event: {
        id: eventId,
      },
    });

    axios
      .put(API_EVENT_BET_RESTRICTIONS_UPDATE, dataForRequest)
      .then(res => res.data)
      .then(onSuccess)
      .catch(onError)
      .finally(() => setIsFetching(prev => ({ ...prev, update: false })));

    function onSuccess (data) {
      if (data.error) {
        setError(data.error.error);
      } else {
        setRestrictedEvents(data.eventDisallowIds);
        setAllows({ allow: data.allow, allowLeague: data.allowLeague });
        setInitAllows({ allow: data.allow, allowLeague: data.allowLeague });
        setError(null);
      }
    }

    function onError (error) {
      setError(error.message);
    }
  }

  function handleSelectEvent (id: string) {
    setSelectedEvents(events => {
      if (events?.includes(id)) {
        return events.filter(v => v !== id);
      }
      return [ ...events, id ];
    });
  }

  function onChangeAllows () {
    setAllows(prev => ({
      allow: !prev.allow,
      allowLeague: isRank ? prev.allowLeague : !prev.allow
    }));
  }

  function onChangeAllowsLeague () {
    setAllows(prev => ({
      ...prev, allowLeague: !prev.allowLeague
    }));
  }

  return (
    <Grid>
      {error && (
        <Grid.Row>
          <Grid.Column>
            <Message error>{error}</Message>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column width={13} className="allows-row">
          <Checkbox
            label="Allow event to be combinable within the same league"
            id="bet-restriction--allow-league-checkbox"
            checked={allows.allowLeague}
            disabled={!allows.allow || isRank}
            onChange={onChangeAllowsLeague}
          />
          <Checkbox
            label="Allow event to be combinable"
            id="bet-restriction--allow-event-checkbox"
            checked={allows.allow}
            onChange={onChangeAllows}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <Button
            id="bet-restriction--update-btn"
            primary
            floated="right"
            onClick={updateOnClick}
            disabled={updateIsDisabled || isFetching.update}
            content="Update"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h3">Restricted events</Header>
          {eventsWithDescriptionIsFetching ? (
            <Loading />
          ) : (
            <CheckboxList
              data={restrictedEventsWithDescription}
              onChange={handleSelectEvent}
              selectedList={selectedEvents}
              parentDomId="bet-restriction--restricted-events-list--"
            />
          )}
        </Grid.Column>

        <Grid.Column>
          <Header as="h3">To restrict</Header>
          <Input
            id="bet-restriction--search-input"
            icon="search"
            placeholder="Search events (3 letters min)..."
            onChange={(e, { value }) => setSearchEvent(value)}
            className="TabBetRestrictions-searchField"
            value={searchEvent}
          />
          {eventsSearchDataIsFetching ? (
            <Loading />
          ) : (
            eventsSearchData && (
              <>
                <Header as="h4">Search results</Header>
                <CheckboxList
                  data={eventsSearchData.result}
                  onChange={handleSelectEvent}
                  selectedList={selectedEvents}
                  parentDomId="bet-restriction--search-events-list--"
                />
              </>
            )
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const CheckboxList = ({
  data,
  onChange,
  selectedList,
  parentDomId,
}:{
  data: Array<EventType>;
  onChange: (id: string) => void;
  selectedList: Array<string>;
  parentDomId: string;
}) =>
  Array.isArray(data) && data.length ? (
    <List>
      {data.map(item => (
        <List.Item key={item.id}>
          <Checkbox
            id={parentDomId + item.id.toLowerCase() + '-checkbox'}
            label={`${item.description} (${item.sportDescription}, ${moment(item.startDate).format(
              DATE_FORMAT_DATE_TIME
            )}`}
            checked={selectedList?.includes(item?.id)}
            onChange={() => onChange(item?.id)}
          />
        </List.Item>
      ))}
    </List>
  ) : (
    <div>No events</div>
  );
