import React from 'react';

import moment from 'moment';

export const useActualTime = () => {
  const [ now, setNow ] = React.useState(Date.now());

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(Date.now());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return now;
};

export const DateTime = React.memo<any>(({
  children,
  format = 'DD-MM-YYYY HH:mm:ss'
}:{
  children: string | number;
  format?: string;
}) => {
  return moment(children).format(format);
});
