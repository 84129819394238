import { List } from 'semantic-ui-react';

import { OriginsAccordion } from '../../components/form-components/OriginsAccordion';
import {
  QueryStateHideTestAccountsFilter
} from '../../components/search-and-filters-components/QueryStateHideTestAccountsFilter';
import { BetStatusesEnum, BetsSortByEnum, BetsTablePageType, FILTER_INTERVALS } from '../../constants';
import { BetsColumnLabelEnum, BetTypeTypeEnum } from '../../types';
import { BetsTableWithFilters } from '../Bets/BetsTableWithFilters';
import './RiskManager.css';

const LARGE_BETS_COLUMNS = [
  BetsColumnLabelEnum.Date,
  BetsColumnLabelEnum.Player,
  BetsColumnLabelEnum.Outcomes,
  BetsColumnLabelEnum.Stake,
  BetsColumnLabelEnum.Price,
  BetsColumnLabelEnum.Winning
];

const LARGE_BETS_PARAMS = {
  sortBy: [ BetsSortByEnum.BET_SORT_STAKE ],
  betStatuses: [ BetStatusesEnum.BET_STATUS_PENDING ],
  limit: 10,
};

const LARGE_SINGLES_PARAMS = {
  ...LARGE_BETS_PARAMS,
  betTypes: [ BetTypeTypeEnum.Bet_Single ],
};

const FILTERS = [];

const TableHeader = ({ title, parentDomId }) => {
  return (
    <div className="content-header singles-header">
      <h2>{title}</h2>
      <List horizontal>
        <List.Item>
          <OriginsAccordion
            parentDomId={parentDomId}
            horizontal
          />
        </List.Item>
        <List.Item>
          <QueryStateHideTestAccountsFilter parentDomId={parentDomId} />
        </List.Item>
      </List>
    </div>
  );
};

export const LargeSingles = () => (
  <div className="content-container">
    <TableHeader
      title="Singles"
      parentDomId="large-bets--singles--"
    />
    <BetsTableWithFilters
      columns={LARGE_BETS_COLUMNS}
      initialParams={LARGE_SINGLES_PARAMS}
      filters={FILTERS}
      pageType={BetsTablePageType.LARGE_SINGLE}
      parentDomId="large-bets--singles--"
      defaultPeriod={FILTER_INTERVALS.month.value}
    />
  </div>
);

const LARGE_MULTIPLES_PARAMS = {
  ...LARGE_BETS_PARAMS,
  betTypes: [ BetTypeTypeEnum.Bet_Multiple ],
};

export const LargeMultiples = () => (
  <div className="content-container">
    <TableHeader
      title="Multiples"
      parentDomId="large-bets--multiples--"
    />
    <BetsTableWithFilters
      columns={LARGE_BETS_COLUMNS}
      initialParams={LARGE_MULTIPLES_PARAMS}
      filters={FILTERS}
      pageType={BetsTablePageType.LARGE_MULTIPLE}
      parentDomId="large-bets--multiples--"
      defaultPeriod={FILTER_INTERVALS.month.value}
    />
  </div>
);
