import { useQueryClient } from '@tanstack/react-query';
import Axios from 'axios';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Form, Header } from 'semantic-ui-react';

import { CashoutSettingsForm } from '../../components/form-components/CashoutSettings';
import { EventPathSubmitButton } from '../../components/form-components/FormComponents';
import { API_EVENT_PATH_UPDATE } from '../../constants';
import { removeEqualsFields, removeEmptyFields } from '../../utils';

export const CashoutSettingsEdit = ({
  eventPathId,
  version,
  inherited,
  enabled,
  parentDomId,
  originId,
  revalidationParentKey,
}: {
  eventPathId: string;
  version: string;
  inherited?: boolean;
  enabled: boolean;
  parentDomId: string;
  originId: string;
  revalidationParentKey?: string;
}) => {
  const queryClient = useQueryClient();

  const showInherit = inherited != null;

  const initialValue = {
    cashoutInherited: inherited,
    cashoutEnabled: enabled,
  };

  async function submit (values, { setSubmitting, resetForm }) {
    try {
      const dataForRequest = removeEmptyFields({
        id: eventPathId,
        version,
        originId,
        ...removeEqualsFields(initialValue, values),
        cashoutInherited: values.cashoutInherited,
        cashoutEnabled: values.cashoutEnabled,
      });

      Axios
        .put(API_EVENT_PATH_UPDATE, dataForRequest)
        .then(response => {
          toast.success('Cashout settings have been updated');
          resetForm({
            ...response.data.result,
            values,
          });
        })
        .then(async () => {
          if (revalidationParentKey) {
            await queryClient.invalidateQueries({
              queryKey: [ revalidationParentKey ]
            });
          }
        })
      ;
    } catch (error) {
      toast.error(error);
    }

    setSubmitting(false);
  }

  return (
    <>
      <Header>Cashout Settings</Header>
      <Formik
        initialValues={initialValue}
        enableReinitialize
        onSubmit={submit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <CashoutSettingsForm
              id={parentDomId}
              enabled={enabled}
              showInherit={showInherit}
              cashoutInheritedName="cashoutInherited"
              cashoutEnabledName="cashoutEnabled"
            />
            <EventPathSubmitButton id={parentDomId + 'cashout-settings--submit-btn'} />
          </Form>
        )}
      </Formik>
    </>
  );
};
