import React, { useCallback, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import { Button, Grid, Header, Icon, Modal } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { getTreeWithPresetValues } from './helpers';
import { IrmBoardTable } from './IrmBoardTable';
import { MarketsModalList } from './MarketsModalList';
import { ModalPeriodsTree } from './ModalPeriodsTree';
import { PresetsActionPanel } from './PresetsActionPanel';
import { PresetsListDropdown } from './PresetsListDropdown';
import {
  API_MARKET_PRESET_LIST,
  API_MARKET_TEMPLATE_CREATE,
  API_PERIOD_FIND
} from '../../../constants';
import { ModalStateType, PeriodType, SingleByEventMarketType } from '../../../types';
import './MarketsEditor.css';

const NUMBER_OF_MARKETS_KEY = 'numberOfMarkets';

const initForPeriodsTree = {
  node: {
    id: undefined,
    description: undefined,
    inRunning: undefined,
    length: undefined,
    parentId: undefined,
    sequence: undefined,
  },
  children: undefined,
  opened: undefined,
  checked: undefined,
};

export const MarketsEditor = ({
  markets,
  originId,
  modalState,
  setModalState,
  eventId,
  sportId,
}: {
  markets: Array<SingleByEventMarketType>;
  modalState: ModalStateType;
  setModalState: (modalState: ModalStateType) => void;
  eventId: string;
  sportId: string;
  originId: string;
}) => {
  const [ modalMarketsState, setModalMarketsState ] = useState({ objList: [] });
  const [ presetsState, setPresetsState ] = useState({ presetsList: [] });
  const [ selectedPreset, setSelectedPreset ] = useState({ preset: undefined });
  const [ periodsTreeState, setPeriodsTreeState ] = useState(initForPeriodsTree);
  const [ selectedPeriodsState, setSelectedPeriodsState ] = useState({ periods: [] });
  const [ selectedMarketsState, setSelectedMarketsState ] = useState({ markets: [] });
  const [ allMarketsStatus, setAllMarketsStatus ] = useState({ checked: false });
  const [ periodsList, setPeriodsList ] = useState([]);

  const periodsConfig = useMemo(() => ({
    url: API_PERIOD_FIND,
    params: { sportEventPathId: sportId },
  }),[ sportId ]);

  const presetsConfig = useMemo(() => {
    return {
      url: API_MARKET_PRESET_LIST,
      params: {
        sportId: sportId,
      }
    };
  },[ sportId ]);

  const { update: updateForMarketsCreate } = useAxiosRequest(null, {
    onSuccess: async () => {
      toast.success('Markets were successfully created');
      closeModal();
    },
  });

  useAxiosRequest<{ result: Array<PeriodType> }>(periodsConfig, {
    onSuccess: data => {
      setPeriodsList(data?.result);
    },
  });

  useAxiosRequest(presetsConfig, { onSuccess: onSuccessForPresetsList });

  const { update: getPresetsUpdate } = useAxiosRequest(null, {
    onSuccess: onSuccessForPresetsList
  });


  const filterMarketsToDisplay = useCallback(data => {
    return data
      .filter(market => {
        if (market.allowMultiMarkets) {
          const specialByPeriod = markets.filter(
            sortedMarket =>
              sortedMarket.market.typeId === market.id &&
              sortedMarket.market.periodId === market.periodId
          );
          return specialByPeriod.length < 10;
        } else {
          return !market.marketExist;
        }
      })
      .map(market => {
        if (market.allowMultiMarkets) {
          const oldMarketInfo = modalMarketsState.objList.find(
            oldMarketState =>
              oldMarketState.id === market.id && oldMarketState.periodId === market.periodId
          );
          if (oldMarketInfo) {
            market.numberOfMarkets = oldMarketInfo.numberOfMarkets;
          } else {
            market.numberOfMarkets = 0;
          }
          return market;
        } else {
          return market;
        }
      });
  },[ markets, modalMarketsState ]);

  const updateNumberOfSpecials = useCallback((
    counter: number,
    marketId: string,
    periodId: string
  ) => {
    const updatedMarket = selectedMarketsState.markets.filter(
      market => market.marketId === marketId && market.periodId === periodId
    );
    if (updatedMarket.length !== 0) {
      setSelectedMarketsState({
        markets: selectedMarketsState.markets
          .filter(market => !(market.marketId === marketId && market.periodId === periodId))
          .concat([
            {
              ...updatedMarket[0],
              numberOfMarkets: counter,
            }
          ]),
      });
    }
  },[ selectedMarketsState ]);
  const closeModal = useCallback(() => {
    setModalState({
      showModal: false,
    });
    setSelectedPeriodsState({
      periods: [],
    });
    setSelectedMarketsState({
      markets: [],
    });
    setModalMarketsState({
      objList: [],
    });
    setAllMarketsStatus({ checked: false });
  }, [ setModalState, setModalMarketsState ]);

  const setNumberOfMarkets = useCallback((marketId, periodId, numberOfMarkets) => {
    setModalMarketsState({
      objList: modalMarketsState.objList.map(market => {
        if (market.id === marketId && market.periodId === periodId) {
          market.numberOfMarkets = numberOfMarkets;
          return market;
        } else return market;
      }),
    });
  },[ modalMarketsState ]);

  function onSuccessForSelectedPeriod (data) {
    if (data) {
      const result = data.result;

      setModalMarketsState({
        objList: filterMarketsToDisplay(result),
      });
    }
  }

  function onSuccessForPresetsDeleting () {
    getPresetsUpdate(presetsConfig);
    toast.success('Preset was successfully deleted');
    setSelectedMarketsState({ markets: [] });
    setSelectedPeriodsState({ periods: [] });
    setModalMarketsState({ objList: [] });
    setPeriodsTreeState(getTreeWithPresetValues([], periodsTreeState));
  }

  function onSuccessForGettingPresetMarkets (data) {
    if (!data) return;

    const result = data.result;
    setModalMarketsState({
      objList: filterMarketsToDisplay(result),
    });

    const currentPreset = selectedPreset.preset;

    if (currentPreset) {
      setSelectedMarketsState({
        markets: currentPreset.items.map(item => {
          return {
            marketId: item.marketTypeId,
            periodId: item.periodId,
          };
        }),
      });
    }
  }

  function onSuccessForPresetsList (presetsData) {
    if (presetsData) {
      setPresetsState({
        presetsList: presetsData.presetList,
      });
    }
  }

  function getMarketsForPeriods () {
    const allMarkets = [];
    let containsEmptySpecials = false;
    selectedMarketsState.markets.forEach(market => {
      if (NUMBER_OF_MARKETS_KEY in market && market.numberOfMarkets === 0) {
        containsEmptySpecials = true;
      }
      allMarkets.push({
        periodId: market.periodId,
        marketTypeId: modalMarketsState.objList.find(
          marketFromState => marketFromState.id === market.marketId
        ).id,
        extraFields: [],
        ...(NUMBER_OF_MARKETS_KEY in market && {
          multiMarketCount: market.numberOfMarkets,
        }),
      });
    });
    return [ allMarkets, containsEmptySpecials ];
  }

  function createMarkets () {
    const [ selectedMarkets, containsEmptySpecials ] = getMarketsForPeriods();
    containsEmptySpecials
      ? toast.error('Specify the number of special markets or deselect special markets with 0')
      : updateForMarketsCreate({
        method: 'POST',
        url: API_MARKET_TEMPLATE_CREATE,
        data: {
          eventId: eventId,
          markets: selectedMarkets,
          originId,
        },
      });
  }

  function onSuccessForPresetsCreating () {
    getPresetsUpdate(presetsConfig);
    toast.success('Preset was successfully created');
  }

  function onSuccessForPresetsUpdating () {
    getPresetsUpdate(presetsConfig);
    toast.success('Preset was successfully updated');
  }

  return (
    <Modal
      closeIcon
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={modalState.showModal}
      onClose={closeModal}
      size="large"
    >
      <Modal.Header>Markets Editor</Modal.Header>
      <Modal.Content scrolling className="markets-modal-content">
        <Grid celled className="markets-modal-content__grid">
          <Grid.Column width={4}>
            <Header as="h3">Periods</Header>
            {periodsList.length && (
              <ModalPeriodsTree
                selectedPeriodsState={selectedPeriodsState}
                setSelectedPeriodsState={setSelectedPeriodsState}
                onSuccessForSelectedPeriod={onSuccessForSelectedPeriod}
                eventId={eventId}
                periodsList={periodsList}
                periodsTreeState={periodsTreeState}
                setPeriodsTreeState={setPeriodsTreeState}
                selectedMarketsState={selectedMarketsState}
                setSelectedMarketsState={setSelectedMarketsState}
              />
            )}
          </Grid.Column>
          <Grid.Column width={5} className="list-of-markets">
            <Grid.Row>
              <Header as="h3">Markets</Header>
              <MarketsModalList
                markets={markets}
                modalMarketsState={modalMarketsState}
                selectedMarketsState={selectedMarketsState}
                setSelectedMarketsState={setSelectedMarketsState}
                allMarketsStatus={allMarketsStatus}
                setAllMarketsStatus={setAllMarketsStatus}
                updateNumberOfSpecials={updateNumberOfSpecials}
                setNumberOfMarkets={setNumberOfMarkets}
              />
            </Grid.Row>
          </Grid.Column>
          <IrmBoardTable markets={markets} originId={originId} />
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Grid>
          <Grid.Row>
            <PresetsListDropdown
              presetsState={presetsState}
              selectedPreset={selectedPreset}
              setSelectedPreset={setSelectedPreset}
              periodsTreeState={periodsTreeState}
              setPeriodsTreeState={setPeriodsTreeState}
              setSelectedPeriodsState={setSelectedPeriodsState}
              eventId={eventId}
              onSuccessForGettingPresetMarkets={onSuccessForGettingPresetMarkets}
              setSelectedMarketsState={setSelectedMarketsState}
              setModalMarketsState={setModalMarketsState}
              selectedMarketsState={selectedMarketsState}
              onSuccessForPresetsUpdating={onSuccessForPresetsUpdating}
            />
            <PresetsActionPanel
              selectedMarketsState={selectedMarketsState}
              onSuccessForPresetsCreating={onSuccessForPresetsCreating}
              closeModal={closeModal}
              sportId={sportId}
              selectedPreset={selectedPreset}
              setSelectedPreset={setSelectedPreset}
              onSuccessForPresetsDeleting={onSuccessForPresetsDeleting}
              onSuccessForPresetsUpdating={onSuccessForPresetsUpdating}
            />
            <Grid.Column width={3}>
              <Button icon labelPosition="right" float="right" onClick={createMarkets}>
                Create markets
                <Icon name="arrow right" />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};
