import React, { ReactNode } from 'react';

import { toast } from 'react-toastify';
import { Popup, Button } from 'semantic-ui-react';

import { MarketStatusLabel } from './MarketStatusLabel';
import {
  MARKET_STATUS_ENUM,
  MARKET_STATUSES,
  MARKET_STATUSES_UPDATE_DATA
} from '../../../../constants';
import { useGlobalStateContext } from '../../../../hooks-and-global-states/global-context';
import { useBoolean } from '../../../../hooks-and-global-states/hooks';
import { useMarketUpdate } from '../../../../react-query/mutations';
import { MarketStatusType, OutcomeSinglesByEventType } from '../../../../types';
import { isMarketOpeningEnable, removeEmptyFields } from '../../../../utils';

function getStatusCollection (outcomes: OutcomeSinglesByEventType[], status: MarketStatusType) {
  if (outcomes.length === 0) {
    return [
      ...MARKET_STATUSES_UPDATE_DATA,
      [ MARKET_STATUS_ENUM.SETTLED, MARKET_STATUSES.SETTLED, 'Settle', 'Settle' ]
    ];
  } else if (status === MARKET_STATUS_ENUM.RESULTED) {
    return [[ MARKET_STATUS_ENUM.SETTLED, MARKET_STATUSES.SETTLED, 'Settle', 'Settle' ]];
  }

  return MARKET_STATUSES_UPDATE_DATA;
}

type TMarketStatusLabelUpdate = {
  id: string;
  status: MarketStatusType;
  manualMode: boolean;
  outcomes: Array<OutcomeSinglesByEventType>;
  canChange?: boolean;
  parentDomId: string;
};

const MarketStatusLabelUpdateCanChange = ({
  children,
  status,
  id,
  manualMode,
  outcomes,
  parentDomId,
}: TMarketStatusLabelUpdate & {children: ReactNode}) => {
  const { value, setFalse, setTrue } = useBoolean(false);
  const { originId } = useGlobalStateContext();

  const mutMarketUpdate = useMarketUpdate({
    onSuccess: () => {
      setFalse();
      toast.success('Update successful');
    },
  });

  const statusCollection = getStatusCollection(outcomes, status);

  function updateMarketData (data) {
    if (data.status === MARKET_STATUS_ENUM.OPEN && !isMarketOpeningEnable(outcomes)) {
      toast.error('Cannot open some markets. Please change null outcome prices');
    } else {
      const dataForRequest = removeEmptyFields({
        originId,
        id,
        ...data,
      });

      mutMarketUpdate.mutate(dataForRequest);
    }
  }

  return (
    <Popup
      open={value}
      onOpen={setTrue}
      onClose={setFalse}
      trigger={
        <span style={{ cursor: 'pointer' }}>
          {children}
        </span>
      }
      content={
        <div>
          {statusCollection.filter(
            ([ enumMarketStatus ]) => enumMarketStatus !== status
          ).map(([ enumMarketStatus, { color }, action ]: Array<any>) => {
            return (
              <p key={String(enumMarketStatus)}>
                <Button
                  id={parentDomId + String(action).toLowerCase() + '-action'}
                  loading={mutMarketUpdate.isPending}
                  color={color}
                  fluid
                  onClick={() => {
                    updateMarketData({
                      status: enumMarketStatus,
                      outcomes: outcomes,
                    });
                  }}
                >
                  {String(action)}
                </Button>
              </p>
            );
          })}
          {manualMode && (
            <>
              <p>
                <Button
                  id={parentDomId + 'stop-manual-mode-action'}
                  loading={mutMarketUpdate.isPending}
                  color="grey"
                  fluid
                  onClick={() => {
                    updateMarketData({
                      manualMode: false,
                      outcomes: outcomes,
                    });
                  }}
                  content="Disable Manual Mode"
                />
              </p>
              <p>After disabling Manual Mode market will receive feed status updates</p>
            </>
          )}
        </div>
      }
      on="click"
      position="top right"
    />
  );
};

export const MarketStatusLabelUpdate = React.memo<TMarketStatusLabelUpdate>(({
  canChange,
  status,
  id,
  manualMode,
  outcomes,
  parentDomId,
}: TMarketStatusLabelUpdate) => {
  const isStatusUpdatable = canChange || status === MARKET_STATUS_ENUM.RESULTED;

  if (!isStatusUpdatable)
    return <MarketStatusLabel status={status} size="tiny" />;

  return (
    <MarketStatusLabelUpdateCanChange
      manualMode={manualMode}
      id={id}
      status={status}
      outcomes={outcomes}
      parentDomId={parentDomId}
    >
      <MarketStatusLabel
        id={parentDomId + 'main-button'}
        status={status}
        size="tiny"
      />
    </MarketStatusLabelUpdateCanChange>
  );
});
