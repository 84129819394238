import axios from 'axios';

import { IBetPlaceRequestData } from './types';
import {
  API_BET_PLACE,
  API_EVENT_COPY,
  API_EVENT_UPDATE,
  API_MARKET,
  API_MARKET_CREATE,
  API_MARKETS_UPDATE,
  API_ORIGIN,
  API_ORIGIN_MARGINS_UPDATE,
  API_PRICE_CREATE,
  API_PRICES_CREATE,
  API_SETTLE_BETS,
  API_VOID_BETS
} from '../constants';
import { LeagueLimitGroup } from '../types';

export const fnBetPlace = (data: IBetPlaceRequestData) => {
  return axios.post(API_BET_PLACE, data );
};

export const fnBetsSettle = (data: {ids: string[]}) => {
  return axios.post(API_SETTLE_BETS, data );
};

export const fnBetsVoid = (data: object) => {
  return axios.post(API_VOID_BETS, data );
};

export const fnStatusHide = (data: object) => {
  return axios.put(API_EVENT_UPDATE, data );
};

export const fnEventCopy = (data: object) => {
  return axios.post(API_EVENT_COPY, data );
};

export const fnMarketsUpdate = (data: object) => {
  return axios.put(API_MARKETS_UPDATE, data);
};

export const fnMarketUpdate = (marketId: string, data: object) => {
  return axios.put(`${API_MARKET}/${marketId}/update`, data);
};

export const fnMarketCreate = (data: object) => {
  return axios.post(API_MARKET_CREATE, data);
};

export const fnPricesCreate = (data: object) => {
  return axios.post(API_PRICES_CREATE, data);
};

export const fnOriginLimitGroupUpdate = (data: {originId: string, limitGroups: LeagueLimitGroup[]}) => {
  return axios.put(`${API_ORIGIN}/${data.originId}/limitGroups`, data);
};

export const fnPriceCreate = (data: object) => {
  return axios.post(API_PRICE_CREATE, data);
};

export const fnOriginMarginUpdate = (data: object) => {
  return axios.put(API_ORIGIN_MARGINS_UPDATE, data);
};
