import React from 'react';

import { Field, ErrorMessage, connect } from 'formik';
import {
  Form,
  Checkbox,
  Input,
  Dropdown,
  TextArea,
  List,
  DropdownProps,
  SemanticWIDTHS
} from 'semantic-ui-react';
import { $Diff } from 'utility-types';

import { getField } from './utils';
import { MARKET_TYPE_ID, PERIOD_ID } from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import './FormComponents.css';
import { useLimitGroups } from '../../hooks-and-global-states/hooks';
import { FieldTypesEnum } from '../../types';
import { getOptionsWithIds } from '../../utils';

type TPhoenixField = {
  name: string;
  type?: typeof FieldTypesEnum[keyof typeof FieldTypesEnum];
  label?: string;
  inline?: boolean;
  disabled?: boolean;
  width?: SemanticWIDTHS;
  required?: boolean;
  fieldClassName?: string;
  toggle?: boolean;
}

export const PhoenixField = React.memo(({
  label,
  inline,
  type = FieldTypesEnum.TEXT,
  name,
  disabled,
  width,
  required,
  fieldClassName,
  toggle,
  ...props
}: TPhoenixField) => (
  <Field name={name}>
    {({ field, form: { setFieldValue, touched, errors }}) => {
      const errorField = getField(errors, field.name);
      const touchedField = getField(touched, field.name);
      const error = touchedField && errorField;
      const commonProps = {
        ...field,
        ...props,
        inline,
        type,
      };

      return (
        <Form.Field
          error={error}
          disabled={disabled}
          width={width}
          required={required}
          className={`${name} ${fieldClassName}`}
        >
          {type === 'checkbox' ? (
            <Checkbox
              {...commonProps}
              value={undefined}
              label={label}
              checked={field.value}
              onChange={(e, { checked }) => {
                setFieldValue(name, checked);
              }}
              toggle={toggle}
            />
          ) : (
            <>
              {label && <label>{label}</label>}
              {type === 'textArea'
                ? <TextArea {...commonProps} />
                : <Input {...commonProps} />
              }
            </>
          )}
          <ErrorMessage name={field.name} />
        </Form.Field>
      );
    }}
  </Field>
));

export const PhoenixFieldRender = ({
  name,
  required,
  children,
  width,
  disabled,
  label,
  fieldClassName,
}: {
  name: string;
  required?: boolean;
  children: React.ComponentProps<typeof Field>['children'];
  width?: SemanticWIDTHS;
  disabled?: boolean;
  label?: string;
  fieldClassName?: string;
}) => (
  <Field name={name}>
    {formikRenderProps => {
      const {
        field,
        form: { touched, errors },
      } = formikRenderProps;
      const error = touched[field.name] && errors[field.name];
      return (
        <Form.Field
          error={!!error}
          required={required}
          width={width}
          disabled={disabled}
          className={`${name} ${fieldClassName}`}
        >
          {label && <label>{label}</label>}
          {children(formikRenderProps)}
          <ErrorMessage name={field.name} />
        </Form.Field>
      );
    }}
  </Field>
);

type PhoenixFieldInputProps = {
  name?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  value?: boolean;
  fieldClassName?: string;
};

const MemoDropdown = ({
  setFieldValue,
  options,
  name,
  cutoffTime,
  fillOutcomes,
  changeLimitForOrigin,
  customOnChange,
  ...props
}) => {
  const onChange = React.useCallback((e, data) => {
    if (!data) return;
    setFieldValue(name, props.clearable && data.value === '' ? null : data.value);

    //TODO check custom onChange, make 1 props for all custom onChanges
    if (customOnChange) {
      customOnChange(data.value, setFieldValue);
    }

    if (data.name === MARKET_TYPE_ID || data.name === PERIOD_ID) {
      fillOutcomes(data, cutoffTime, setFieldValue);
    }

    if (changeLimitForOrigin) {
      const minStake = String(data.options.find(o => o.value === data.value).rate);
      changeLimitForOrigin(minStake);
    }

  },[
    setFieldValue,
    name,
    props.clearable,
    customOnChange,
    changeLimitForOrigin,
    fillOutcomes,
    cutoffTime
  ]);

  return (
    <Dropdown
      {...props}
      name={name}
      options={getOptionsWithIds(options || [], props.id)}
      onChange={onChange}
    />
  );
};

export type PhoenixFieldDropdownProps = $Diff<
  DropdownProps,
  {
    value?: DropdownProps['value'];
    onChange?: DropdownProps['onChange'];
    setConfigForm?: (configForm) => void;
    configForm?: {
      eventId: string;
      marketTypeId: string | null;
      periodId: string | null;
    };
    itemsTypeGroup?: string;
    setItemsTypeGroup?: (string) => void;
    startTime?: string;
    cutoffTime?: string;
    fillOutcomes?: any;
    changeLimitForOrigin?: (value: string | number) => void;
  }
> & PhoenixFieldInputProps;

export const PhoenixFieldDropdown = ({
  name,
  label,
  placeholder = 'Choose one',
  required,
  cutoffTime,
  fillOutcomes,
  fieldClassName,
  ...props
}: PhoenixFieldDropdownProps) => (
    <PhoenixFieldRender
      name={name}
      required={required}
      label={label}
      disabled={props.disabled}
      fieldClassName={fieldClassName}
    >
      {({ form: { setFieldValue }, field }) => {
        const commonProps = { placeholder, ...props, ...field };
        return (
          <MemoDropdown
            {...commonProps}
            name={name}
            setFieldValue={setFieldValue}
            selection
            search
            cutoffTime={cutoffTime}
            fillOutcomes={fillOutcomes}
          />
        );
      }}
    </PhoenixFieldRender>
  );

export const PhoenixFieldTextWithDefaultOrManuallyInput = ({
  setFieldValue,
  dynamicDefaultValue,
  ...props
}) => {
  React.useEffect(() => {
    setFieldValue(props.name, dynamicDefaultValue);
  }, [ dynamicDefaultValue, setFieldValue, props.name ]);
  return <Input {...props} />;
};

export const PhoenixFieldTextWithDefaultOrManually = ({
  dynamicDefaultValue,
  name,
  label,
  width = 16,
  required,
  id,
}: {
  dynamicDefaultValue: string | null | undefined;
  name: string;
  label: string;
  width?: SemanticWIDTHS;
  required?: boolean;
  id: string;
}) => {
  const [ manually, setManually ] = React.useState(false);
  return (
    <>
      <Form.Group>
        <PhoenixFieldRender
          name={name}
          width={width}
          required={required}
          disabled={!manually}
          label={label}
        >
          {({ form: { setFieldValue }, field }) => (
            <PhoenixFieldTextWithDefaultOrManuallyInput
              id={id + '-input'}
              dynamicDefaultValue={dynamicDefaultValue}
              setFieldValue={setFieldValue}
              {...field}
            />
          )}
        </PhoenixFieldRender>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <Checkbox
            id={id + '-checkbox'}
            label={`Set ${label.toLowerCase()} manually`}
            checked={manually}
            onChange={() => setManually(!manually)}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

export const PhoenixFieldMultipleRadioType = ({
  name,
  label,
  required,
  options,
  horizontal,
  ...props
}: PhoenixFieldInputProps & {
  options: Array<{
    value: string | number;
    text: string;
    id: string;
  }>;
  horizontal?: boolean;
}) => (
    <PhoenixFieldRender
      name={name}
      required={required}
      label={label}
      disabled={props.disabled}
    >
      {({ form: { setFieldValue }, field }) => (
          <List relaxed horizontal={horizontal}>
            {options.map(({ value, text, id }) => (
                <List.Item key={value}>
                  <Checkbox
                    id={id}
                    radio
                    label={text}
                    value={undefined}
                    checked={field.value === value}
                    onChange={() => {
                      setFieldValue(name, value);
                    }}
                  />
                </List.Item>
              ))}
          </List>
        )}
    </PhoenixFieldRender>
  );

export const PhoenixFieldCheckboxType = ({
  name,
  label,
  required,
  value,
  ...props
}: PhoenixFieldInputProps) => (
    <PhoenixFieldRender name={name} required={required} disabled={props.disabled}>
      {({ form: { setFieldValue }, field }) => (
          <Checkbox
            label={label}
            value={undefined}
            defaultChecked={value}
            checked={field.value}
            onChange={() => {
              setFieldValue(name, !field.value);
            }}
          />
        )}
    </PhoenixFieldRender>
  );

const SubmitButton = ({ formik: { isValid, dirty, isSubmitting }, onClick, id }) => {
  const { isDefaultOrigin } = useGlobalStateContext();
  const submitButtonText = isDefaultOrigin ? 'Submit for all origins' : 'Submit';
  return (
    <Form.Button
      id={id}
      type="submit"
      loading={isSubmitting}
      disabled={!isValid || !dirty}
      onClick={onClick}
    >
      {submitButtonText}
    </Form.Button>
  );
};

export const PhoenixSubmitButton: React.ComponentType<{id: string}> = connect(SubmitButton);

const EventPathTreeSubmitButton = ({ formik: { isValid, dirty, isSubmitting }, id }) => {
  const { isDefaultOrigin } = useGlobalStateContext();

  const submitButtonText = isDefaultOrigin ? 'Submit for all origins' : 'Submit';
  return (
    <Form.Button
      id={id}
      type="submit"
      loading={isSubmitting}
      disabled={!isValid || !dirty}
    >
      {submitButtonText}
    </Form.Button>
  );
};

export const EventPathSubmitButton: React.ComponentType<{id: string}> =
  connect(EventPathTreeSubmitButton);

export const LimitGroupsDropdown = ({
  className,
}:{
  className?: string,
}) => {
  const { originId } = useGlobalStateContext();

  const { limits, isFetching } = useLimitGroups({ originId });

  const options = React.useMemo(() => {
    if (limits.length !== 0) {
      return limits.map(({ id, description }) => ({
        value: id,
        text: description,
      }));
    }
    return [];
  }, [ limits ]);

  return (
    <PhoenixFieldDropdown
      id="limit-group-dropdown"
      name="defaultLimitGroupId"
      loading={isFetching}
      options={options}
      label="Default Limit Group"
      className={className}
    />
  );
};
