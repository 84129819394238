import React from 'react';

import { Button, Dropdown } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { API_VOID_BETS } from '../../constants';

export const VoidBetButton = ({
  betId,
  refresh,
  voidReasonList,
  parentDomId,
}: {
  betId: string;
  refresh: () => void;
  voidReasonList: Array<{
    id: string;
    description: string;
    value: string;
  }>;
  parentDomId: string;
}) => {
  const [ showReason, setShowReason ] = React.useState(false);
  const [ voidReason, setVoidReason ] = React.useState('');

  const options = React.useMemo(() => {
    return voidReasonList.map(({ value, description }) => ({
      key: value,
      value,
      text: description,
      id: parentDomId + 'void-reason-dropdown--' + value.toLowerCase().replace(/_/g, '-') + '-option'
    }));
  }, [ voidReasonList, parentDomId ]);

  const { isFetching, update } = useAxiosRequest(null);
  const [ prevIsFetching, setPrevIsFetching ] = React.useState(isFetching);

  if (prevIsFetching !== isFetching) {
    if (!isFetching) {
      setShowReason(false);
      setVoidReason('');
      refresh();
    }
    setPrevIsFetching(isFetching);
  }

  function voidReasonOnChange (e, data) {
    setVoidReason(data.value);
  }

  function submitOnChange () {
    update({
      method: 'post',
      url: API_VOID_BETS,
      data: {
        bets: [
          {
            id: betId,
            reason: voidReason,
          }
        ],
      },
    });
  }

  function voidOnChange () {
    setShowReason(s => !s);
  }

  if (showReason) {
    return (
      <Button.Group size="mini">
        <Button
          id={parentDomId + 'close-btn'}
          color="red"
          icon="close"
          disabled={isFetching}
          onClick={() => setShowReason(false)}
        />
        <Dropdown
          id={parentDomId + 'void-reason-dropdown'}
          placeholder="Void Reason"
          button
          defaultOpen
          className="violet basic icon"
          icon="angle down"
          options={options}
          onChange={voidReasonOnChange}
        />

        <Button
          id={parentDomId + 'submit-btn'}
          disabled={!voidReason}
          loading={isFetching}
          color="blue"
          onClick={submitOnChange}
          content="Submit"
        />
      </Button.Group>
    );
  }

  return (
    <Button
      id={parentDomId + 'main-btn'}
      size="mini"
      onClick={voidOnChange}
      color="violet"
      content="void"
    />
  );
};
