import React from 'react';

import { Label } from 'semantic-ui-react';

import { usePlayerFactorGroupById } from '../../utils';

export const PlayerFactorGroupLabel = ({
  userFactorGroupId,
  simple,
}:{
  userFactorGroupId: string;
  simple?: boolean;
}) => {
  const playerFactorGroup = usePlayerFactorGroupById(userFactorGroupId);

  if (!playerFactorGroup) {
    return null;
  }

  if (simple) {
    return (
      <div>
        {playerFactorGroup.shortDescription}
      </div>
    );
  }

  const style: Record<string, string> = {
    backgroundColor: playerFactorGroup.color,
    border: '1px solid gray',
  };

  return (
    <Label
      horizontal
      size="mini"
      style={style}
    >
      {playerFactorGroup.shortDescription}
    </Label>
  );
};
