import React from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Label, Menu } from 'semantic-ui-react';

import {
  LINK_BET_RESTRICTIONS,
  LINK_BETS,
  LINK_IRM,
  LINK_MARKETS,
  TRADER_PERM_ALL,
  TRADER_PERM_EVENT_EDITOR
} from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { EventType } from '../../types';
import './EventEditMenuComponents.scss';

export const MenuCopyTabular = ({
  isMainEvent,
  pairedEventHideState,
  eventHideState,
  event,
  id,
}: {
  isMainEvent: boolean;
  pairedEventHideState: boolean;
  eventHideState: boolean;
  event: EventType;
  id: string;
}) => {
  const navigate = useNavigate();

  const getColor = (isHide: boolean) => {
    return isHide ? 'red' : 'green';
  };

  const feedColor = getColor(isMainEvent ? eventHideState : pairedEventHideState);
  const copyColor = getColor(isMainEvent ? pairedEventHideState : eventHideState);

  const redirectOnEvent = (newEventId: string) => {
    navigate(`/event-edit/${newEventId}`);
  };

  return (
    <Menu attached="top" tabular>
      <Menu.Item
        id={id + 'main-event'}
        active={isMainEvent}
        onClick={() => redirectOnEvent(event.mainEventId)}
      >
        Feed
        <Label
          className="event-edit_menu-tab-label"
          circular
          color={feedColor}
        />
      </Menu.Item>
      <Menu.Item
        id={id + 'copy-event'}
        active={!isMainEvent}
        onClick={() => redirectOnEvent(event.othersCopyEvents[0])}
      >
        Copy
        <Label
          className="event-edit_menu-tab-label"
          circular
          color={copyColor}
        />
      </Menu.Item>
    </Menu>
  );
};

export const MainMenuTabular = React.memo(() => {
  const { permissions } = useGlobalStateContext();
  const enableEditing =
    !permissions?.denyPermissions?.includes(TRADER_PERM_EVENT_EDITOR) &&
    [ TRADER_PERM_EVENT_EDITOR, TRADER_PERM_ALL ].some(permission =>
      permissions?.allowPermissions?.includes(permission)
    );

  const { pathname } = useLocation();
  const marketsIsActive = pathname?.includes(LINK_MARKETS);
  const betRestrictionsIsActive = pathname?.includes(LINK_BET_RESTRICTIONS);
  const betIsActive = pathname?.includes(LINK_BETS);
  const irmIsActive = pathname?.includes(LINK_IRM);
  const descriptionIsActive =
    !marketsIsActive && !betRestrictionsIsActive && !betIsActive && !irmIsActive;

  return (
    <Menu tabular>
      <Menu.Item
        id="event-edit-main-menu-description-tab"
        as={Link}
        name="Description"
        to=""
        active={descriptionIsActive}
      />
      <Menu.Item
        id="event-edit-main-menu-markets-tab"
        as={Link}
        name="Markets"
        to={LINK_MARKETS}
        active={marketsIsActive}
      />
      {enableEditing && (
        <Menu.Item
          id="event-edit-main-menu-bet-restrictions-tab"
          as={Link}
          name="Bet restrictions"
          to={LINK_BET_RESTRICTIONS}
          active={betRestrictionsIsActive}
        />
      )}
      <Menu.Item
        id="event-edit-main-menu-bets-tab"
        as={Link}
        name="Bets"
        to={LINK_BETS}
        active={betIsActive}
      />
      {enableEditing && (
        <Menu.Item
          id="event-edit-main-menu-irm-tab"
          as={Link}
          name="IRM"
          to={LINK_IRM}
          active={irmIsActive}
        />
      )}
    </Menu>
  );
});
