import { createContext, FC, useContext, useEffect, useMemo, useReducer } from 'react';

import { filterObject, getBetParams } from './helpers';
import {
  ASCENDING,
  BET_ACCEPTED_FILTER,
  BetsSortByEnum,
  BetStatusesEnum,
  DESCENDING,
  FILTER_INTERVALS,
  LONG_POLL_INTERVAL,
  MAX_STAKE_FILTER,
  MIN_STAKE_FILTER,
  SHORT_POLL_INTERVAL
} from '../../../constants';
import { useLocationParams } from '../../../hooks-and-global-states/hooks';
import { KEY_BETS, useCacheBets } from '../../../react-query/query';
import { BetsTableFiltersEnumNew, BetsTableFiltersStringArrayEnum, BetType } from '../../../types';
import { BetsColumnEnum, COLUMNS, INIT_COLUMNS } from '../model/constants';
import {
  IBetsContext,
  IBetSlice,
  IBetsReducerStore,
  ISorting,
  IStake,
  TAction,
  TFilters
} from '../model/types';

export const CHANGE_SELECTED_BETS = 'change-selected-bets';
export const TOGGLE_ACTIVE_COLUMN = 'change-active-column';
export const TOGGLE_FILTERS = 'toggle-filters';
export const TOGGLE_LIVE_MODE = 'toggle-live-mode';
export const CHANGE_SORT = 'set-sort';
export const CHANGE_FILTER = 'change-filter';
export const CHANGE_LIMIT = 'change-limit';
export const INITIAL_CHANGES = 'initial-changes';

const DEFAULT_LIMIT = 50;

const FILTERS_FOR_LONG_POLLING: string[] = [
  BetsTableFiltersEnumNew.originIds,
  BetsTableFiltersEnumNew.betRejectReasons,
  BetsTableFiltersEnumNew.eventId
];

export const BetsReducer = (
  state: IBetsReducerStore,
  action: TAction
): IBetsReducerStore => {
  const type = action.type;

  switch (type) {
    case TOGGLE_ACTIVE_COLUMN: {
      const payload = action?.payload;
      const { activeColumns } = state;

      const updatedColumns= {} as Record<BetsColumnEnum, boolean>;
      const updatedColumnsList = [];

      const columns = Object.entries(activeColumns);

      for (let i = 0; i < columns.length; i++) {
        const [ key, value ] = columns[i];
        const updatedItem = key === payload ? !value : value;
        updatedColumns[key] = updatedItem;
        if (updatedItem) {
          updatedColumnsList.push(key);
        }
      }

      return {
        ...state,
        activeColumns: updatedColumns,
        activeColumnsList: updatedColumnsList,
      };
    }

    case TOGGLE_FILTERS: {
      return {
        ...state,
        withFilters: !state.withFilters
      };
    }

    case CHANGE_SELECTED_BETS: {
      const payload = action?.payload;
      const oldBets = state.selectedBets;
      const currentBet = Array.isArray(payload) ? null : payload;
      let updatedSelectedBets: BetType[];

      if (Array.isArray(payload)) {
        updatedSelectedBets = payload;
      } else if (oldBets.some(b => b.id === currentBet.id)) {
        updatedSelectedBets = oldBets.filter(b => b.id !== currentBet.id);
      } else {
        updatedSelectedBets = [ ...oldBets, currentBet ];
      }

      return {
        ...state,
        selectedBets: updatedSelectedBets
      };
    }

    case CHANGE_SORT: {
      const payload = action?.payload;
      return {
        ...state,
        sorting: payload
      };
    }

    case CHANGE_FILTER: {
      const payload = action?.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload
        },
      };
    }

    case CHANGE_LIMIT: {
      const payload = action?.payload;
      return {
        ...state,
        limit: Number(payload)
      };
    }

    case TOGGLE_LIVE_MODE:
      return {
        ...state,
        liveMode: !state.liveMode
      };

    case INITIAL_CHANGES: {
      const payload = action?.payload;

      return {
        ...state,
        liveMode: payload.liveMode,
        filters: {
          ...state.filters,
          ...payload.filters
        },
      };
    }

    default:
      return state;
  }
};

const INITIAL_FILTERS_STATE: Partial<TFilters> = {
  [BetsTableFiltersEnumNew.originIds]: [],
  [BetsTableFiltersEnumNew.betId]: '',
  [BetsTableFiltersEnumNew.hideTestAccounts]: false,
  [BetsTableFiltersEnumNew.userId]: '',
  [BetsTableFiltersEnumNew.factorGroupIds]: [],
  [BetsTableFiltersEnumNew.eventId]: '',
  [BetsTableFiltersEnumNew.betType]: [],
  [BetsTableFiltersEnumNew.betAccepted]: '',
  [BetsTableFiltersEnumNew.bookType]: '',
  [BetsTableFiltersEnumNew.betStatuses]: [],
  [BetsTableFiltersEnumNew.betRejectReasons]: [] as string[],
  [BetsTableFiltersEnumNew.stake]: {
    [MIN_STAKE_FILTER]: '',
    [MAX_STAKE_FILTER]: '',
  },
  [BetsTableFiltersEnumNew.period]: '',
  [BetsTableFiltersEnumNew.startDate]: '',
  [BetsTableFiltersEnumNew.endDate]: '',
  [BetsTableFiltersEnumNew.sports]: [] as string[],
  [BetsTableFiltersEnumNew.marketType]: '',
  [BetsTableFiltersEnumNew.showOnlyOutrights]: false,
};

const INITIAL_SORT_STATE: ISorting = {
  sortBy: null,
  sortDir: DESCENDING,
};

const INITIAL_REDUCER_STORE: IBetsReducerStore = {
  selectedBets: [],
  limit: DEFAULT_LIMIT,
  activeColumns: INIT_COLUMNS,
  activeColumnsList: COLUMNS,
  withFilters: true,
  liveMode: true,
  sorting: INITIAL_SORT_STATE,
  filters: INITIAL_FILTERS_STATE,
};

const INITIAL_CONTEXT = {
  store: {
    ...INITIAL_REDUCER_STORE,
    bets: [],
    betsFetching: false,
    isFiltersEmpty: true,
    filtersToShow: [],
    betsParams: [],
    simpleFetch: true,
  },
  actions: {
    changeColumn: () => null,
    toggleFilters: () => null,
    changeSelectedBets: () => null,
    liveModeCheckboxOnChange: () => null,
    filterOnChange: () => null,
    changeSort: () => null,
    clearFilters: () => null,
    addLimit: () => null,
    refetch: () => null,
  }
};

const BetsContext = createContext<IBetsContext>(INITIAL_CONTEXT);

export const BetsSlice: FC<IBetSlice> = ({
  children,
  defaultPeriod= FILTER_INTERVALS.day.value
}) => {
  const { queryObj, isQueryClear, changeQuery, clearQuery } = useLocationParams();
  const [ store, dispatch ] = useReducer(BetsReducer, INITIAL_REDUCER_STORE);

  const {
    liveMode,
    sorting,
    limit,
    filters,
  } = store;

  const betParams = useMemo(() => {
    return getBetParams(filters, limit, liveMode, sorting.sortBy, sorting.sortDir);
  }, [ filters, limit, liveMode, sorting ]);

  const actualPollInterval = useMemo(() => {
    const excludeValues = [[], {}, null, undefined, '' ];
    const notEmptyFilters = filterObject(filters, excludeValues);

    const betAcceptedStop = Object.keys(notEmptyFilters).includes(BET_ACCEPTED_FILTER) &&
      filters[BET_ACCEPTED_FILTER] === 'Failure';

    const stakeStop = !!filters?.stake?.minStake || !!filters?.stake?.maxStake;

    if (
      Object.keys(notEmptyFilters).some(f => FILTERS_FOR_LONG_POLLING.includes(f)) ||
      stakeStop ||
      betAcceptedStop
    ) {
      return LONG_POLL_INTERVAL;
    } else {
      return SHORT_POLL_INTERVAL;
    }
  },[ filters ]);

  const queryKey = useMemo(() => {
    const {
      limit,
      // eslint-disable-next-line
      startDate, endDate,
      ...otherParams
    } = betParams;

    return [
      KEY_BETS,
      liveMode && actualPollInterval,
      {
        ...otherParams,
        period: filters.period,
      },
      limit
    ];
  },[ betParams, liveMode, actualPollInterval, filters.period ]);

  const {
    data: bets,
    isFetching: betsFetching,
    refetch,
    simpleFetch,
  } = useCacheBets({
    params: betParams,
    refetchInterval: liveMode && actualPollInterval,
    queryKey,
  });

  const filtersToShow = [
    BetsTableFiltersEnumNew.originIds,
    BetsTableFiltersEnumNew.hideTestAccounts,
    BetsTableFiltersEnumNew.betId,
    BetsTableFiltersEnumNew.userId,
    BetsTableFiltersEnumNew.factorGroupIds,
    BetsTableFiltersEnumNew.eventId,
    BetsTableFiltersEnumNew.betType,
    BetsTableFiltersEnumNew.betAccepted,
    BetsTableFiltersEnumNew.bookType,
    BetsTableFiltersEnumNew.betStatuses,
    filters.betStatuses?.includes(BetStatusesEnum.BET_STATUS_FAIL)
      ? BetsTableFiltersEnumNew.betRejectReasons
      : null,
    BetsTableFiltersEnumNew.stake,
    !liveMode ? BetsTableFiltersEnumNew.period : null,
    filters.period === 'custom' ? BetsTableFiltersEnumNew.startDate : null,
    filters.period === 'custom' ? BetsTableFiltersEnumNew.endDate : null,
    BetsTableFiltersEnumNew.sports,
    BetsTableFiltersEnumNew.marketType,
    BetsTableFiltersEnumNew.showOnlyOutrights
  ].filter(f => f);

  /** Initial state from query */
  useEffect(() => {
    const initialFiltersState = {} as Partial<TFilters>;
    // eslint-disable-next-line
    const { stake, ...otherFields } = INITIAL_FILTERS_STATE;

    const filtersList = [ ...Object.keys(otherFields), MIN_STAKE_FILTER, MAX_STAKE_FILTER ];

    for (let i = 0; i < filtersList.length; i++) {
      const currentField = filtersList[i];

      if (!queryObj[currentField]) continue;

      /** Create stake object */
      if (currentField === MIN_STAKE_FILTER || currentField === MAX_STAKE_FILTER) {
        const stakeObj = initialFiltersState.stake || {} as any;
        stakeObj[currentField] = queryObj[currentField] as string;
        initialFiltersState.stake = stakeObj;
        continue;
      }

      let currentValue: any = queryObj[currentField];

      /** Replace "true" to boolean */
      if (currentValue === 'true') {
        currentValue = true;
      } else if (currentValue === 'false') {
        currentValue = false;
      }

      if (
        !Array.isArray(currentValue) && (
          BetsTableFiltersStringArrayEnum[currentField] ||
          currentField === BetsTableFiltersEnumNew.betType ||
          currentField === BetsTableFiltersEnumNew.betStatuses
        )
      ) {
        currentValue = [ currentValue ];
      }

      initialFiltersState[currentField] = currentValue;
    }

    if (
      initialFiltersState.startDate ||
      initialFiltersState.endDate
    ) {
      initialFiltersState.period = 'custom';
    }

    const initialLiveMode = !(
      initialFiltersState.period ||
      initialFiltersState.startDate ||
      initialFiltersState.endDate
    );

    dispatch({
      type: INITIAL_CHANGES,
      payload: {
        liveMode: initialLiveMode,
        filters: initialFiltersState
      }
    });
  // eslint-disable-next-line
  },[]);

  function changeColumn (column: BetsColumnEnum) {
    dispatch({
      type: TOGGLE_ACTIVE_COLUMN,
      payload: column
    });
  }

  function toggleFilters () {
    dispatch({
      type: TOGGLE_FILTERS,
    });
  }

  function addLimit (newLimit: string | number) {
    dispatch({
      type: CHANGE_SELECTED_BETS,
      payload: []
    });
    dispatch({
      type: CHANGE_LIMIT,
      payload: newLimit
    });
  }

  function changeSelectedBets (bet: BetType | BetType[]) {
    dispatch({
      type: CHANGE_SELECTED_BETS,
      payload: bet
    });
  }

  function liveModeCheckboxOnChange () {
    if (!liveMode) {
      dispatch({
        type: CHANGE_SORT,
        payload: INITIAL_SORT_STATE
      });
      // eslint-disable-next-line
      const { stake, ...otherFilters } = store.filters;

      const oldFilterStateForStore = {
        ...otherFilters,
        stake: {
          [MIN_STAKE_FILTER]: store.filters.stake[MIN_STAKE_FILTER],
          [MAX_STAKE_FILTER]: store.filters.stake[MAX_STAKE_FILTER],
        },
      };

      const newFilterState = {
        [BetsTableFiltersEnumNew.startDate]: '',
        [BetsTableFiltersEnumNew.endDate]: '',
        [BetsTableFiltersEnumNew.period]: '',
      };

      changeQuery(newFilterState);

      dispatch({
        type: CHANGE_FILTER,
        payload: {
          ...oldFilterStateForStore,
          ...newFilterState
        },
      });
      dispatch({
        type: CHANGE_SELECTED_BETS,
        payload: []
      });

      dispatch({
        type: CHANGE_LIMIT,
        payload: DEFAULT_LIMIT
      });
    } else {
      filterOnChange(BetsTableFiltersEnumNew.period, defaultPeriod);
    }

    dispatch({
      type: TOGGLE_LIVE_MODE,
    });
  }

  function changeSort (sortBy: BetsSortByEnum) {
    const oldSortBy = sorting.sortBy;
    const oldSortDir = sorting.sortDir;

    const newSortState = {
      sortBy,
      sortDir: oldSortBy === sortBy && oldSortDir === DESCENDING
        ? ASCENDING as typeof ASCENDING
        : DESCENDING as typeof DESCENDING
    };

    changeQuery(newSortState);

    dispatch({
      type: CHANGE_SORT,
      payload: newSortState
    });
    dispatch({
      type: CHANGE_SELECTED_BETS,
      payload: []
    });
  }

  function clearFilters () {
    clearQuery();
    defaultLimitAction();
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        ...INITIAL_FILTERS_STATE,
        originIds: [],
      },
    });
    dispatch({
      type: CHANGE_SORT,
      payload: INITIAL_SORT_STATE
    });
    dispatch({
      type: TOGGLE_LIVE_MODE,
      payload: true
    });
    dispatch({
      type: CHANGE_SELECTED_BETS,
      payload: []
    });
    dispatch({
      type: CHANGE_LIMIT,
      payload: DEFAULT_LIMIT
    });
  }

  function defaultLimitAction () {
    dispatch({
      type: CHANGE_LIMIT,
      payload: DEFAULT_LIMIT
    });
  }

  function filterOnChange (
    name: BetsTableFiltersEnumNew,
    value: string | boolean | string[] | IStake
  ) {
    dispatch({
      type: CHANGE_SELECTED_BETS,
      payload: []
    });

    // eslint-disable-next-line
    const { stake, ...otherFilters } = store.filters;

    const oldFilterStateForStore = {
      ...otherFilters,
      stake: {
        [MIN_STAKE_FILTER]: store.filters.stake[MIN_STAKE_FILTER],
        [MAX_STAKE_FILTER]: store.filters.stake[MAX_STAKE_FILTER],
      }
    };

    // =============== STRING ===============
    if (
      name === BetsTableFiltersEnumNew.betId ||
      name === BetsTableFiltersEnumNew.userId ||
      name === BetsTableFiltersEnumNew.eventId ||
      name === BetsTableFiltersEnumNew.betAccepted ||
      name === BetsTableFiltersEnumNew.bookType ||
      name === BetsTableFiltersEnumNew.period ||
      name === BetsTableFiltersEnumNew.startDate ||
      name === BetsTableFiltersEnumNew.endDate ||
      name === BetsTableFiltersEnumNew.marketType
    ) {
      const newFilterState = { [name]: value as string };

      changeQuery(newFilterState);

      dispatch({
        type: CHANGE_FILTER,
        payload: {
          ...oldFilterStateForStore,
          ...newFilterState
        }
      });
    }


    // =============== BOOLEAN ===============
    if (
      name === BetsTableFiltersEnumNew.hideTestAccounts ||
      name === BetsTableFiltersEnumNew.showOnlyOutrights
    ) {
      const newFilterState = { [name]: value as boolean };

      if (!value) {
        changeQuery({ [name]: null });
      } else {
        changeQuery(newFilterState);
      }

      dispatch({
        type: CHANGE_FILTER,
        payload: {
          ...oldFilterStateForStore,
          ...newFilterState
        }
      });
    }

    // =============== ARRAY ===============
    if (
      name === BetsTableFiltersEnumNew.factorGroupIds ||
      name === BetsTableFiltersEnumNew.originIds ||
      name === BetsTableFiltersEnumNew.betRejectReasons ||
      name === BetsTableFiltersEnumNew.betType ||
      name === BetsTableFiltersEnumNew.sports
    ) {
      const newValue = [ ...value as string[] ];
      const newFilterState = { [name]: newValue };

      if (!newValue?.length) {
        changeQuery({ [name]: null });
      } else {
        changeQuery(newFilterState);
      }

      dispatch({
        type: CHANGE_FILTER,
        payload: {
          ...oldFilterStateForStore,
          ...newFilterState
        }
      });
    }

    if (name === BetsTableFiltersEnumNew.betStatuses) {
      const newValue = [ ...value as BetStatusesEnum[] ];
      const newFilterState = { [name]: newValue };
      const queryForUpdate = !newValue?.length
        ? { [name]: null }
        : newFilterState;

      if (!newValue.includes(BetStatusesEnum.BET_STATUS_FAIL)) {
        queryForUpdate[BetsTableFiltersEnumNew.betRejectReasons] = null;
        newFilterState[BetsTableFiltersEnumNew.betRejectReasons] = [];
      }

      changeQuery(queryForUpdate);

      dispatch({
        type: CHANGE_FILTER,
        payload: {
          ...oldFilterStateForStore,
          ...newFilterState
        }
      });
    }

    // =============== STAKE ===============
    if (name === BetsTableFiltersEnumNew.stake) {
      const { minStake, maxStake } = value as IStake;

      const newStake = {
        [name]: { minStake, maxStake }
      };

      changeQuery({
        minStake,
        maxStake,
      });

      dispatch({
        type: CHANGE_FILTER,
        payload: {
          ...oldFilterStateForStore,
          ...newStake
        }
      });
    }
  }


  const value = {
    store: {
      ...store,
      bets,
      betsFetching,
      isFiltersEmpty: isQueryClear && limit === DEFAULT_LIMIT,
      filtersToShow,
      betsParams: queryKey,
      simpleFetch,
    },
    actions: {
      changeColumn,
      toggleFilters,
      changeSelectedBets,
      liveModeCheckboxOnChange,
      filterOnChange,
      changeSort,
      clearFilters,
      addLimit,
      refetch,
    }
  };

  return (
    <BetsContext.Provider value={value}>
      {children}
    </BetsContext.Provider>
  );
};

const useBetsSlice = () => {
  return useContext(BetsContext);
};

export const useBetsStore = () => {
  const { store } = useBetsSlice();
  return store;
};

export const useBetsActions = () => {
  const { actions } = useBetsSlice();
  const {
    changeColumn,
    toggleFilters,
    changeSelectedBets,
    liveModeCheckboxOnChange,
    filterOnChange,
    changeSort,
    clearFilters,
    addLimit,
    refetch
  } = actions;

  return {
    changeColumn,
    toggleFilters,
    changeSelectedBets,
    liveModeCheckboxOnChange,
    filterOnChange,
    changeSort,
    clearFilters,
    addLimit,
    refetch
  };
};







