import { KeyboardEvent, useRef, useState } from 'react';

import cx from 'classnames';
import { Icon, Input, Table } from 'semantic-ui-react';

import { Money } from '../../components/table-components/Money';
import { KEY_ENTER } from '../../constants';
import { useClickOutside } from '../../hooks-and-global-states/hooks';
import { FieldTypesEnum } from '../../types';

export const LimitGroupRow = ({
  description,
  maxPayoutLiveMultiple,
  maxPayoutLiveSingle,
  maxPayoutMultiple,
  maxPayoutSingle,
  maxStakeLiveMultiple,
  maxStakeLiveSingle,
  maxStakeMultiple,
  maxStakeSingle,
  updateLimit,
  index,
  defaultOriginLimit,
}) => {
  const { current } = useRef({
    maxPayoutSingle,
    maxPayoutMultiple,
    maxPayoutLiveSingle,
    maxPayoutLiveMultiple,
    maxStakeSingle,
    maxStakeMultiple,
    maxStakeLiveSingle,
    maxStakeLiveMultiple,
  });

  const notDefaultRow =
    Number(defaultOriginLimit.maxPayoutSingle) !== Number(current.maxPayoutSingle) ||
    Number(defaultOriginLimit.maxPayoutMultiple) !== Number(current.maxPayoutMultiple) ||
    Number(defaultOriginLimit.maxPayoutLiveSingle) !== Number(current.maxPayoutLiveSingle) ||
    Number(defaultOriginLimit.maxPayoutLiveMultiple) !== Number(current.maxPayoutLiveMultiple) ||
    Number(defaultOriginLimit.maxStakeSingle) !== Number(current.maxStakeSingle) ||
    Number(defaultOriginLimit.maxStakeMultiple) !== Number(current.maxStakeMultiple) ||
    Number(defaultOriginLimit.maxStakeLiveSingle) !== Number(current.maxStakeLiveSingle) ||
    Number(defaultOriginLimit.maxStakeLiveMultiple) !== Number(current.maxStakeLiveMultiple);

  const updateMaxPayoutSingle = value => updateLimit(index, 'maxPayoutSingle', value);
  const updateMaxPayoutMultiple = value => updateLimit(index, 'maxPayoutMultiple', value);
  const updateMaxPayoutLiveSingle = value => updateLimit(index, 'maxPayoutLiveSingle', value);
  const updateMaxPayoutLiveMultiple = value => updateLimit(index, 'maxPayoutLiveMultiple', value);
  const updateMaxStakeSingle = value => updateLimit(index, 'maxStakeSingle', value);
  const updateMaxStakeMultiple = value => updateLimit(index, 'maxStakeMultiple', value);
  const updateMaxStakeLiveSingle = value => updateLimit(index, 'maxStakeLiveSingle', value);
  const updateMaxStakeLiveMultiple = value => updateLimit(index, 'maxStakeLiveMultiple', value);

  return (
    <Table.Row className={notDefaultRow ? 'limit-group__row_high' : ''}>
      <Table.Cell>{description}</Table.Cell>
      <TableCellComponent
        id={`limits-page--max-payout--prematch--single--${index + 1}-row--`}
        value={maxPayoutSingle}
        onChange={updateMaxPayoutSingle}
        defaultValue={defaultOriginLimit.maxPayoutSingle}
      />
      <TableCellComponent
        id={`limits-page--max-payout--prematch--multiply--${index + 1}-row--`}
        value={maxPayoutMultiple}
        onChange={updateMaxPayoutMultiple}
        defaultValue={defaultOriginLimit.maxPayoutMultiple}
      />
      <TableCellComponent
        id={`limits-page--max-payout--live--single--${index + 1}-row--`}
        value={maxPayoutLiveSingle}
        onChange={updateMaxPayoutLiveSingle}
        defaultValue={defaultOriginLimit.maxPayoutLiveSingle}
      />
      <TableCellComponent
        id={`limits-page--max-payout--live--multiply--${index + 1}-row--`}
        value={maxPayoutLiveMultiple}
        onChange={updateMaxPayoutLiveMultiple}
        defaultValue={defaultOriginLimit.maxPayoutLiveMultiple}
      />
      <TableCellComponent
        id={`limits-page--max-stake--prematch--single--${index + 1}-row--`}
        value={maxStakeSingle}
        onChange={updateMaxStakeSingle}
        defaultValue={defaultOriginLimit.maxStakeSingle}
      />
      <TableCellComponent
        id={`limits-page--max-stake--prematch--multiply--${index + 1}-row--`}
        value={maxStakeMultiple}
        onChange={updateMaxStakeMultiple}
        defaultValue={defaultOriginLimit.maxStakeMultiple}
      />
      <TableCellComponent
        id={`limits-page--max-stake--live--single--${index + 1}-row--`}
        value={maxStakeLiveSingle}
        onChange={updateMaxStakeLiveSingle}
        defaultValue={defaultOriginLimit.maxStakeLiveSingle}
      />
      <TableCellComponent
        id={`limits-page--max-stake--live--multiply--${index + 1}-row--`}
        value={maxStakeLiveMultiple}
        onChange={updateMaxStakeLiveMultiple}
        defaultValue={defaultOriginLimit.maxStakeLiveMultiple}
      />
    </Table.Row>
  );
};

const TableCellComponent = ({
  value,
  onChange,
  id,
  defaultValue,
}:{
  value: string,
  onChange: (v: string) => void,
  id: string,
  defaultValue: string,
}) => {
  const { current: initialValue } = useRef(value);
  const wrapperRef = useRef(null);

  const [ edit, setEdit ] = useState(false);

  const isDirty = Number(value) !== Number(initialValue);
  const notDefault = Number(defaultValue) !== Number(initialValue);

  useClickOutside(wrapperRef, saveChanges);

  function resetToDefault (e) {
    e.stopPropagation();
    onChange(initialValue);
  }

  function saveChanges () {
    setEdit(false);
  }

  function cancelChanges () {
    setEdit(false);
    onChange(initialValue);
  }


  function editCell () {
    setEdit(true);
  }

  return (
    <Table.Cell
      className={cx('limit-group__cell-wrap', notDefault && 'limit-group__cell-wrap_high')}
      selectable={!edit}
    >
      <>
        {edit ? (
          <div ref={wrapperRef} className="limit-group__cell">
            <Input
              id={id + 'price-input'}
              type={FieldTypesEnum.NUMBER}
              value={value}
              onChange={e => onChange(e.target.value)}
              transparent
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === KEY_ENTER) {
                  saveChanges();
                }
              }}
            />
            <Icon
              className="limit-group__icon limit-group__icon"
              id={id + 'cancel-btn'}
              name="close"
              link
              onClick={cancelChanges}
            />
          </div>
        ) : (
          <div
            className="limit-group__cell limit-group__cell_static"
            onClick={editCell}
          >
            <Money>{Number(value)}</Money>
            {isDirty && (
              <Icon
                className="limit-group__icon limit-group__icon"
                id={id + 'reset-to-default-btn'}
                name="redo"
                onClick={resetToDefault}
              />
            )}
          </div>
        )}
      </>
    </Table.Cell>
  );
};
