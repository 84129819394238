import { Header, List } from 'semantic-ui-react';

import { OriginsAccordion } from '../../../../components/form-components/OriginsAccordion';
import {
  QueryStateFilterByPeriod
} from '../../../../components/search-and-filters-components/QueryStateFilterByPeriod';
import {
  QueryStateCheckbox
} from '../../../../components/search-and-filters-components/QueryStateInputs';
import { FILTER_INTERVALS } from '../../../../constants';
import { useQueryState } from '../../../../hooks-and-global-states/history-hooks';
import { SportsDropdown } from '../../../../pages/EventPaths/SportsDropdown';

export const Filters = () => {
  const { queryState, setQueryState } = useQueryState();

  return (
    <>
      <Header>Filters</Header>
      <List>
        <List.Item>
          <OriginsAccordion parentDomId="settled-by-players--" />
        </List.Item>
        <List.Item>
          <QueryStateCheckbox
            id="settled-by-players--swoh-life-time-checkbox"
            name="showLifetime"
            label="Show Lifetime Stats"
          />
        </List.Item>
        <List.Item>
          <SportsDropdown
            id="settled-by-players--sport-id-dropdown"
            fluid
            value={queryState.sportIds}
            onChange={value => setQueryState('sportIds', value)}
          />
        </List.Item>
        <List.Item>
          <QueryStateFilterByPeriod
            id="settled-by-players--period-dropdown"
            defaultValue={FILTER_INTERVALS.day.value}
          />
        </List.Item>
      </List>
    </>
  );
};
