import React from 'react';

import { Button, Card, Grid } from 'semantic-ui-react';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider';

import { ScoreForPeriodRow } from './ScoreForPeriodRaw';
import { TREE_LEVELS } from '../../../constants';
import { PeriodsScoresTreeNodeType, ScoreOpponentForPeriod } from '../../../types';


import './IrmScoreboard.css';

export const IrmScoreboard = ({
  eventId,
  opponentsInfo,
  treeObjState,
  nodeStatusesState,
  setNodeStatusesState,
  setApproveEndMatchModal,
  setRevertPeriodModal,
  setTreeObjState,
  revertPeriodIsFetching,
  endEventIsFetching,
}: {
  opponentsInfo: {
    homeOpponent: ScoreOpponentForPeriod;
    awayOpponent: ScoreOpponentForPeriod;
  };
  treeObjState: PeriodsScoresTreeNodeType;
  setTreeObjState: (treeObj: PeriodsScoresTreeNodeType) => void;
  nodeStatusesState: object;
  setNodeStatusesState: (nodeStatusesState: object) => void;
  setApproveEndMatchModal: (approveEndMatchModal: boolean) => void;
  setRevertPeriodModal: (revertPeriodModal: boolean) => void;
  eventId: string;
  revertPeriodIsFetching: boolean;
  endEventIsFetching: boolean;
}) => {
  const homeOpponentDescription = opponentsInfo.homeOpponent.description;
  const awayOpponentDescription = opponentsInfo.awayOpponent.description;

  function getScoreForPeriodRaws (nodeObj, treeLevel): any {
    const tags = [
      <ScoreForPeriodRow
        nodeObj={nodeObj}
        key={nodeObj.node.periodId}
        nodeStatusesState={nodeStatusesState}
        setNodeStatusesState={setNodeStatusesState}
        eventId={eventId}
        treeObjState={treeObjState}
        setTreeObjState={setTreeObjState}
        opponentsInfo={opponentsInfo}
        treeLevel={treeLevel}
      />
    ];

    if (nodeObj.children && treeLevel === TREE_LEVELS.HEADER_LEVEL) {
      tags.push(<Divider className={'divider'} key={'divider_' + nodeObj.node.periodId} />);
      tags.push(nodeObj.children.map(child => getScoreForPeriodRaws(child, treeLevel + 1)));
    } else if (nodeObj.children && nodeStatusesState[nodeObj.node.periodId]) {
      tags.unshift(<Divider className={'divider'} key={'divider_' + nodeObj.node.periodId} />);
      tags.push(nodeObj.children.map(child => getScoreForPeriodRaws(child, treeLevel + 1)));
      tags.push(<Divider className={'divider'} key={'divider_' + nodeObj.node.periodId} />);
    } else if (nodeObj.children && nodeObj.node.isActive) {
      const activePeriod = nodeObj.children.find(period => period.node.isActive);
      if (activePeriod) {
        tags.push(getScoreForPeriodRaws(activePeriod, treeLevel + 1));
      }
    }

    return tags;
  }

  return (
    <Grid.Row>
      <Card style={{ width: '100%' }}>
        <Card.Content>
          <Grid>
            <Grid.Row className="scoreboard-header-row">
              <Grid.Column
                width={6}
                textAlign="center"
                className="opponent_description"
              >
                {homeOpponentDescription}
              </Grid.Column>
              <Grid.Column
                width={4}
                textAlign="center"
                className="scoreboard_buttons_panel"
              >
                {treeObjState && (
                  <>
                    <Button
                      id="irm--score-button-9"
                      onClick={() => setApproveEndMatchModal(true)}
                      icon="flag checkered"
                      loading={endEventIsFetching}
                    />
                    <Button
                      id="irm--score-button-10"
                      onClick={() => setRevertPeriodModal(true)}
                      icon="undo"
                      loading={revertPeriodIsFetching}
                    />
                  </>
                )}
              </Grid.Column>
              <Grid.Column
                width={6}
                textAlign="center"
                className="opponent_description"
              >
                {awayOpponentDescription}
              </Grid.Column>
            </Grid.Row>
            {treeObjState && getScoreForPeriodRaws(treeObjState, 1)}
          </Grid>
        </Card.Content>
      </Card>
    </Grid.Row>
  );
};
