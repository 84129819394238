import React from 'react';

import { toast } from 'react-toastify';
import { Button, Checkbox, Header, Icon, Label, Modal, Popup, Table } from 'semantic-ui-react';
import { $Keys } from 'utility-types';

import { useMarketRowContext } from './MarketRowContext';
import classes from './MarketRows.module.scss';
import { MarketStatusLabelUpdate } from './MarketStatusLabelUpdate';
import { OutcomeResultSelector } from './OutcomeResultSelector';
import { OutcomeRow } from './OutcomeRow';
import { OutcomeBets } from '../../../../components/sidebar-components/OutcomeBets';
import { Money } from '../../../../components/table-components/Money';
import {
  ADD_PRICES_WITH_INDEX_ACTION,
  BetStatusesEnum,
  BOOK_TYPES,
  CHANGE_CURRENT_AND_LOCAL_PRICES_INDEX_ACTION,
  CHANGE_CURRENT_PRICE_INDEX_ACTION,
  CHANGE_LOCAL_PRICE_INDEX_ACTION,
  RESET_DELTA_INDEX_ACTION,
  REWRITE_PRICES,
  SAVE_PRICES_ACTION,
  TRADER_PERM_ALL,
  TRADER_PERM_EVENT_EDITOR
} from '../../../../constants';
import { useGlobalStateContext } from '../../../../hooks-and-global-states/global-context';
import { useBoolean } from '../../../../hooks-and-global-states/hooks';
import { useMarketUpdate, usePricesCreate } from '../../../../react-query/mutations';
import { EventType, MarketStatusType, PriceLocalMarketsType } from '../../../../types';
import { calculateMargin } from '../../../../utils';
import { isNumberHelp } from '../../helpers';
import {
  checkNumberCorrect,
  formatMargin,
  handleAllOutcomesResultChangeFunc,
  handleOutcomeResultChangeFunc,
  localOutcomesGetter,
  toggleEditFunc,
  useHighlightedRowRef
} from '../helpers';
import { MarketEdit } from '../MarketEdit';
import './MarketRows.scss';

const pricesReducer = (state: PriceLocalMarketsType[], action: { type: string, payload?: any }) => {
  switch (action.type) {
    case CHANGE_LOCAL_PRICE_INDEX_ACTION: {
      const index = action.payload.index;
      const result = [ ...state ];
      result[index].localPrice = action.payload.localPrice;
      result[index].correct = checkNumberCorrect(action.payload.localPrice);
      return result;
    }
    case CHANGE_CURRENT_PRICE_INDEX_ACTION: {
      const index = action.payload.index;
      const result = [ ...state ];
      result[index].currentPrice = action.payload.currentPrice;
      return result;
    }
    case CHANGE_CURRENT_AND_LOCAL_PRICES_INDEX_ACTION: {
      const index = action.payload.index;
      const result = [ ...state ];
      const delta = action.payload.currentPrice - state[index].currentPrice;
      result[index].currentPrice = action.payload.currentPrice;
      result[index].localPrice = action.payload.localPrice;
      result[index].correct = checkNumberCorrect(action.payload.localPrice);
      result[index].delta = delta;
      return result;
    }
    case ADD_PRICES_WITH_INDEX_ACTION: {
      return [ ...state, ...action.payload.prices ];
    }
    case REWRITE_PRICES: {
      return [ ...action.payload.prices ];
    }
    case RESET_DELTA_INDEX_ACTION: {
      const index = action.payload.index;
      const result = [ ...state ];
      result[index].delta = 0;
      return result;
    }
    case SAVE_PRICES_ACTION: {
      return [ ...state ].map(el => ({
        ...el,
        delta: Number(el.localPrice) - Number(el.currentPrice),
        currentPrice: el.localPrice,
      }));
    }
    default:
      return state;
  }
};

export const MarketRows = ({
  event,
  openSidebar,
  selectMarket,
  marketSelected,
  queryOutcomeId,
}: {
  event: EventType;
  openSidebar: (component: React.ReactNode) => void;
  selectMarket: (market: {
    description: string;
    id: string;
    status: MarketStatusType;
    isOutright: boolean;
    periodDescription: string;
    periodId: string;
    typeId: string;
    bookType: $Keys<typeof BOOK_TYPES>;
  }) => void;
  marketSelected?: boolean;
  queryOutcomeId?: string;
}) => {
  const { originId, permissions } = useGlobalStateContext();
  const hideStatusState = event?.hideMode;
  const highlightedRowRef = useHighlightedRowRef();

  const {
    market,
    outcomes,
    canEditMarket,
    marketBetsCount,
    marketBetsCountSingles,
    marketBetsTotal,
    isDynamicMarket,
    outcomesToShow,
    showTwoOutcomes,
    toggleShowTwoOutcomes,
    marginFirstPrice,
    marketManualMode,
    marketManualCreated,
    showInIRM,
    isBetBuilderMarket,
    isOriginMarginOverride,
  } = useMarketRowContext();

  const {
    value: editingResults,
    setValue: setEditingResults,
    setFalse: setEditingResultsFalse,
  } = useBoolean(false);

  const {
    value: isEditModalOpen,
    setTrue: openEditModal,
    setFalse: closeEditModal
  } = useBoolean(false);

  const [ localOutcomes, setLocalOutcomes ] = React.useState(localOutcomesGetter(outcomes));

  const [ pricesForRequest, setPricesForRequest ] = React.useReducer(pricesReducer,[
    ...outcomes.map(outcome => ({
      outcomeId: outcome.outcome.id,
      currentPrice: outcome.currentPrice,
      localPrice: outcome.currentPrice,
      feedPrice: outcome.feedPrice,
      delta: 0,
      correct: true,
    }))
  ]);

  /** Update price array from outcomes */
  React.useEffect(() => {
    const isNewOutcome = pricesForRequest.length !== outcomes.length;
    if (isNewOutcome) {
      const newPrices = outcomes.map(o => {
        return({
          outcomeId: o.outcome.id,
          currentPrice: o.currentPrice,
          localPrice: o.currentPrice,
          feedPrice: o.feedPrice,
          delta: 0,
          correct: true,
        })
      });
      setPricesForRequest({
        type: REWRITE_PRICES,
        payload: {
          prices: [ ...newPrices ],
        }
      });
    }else if(outcomes.every((outcome, index) => outcome.outcome.id !== pricesForRequest[index])) {
      const newPrices = outcomes.map(o => {
        const oldPriceEl = pricesForRequest.find(price => price.outcomeId === o.outcome.id);
        return({
          outcomeId: o.outcome.id,
          currentPrice: o.currentPrice,
          localPrice: o.currentPrice,
          feedPrice: o.feedPrice,
          delta: oldPriceEl.delta,
          correct: oldPriceEl.correct,
        })
      });
      setPricesForRequest({
        type: REWRITE_PRICES,
        payload: {
          prices: [ ...newPrices ],
        }
      });
    }
    // eslint-disable-next-line
  },[ outcomes ]);

  const mutMarketUpdate = useMarketUpdate({
    onSuccess: () => setEditingResultsFalse()
  });
  const mutPricesCreate = usePricesCreate({
    onSuccess: () => {
      toast.success('Prices were saved');
      setPricesForRequest({
        type: SAVE_PRICES_ACTION,
      });
    }
  });

  const enableEditingEvent =
    !permissions?.denyPermissions?.includes(TRADER_PERM_EVENT_EDITOR) &&
    [ TRADER_PERM_EVENT_EDITOR, TRADER_PERM_ALL ].some(permission =>
      permissions?.allowPermissions?.includes(permission)
    );

  const isAllPricesEqual = pricesForRequest.every(el => +el.currentPrice === +el.localPrice);
  const isAllPricesCorrect = pricesForRequest.every(el => el.correct === true);
  const pricesForMargin = isDynamicMarket ? pricesForRequest.slice(0, 2) : pricesForRequest;
  const showEditButton = canEditMarket && enableEditingEvent && !isDynamicMarket && !isBetBuilderMarket;
  const marginCurrentPrice = formatMargin(calculateMargin({
    prices: pricesForMargin.map(el => el.localPrice),
  }));

  function submitSetResults() {
    const outcomesForRequest = Object.keys(localOutcomes).reduce((arr, key) => {
      const { id, result, voidReason } = localOutcomes[key];
      arr.push({
        id,
        result,
        voidReason: voidReason === null ? undefined : voidReason,
      });
      return arr;
    }, []);
    mutMarketUpdate.mutate({
      id: market.id,
      outcomes: outcomesForRequest,
      originId,
    });
  }

  function setResultFunction() {
    toggleEditFunc(editingResults, setLocalOutcomes, localOutcomesGetter, outcomes, setEditingResults);
  }

  function saveAllPrices() {
    mutPricesCreate.mutate({ pricesForRequest });
  }

  return (
    <>
      <Table.Row active>
        <Table.Cell>
          {enableEditingEvent && (
            <Checkbox
              id={`event-edit--markets-tab--${market.id}-markets-row--market-checkbox`}
              checked={marketSelected}
              onChange={() => {
                selectMarket(market);
              }}
              className={classes.checkbox}
            />
          )}
        </Table.Cell>
        <Table.Cell colSpan="3" width="10">
          <div className={classes.label}>
            {(marketManualMode || marketManualCreated) && (
              <Label ribbon color={marketManualCreated ? 'orange' : 'violet'}>
                {marketManualCreated ? 'Manual Created' : 'Manual Trading'}
              </Label>
            )}
            <Header size="small" className="flex-header">
              {market.description} - {market.periodDescription}
              <Label color="grey" size="small">
                {BOOK_TYPES[market.bookType]}
              </Label>
              {showInIRM && (
                <Label color="grey" size="small">
                  IRM
                </Label>
              )}
            </Header>
            {isOriginMarginOverride && (
              <Popup
                content="This market has priceline overrides for at least one origin"
                trigger={
                  <Icon
                    name="percent"
                    size="small"
                    className={classes.percent_btn}
                  />
                }
              />
            )}
          </div>
        </Table.Cell>
        <Table.Cell width="2">
          {editingResults && enableEditingEvent && (
            <OutcomeResultSelector
              marketId={market.id}
              handleClick={handleAllOutcomesResultChangeFunc}
              value={null}
              voidReason={null}
              disabled={!editingResults}
              setLocalOutcomes={setLocalOutcomes}
              localOutcomes={localOutcomes}
              outcomesQuantity={0}
              sportId={event.sportId}
              parentDomId="event-edit--markets-tab--markets-row--market-results-selector--"
            />
          )}
        </Table.Cell>
        <Table.Cell colSpan="3" width="4">
          <div style={{ float: 'right' }}>
            <MarketStatusLabelUpdate
              canChange={canEditMarket && enableEditingEvent}
              status={market.status}
              id={market.id}
              manualMode={marketManualMode}
              market={market}
              outcomes={outcomes}
              parentDomId="event-edit--markets-tab--markets-row--status-update--"
            />
            {showEditButton && (
              <>
                <Button
                  id="event-edit--markets-tab--markets-row--edit-market-btn"
                  className="btn-edit-market"
                  icon="edit"
                  basic
                  size="mini"
                  floated="right"
                  onClick={openEditModal}
                />
                <Modal
                  closeOnDimmerClick={false}
                  open={isEditModalOpen}
                  closeIcon
                  onClose={closeEditModal}
                >
                  {isEditModalOpen &&
                    <MarketEdit
                      marketId={market.id}
                      marketBookType={market.bookType}
                      marketManualCreated={marketManualCreated}
                      marketManualMode={marketManualMode}
                      closeModal={closeEditModal}
                      parentDomId="event-edit--markets-tab--markets-row--edit-market--"
                    />
                  }
                </Modal>
              </>
            )}
          </div>
        </Table.Cell>
      </Table.Row>
      {outcomesToShow.map(({ outcome, liabilityRisk, liabilityRiskSingle }, index) => {
        const localOutcome = localOutcomes[outcome.id];

        return (
          <OutcomeRow
            key={outcome.id + market.id + index}
            liability={liabilityRisk}
            liabilityRiskSingle={liabilityRiskSingle}
            highlightedRowRef={outcome.id === queryOutcomeId ? highlightedRowRef : null}
            handleBetsClick={() => {
              openSidebar(
                <OutcomeBets
                  outcomeId={outcome.id}
                  marketDescription={market.description}
                  outcomeDescription={outcome.description}
                  marketStatus={market.status}
                  liabilityRisk={liabilityRisk}
                  betStatuses={[
                    BetStatusesEnum.BET_STATUS_PENDING,
                    BetStatusesEnum.BET_STATUS_WIN,
                    BetStatusesEnum.BET_STATUS_LOSE
                  ]}
                  parentDomId="event-edit--markets-tab--markets-row--sidebar--"
                />
              );
            }}
            result={editingResults ? localOutcome.result : outcome.result}
            voidReason={editingResults ? localOutcome.voidReason : outcome.voidReason}
            editingResults={editingResults}
            handleOutcomeResultChange={handleOutcomeResultChangeFunc}
            setLocalOutcomes={setLocalOutcomes}
            localOutcomes={localOutcomes}
            eventDescription={event.description}
            eventHideMode={hideStatusState}
            sportId={event.sportId}
            index={index}
            pricesForRequest={pricesForRequest}
            setPricesForRequest={setPricesForRequest}
            parentDomId={`event-edit--markets-tab--${outcome.id}-outcome-row--`}
          />
        );
      })}

      {isDynamicMarket && (
        <Table.Row>
          <Table.Cell
            colSpan="6"
            onClick={toggleShowTwoOutcomes}
            className="dynamic-market-button-show-all"
            textAlign="center"
          >
            <Icon size="small" name={showTwoOutcomes ? 'angle down' : 'angle up'} />
          </Table.Cell>
        </Table.Row>
      )}

      <Table.Row>
        <Table.Cell />
        <Table.Cell />
        <Table.Cell>{!isBetBuilderMarket && <strong>{marginFirstPrice}%</strong>}</Table.Cell>
        <Table.Cell verticalAlign="middle">
          <div className="current-price-margin-wrapper">
            {!isBetBuilderMarket && <span>{marginCurrentPrice}%</span>}
            {!isAllPricesEqual && isAllPricesCorrect && (
              <Button
                id="event-edit--markets-tab--save-all-prices-btn"
                className="btn-save-all-prices"
                color="blue"
                size="mini"
                content="Save"
                onClick={saveAllPrices}
              />
            )}
          </div>
        </Table.Cell>
        <Table.Cell>
          {editingResults && enableEditingEvent ? (
            <>
              <Button
                id="event-edit--markets-tab--set-all-reults--cancel-btn"
                size="mini"
                color="red"
                onClick={setResultFunction}
                content="Cancel"
              />
              <Button
                id="event-edit--markets-tab--set-all-reults--submit-btn"
                disabled={mutMarketUpdate.isPending}
                size="mini"
                color="blue"
                onClick={submitSetResults}
                content="Submit"
              />
            </>
          ) : (
            <Button
              id="event-edit--markets-tab--set-all-reults--main-btn"
              size="mini"
              color="blue"
              onClick={setResultFunction}
              disabled={!enableEditingEvent}
              content="Set Results"
            />
          )}
        </Table.Cell>
        <Table.Cell />
        <Table.Cell />
        <Table.Cell>
          {marketBetsCount === 0 ? (
            'No bets'
          ) : (
            <strong>
              <Money>{marketBetsTotal}</Money>/ {marketBetsCount} /{' '}
              <Money>{isNumberHelp(marketBetsTotal / marketBetsCountSingles, 0)}</Money>
            </strong>
          )}
        </Table.Cell>
      </Table.Row>
    </>
  );
};
