import { ReactElement, useState } from 'react';

export const ToggleProvider = ({
  defaultValue,
  children
}:{
  defaultValue: boolean;
  children: (arg: { value: boolean; toggle: () => void }) => ReactElement
}) => {
  const [ value, setValue ] = useState(defaultValue);

  function toggle() {
    setValue(p => !p);
  }

  return children({ value, toggle });
};
