import React from 'react';

import cx from 'classnames';
import { Label } from 'semantic-ui-react';

import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import './OriginLabel.scss';

const origins = {
  '10000': {
    name: 'Reloadbet',
  },
  '10100': {
    name: 'Tornadobet',
  },
};

type Props = {
  originId: string;
  simple?: boolean;
};

export const OriginLabel = React.memo<Props>(({ originId, simple }: Props) => {
  const origin = origins[originId];
  const { originsList } = useGlobalStateContext();

  const originName = React.useMemo(() => {
    if (origin) return origin.name;
    if (originsList) {
      for (const originComponent of originsList) {
        if (originComponent.id === originId) {
          return originComponent.description;
        }
      }
    }
  }, [ originsList, origin, originId ]);

  const cls = cx(
    `origin-label_${origin?.name.toLowerCase()}` && origin,
    'origin-label_default' && !origin
  );

  if (simple) {
    return (
      <div>
        {originName}
      </div>
    );
  }

  return (
    <Label
      horizontal
      size="mini"
      className={cls}
    >
      {originName}
    </Label>
  );
});
