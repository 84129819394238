import React from 'react';

import { toast } from 'react-toastify';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { IrmEditableDescriptionComponent } from './IrmEditableDescriptionComponent';
import { FlexDiv } from '../../../components/FlexDiv';
import {
  API_MARKET,
  MARKET_STATUS_ENUM,
  MARKET_STATUSES_UPDATE_DATA
} from '../../../constants';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';
import { useBoolean } from '../../../hooks-and-global-states/hooks';
import { Market, OutcomeSinglesByEventType } from '../../../types';
import { isMarketOpeningEnable, MarketWithPricesObjectType } from '../../../utils';
import { isDynamicMarketCheck } from '../Markets/helpers';
import { MarketEdit } from '../Markets/MarketEdit';
import './MarketCardHeaderRow.css';

export const MarketCardHeaderRow = ({
  market,
  activeStatusState,
  outcomes,
  reload,
  canEditMarket,
  marketManualCreated,
  setActiveStatusState,
  isSpecialMarket,
  editedPricesState,
  setEditedPricesState,
}: {
  market: Market;
  activeStatusState: string;
  outcomes: Array<OutcomeSinglesByEventType>;
  reload: () => void;
  canEditMarket: boolean;
  marketManualCreated: boolean;
  setActiveStatusState: (boolean) => void;
  isSpecialMarket: boolean;
  editedPricesState?: Array<MarketWithPricesObjectType>;
  setEditedPricesState?: (editedPricesState: Array<MarketWithPricesObjectType>) => void;
}) => {
  const isDynamicMarket = isDynamicMarketCheck(outcomes);

  const {
    value: isModalVisible,
    setTrue: openModal,
    setFalse: closeModal,
  } = useBoolean(false);

  const [ marketDescription, setMarketDescription ] = React.useState(market.description);

  function descriptionOnChange (value) {
    const editingMarket =  editedPricesState?.find(el => el.id === marketId) || { id: marketId };
    const updatedList = editedPricesState.filter(marketObj => marketObj.id !== marketId);
    updatedList.push({ ...editingMarket, description: value });
    setEditedPricesState(updatedList);
  }

  const marketId = market.id;

  const { originId } = useGlobalStateContext();

  const { update, isFetching } = useAxiosRequest(null);

  const updateMarketStatus = data => {
    if (data.status === MARKET_STATUS_ENUM.OPEN && !isMarketOpeningEnable(outcomes)) {
      toast.error('Cannot open some markets. Please change null outcome prices');
    } else {
      setActiveStatusState(data.status);
      update({
        method: 'PUT',
        url: `${API_MARKET}/${marketId}/update`,
        data: {
          originId,
          marketId,
          ...data,
        },
      });
    }
  };

  const buttonOnClose = React.useCallback( () => {
    closeModal();
    reload();
  },[ closeModal, reload ]);

  return (
    <Grid.Row className="market-header-row">
      <Grid.Column width={8} className="description-column">
        <FlexDiv className="description-flex-div">
          <FlexDiv.Flex>
            <Header size="small" className="description-header">
              {isSpecialMarket ? (
                <IrmEditableDescriptionComponent
                  className="market-name-input"
                  setDescription={setMarketDescription}
                  periodDescription={`- ${market.periodDescription}`}
                  description={marketDescription}
                  onBlur={reload}
                  descriptionOnChange={descriptionOnChange}
                  marketId={market.id}
                  parentDomId="irm--markets--header-row--"
                />
              ) : (
                <>
                  {market.description} - {market.periodDescription}
                </>
              )}
            </Header>
          </FlexDiv.Flex>
          <FlexDiv.Div />
        </FlexDiv>
      </Grid.Column>
      <Grid.Column width={8} textAlign="right">
        <Button.Group size="mini">
          {activeStatusState !== MARKET_STATUS_ENUM.SETTLED ? (
            MARKET_STATUSES_UPDATE_DATA.map(([ enumMarketStatus, { label, color }]: Array<any>) => {
              return (
                <Button
                  key={String(enumMarketStatus)}
                  id={`irm--markets--market-${market.id}--button-${enumMarketStatus}`}
                  loading={isFetching}
                  className="market-action-button"
                  onClick={() => {
                    updateMarketStatus({
                      status: enumMarketStatus,
                    });
                  }}
                  color={enumMarketStatus === activeStatusState ? color : null}
                >
                  {label}
                </Button>
              );
            })
          ) : (
            <Header size="small" className="description-header" color="blue">
              Settled
            </Header>
          )}
        </Button.Group>
        {activeStatusState !== MARKET_STATUS_ENUM.SETTLED && (
          <Button
            id={`irm--markets--market-${market.id}--edit-market-button`}
            style={{ marginLeft: 5 }}
            icon="cog"
            size="mini"
            onClick={openModal}
            disabled={!canEditMarket || isDynamicMarket}
          />
        )}
        <Modal
          closeOnDimmerClick={false}
          open={isModalVisible}
          closeIcon
          onClose={buttonOnClose}
        >
          {isModalVisible &&
            <MarketEdit
              marketId={market.id}
              marketBookType={market.bookType}
              marketManualCreated={marketManualCreated}
              closeModal={buttonOnClose}
              parentDomId={`irm--markets--market-${market.id}--`}
            />
          }
        </Modal>
      </Grid.Column>
    </Grid.Row>
  );
};
