import React from 'react';

import { Input } from 'semantic-ui-react';

import { COLOR_LIGHT_GREY } from '../../constants';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';

const checkIsAmountValid = v => v === '' || !isNaN(v);

export const QueryStateFilterByAmount = ({
  label,
  minName,
  maxName,
  id,
}: {
  label: string;
  minName: string;
  maxName: string;
  id: string;
}) => {
  const { pendingQueryState, setQueryState } = useQueryState();

  return (
    <>
      <div
        style={{
          fontSize: 14,
          paddingTop: 7,
          paddingBottom: 5,
          color: COLOR_LIGHT_GREY,
        }}
      >
        {label}
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Input
            id={id + 'min-stake-input'}
            fluid
            placeholder="min"
            value={pendingQueryState[minName] || ''}
            onChange={(e, { value }) => {
              if (checkIsAmountValid(value)) {
                setQueryState(minName, value, true);
              }
            }}
          />
        </div>
        <span style={{ margin: 10 }}>-</span>
        <div style={{ flex: 1 }}>
          <Input
            id={id + 'max-stake-input'}
            fluid
            placeholder="max"
            value={pendingQueryState[maxName] || ''}
            onChange={(e, { value }) => {
              if (checkIsAmountValid(value)) {
                setQueryState(maxName, value, true);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};
