import React from 'react';

import cx from 'classnames';
import { Segment } from 'semantic-ui-react';

export const Sidebar = ({ sidebarContent, sidebarVisible, closeSidebar }) => {
  const [ currentChildren, setCurrentChildren ] = React.useState(sidebarContent);

  if ((sidebarContent && !currentChildren) || sidebarContent !== currentChildren) {
    setCurrentChildren(sidebarContent);
  }

  return (
    <>
      <div className={cx('phoenix-sidebar', sidebarVisible && 'visible')}>
        <Segment basic>{currentChildren}</Segment>
      </div>
      {sidebarVisible && <div className="phoenix-sidebar-dimmer" onClick={closeSidebar} />}
    </>
  );
};
