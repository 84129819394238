import React from 'react';

import qs from 'qs';
import { Button, Dropdown, Grid, Icon, Input, Modal } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import {
  getMarketsForPresetRequest,
  getTreeWithPresetValues
} from './helpers';
import {
  API_MARKET_PRESET_EDIT,
  API_MARKET_TYPE_TEMPLATE_FIND
} from '../../../constants';
import {
  MarketsForCreationType,
  ModalPeriodsTreeNodeType,
  PresetStateType,
  PresetsType,
  SelectedMarketsStateType,
  SelectedPeriodsStateType
} from '../../../types';

export const PresetsListDropdown = ({
  presetsState,
  selectedPreset,
  setSelectedPreset,
  periodsTreeState,
  setPeriodsTreeState,
  setSelectedPeriodsState,
  eventId,
  onSuccessForGettingPresetMarkets,
  setSelectedMarketsState,
  setModalMarketsState,
  selectedMarketsState,
  onSuccessForPresetsUpdating,
}: {
  presetsState: PresetStateType;
  selectedPreset: { preset: PresetsType };
  setSelectedPreset: (selectedPreset: { preset: PresetsType }) => void;
  periodsTreeState: ModalPeriodsTreeNodeType;
  setPeriodsTreeState: (periodsTreeState: ModalPeriodsTreeNodeType) => void;
  setSelectedPeriodsState: (selectedPeriodsState: SelectedPeriodsStateType) => void;
  eventId: string;
  onSuccessForGettingPresetMarkets: (data) => void;
  setSelectedMarketsState: (selectedMarketsState: SelectedMarketsStateType) => void;
  setModalMarketsState: (marketsState: { objList: Array<MarketsForCreationType> }) => void;
  selectedMarketsState: SelectedMarketsStateType;
  onSuccessForPresetsUpdating: () => void;
}) => {
  const getPresetsOptions = () => {
    const presetsOptions = [];

    presetsState.presetsList.forEach(preset => {
      presetsOptions.push({
        key: preset.id,
        text: preset.description,
        value: preset.id,
      });
    });
    return presetsOptions;
  };

  const { update: updateForGettingMarkets } = useAxiosRequest(null, {
    onSuccess: onSuccessForGettingPresetMarkets,
  });

  const [ editPresetNameModalState, setEditPresetNameModalState ] = React.useState({ show: false });
  const [ inputPresetNameState, setInputPresetNameState ] = React.useState({
    name: selectedPreset.preset ? selectedPreset.preset.description : '',
  });

  const marketsConfig = React.useCallback(periodsList => {
    return {
      url: API_MARKET_TYPE_TEMPLATE_FIND,
      params: {
        eventId: eventId,
        onlyNew: true,
        periodIds: periodsList
      },
      paramsSerializer: v => qs.stringify(v, { arrayFormat: 'repeat' }),
    };
  }, [ eventId ]);

  const setPresetsValues = React.useCallback(value => {
    if (!value) {
      setSelectedPreset({
        preset: undefined,
      });
      setSelectedMarketsState({ markets: [] });
      setSelectedPeriodsState({
        periods: [],
      });
      setPeriodsTreeState(getTreeWithPresetValues([], periodsTreeState));
      setModalMarketsState({ objList: [] });
    } else {
      const selectedPreset = presetsState.presetsList.find(preset => {
        return preset.id === value;
      });
      setSelectedPreset({
        preset: selectedPreset,
      });
      const periodsList = getPresetPeriods(selectedPreset);
      setSelectedPeriodsState({
        periods: periodsList,
      });
      setPeriodsTreeState(getTreeWithPresetValues(periodsList, periodsTreeState));

      updateForGettingMarkets(marketsConfig(periodsList));
      setModalMarketsState({ objList: [] });
    }
  },[
    periodsTreeState,
    marketsConfig,
    presetsState.presetsList,
    setModalMarketsState,
    setPeriodsTreeState,
    setSelectedMarketsState,
    setSelectedPeriodsState,
    setSelectedPreset,
    updateForGettingMarkets
  ]);

  const getPresetPeriods = (preset: PresetsType) => {
    const periodsList = [];
    if (preset) {
      preset.items.forEach(item => {
        if (!periodsList?.includes(item.periodId)) {
          periodsList.push(item.periodId);
        }
      });
    }
    return periodsList;
  };

  const { update: updateForEditingPreset } = useAxiosRequest(null, {
    onSuccess: () => {
      setInputPresetNameState({ name: '' });
      setEditPresetNameModalState({ show: false });
      onSuccessForPresetsUpdating();
    },
  });

  const editPresetsName = () => {
    updateForEditingPreset({
      method: 'PUT',
      url: API_MARKET_PRESET_EDIT,
      data: {
        id: selectedPreset.preset.id,
        description: inputPresetNameState.name,
        items: getMarketsForPresetRequest(selectedMarketsState),
      },
    });
  };

  return (
    <Grid.Column width={4}>
      <Grid.Row>
        <Dropdown
          pointing
          clearable
          options={getPresetsOptions()}
          selection
          onChange={(e, { value }) => {
            setPresetsValues(value);
          }}
        />
        <Button icon onClick={() => setEditPresetNameModalState({ show: true })} floated={'right'}>
          <Icon fitted link name="edit" />
        </Button>
      </Grid.Row>
      <Modal
        size={'mini'}
        open={editPresetNameModalState.show}
        onClose={() => setEditPresetNameModalState({ show: false })}
      >
        <Modal.Header>Edit current preset name</Modal.Header>
        <Modal.Content>
          <Input
            onChange={input => setInputPresetNameState({ name: input.target.value })}
            defaultValue={selectedPreset.preset ? selectedPreset.preset.description : ''}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setEditPresetNameModalState({ show: false })}>
            Close window
          </Button>
          <Button positive onClick={() => editPresetsName()}>
            Edit preset&apos;s name
          </Button>
        </Modal.Actions>
      </Modal>
    </Grid.Column>
  );
};
