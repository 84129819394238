import React from 'react';

import { Header } from 'semantic-ui-react';

import { BetsTablePageType } from '../../constants';
import { BetsTableSimple } from '../../pages/Bets/BetsTableSimple';
import { MarketStatusLabel } from '../../pages/EventEdit/Markets/MarketsTable/MarketStatusLabel';
import { BetStatusType, BetsColumnLabelEnum, BetTypeTypeEnum } from '../../types';
import { Money } from '../table-components/Money';
import '../../pages/Risk/RiskManager.css';

const OUTCOME_BETS_COLUMNS = [
  BetsColumnLabelEnum.Date,
  BetsColumnLabelEnum.Player,
  BetsColumnLabelEnum.Stake,
  BetsColumnLabelEnum.Price,
  BetsColumnLabelEnum.Type,
  BetsColumnLabelEnum.Winning,
  BetsColumnLabelEnum.Status
];

export const OutcomeBets = ({
  outcomeId,
  eventDescription,
  leagueDescription,
  sportDescription,
  liabilityRisk,
  marketDescription,
  marketStatus,
  outcomeDescription,
  betStatuses,
  feedCode,
  parentDomId,
}: {
  outcomeId: string;
  eventDescription?: string;
  leagueDescription?: string;
  sportDescription?: string;
  liabilityRisk: number;
  marketDescription: string;
  marketStatus: string;
  outcomeDescription: string;
  betStatuses?: Array<BetStatusType>;
  feedCode?: string;
  parentDomId: string;
}) => {
  const params = React.useMemo(() => {
    return {
      outcomeIds: [ outcomeId ],
      betTypes: [ BetTypeTypeEnum.Bet_Any ],
      betStatuses,
      limit: 10,
    };
  }, [ outcomeId, betStatuses ]);

  return (
    <>
      <Header>Bets on Outcome</Header>
      <div>
        Risk:{' '}
        <strong>
          <Money>{liabilityRisk * -1}</Money>
        </strong>
      </div>
      {sportDescription && leagueDescription && (
        <div>
          {sportDescription} / {leagueDescription}
        </div>
      )}
      {eventDescription && (
        <div>
          {eventDescription} {feedCode && <>({feedCode})</>}
        </div>
      )}
      <div>
        {marketDescription}: <strong>{outcomeDescription}</strong>
        <MarketStatusLabel
          status={marketStatus}
          size="tiny"
          style={{ float: 'right' }}
        />
      </div>

      <BetsTableSimple
        params={params}
        columns={OUTCOME_BETS_COLUMNS}
        pageType={BetsTablePageType.OUTCOME_BETS}
        parentDomId={parentDomId}
      />
    </>
  );
};
