import React from 'react';

import { Button, Loader } from 'semantic-ui-react';

export const TablePagination = ({
  limit,
  totalCount,
  setPage,
  page,
  currentCount,
  isFetching,
  parentDomId,
}: {
  totalCount?: number;
  limit: number;
  page: number;
  setPage: (func: (page: number) => number) => void;
  currentCount: number;
  isFetching: boolean;
  parentDomId: string;
}) => {

  if (isFetching) return <Loader active={isFetching} inline />;

  return (
    <>
      {totalCount && `Total entries: ${totalCount}. `}
      Showing {currentCount} entries.
      {(totalCount
        ? totalCount > limit
        : currentCount === page * limit) && (
        // TODO: remove "currentCount === page * limit" since we have totalCount
        <Button
          id={parentDomId + 'pagination-btn'}
          style={{ marginLeft: 10 }}
          size="mini"
          onClick={() => {
            setPage(page => page + 1);
          }}
          disabled={totalCount && totalCount <= currentCount}
        >
          Load more
        </Button>
      )}
    </>
  );
};

export const BetsTablePagination = ({
  limit,
  currentCount,
  isFetching,
  setLimitState,
  showButton = true,
  parentDomId,
  withLoader = true,
  loaderInside = false,
}: {
  limit: number;
  currentCount: number;
  isFetching: boolean;
  setLimitState?: (limit: number) => void;
  showButton?: boolean;
  parentDomId: string;
  withLoader?: boolean;
  loaderInside?: boolean;
}) => {
  const isShow =
    ((currentCount % 10 === 0) && showButton) ||
    limit <= currentCount;
  return isFetching && withLoader ? (
    <Loader active={isFetching} inline />
  ) : (
    <>
      Showing {currentCount} entries.
      {isShow && (
        <Button
          id={parentDomId + 'pagination-show-more-btn'}
          style={{ marginLeft: 10 }}
          size="mini"
          onClick={() => {
            setLimitState(limit + 10);
          }}
          disabled={!withLoader && isFetching}
          content={loaderInside && isFetching ? '...' : 'Load more'}
        />
      )}
    </>
  );
};
