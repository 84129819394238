import React from 'react';

import { Checkbox, Input, List } from 'semantic-ui-react';

import {
  MarketsForCreationType,
  SelectedMarketsStateType,
  SingleByEventMarketType
} from '../../../types';
import './MarketCheckboxRow.css';

const MAX_NUMBER_OF_SPECIALS = 10;

export const MarketCheckboxRow = ({
  markets,
  market,
  selectedMarketsState,
  setSelectedMarketsState,
  updateNumberOfSpecials,
  setNumberOfMarkets,
}: {
  markets: Array<SingleByEventMarketType>;
  market: MarketsForCreationType;
  selectedMarketsState: SelectedMarketsStateType;
  setSelectedMarketsState: (selectedMarketsState: SelectedMarketsStateType) => void;
  updateNumberOfSpecials: (counter: number, marketId: string, periodId: string) => void;
  setNumberOfMarkets: (marketId: string, periodId: string, newNumberOfMarkets) => void;
}) => {
  const selectMarketsCheckbox = React.useCallback(
    (marketId, periodId) => {
      const selectedItems = selectedMarketsState.markets;

      const indexOfItem = selectedItems.findIndex(
        item => item.marketId === marketId && item.periodId === periodId
      );

      if (indexOfItem === -1) {
        selectedItems.push({
          marketId: marketId,
          periodId: periodId,
          ...(market.allowMultiMarkets && {
            numberOfMarkets: market.numberOfMarkets,
          }),
        });
      } else {
        selectedItems.splice(indexOfItem, 1);
      }

      setSelectedMarketsState({
        ...selectedMarketsState,
      });
    },
    [ market, selectedMarketsState, setSelectedMarketsState ]
  );

  const isMarketChecked = (marketId, periodId) => {
    let isChecked = false;

    selectedMarketsState.markets.forEach(market => {
      if (market.periodId === periodId && market.marketId === marketId) {
        isChecked = true;
      }
    });
    return isChecked;
  };

  const getNumberOfCreatedSpecials = (periodId, typeId) => {
    const createdMarkets = markets.filter(
      market => market.market.typeId === typeId && market.market.periodId === periodId
    );
    return createdMarkets.length;
  };

  const onChangeInput = (e, data) => {
    const inputMarketsNumber = Number(data.value);
    const possibleNumber =
      MAX_NUMBER_OF_SPECIALS - getNumberOfCreatedSpecials(market.periodId, market.id);
    if (inputMarketsNumber) {
      if (inputMarketsNumber < possibleNumber) {
        setNumberOfMarkets(market.id, market.periodId, inputMarketsNumber);
        updateNumberOfSpecials(inputMarketsNumber, market.id, market.periodId);
      } else {
        setNumberOfMarkets(market.id, market.periodId, possibleNumber);
        updateNumberOfSpecials(MAX_NUMBER_OF_SPECIALS, market.id, market.periodId);
      }
    } else if (inputMarketsNumber === 0) {
      setNumberOfMarkets(market.id, market.periodId, inputMarketsNumber);
      updateNumberOfSpecials(inputMarketsNumber, market.id, market.periodId);
    }
  };

  return (
    <List.Item>
      <Checkbox
        key={market.id}
        label={market.description + ' (' + market.periodDescription + ')'}
        onChange={() => {
          selectMarketsCheckbox(market.id, market.periodId);
        }}
        checked={isMarketChecked(market.id, market.periodId)}
      />
      {market.allowMultiMarkets && (
        <Input
          className={'counter-for-specials-input'}
          size={'mini'}
          value={market.numberOfMarkets}
          onChange={onChangeInput}
        />
      )}
    </List.Item>
  );
};
