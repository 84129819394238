import React from 'react';

import Axios from 'axios';
import { FieldArray, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Form, Header, Icon, Modal } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';
import { array, number, object, string } from 'yup';

import { DefaultSubmitModal } from './DefaultSubmitModal';
import {
  LimitGroupsDropdown,
  PhoenixField,
  PhoenixFieldDropdown
} from '../../components/form-components/FormComponents';
import {
  API_CURRENCY_LIST,
  API_ORIGIN_RESET,
  API_ORIGIN_UPDATE,
  ORIGIN_ID_DEFAULT
} from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { FieldTypesEnum, OriginType } from '../../types';
import { getOptionsWithIds, removeEmptyFields, replaceId } from '../../utils';

const MAIN_FIELDS = [
  {
    name: 'webSite',
    label: 'Website',
    className: 'width-xxl',
    type: FieldTypesEnum.TEXT,
  },
  {
    name: 'description',
    label: 'Description',
    className: 'width-xxl',
    type: FieldTypesEnum.TEXT,
  },
  {
    name: 'limitGroup',
    className: 'width-s',
    limitType: true,
  },
  {
    name: 'maxOutcomeCount',
    label: 'Max possible number of legs in a betslip',
    className: 'width-l',
    type: FieldTypesEnum.NUMBER,
  },
  {
    name: 'inrunningSettings.inRunningDelay',
    label: 'InRunning Delay',
    className: 'width-s',
    fieldClassName: 'inline-element',
    type: FieldTypesEnum.NUMBER,
  },
  {
    name: 'inrunningSettings.toleranceRate',
    label: 'Tolerance Rate',
    className: 'width-s',
    fieldClassName: 'inline-element',
    type: FieldTypesEnum.NUMBER,
  },
  {
    name: 'inrunningSettings.acceptPriceMoveUp',
    label: 'Accept Price Move Up',
    type: FieldTypesEnum.CHECKBOX,
    toggle: true,
  },
  {
    name: 'inrunningSettings.acceptPriceMoveDown',
    label: 'Accept Price Move Down',
    type: FieldTypesEnum.CHECKBOX,
    toggle: true,
  }
];

const CASHOUT_FIELDS = [
  {
    name: 'cashOutSettings.prematchSingleMargin',
    label: 'Prematch Single Margin',
    className: 'width-m',
    fieldClassName: 'inline-element',
  },
  {
    name: 'cashOutSettings.prematchOutrightSingleMargin',
    label: 'Prematch Outright Single Margin',
    className: 'width-m',
    fieldClassName: 'inline-element',
  },
  {
    name: 'cashOutSettings.liveSingleMargin',
    label: 'Live Single Margin',
    className: 'width-m',
    fieldClassName: 'inline-element',
  },
  {
    name: 'cashOutSettings.prematchMultiMargin',
    label: 'Prematch Multi Margin',
    className: 'width-m',
    fieldClassName: 'inline-element',
  },
  {
    name: 'cashOutSettings.prematchOutrightMultiMargin',
    label: 'Prematch Outright Multi Margin',
    className: 'width-m',
    fieldClassName: 'inline-element',
  },
  {
    name: 'cashOutSettings.liveMultiMargin',
    label: 'Live Multi Margin',
    className: 'width-m',
    fieldClassName: 'inline-element',
  },
  {
    name: 'cashOutSettings.multiBetFactor',
    label: 'Multi-bet factor - additional juice weighting for multi-bets',
    className: 'width-l',
  }
];

const OriginSchema = object().shape({
  description: string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
  maxOutcomeCount: number().min(0, ' ').required(' '),
  inrunningSettings: object().shape({
    inRunningDelay: number().min(0, ' ').required(' '),
    toleranceRate: number().min(0, ' ').required(' '),
  }),
  cashOutSettings: object().shape({
    prematchSingleMargin: number().min(0, ' ').required(' '),
    prematchOutrightSingleMargin: number().min(0, ' ').required(' '),
    liveSingleMargin: number().min(0, ' ').required(' '),
    prematchMultiMargin: number().min(0, ' ').required(' '),
    prematchOutrightMultiMargin: number().min(0, ' ').required(' '),
    liveMultiMargin: number().min(0, ' ').required(' '),
    multiBetFactor: number().min(0, ' ').required(' '),
  }),
  limits: array().of(object().shape({
    values: array().of(object().shape({
      currencyId: string().required(' '),
      value: number().min(0, ' ').required(' '),
    }))
  }))
});

export const OriginSettingsForm = ({
  origin,
}: {
  origin: OriginType;
}) => {
  const { originId, updateOriginsList } = useGlobalStateContext();

  function onSubmit (values: any, { setSubmitting, resetForm }) {
    if (originId !== ORIGIN_ID_DEFAULT) {
      const dataForRequest = removeEmptyFields(values);

      delete dataForRequest.defaultOrigin;
      Axios.put(API_ORIGIN_UPDATE, dataForRequest)
        .then(response => {
          toast.success('Origin settings have been updated');
          /** Reset form state with new version from success request */
          const nextFormState = {
            values: {
              ...values,
              ...response.data.result,
            }
          };
          resetForm(nextFormState);
          updateOriginsList();
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
          setSubmitting(false);
        });
    }
  }

  return (
    <Formik
      validationSchema={OriginSchema}
      initialValues={origin}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        values,
        setSubmitting,
        resetForm,
        isSubmitting,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <Form>
          <div className="origin-settings__block-wrapper">
            <Header
              as='h2'
              className="origin-settings__block-header"
              content="Main info"
            />

            <div className="origin-settings__block-content">
              {MAIN_FIELDS.map(field => {
                if (field.limitType) {
                  return (
                    <LimitGroupsDropdown
                      key={field.name}
                      className={field.className}
                    />
                  );
                } else {
                  return (
                    <PhoenixField
                      key={field.name}
                      id={'origin-page--' + replaceId(field.name)}
                      name={field.name}
                      label={field.label}
                      className={field.className}
                      fieldClassName={field.fieldClassName || ''}
                      toggle={field.toggle}
                      type={field.type}
                    />
                  );
                }
              })}
            </div>
          </div>

          <div className="origin-settings__block-wrapper">
            <Header
              as='h2'
              className="origin-settings__block-header"
              content="Minimal stake amount"
            />

            <div className="origin-settings__block-content">
              <MinimalStakeAmountSection
                formValues={values}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>

          <div className="origin-settings__block-wrapper">
            <Header
              as='h2'
              className="origin-settings__block-header"
              content="Cashout Settings"
            />

            <div className="origin-settings__block-content">
              {CASHOUT_FIELDS.map(field => (
                <PhoenixField
                  key={field.name}
                  id={'origin-page--' + replaceId(field.name)}
                  name={field.name}
                  label={field.label}
                  className={field.className}
                  fieldClassName={field.fieldClassName}
                  type={FieldTypesEnum.NUMBER}
                />
              ))}
            </div>
          </div>

          <div className="origin-settings__button-group">
            {originId === ORIGIN_ID_DEFAULT ? (
              <DefaultSubmitModal
                values={values}
                resetForm={resetForm}
                setSubmitting={setSubmitting}
                origin={origin}
                dirty={dirty}
              />
            ) : (
              <Button
                id="origin-page--submit-btn"
                positive
                size="big"
                type="submit"
                loading={isSubmitting}
                disabled={!isValid || !dirty}
                onClick={() => handleSubmit()}
                content="Submit"
              />
            )}
            <ResetToDefaultButton
              originId={originId}
            />
          </div>

        </Form>
      )}
    </Formik>
  );
};

const ResetToDefaultButton = ({
  originId,
}: {
  originId: string;
}) => {
  const [ isOpen, setIsOpen ] = React.useState(false);
  const { updateOriginsList } = useGlobalStateContext();

  const { update } = useAxiosRequest(null, {
    onSuccess: () => {
      toast.success('Origin settings have been reset to default settings ');
      updateOriginsList();
      setIsOpen(false);
    },
  });

  function onClick () {
    update({
      method: 'PUT',
      url: API_ORIGIN_RESET,
      data: {
        originId: originId,
      },
    });
  }

  return (
    <>
      <Button
        id="origin-page--reset-btn"
        disabled={originId === ORIGIN_ID_DEFAULT}
        onClick={() => setIsOpen(true)}
        size="big"
      >
        Reset
      </Button>
      <Modal closeIcon open={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Content>
          <p>Do you really want to reset to default?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="origin-page--reset-cancel-btn"
            color="red"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Icon name="remove" /> No
          </Button>
          <Button
            id="origin-page--reset-accept-btn"
            color="green"
            onClick={onClick}
          >
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const MinimalStakeAmountSection = ({
  formValues,
  setFieldValue,
}:{
  formValues: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) => {
  const { data: currencies, isFetching: currenciesFetch } = useAxiosRequest<any>(API_CURRENCY_LIST);
  const { limits } = formValues;

  const [ currencyListOptions, firstCurrency ] = React.useMemo(() => {
    if (!currencies?.result || currenciesFetch) return [
      [{ text: '', value: '', rate: '' }],
      { text: '', value: '', rate: '' }
    ];
    const [ first, ...all ] = currencies.result
      .map((c: any) => ({ value: c.id, text: c.description, rate: c.rate }));

    /** Sorting for remove selected currency from list *
     * not ready!!!
     * const selectedCurrencies = limits[0].values.map(cur => Number(cur.currencyId)); *
     * const accessibleCurrencies = all *
     *  .filter(currency => !selectedCurrencies.includes(Number(currency.value))); */

    return [ all, first ];
  },[ currencies, currenciesFetch ]);

  return (
    <>
      <FieldArray
        name="limits.0.values"
        render={arrayHelpers => {
          function addCurrency () {
            arrayHelpers.push({
              currencyId: '',
              value: '',
            });
          }
          function deleteCurrency (index: number) {
            arrayHelpers.remove(index);
          }
          function changeLimit (index: number, value: string) {
            setFieldValue(`limits.0.values.${index}.value`, value);
          }


          return (
            <div>
              {limits[0]?.values.map((_limit: any, index: number) => {
                return (
                  <React.Fragment key={`limit values ${index}`}>
                    {index === 0 ? (
                      <PhoenixField
                        id={'origin-page--limits--row-' + (index + 1) + '-currency-input'}
                        name={`limits.0.values.${index}.currencyId`}
                        label="Currency"
                        fieldClassName="inline-element-s"
                        className="width-xl"
                        value={firstCurrency?.text || ''}
                        loading={currenciesFetch}
                      />
                    ) : (
                      <PhoenixFieldDropdown
                        id={'origin-page--limits--row-' + (index + 1) + '-dropdown'}
                        name={`limits.0.values.${index}.currencyId`}
                        options={getOptionsWithIds(
                          currencyListOptions,
                          'origin-page--limits--row-' + (index + 1) + '-dropdown')
                        }
                        className="width-xl"
                        fieldClassName="inline-element-s"
                        changeLimitForOrigin={(value: any) => changeLimit(index, value)}
                        loading={currenciesFetch}
                      />
                    )}

                    <PhoenixField
                      id={'origin-page--limits--row-' + (index + 1) + '-input'}
                      name={`limits.0.values.${index}.value`}
                      label={index === 0 ? 'Value' : null}
                      className="width-xs"
                      fieldClassName="inline-element-s"
                      disabled={currenciesFetch}
                      type={FieldTypesEnum.NUMBER}
                    />
                    {index !== 0 && (
                      <Icon
                        id={'origin-page--limits--row-' + (index + 1) + '-delete-btn'}
                        name="delete"
                        size="large"
                        className="origin-settings__btn-delete"
                        onClick={() => deleteCurrency(index)}
                      />
                    )}
                  </React.Fragment>
                );
              })}
              <Button
                id="origin-page--limits-add-currency-btn"
                content="Add currency"
                icon="plus"
                onClick={addCurrency}
              />
            </div>
          );
        }}
      />
    </>
  );
};
