import { useMemo, useState } from 'react';

import Axios from 'axios';
import cx from 'classnames';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Segment, Header, List, Dropdown, Form, Input } from 'semantic-ui-react';

import { OpponentEdit } from './OpponentEdit';
import { ReloadTickerProvider } from './ReloadTickerProvider';
import { AxiosRequest } from '../../components/fetch-components/AxiosRequest';
import { API_OPPONENT_CREATE, API_OPPONENT_FIND, DESCRIPTION } from '../../constants';
import { OpponentType } from '../../types';
import { getIconNameFromOpponentType, getOptionsWithIds, removeEmptyFields } from '../../utils';

import './styles/OpponentsList.scss';

const opponentTypeOptions = [
  {
    key: 1,
    text: 'Team',
    value: 'TEAM',
    icon: getIconNameFromOpponentType('TEAM'),
  },
  {
    key: 2,
    text: 'Player',
    value: 'PLAYER',
    icon: getIconNameFromOpponentType('PLAYER'),
  },
  {
    key: 3,
    text: 'Horse',
    value: 'HORSE',
    icon: getIconNameFromOpponentType('HORSE'),
  },
  {
    key: 4,
    text: 'Dog',
    value: 'DOG',
    icon: getIconNameFromOpponentType('DOG'),
  }
];

const OppForm = ({
  opponents,
  leagueId,
  reload,
}:{
  opponents: Array<OpponentType>;
  leagueId: string;
  reload: () => void;
}) => {
  const initialValues = {
    description: '',
    type: opponents.length > 0 ? opponents[0].type : opponentTypeOptions[0].value,
    eventPathId: leagueId,
  };

  function onSubmit (values, { setSubmitting, setValues }) {
    const dataForRequest = removeEmptyFields(values);
    Axios.post(API_OPPONENT_CREATE, dataForRequest)
      .then(() => {
        toast.success('Opponent has been created');
        reload();
        setValues(initialValues);
        setSubmitting(false);
      })
      .catch(error => {
        // eslint-disable-next-line
        console.error(error);
        setSubmitting(false);
      });
  }

  function validate (values) {
    const errors = {};

    const description = opponents?.some(({ description }) =>
      description === values.description) && 'Opponent with such name exists';

    if (description) {
      errors[DESCRIPTION] = description;
    }

    return errors;
  }

  return (
    <>
      <Segment vertical>
        <Header>Add new opponent</Header>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            errors,
            isValid,
            isSubmitting
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group inline>
                <Form.Field>
                  <Dropdown
                    id="opponent-form--type-dropdown"
                    options={getOptionsWithIds(opponentTypeOptions, 'opponent-form--type-dropdown')}
                    value={values.type}
                    scrolling={false}
                    onChange={(_e, { value }) => {
                      setFieldValue('type', value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    id="opponent-form--new-opp-dropdown"
                    className={cx(!!errors.description && 'opponent-list__opp-input_error')}
                    placeholder="New Opponent: "
                    value={values.description}
                    onChange={(_e, { value }) => {
                      setFieldValue(DESCRIPTION, value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Button
                    id="opponent-form--submit-btn"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isValid || !values.description}
                  >
                    Create
                  </Form.Button>
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
};

const ListOfOpps = ({
  opponents,
  reload,
}:{
  opponents: Array<any>;
  reload: () => void;
}) => {
  const [ search, setSearch ] = useState('');

  const opponentsToShow = useMemo(() => {
    if (!search) return opponents;

    return opponents.filter(item => {
      return (
        item?.description?.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [ opponents, search ]);

  return (
    <div className="opponent-list__opp-list-component">
      <div className="opponent-list__header">
        <Header>List of opponents</Header>
        <Input
          icon='search'
          placeholder='Search...'
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </div>

      <div className="opponent-list__list-warp">
        {opponentsToShow?.length === 0 ? (
          'No opponents'
        ) : (
          <List>
            {opponentsToShow.map((opponent, index) => (
              <List.Item key={opponent.id}>
                <OpponentEdit
                  opponent={opponent}
                  reload={reload}
                  parentId={`opp-list--item-${index + 1}--`}
                />
              </List.Item>
            ))}
          </List>
        )}
      </div>
    </div>
  );
};

export const OpponentsList = ({ leagueId }: { leagueId: string }) => {
  return (
    <>
      <Header>Opponents</Header>
      <Segment>
        <ReloadTickerProvider>
          {({ reload, reloadTicker }) => (
            <AxiosRequest
              reloadTicker={reloadTicker}
              cache="reload"
              config={{
                url: API_OPPONENT_FIND,
                params: {
                  eventPathId: leagueId,
                  limit: 99999,
                },
              }}
            >
              {({ data: { result }}: { data?: { result: Array<OpponentType> } }) => {
                return (
                  <>
                    <OppForm
                      reload={reload}
                      leagueId={leagueId}
                      opponents={result}
                    />
                    <ListOfOpps
                      reload={reload}
                      opponents={result}
                    />
                  </>
                );
              }}
            </AxiosRequest>
          )}
        </ReloadTickerProvider>
      </Segment>
    </>
  );
};
