import React from 'react';

import { useLocation } from 'react-router-dom';

import { DOCUMENT_TITLES_BY_PATH, HOME_PATH } from '../constants';

export const TitleContext = React.createContext<{
  title: string;
  setTitle: (arg: (prev) => string) => void;
}>(null);

export const TitleProvider = ({
  children
}:{
  children: React.ReactNode
}) => {
  const { pathname } = useLocation();

  const [ title, setTitle ] = React.useState('Phoenix');

  React.useEffect(() => {
    const titleValue = getValueForTitle(pathname);
    setTitle(titleValue);
    document.title = title;
  }, [ pathname, title ]);

  const value = React.useMemo(() => ({
    title,
    setTitle
  }),[ title ]);
  return (
    <TitleContext.Provider value={value}>
      {children}
    </TitleContext.Provider>
  );
};

function getValueForTitle (path: string) {
  const [ , firstPath, secondPath ] = path.split('/');
  const parentPath = '/' + firstPath;

  if (!DOCUMENT_TITLES_BY_PATH[parentPath]) return DOCUMENT_TITLES_BY_PATH[HOME_PATH];

  const pathVariable = !isNaN(+secondPath) && ` #${secondPath}`;

  return pathVariable
    ? DOCUMENT_TITLES_BY_PATH[parentPath] + pathVariable
    : DOCUMENT_TITLES_BY_PATH[parentPath];

}
