import React from 'react';

import cx from 'classnames';
import { Icon } from 'semantic-ui-react';

import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import { BetsTableFiltersEnum } from '../../types';

import './TextWithCopyButton.scss';

const FILTER_TYPES = {
  id: BetsTableFiltersEnum.userId,
  userName: null,
  betId: BetsTableFiltersEnum.betId,
};

export const TextWithCopyButton = ({
  prevText,
  value,
  type,
}:{
  prevText?: string;
  value: string;
  type: keyof typeof FILTER_TYPES
}) => {
  const { setQueryState } = useQueryState();
  const [ showText, setShowText ] = React.useState({ s: false, v: false });

  function onClick (e) {
    e.preventDefault();
    navigator.clipboard.writeText(value);

    if ((e.ctrlKey || e.metaKey) && FILTER_TYPES[type]) {
      setQueryState(FILTER_TYPES[type], value);
    } else {
      setShowText({ s: true, v:false });

      setTimeout(() => {
        setShowText({ s: true, v: true });
      }, 10);

      setTimeout(() => {
        setShowText({ s: true, v: false });
      }, 5000);

      setTimeout(() => {
        setShowText({ s: false, v: false });
      }, 5400);
    }
  }

  return (
    <>
      <div
        className="copy-btn-string__wrapper"
        onClick={onClick}
      >
        <div className="copy-btn-string__no-space-wrapper">
          {prevText} {value}
          <Icon
            className={cx(
              'copy-btn-string__button',
              showText.v && 'copy-btn-string__button_visible'
            )}
            name="copy outline"
          />
        </div>
        {showText.s && (
          <p className={cx(
            'copy-btn-string__text',
            showText.v && 'copy-btn-string__text_visible')}
          >
            Copied
          </p>
        )}
      </div>
    </>
  );
};
