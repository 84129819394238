import { useState } from 'react';

import { Header, Grid, Segment } from 'semantic-ui-react';

import { Filters } from './components/Filters';
import { PlayersTable } from './components/PlayersTable';
import { useQueryState } from '../../../hooks-and-global-states/history-hooks';
import { useSettleByPlayers } from '../../../react-query/query';
import { PAGE_LIMIT } from '../model/constants';
import './styles.scss';

export const SettledByPlayersComponent = () => {
  const { queryState } = useQueryState();
  const [ page, setPage ] = useState<number>(1);

  const disableRequest = queryState.period === 'custom' &&
    (!queryState?.startDate ||
    !queryState?.endDate);

  const {
    data,
    isFetching,
    error
  } = useSettleByPlayers({
    params: { queryState, pageLimit: PAGE_LIMIT, page },
    enabled: !disableRequest
  });

  return (
    <Grid
      columns="equal"
      className="settled-by-players-container"
    >
      <Grid.Column width="3">
        <Filters />
      </Grid.Column>
      <Grid.Column columns={13}>
        <Header>Settled By Players</Header>
        {error ? (
          <Segment inverted color="red">
            {String(error)}
          </Segment>
        ) : (
          <PlayersTable
            bets={data?.bets || []}
            performance={data?.performance}
            totalCount={data?.totalCount || 1}
            page={page}
            setPage={setPage}
            isFetching={isFetching}
            limit={PAGE_LIMIT}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};
