import { useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Segment, Button, Modal } from 'semantic-ui-react';

import { EventCard } from './EventCard';
import { MainMenuTabular, MenuCopyTabular } from './EventEditMenuComponents';
import { PhoenixLoader } from '../../components/UI/PhoenixLoader';
import { QUERY_STATUS } from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import { useEventCopy, useStatusHide } from '../../react-query/mutations';
import { KEY_EVENT, useEvent } from '../../react-query/query';
import { EventType } from '../../types';

const EventEditComponent = ({
  event,
}: {
  event: EventType;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { originId } = useGlobalStateContext();

  const isMainEvent = event.mainEventId === event.id;

  const mutStatusHide = useStatusHide({
    onSuccess: async () => {
      toast.success('Event was successfully changed');
      await queryClient.invalidateQueries({ queryKey: [ KEY_EVENT ] });
      closeModal();
    }
  });

  const mutCopyEvent = useEventCopy({
    onSuccess: ({ data }) => {
      toast.success('Event was successfully created');
      navigate(`/event-edit/${data['id']}`);
    }
  });

  const { data: secondEvent } = useEvent({
    params: {
      eventIds: isMainEvent ? event.othersCopyEvents[0] : event.mainEventId,
      originId
    },
    refetchInterval: 3000,
    enabled: (!!isMainEvent && !!event?.mainEventId) ||
      (!!event?.othersCopyEvents?.length && !!event.othersCopyEvents[0]),
  });

  const [ modalDescription, setModalDescription ] = useState('');
  const [ modalStatus, setModalStatus ] = useState(
    { isOpen: false, copying: false }
  );

  const outletContext = useMemo(() => {
    return {
      event,
      hideStatusState: event.hideMode,
      eventId: event.id,
    };
  }, [ event ]);

  function applyModalClick () {
    if (modalStatus.copying) {
      mutCopyEvent.mutate({
        eventId: event.id,
      });
    } else {
      mutStatusHide.mutate({
        event: {
          id: event.id,
          version: event.version,
          hideMode: !event.hideMode,
        },
        originId: originId,
      });
    }
  }

  function closeModal() {
    setModalStatus({
      isOpen: false,
      copying: false,
    });
  }

  function openModal() {
    setModalStatus({
      isOpen: true,
      copying: false,
    });
  }

  return (
    <div className="content-container">
      <Modal
        onClose={closeModal}
        onOpen={openModal}
        open={modalStatus.isOpen}
      >
        <Modal.Content image>{modalDescription}</Modal.Content>
        <Modal.Actions>
          <Button
            id="event-edit-modal-close"
            color="red"
            onClick={closeModal}
            content="Nope"
          />
          <Button
            content="Apply"
            labelPosition="right"
            icon="checkmark"
            onClick={applyModalClick}
            positive
            id="event-edit-modal-apply"
          />
        </Modal.Actions>
      </Modal>

      {event.mainEventId !== '0' && (
        <MenuCopyTabular
          id="event-edit-copy-menu-"
          isMainEvent={isMainEvent}
          pairedEventHideState={secondEvent?.hideMode}
          eventHideState={event.hideMode}
          event={event}
        />
      )}

      <Segment basic className="event-edit-container">
        <EventCard
          event={event}
          setModalStatus={setModalStatus}
          setModalDescription={setModalDescription}
        />
        <MainMenuTabular/>

        <Outlet context={outletContext} />
      </Segment>
    </div>
  );
};

export const EventEdit = () => {
  const { eventId } = useParams();
  const { queryState } = useQueryState();
  const { originId, setIsAllOrigins } = useGlobalStateContext();
  const { status, data, error } = useEvent({
   params: { eventIds: eventId, originId },
   refetchInterval: 5000,
});

  useEffect(() => {
  /** Use default "is all origins" true, if query state origin is empty */
    setIsAllOrigins(!queryState.originId);
    return () => {
      setIsAllOrigins(true);
    };
  // eslint-disable-next-line
  },[]);

  if (status === QUERY_STATUS.PENDING && !data) {
    return <PhoenixLoader/>;
  }

  if (status === QUERY_STATUS.ERROR) {
    return <>Error: {error}</>;
  }

  return (
    <EventEditComponent event={data}/>
  );
};
