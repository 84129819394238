import { useMemo } from 'react';

import { CachePolicy, useAxiosRequest } from 'use-axios-request';

import {
  DATE_FILTER_INTERVALS_DEFAULT
} from '../../components/search-and-filters-components/QueryStateFilterByPeriod';
import { API_TRADER_VOID_REASON_LIST, FILTER_INTERVALS } from '../../constants';
import { BetsRequest, QueryStateType } from '../../types';

export const useTraiderVoidReasons = () => {
  const { data } = useAxiosRequest<any>(API_TRADER_VOID_REASON_LIST, {
    cache: CachePolicy.CacheAndNetwork,
  });

  return useMemo(() => {
    if (Array.isArray(data?.result)) {
      return data.result.map(reason => ({
        ...reason,
        description: (
          reason.description.charAt(0) +
          reason.description.slice(1).toLowerCase()
        ).replace('_', ' '),
      }));
    }
    return [];
  }, [ data ]);
};

export function getParamsWithFilters (
  initialParams: BetsRequest & {originIds?: string[]; betId?: string; factorGroupIds?: string[]},
  queryState: QueryStateType
) {
  const params = {
    ...initialParams,
  };

  if (queryState.originIds) {
    params.originIds = queryState.originIds;
  }

  if (queryState.betId) {
    params.betId = queryState.betId;
  }

  if (queryState.betRejectReasons && !!queryState?.betRejectReasons?.length) {
    params.betRejectReasons = queryState.betRejectReasons;
  }

  if (queryState.betStatuses && !!queryState?.betStatuses?.length) {
    params.betStatuses = queryState.betStatuses;
  }

  if (queryState.betType && !!queryState?.betType?.length) {
    params.betTypes = [ queryState.betType ];
  }

  if (typeof queryState.bookType === 'string') {
    params.bookTypes = [ queryState.bookType ];
  }

  if (typeof queryState.userId === 'string') {
    params.userIds = [ queryState.userId ];
  }

  if (typeof queryState.eventId === 'string') {
    params.eventIds = [ queryState.eventId ];
  }

  if (typeof queryState.minStake === 'string') {
    params.minStake = Number(queryState.minStake);
  }

  if (typeof queryState.maxStake === 'string') {
    params.maxStake = Number(queryState.maxStake);
  }

  if (queryState.sports && !!queryState?.sports?.length) {
    params.sportIds = queryState.sports;
  }

  if (typeof queryState.marketPeriod === 'string') {
    params.periodTypeId = queryState.marketPeriod;
  }

  if (typeof queryState.marketType === 'string') {
    params.marketTypeId = queryState.marketType;
  }

  if (queryState.hideTestAccounts === 'true') {
    params.hideTestAccounts = queryState.hideTestAccounts;
  }

  if (queryState.showOnlyOutrights === 'true') {
    params.isOutright = queryState.showOnlyOutrights;
  }

  if (queryState.period) {
    const periodConfig = DATE_FILTER_INTERVALS_DEFAULT.find(({ value }) => value === queryState.period);
    if (periodConfig) {
      if (
        periodConfig.value === FILTER_INTERVALS.custom.value ||
        periodConfig.value === FILTER_INTERVALS.all.value
      ) {
        params.startDate = queryState.startDate;
        params.endDate = queryState.endDate;
      } else {
        params.startDate = periodConfig.getter();
        params.endDate = periodConfig.dateNow();
      }
    }
  }

  if (queryState.betAccepted) {
    if (queryState.betAccepted === 'Success') {
      params.betRejectReasons = [ 'BET_REASON_SUCCESS' ];
    } else {
      params.betRejectReasons = [
        'BET_REASON_UNKNOWN',
        'BET_REASON_OWALLET',
        'BET_REASON_LIMITS',
        'BET_REASON_RESTRICTIONS',
        'BET_REASON_PRICE_CHANGED',
        'BET_REASON_OTHER'
      ];
    }
  }

  if (queryState.factorGroupIds) {
    params.factorGroupIds = queryState.factorGroupIds;
  }

  return params;
}
