import axios from 'axios';

import {
  API_MARKET_TYPE_OUTCOME_LIST,
  DEFAULT_OUTCOME,
  MARKET_TYPE_ID,
  ORIGIN_DEFAULT_ID,
  OUTCOMES,
  PERIOD_ID
} from '../../constants';
import { OriginType } from '../../types';

export const fetchOutcomes = ({ setFieldValue, data, configForm }) => {
  const marketTypeId = data.name === MARKET_TYPE_ID ? data.value : configForm.marketTypeId;
  const periodId = data.name === PERIOD_ID ? data.value : configForm.periodId;
  const config = {
    url: API_MARKET_TYPE_OUTCOME_LIST,
    params: {
      eventId: configForm.eventId,
      marketTypeId: marketTypeId,
      periodId: periodId
    }
  };

  axios(config)
    .then(responce => responce.data)
    .then(data => data.result)
    .then(outcomes => {
      if (Array.isArray(outcomes) && !!outcomes?.length) {
        return outcomes.map(outcome => ({
            description: outcome.description,
            price: '',
            ordinalPosition: outcome.ordinalPosition,
            opponentId: null,
          }));
      } else {
        return [ DEFAULT_OUTCOME, DEFAULT_OUTCOME ];
      }
    })
    .then(outcomesFormated => setFieldValue(OUTCOMES, outcomesFormated))
    // eslint-disable-next-line
    .catch(e => console.log(e));
};

export function createOriginsBlock (arr: OriginType[], withDefaultOrigin?: boolean): Array<{
  description: string,
  parentId: string,
  list: OriginType[]
}> {
  if (!Array.isArray(arr) || !arr) return [];

  const filteredArr = !withDefaultOrigin
    ? [ ...arr ].filter(origin => Number(origin.id) !== ORIGIN_DEFAULT_ID)
    : [ ...arr ];

  const sortArr = filteredArr.sort((a, b) => Number(a.parentId) - Number(b.parentId));

  const collections = {
    withoutParent: {
      description: 'No parent',
      parentId: '0',
      list: []
    }
  };

  for (const origin of sortArr) {
    if (origin.parentId === '0') {
      collections[origin.id] = {
        description: origin.description,
        parentId: origin.id,
        list: [ origin ]
      };
    } else {
      if (collections[origin.parentId]) {
        collections[origin.parentId].list.push(origin);
      } else {
        collections.withoutParent.list.push(origin);
      }
    }
  }

  for (const collection in collections) {
    if (!collections[collection]?.list?.length) {
      delete collections[collection];
    }
  }

  return Object.values(collections);
}

export function getOriginsDescriptions (originIds: string[], originsData, isAllOriginsIncludes) {
  if (isAllOriginsIncludes) return 'All origins';
  const filteredOrigins = originsData.filter(el => originIds?.includes(el.id));
  return filteredOrigins.map(el => el.description).join(', ');
}

export function getField (collection, field) {
  const compositeName = /.*\..*/gm.test(field);
  if (!compositeName) return collection[field];

  const levels = field.split('.');
  let result = { ...collection };

  for (const level of levels) {
    const errorLevel = result[level] || null;
    if (!errorLevel) return !!errorLevel;
    result = errorLevel;
  }

  return !!result;
}
