import React from 'react';

import moment from 'moment';
import { Table, Header, Grid } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import styles from './NewLeaguesReport.module.css';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import {
  QueryStateStartAndEndDate
} from '../../components/search-and-filters-components/QueryStateFilterByPeriod';
import { TableSkeleton } from '../../components/UI/TableSkeleton';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import { DateTime } from '../../hooks-and-global-states/time-hooks';

export const NewLeaguesReport = () => {
  return (
    <ErrorBoundary>
      <NewLeaguesReportComponent />
    </ErrorBoundary>
  );
};

const NewLeaguesReportComponent = () => {
  const { queryState } = useQueryState();
  const { startDateTo, startDateFrom } = queryState;

  const { data, isFetching } = useAxiosRequest<{
    result: Array<{
      id: string;
      description: string;
      regionDescription: string;
      sportDescription: string;
      date: string;
    }>;
  }>(
    React.useMemo(() => {
      const to = startDateTo || moment().utc().format();
      const from = startDateFrom || moment(to).utc().subtract(1, 'days').format();

      return {
        // TODO to constant
        url: '/v1/DealerAPI/leagues/findByDate',
        params: {
          from,
          to,
        },
      };
    }, [ startDateTo, startDateFrom ])
  );

  return (
    <div className={styles.wrapper}>
      <Header>New Leagues</Header>

      <Grid>
        <Grid.Column width={3}>
          <QueryStateStartAndEndDate
            id="new-leagues--"
            startDateParam="startDateFrom"
            endDateParam="startDateTo"
          />
        </Grid.Column>
        <Grid.Column width={13}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Created Date</Table.HeaderCell>
                <Table.HeaderCell>Sport</Table.HeaderCell>
                <Table.HeaderCell>Region</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isFetching || !data ? (
                <TableSkeleton
                  rows={5}
                  columns={5}
                  rowHeight={1}
                />
              ) : (
                data?.result?.map(({ id, description, date, sportDescription, regionDescription }) => (
                  <Table.Row key={id}>
                    <Table.Cell>{id}</Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                    <Table.Cell>
                      <DateTime>{date}</DateTime>
                    </Table.Cell>
                    <Table.Cell>{sportDescription}</Table.Cell>
                    <Table.Cell>{regionDescription}</Table.Cell>
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </div>
  );
};
