import { QueryStateDropdownMultipleSelection } from './QueryStateInputs';
import { FACTOR_GROUP_FILTER } from '../../constants';
import { usePlayerFactorGroup } from '../../react-query/query';
import { getOptionsWithIds } from '../../utils';

export const PlayerFactorGroupsFilter = ({
  parentDomId,
}:{
  parentDomId: string;
}) => {
  const { data: playerFactorGroups } = usePlayerFactorGroup({
    refetchInterval: 10000
  });
  const options = playerFactorGroups?.map(group =>
    ({ value: group.id, text: group.shortDescription })) || [];

  return (
    <QueryStateDropdownMultipleSelection
      id={parentDomId + 'factor-groups-dropdown'}
      placeholder="Factor groups"
      multiple
      fluid
      name={FACTOR_GROUP_FILTER}
      options={getOptionsWithIds(
        options,
        parentDomId + 'factor-groups-dropdown'
      )}
    />
  );
};
