import React from 'react';

import { Link } from 'react-router-dom';
import { Table, Popup, Button, Label, Icon } from 'semantic-ui-react';

import { CreateCustomBetModal } from './CreateCustomBetModal';
import { HideModeOutcomeIcon } from './HideModeOutcomeIcon';
import { useGetOutcomeByIndex, useMarketRowContext } from './MarketRowContext';
import { OutcomeResultSelector } from './OutcomeResultSelector';
import { Price } from './Price';
import { UpdatePriceForm } from './UpdatePriceForm';
import { FlexDiv } from '../../../../components/FlexDiv';
import { Money } from '../../../../components/table-components/Money';
import {
  OUTCOME_RESULTS,
  TRADER_PERM_EVENT_EDITOR,
  TRADER_PERM_PLACE_BET,
  MARKET_STATUS_ENUM,
  TRADER_PERM_ALL,
  OutcomeResultsEnum
} from '../../../../constants';
import { useGlobalStateContext } from '../../../../hooks-and-global-states/global-context';
import { PriceLocalMarketsType } from '../../../../types';
import './OutcomeRow.css';
import { isNumberHelp } from '../../helpers';

export const OutcomeRow = ({
  liability,
  liabilityRiskSingle,
  highlightedRowRef,
  handleBetsClick,
  editingResults,
  handleOutcomeResultChange,
  setLocalOutcomes,
  localOutcomes,
  eventDescription,
  eventHideMode,
  sportId,
  index,
  pricesForRequest,
  setPricesForRequest,
  result,
  parentDomId,
}: {
  liability: number;
  liabilityRiskSingle: number;
  highlightedRowRef: null | React.RefObject<HTMLDivElement>;
  handleBetsClick: () => void;
  editingResults: boolean;
  handleOutcomeResultChange: (payload: {
    id: string;
    result: keyof typeof OutcomeResultsEnum;
    voidReason?: string;
    localOutcomes: object;
  }) => void;
  setLocalOutcomes: (string: object) => void;
  localOutcomes: object;
  eventDescription: string;
  eventHideMode: boolean;
  sportId: string;
  index: number;
  pricesForRequest: Array<PriceLocalMarketsType>,
  setPricesForRequest: (prev: { payload: { index: number }; type: string }) => void;
  result: keyof typeof OutcomeResultsEnum;
  parentDomId: string;
}) => {
  const { permissions } = useGlobalStateContext();

  const {
    canEditMarket,
    outcomesQuantity,
    market: {
      status: marketStatus,
      description: marketDescription,
      id: marketId
    },
  } = useMarketRowContext();

  const {
    firstPrice,
    currentPrice: price,
    manualMode,
    betsTotal,
    betsCount,
    betsCountSingle,
    outcome: {
      id,
      description,
      voidReason,
      hideModeFeed,
      hideModeTrader,
    }
  } = useGetOutcomeByIndex(index);

  const [ openBetModal, setOpenBetModal ] = React.useState(false);

  const enableEventEditing = React.useMemo(() => {
    return !permissions?.denyPermissions?.includes(TRADER_PERM_EVENT_EDITOR) &&
      [ TRADER_PERM_EVENT_EDITOR, TRADER_PERM_ALL ].some(permission =>
        permissions?.allowPermissions?.includes(permission)
      );
  },[ permissions?.allowPermissions, permissions?.denyPermissions ]);

  const showPlaceCustomBet = React.useMemo(() => {
    return !permissions?.denyPermissions?.includes(TRADER_PERM_PLACE_BET) &&
      [ TRADER_PERM_PLACE_BET, TRADER_PERM_ALL ].some(permission =>
        permissions?.allowPermissions?.includes(permission)
      );
  },[ permissions?.allowPermissions, permissions?.denyPermissions ]);

  const disablePlaceCustomBet = marketStatus !== MARKET_STATUS_ENUM.OPEN ||
    hideModeFeed || hideModeTrader || eventHideMode;

  function handleOutcomeResultChangeWithId ({ result, voidReason }) {
    handleOutcomeResultChange({
      id,
      result,
      voidReason,
      setLocalOutcomes,
      localOutcomes,
    });
  }

  return (
    <Table.Row
      style={{
        backgroundColor: OUTCOME_RESULTS[result] ? OUTCOME_RESULTS[result].backgroundColor : '#eee',
      }}
      warning={!!highlightedRowRef}
      className="market-table__outcome-row"
    >
      <Table.Cell />
      <Table.Cell>
        <div ref={highlightedRowRef} />
        <FlexDiv>
          {!!manualMode && (
            <Popup
              trigger={
                <Label
                  className="popup-label"
                  ribbon
                  color="violet"
                >
                  Manual Trading
                </Label>
              }
              content="This outcome ignores feed data"
              inverted
            />
          )}
          <FlexDiv.Flex>{description}</FlexDiv.Flex>
          <FlexDiv.Div>
            {canEditMarket && (
              <HideModeOutcomeIcon
                hideModeTrader={hideModeTrader}
                hideModeFeed={hideModeFeed}
                marketId={marketId}
                outcomeId={id}
                disabled={!enableEventEditing}
                parentDomId={parentDomId}
              />
            )}
          </FlexDiv.Div>
        </FlexDiv>
      </Table.Cell>

      <Table.Cell>
        <Price>{firstPrice}</Price>
      </Table.Cell>

      <Table.Cell>
        <UpdatePriceForm
          index={index}
          enableEventEditing={enableEventEditing}
          pricesForRequest={pricesForRequest}
          setPricesForRequest={setPricesForRequest}
          parentDomId={parentDomId + 'update-price-form--'}
        />
      </Table.Cell>

      <Table.Cell>
        <OutcomeResultSelector
          localId={marketId + id}
          handleClick={handleOutcomeResultChangeWithId}
          value={result}
          voidReason={voidReason}
          disabled={!editingResults}
          setLocalOutcomes={setLocalOutcomes}
          localOutcomes={localOutcomes}
          outcomesQuantity={outcomesQuantity}
          sportId={sportId}
          parentDomId={parentDomId + 'outcome-result-selector--'}
        />
      </Table.Cell>

      <Table.Cell>
        <Money>{liability}</Money>
      </Table.Cell>
      <Table.Cell>
        <Money>{liabilityRiskSingle}</Money>
      </Table.Cell>

      <Table.Cell>
        <div className="outcome-row__bets-cell">
          {betsCount === 0 ? (
            'No bets'
          ) : (
            <Link
              id={parentDomId + 'bets-link'}
              to="#"
              onClick={handleBetsClick}
            >
              <Money>{+betsTotal}</Money> / {+betsCount} /
              <Money>
                {isNumberHelp((+betsTotal / +betsCountSingle), 0)}
              </Money>
            </Link>
          )}
          {showPlaceCustomBet && (
            <Button
              id={parentDomId + 'add-custom-bet-btn'}
              icon
              size="mini"
              className="add-custom-bet"
              disabled={disablePlaceCustomBet}
              onClick={() => setOpenBetModal(true)}
            >
              <Icon name="plus" />
            </Button>
          )}
        </div>
      </Table.Cell>

      <CreateCustomBetModal
        openBetModal={openBetModal}
        description={description}
        marketDescription={marketDescription}
        eventDescription={eventDescription}
        setOpenBetModal={setOpenBetModal}
        outcomeId={id}
        price={price}
        parentDomId={parentDomId + 'add-custom-bet'}
      />
    </Table.Row>
  );
};
