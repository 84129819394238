import React from 'react';

import { Input } from 'semantic-ui-react';

import { OutcomeSinglesByEventType } from '../../../types';
import {
  getUpdatedMarketsWithPricesObj,
  MarketPricesStateType,
  MarketWithPricesObjectType
} from '../../../utils';

const MINIMAL_PRICE_VALUE = 1.01;
const DASH_SYMBOL = '-';

export const IrmPriceComponent = ({
  outcomeId,
  marketId,
  editedPricesState,
  setEditedPricesState,
  eventMarginState,
  useMarginState,
  selectedOutcomeState,
  selectOutcome,
  marketOutcomes,
  marketPricesState,
  setMarketPricesState,
  canEditPrice,
  parentDomId,
}: {
  outcomeId: string;
  marketId: string;
  editedPricesState: Array<MarketWithPricesObjectType>;
  setEditedPricesState: (editedPricesState: Array<MarketWithPricesObjectType>) => void;
  eventMarginState: number;
  useMarginState: boolean;
  selectedOutcomeState: string;
  selectOutcome: (outcome: string) => void;
  marketOutcomes: Array<OutcomeSinglesByEventType>;
  marketPricesState?: MarketPricesStateType;
  setMarketPricesState?: (arg: MarketPricesStateType) => void;
  canEditPrice: boolean;
  parentDomId: string;
}) => {
  const [ isIncorrectInput, setIsIncorrectInput ] = React.useState(false);

  const setPrice = React.useCallback(e => {
    const price = e.currentTarget.value;

    const updatedOutcomes = {};

    const checkNumberIsComplete = (price: string) => {
      const numberParts = price.split('.');
      return numberParts.length === 1 || (numberParts.length === 2 && !(numberParts[1] === ''));
    };

    if (isNaN(Number(price)) || price < 1 || !checkNumberIsComplete(price)) {
      setIsIncorrectInput(true);
    } else {
      setIsIncorrectInput(false);
    }

    let updatedPricesObj = getUpdatedMarketsWithPricesObj(
      editedPricesState,
      price,
      outcomeId,
      marketId
    );

    updatedOutcomes[outcomeId] = price;

    const getSecondOutcomeId = (marketOutcomes, firstOutcomeId) => {
      for (const outcome of marketOutcomes) {
        if (outcome.outcome.id !== firstOutcomeId) {
          return outcome.outcome.id;
        }
      }
    };

    /** if editing market has two outcomes and auto calculating with
     * margin is enable, calculate second price */
    if (marketOutcomes.length === 2 && useMarginState) {
      const secondOutcomeId = getSecondOutcomeId(marketOutcomes, outcomeId);
      let secondOutcomePrice = Number(((price * 100) / (eventMarginState * price - 100)).toFixed(2));

      if (secondOutcomePrice < MINIMAL_PRICE_VALUE) {
        secondOutcomePrice = MINIMAL_PRICE_VALUE;
      }

      updatedOutcomes[secondOutcomeId] = secondOutcomePrice;

      updatedPricesObj = getUpdatedMarketsWithPricesObj(
        updatedPricesObj,
        String(secondOutcomePrice),
        secondOutcomeId,
        marketId
      );
    }
    setMarketPricesState({
      ...marketPricesState,
      [marketId]: {
        ...marketPricesState[marketId],
        ...updatedOutcomes,
      },
    });
    setEditedPricesState(updatedPricesObj);
  },[
    editedPricesState,
    setEditedPricesState,
    marketOutcomes,
    marketPricesState,
    setMarketPricesState,
    eventMarginState,
    useMarginState,
    marketId,
    outcomeId
  ]);

  const searchRef = React.useRef(null);

  if (outcomeId === selectedOutcomeState) {
    searchRef.current.focus();
  }

  const getPlaceholder = () => {
    const currentPrice = marketPricesState[marketId][outcomeId];
    return currentPrice === 0 ? DASH_SYMBOL : currentPrice;
  };

  const getOutcomeValue = () => {
    const currentPrice = marketPricesState[marketId][outcomeId];
    return currentPrice === 0 ? undefined : currentPrice;
  };

  const isEditedOutcome = React.useCallback(
    (marketId, outcomeId) => {
      const market = editedPricesState?.find(mrkt => mrkt.id === marketId);
      return !!(market && market?.outcomes?.find(outcome => outcome.id === outcomeId));
    },
    [ editedPricesState ]
  );

  return (
    <Input
      id={parentDomId + 'price-input'}
      className={isEditedOutcome(marketId, outcomeId) ? 'edited' : 'simple'}
      size="small"
      value={getOutcomeValue()}
      onChange={setPrice}
      error={isIncorrectInput}
      onBlur={() => selectOutcome(undefined)}
      onSelect={() => selectOutcome(outcomeId)}
      placeholder={getPlaceholder()}
      ref={searchRef}
      disabled={!canEditPrice}
    />
  );
};
