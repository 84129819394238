import {
  BetTypeDropdowns,
  PeriodComponent,
  PlayerFactorGroupDropdown,
  StakeFilter,
  SportsDropdownFilter
} from './CompositeComponents';
import {
  CheckboxComponent,
  DatePickerComponent,
  DropdownComponent,
  DropdownWithAxiosComponent,
  InputComponent
} from './SimpleComponents';
import { OriginsAccordion } from '../../../../components/form-components/OriginsAccordionNew';
import {
  API_MARKET_TYPE_FIND,
  BET_ACCEPTED_STATUS_DROPDOWN_OPTIONS,
  BET_REJECT_REASONS_DROPDOWN_OPTIONS,
  BET_STATUS_DROPDOWN_OPTIONS,
  BOOK_TYPES_OPTIONS
} from '../../../../constants';
import { BetsTableFiltersEnumNew } from '../../../../types';
import { getOptionsWithIds } from '../../../../utils';
import { IFilterComponent, IStake } from '../../model/types';


export const FILTERS_COMPONENTS: Record<BetsTableFiltersEnumNew, IFilterComponent> = {
  [BetsTableFiltersEnumNew.hideTestAccounts]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <CheckboxComponent
        label="Hide test accounts"
        id={parentDomId + 'hide-test-account-checkbox'}
        name={BetsTableFiltersEnumNew.hideTestAccounts}
        value={value as boolean}
        action={action}
      />
    ),
  },
  [BetsTableFiltersEnumNew.betId]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <InputComponent
        id={parentDomId + 'bet-id-input'}
        value={value}
        action={action}
        placeholder="Bet Id"
        name={BetsTableFiltersEnumNew.betId}
        fluid
      />
    ),
  },
  [BetsTableFiltersEnumNew.userId]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <InputComponent
        id={parentDomId + 'account-id-input'}
        value={value}
        action={action}
        placeholder="Account Id"
        name={BetsTableFiltersEnumNew.userId}
        fluid
      />
    ),
  },
  [BetsTableFiltersEnumNew.factorGroupIds]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <PlayerFactorGroupDropdown
        parentDomId={parentDomId}
        value={value as string[]}
        action={action}
      />
    ),
  },
  [BetsTableFiltersEnumNew.eventId]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <InputComponent
        id={parentDomId + 'event-id-input'}
        value={value}
        action={action}
        placeholder="Event Id"
        name={BetsTableFiltersEnumNew.eventId}
        fluid
      />
    ),
  },
  [BetsTableFiltersEnumNew.betType]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <BetTypeDropdowns
        parentDomId={parentDomId}
        value={value as string[]}
        action={action}
      />
    ),
  },
  [BetsTableFiltersEnumNew.betAccepted]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <DropdownComponent
        id={parentDomId + 'accepted-dropdown'}
        placeholder="Accepted"
        fluid
        options={BET_ACCEPTED_STATUS_DROPDOWN_OPTIONS}
        name={BetsTableFiltersEnumNew.betAccepted}
        value={value as string}
        action={action}
        clearable
      />
    ),
  },
  [BetsTableFiltersEnumNew.bookType]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <DropdownComponent
        id={parentDomId + 'book-type-dropdown'}
        selection
        fluid
        clearable
        value={value as string}
        action={action}
        name={BetsTableFiltersEnumNew.bookType}
        placeholder="Book type"
        options={getOptionsWithIds(
          BOOK_TYPES_OPTIONS,
          parentDomId + 'book-type-dropdown'
        )}
      />
    ),
  },
  [BetsTableFiltersEnumNew.betStatuses]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <DropdownComponent
        id={parentDomId + 'bet-status-dropdown'}
        placeholder="Status"
        multiple
        fluid
        name={BetsTableFiltersEnumNew.betStatuses}
        options={getOptionsWithIds(BET_STATUS_DROPDOWN_OPTIONS, parentDomId + 'bet-status-dropdown')}
        value={value as string[]}
        action={action}
        clearable
      />
    ),
  },
  [BetsTableFiltersEnumNew.stake]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <StakeFilter
        parentDomId={parentDomId}
        value={value as IStake}
        action={action}
      />
    ),
  },
  [BetsTableFiltersEnumNew.period]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <PeriodComponent
        parentDomId={parentDomId}
        value={value as string}
        action={action}
      />
    ),
  },
  [BetsTableFiltersEnumNew.startDate]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <DatePickerComponent
        name={BetsTableFiltersEnumNew.startDate}
        placeholder="Start date"
        position="right-start"
        value={value as string}
        action={action}
        id={parentDomId + 'start-date'}
      />
    ),
  },
  [BetsTableFiltersEnumNew.endDate]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <DatePickerComponent
        name={BetsTableFiltersEnumNew.endDate}
        placeholder="End date"
        position="right-start"
        value={value as string}
        action={action}
        id={parentDomId + 'end-date'}
      />
    ),
  },
  [BetsTableFiltersEnumNew.sports]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <SportsDropdownFilter
        parentDomId={parentDomId}
        value={value as string[]}
        action={action}
      />
    ),
  },
  [BetsTableFiltersEnumNew.showOnlyOutrights]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <CheckboxComponent
        id={parentDomId + 'only-outrights-checkbox'}
        value={value as boolean}
        action={action}
        name={BetsTableFiltersEnumNew.showOnlyOutrights}
        label="Show only outrights"
      />
    ),
  },
  [BetsTableFiltersEnumNew.betRejectReasons]: {
    render: ({
      parentDomId,
      value,
      action
    }) => (
      <DropdownComponent
        id={parentDomId + 'rejected-reason-dropdown'}
        placeholder="Rejected reason"
        multiple
        fluid
        name={BetsTableFiltersEnumNew.betRejectReasons}
        options={getOptionsWithIds(
          BET_REJECT_REASONS_DROPDOWN_OPTIONS,
          parentDomId + 'rejected-reason-dropdown'
        )}
        value={value as string[]}
        action={action}
        clearable
      />
    ),
  },
  [BetsTableFiltersEnumNew.originIds]: {
    render: ({
      parentDomId,
      value,
      action
    }) => {

      const originAction = (origins: string[]) => {
        action(BetsTableFiltersEnumNew.originIds, origins);
      };

      return (
        <OriginsAccordion
          parentDomId={parentDomId}
          originAction={originAction}
          originsParentState={value as string[]}
          type="multi-origins"
        />
      );
    },
  },
  [BetsTableFiltersEnumNew.marketType]: {
    render: ({ parentDomId, sportIds, action, value }) => {
      return (
        <DropdownWithAxiosComponent
          id={parentDomId + 'market-type-dropdown'}
          placeholder="Market Type"
          fluid
          multiply={false}
          axiosConfig={{
            url: API_MARKET_TYPE_FIND,
            params: { sportEventPathId: sportIds[0] },
          }}
          name={BetsTableFiltersEnumNew.marketType}
          action={action}
          value={value}
          isShown={sportIds.length === 1}
        />
      );
    }
  },
};
