import { useParams } from 'react-router-dom';
import { Dimmer, Header, Loader, Segment } from 'semantic-ui-react';

import { EditEventPathTreeRequest } from './EditEventPathTreeRequest';
import { RegionForm } from './EventPathForms';
import { InRunningDelayComponent } from './InRunningDelayComponent';
import { LimitGroupsEdit } from './LimitGroupsEdit';
import { OriginsAccordion } from '../../components/form-components/OriginsAccordion';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';

export const EditRegion = () => {
  const { regionId } = useParams();
  const { originId } = useGlobalStateContext();

  if (!regionId) return null;

  return (
    <>
      <Header size="large">Edit Region</Header>
      <EditEventPathTreeRequest
        url="regions"
        id={regionId}
      >
        {({ eventPath, isFetching }) => {
          const { description, version, id, sportId, limitGroupId, limitGroupInherited } =
            eventPath || ({} as any);
          return (
            <>
              <Segment color="pink">
                <Dimmer active={isFetching} inverted>
                  <Loader />
                </Dimmer>
                <RegionForm
                  id={id}
                  description={description}
                  parentEventPathId={sportId}
                  version={version}
                />
              </Segment>
              <Segment color="pink">
                <OriginsAccordion
                  parentDomId="path-tree--edit-region--origins-filter"
                  multiChoice={false}
                  withSubOrigins={false}
                  withDefaultOrigin
                  icon="filter"
                />
                <LimitGroupsEdit
                  parentDomId="path-tree--edit-region--"
                  id={id}
                  limitGroupId={limitGroupId}
                  limitGroupInherited={limitGroupInherited}
                  version={version}
                  originId={originId}
                />
                <InRunningDelayComponent
                  parentDomId="path-tree--edit-region--"
                  eventPathId={regionId}
                  originId={originId}
                />
              </Segment>
            </>
          );
        }}
      </EditEventPathTreeRequest>
    </>
  );
};
