import React from 'react';

import { Header, Label, Popup, SemanticCOLORS } from 'semantic-ui-react';

import { EventOutcomeInfo } from '../../../components/table-components/EventOutcomeInfo';
import { MoneyWithUserCurrency } from '../../../components/table-components/Money';
import { TableCellPlayerInfo } from '../../../components/table-components/TableCellPlayerInfo';
import { TextWithCopyButton } from '../../../components/table-components/TextWithCopyButton';
import {
  BET_REJECT_REASONS,
  BET_STATUSES,
  BetsSortByEnum, BetStatusesEnum,
  OutcomeResultsEnum
} from '../../../constants';
import { DateTime } from '../../../hooks-and-global-states/time-hooks';
import { BetsColumnLabelEnum, BetsRequest, BetType } from '../../../types';
import { formatPrice } from '../../../utils';
import { VoidBetButton } from '../VoidBetButton';
import './Columns.scss';

export const BETS_COLUMNS: {
  [key in keyof typeof BetsColumnLabelEnum]: {
    sortByEnum?: BetsRequest['sortBy'][number];

    render: (
      bet: BetType,
      opt: {
        refresh: (arg0: void) => void;
        voidReasonList: Array<{
          id: string;
          description: string;
          value: string;
        }>;
        showCopyButtons?: {
          userId?: boolean;
          betId?: boolean;
          userName?: boolean;
        };
        parentDomId: string;
      }
    ) => React.ReactNode;
  };
} = {
  Date: {
    sortByEnum: BetsSortByEnum.BET_SORT_DATE,
    render: bet =>
      <>
        <DateTime>{bet.date}</DateTime>
        {bet.firstBets && <p style={{ color: 'purple' }}>First</p>}
      </>,
  },
  Player: {
    render: (bet, { showCopyButtons, parentDomId }) => (
      <TableCellPlayerInfo
        firstName={bet.user.firstName}
        lastName={bet.user.lastName}
        username={bet.user.username}
        id={bet.user.id}
        origin={bet.user.origin.id}
        userFactorGroupId={bet.user.factorGroup && bet.user.factorGroup.id}
        showCopyButtons={showCopyButtons}
        parentDomId={parentDomId}
      />
    ),
  },
  Outcomes: {
    render: (bet, { parentDomId }) =>
      bet.outcomes.map((outcome, index) => {
        return (
          <div key={outcome.id + index}>
            {index !== 0 && <div className="bet-monitor-separator" />}
            <EventOutcomeInfo
              sportDescription={outcome.sport.description}
              leagueDescription={outcome.league.description}
              eventId={outcome.event.id}
              eventDescription={outcome.event.description}
              outcomeId={outcome.id}
              outcomeMarketDescription={outcome.market.description}
              outcomeMarketPeriodDescription={outcome.market.periodDescription}
              outcomeDescription={outcome.description}
              outcomePrice={outcome.price}
              isEachWay={bet.isEachWay}
              result={outcome.result}
              parentDomId={parentDomId}
            />
          </div>
        );
      }),
  },
  Stake: {
    sortByEnum: BetsSortByEnum.BET_SORT_STAKE,
    render: bet => (
      <MoneyWithUserCurrency usd={bet.stakeUSD} currency={bet.user.currency}>
        {bet.stake}
      </MoneyWithUserCurrency>
    ),
  },
  Price: {
    render: bet => (
      <>
        {formatPrice(getPriceFromOutcomes(bet.outcomes))}
      </>
    ),
  },
  Winning: {
    sortByEnum: BetsSortByEnum.BET_SORT_PAYOUT,
    render: bet => {
      return (
        <MoneyWithUserCurrency usd={bet.payoutUSD} currency={bet.user.currency}>
          {bet.payout}
        </MoneyWithUserCurrency>
      );
    },
  },
  Type: {
    render: (bet, { showCopyButtons }) => {
      const firstData = () => {
        if (bet.type === 'System') {
          return 'Multiple (System)';
        } else if (bet.type === 'Single') {
          if (!bet.traderName) {
            return bet.type;
          } else {
            return (
              <>
                <div>{'Single'}</div>
                <div>{`Trader: ${bet.traderName}`}</div>
              </>
            );
          }
        } else {
          return bet.type;
        }
      };

      return (
        <>
          {firstData()}
          {showCopyButtons?.betId && (
            <TextWithCopyButton
              prevText="bet ID:"
              value={bet.id}
              type="betId"
            />
          )}
        </>
      );
    },
  },
  Status: {
    render: (bet, { voidReasonList }) => {
      const { label, color } = BET_STATUSES[bet.status] || {
        label: 'unknown',
        color: 'grey',
      };

      return (
        <>
          <span className="nowrap">
            <Label
              circular
              color={color as SemanticCOLORS}
              empty
            /> {label}
          </span>
          {bet.status === BetStatusesEnum.BET_STATUS_FAIL && (
            <>
              <br />
              <Popup
                trigger={
                  <div className="bets-filters__status-filter">
                    ({BET_REJECT_REASONS[bet.rejectReason] || BET_REJECT_REASONS['BET_REASON_OTHER']})
                  </div>
                }
                content={bet.rejectReasonDescription}
                hideOnScroll
              />
            </>
          )}
          {bet.status === BetStatusesEnum.BET_STATUS_TRADER_VOID && (
            <>
              <br />
              <span style={{ marginLeft: 15 }}>
                (
                {voidReasonList.find(v => v.value === bet.voidReason)?.description || 'Unknown reason'}
                )
              </span>
              <br />
              <span
                style={{
                  marginLeft: 15,
                  maxWidth: 100,
                  display: 'inline-block',
                  wordBreak: 'break-all',
                }}
              >
                {bet.traderVoidName}
              </span>
            </>
          )}
        </>
      );
    },
  },
  Actions: {
    render: (bet, { refresh, voidReasonList, parentDomId }) => {
      if (
        bet.status !== BetStatusesEnum.BET_STATUS_TRADER_VOID &&
        bet.status !== BetStatusesEnum.BET_STATUS_PORTAL_MANUAL_PAYOUT &&
        !bet.isSettledByPortal &&
        bet.status !== BetStatusesEnum.BET_STATUS_FAIL &&
        bet.status !== BetStatusesEnum.BET_STATUS_VOID
      )
        return (
          <VoidBetButton
            betId={bet.id}
            refresh={refresh}
            voidReasonList={voidReasonList}
            parentDomId={parentDomId + 'void-bet--'}
          />
        );

      if (bet.isSettledByPortal || bet.status === BetStatusesEnum.BET_STATUS_PORTAL_MANUAL_PAYOUT)
        return <Header as="h4" color="purple" content="Portal" />;
    },
  },
};

function getPriceFromOutcomes (outcomes: BetType['outcomes']) {
  let price = 1;
  for (const outcome of outcomes) {
    if (outcome.result !== OutcomeResultsEnum.VOID) {
      price = price * outcome.price;
    }
  }
  return price;
}
