import React from 'react';

import { Checkbox, Header, List } from 'semantic-ui-react';

import {
  LeaguesQueryDropdownWithAxios,
  RegionsQueryDropdownWithAxios,
  SportsQueryDropdownWithAxios
} from '../../components/form-components/DropdownWithAxios';
import { ClearFiltersButton } from '../../components/search-and-filters-components/ClearFiltersButton';
import {
  MarketStatusesFilter
} from '../../components/search-and-filters-components/MarketStatusesFilter';
import {
  QueryStateStartAndEndDate
} from '../../components/search-and-filters-components/QueryStateFilterByPeriod';
import {
  QueryStateInput,
  QueryStateMultipleCheckbox
} from '../../components/search-and-filters-components/QueryStateInputs';
import { MANUAL_MODE_FILTER_OPTIONS } from '../../constants';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';

export const EventFilters = () => {
  const { queryState, setQueryStateWithParams } = useQueryState();

  function liveOnlyOnChange () {
    if (Boolean(queryState.liveOnly)) {
      setQueryStateWithParams({
        liveOnly: null,
        startDateFrom: !queryState.liveOnly ? null : queryState.startDateFrom
      });
    } else {
      setQueryStateWithParams({
        liveOnly: true,
        startDateFrom: null,
      });
    }
  }

  function withBetsOnlyOnChange (_e, data) {
    setQueryStateWithParams({
      withBetsOnly: data?.checked
    });
  }

  return (
    <List>
      <List.Item>
        <ClearFiltersButton
          id="events-filter-clear"
          resetStartDateToToday
        />
      </List.Item>
      <List.Item>
        <QueryStateInput
          id="events-filter-search"
          icon="search"
          name="search"
          placeholder="Search..."
          fluid
        />
      </List.Item>
      <List.Item>
        <QueryStateStartAndEndDate
          id="events-filter-"
          startDateParam="startDateFrom"
          endDateParam="startDateTo"
        />
      </List.Item>
      <List.Item>
        <Header as="h5" style={{ marginBottom: 10 }}>
          Sports / Regions / Leagues
        </Header>
        <SportsQueryDropdownWithAxios
          id="events-filter-sport-id"
          fluid
          name="sportIds"
          onChange={(name, value) => {
            setQueryStateWithParams({
              sportIds: String(value),
              regionIds: '',
              leagueIds: '',
            });
          }}
        />
      </List.Item>
      <List.Item>
        <RegionsQueryDropdownWithAxios
          id="events-filter-region-id"
          fluid
          name="regionIds"
          sports={queryState.sportIds}
          onChange={(name, value) => {
            setQueryStateWithParams({
              regionIds: String(value),
              leagueIds: '',
            });
          }}
        />
      </List.Item>
      <List.Item>
        <LeaguesQueryDropdownWithAxios
          id="events-filter-league-id"
          fluid
          name="leagueIds"
          regions={queryState.regionIds}
          onChange={(name, value) => {
            setQueryStateWithParams({
              leagueIds: String(value),
            });
          }}
        />
      </List.Item>
      <List.Item>
        <Checkbox
          id="events-filter-live-only"
          name="liveOnly"
          label="Show live only"
          checked={Boolean(queryState.liveOnly)}
          onChange={liveOnlyOnChange}
          style={{
            margin: '5px 0'
          }}
        />
      </List.Item>
      <List.Item>
        <Checkbox
          id="events-filter-with-bets-only"
          name="withBetsOnly"
          label="Show with bets only"
          checked={Boolean(queryState.withBetsOnly)}
          onChange={withBetsOnlyOnChange}
          style={{ margin: '5px 0' }}
        />
      </List.Item>
      <List.Item>
        <Header as="h5">Market statuses</Header>
        <MarketStatusesFilter id="events-filter-market-status-" />
      </List.Item>
      <List.Item>
        <Header as="h5">Manual mode</Header>
        <QueryStateMultipleCheckbox
          id="events-filter-manual-mode-"
          options={MANUAL_MODE_FILTER_OPTIONS}
          name="manualMode"
        />
      </List.Item>
    </List>
  );
};
