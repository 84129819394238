import cx from 'classnames';
import { Button, Label, Icon, Dropdown, SemanticCOLORS } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import {
  OUTCOME_RESULT_OPTIONS,
  OutcomeResultsEnum,
  SPORT_ID_GREYHOUNDS,
  SPORT_ID_HORSE_RASING
} from '../../../constants';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';
import { useVoidReasonList } from '../../../react-query/query';
import { MarketUpdateResponse } from '../../../types';
import { submitOutcomeResultsFunc } from '../Markets/helpers';
import './IrmOutcomeResultSelector.css';

export const IrmOutcomeResultSelector = ({
  value,
  voidReason,
  handleClick,
  setLocalOutcomes,
  localOutcomes,
  marketId,
  setActiveStatusState,
  refreshMarkets,
  sportId,
  outcomesLength,
  parentDomId,
}: {
  value: string;
  voidReason: string;
  handleClick: (arg0: {
    result: OutcomeResultsEnum | string;
    setLocalOutcomes: (string: object) => void;
    localOutcomes: object,
    voidReason?: any
  }) => object;
  setLocalOutcomes: (string: object) => void;
  localOutcomes: object;
  marketId: string;
  setActiveStatusState: (boolean) => void;
  refreshMarkets: () => void;
  sportId: string;
  outcomesLength: number;
  parentDomId: string;
}) => {
  const { originId } = useGlobalStateContext();
  const { data: voidReasons } = useVoidReasonList();

  const { update } = useAxiosRequest(null, {
    onSuccess: (data: { result: MarketUpdateResponse }) => {
      setActiveStatusState(data.result.manualStatus);
      refreshMarkets();
    },
  });

  let i = 2;
  const placesList = [];
  while (i <= outcomesLength) {
    placesList.push({
      id: i - 1,
      description: `Place ${i}`,
      value: `PLACE_${i}`,
    });
    i++;
  }

  return (
    <>
      <Button.Group size={'mini'}>
        {OUTCOME_RESULT_OPTIONS
          .filter(option => option[0] !== OutcomeResultsEnum.NO_RESULT)
          .map(([ id, label, color ]) => {

            if (id === OutcomeResultsEnum.VOID){
            return  (
              <Dropdown
                key={id}
                id={parentDomId + 'void-dropdown'}
                text="Void"
                button
                className={cx('mini', color, value !== OutcomeResultsEnum.VOID && 'basic')}
                icon={<Icon style={{ margin: 0 }} name="angle down"/>}
              >
                <Dropdown.Menu>
                  {voidReasons?.map(({ value: voidReasonValue, description }) => (
                    <Dropdown.Item
                      key={voidReasonValue}
                      id={
                        parentDomId +
                        'void-dropdown--' +
                        voidReasonValue.toLowerCase().replace(/_/g, '-') +
                        '-option'
                      }
                      onClick={() => {
                        const updatedLocalOutcomes = handleClick({
                          result: OutcomeResultsEnum.VOID,
                          voidReason: voidReasonValue,
                          setLocalOutcomes: setLocalOutcomes,
                          localOutcomes: localOutcomes,
                        });
                        submitOutcomeResultsFunc(marketId, originId, updatedLocalOutcomes, update);
                      }}
                    >
                      {voidReasonValue === voidReason ? (
                        <Label horizontal color={color as SemanticCOLORS}>
                          {description}
                        </Label>
                      ) : (
                        description
                      )}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            );
            } else if (id === OutcomeResultsEnum.SET_PLACE) {
              if (
                [ SPORT_ID_GREYHOUNDS, SPORT_ID_HORSE_RASING ].includes(sportId) &&
                placesList.length > 0
              ) {
                return (
                  <Dropdown
                    key={id}
                    id={parentDomId + 'set-place-dropdown'}
                    text="Set Place"
                    button
                    className={cx('mini', color, value && !value.includes('PLACE') && 'basic')}
                    icon={<Icon style={{ margin: 0 }} name="angle down" />}
                  >
                    <Dropdown.Menu>
                      {placesList.map(({ value, description }) => (
                        <Dropdown.Item
                          key={value}
                          id={
                            parentDomId +
                            'set-place-dropdown--' +
                            value.toLowerCase().replace(/_/g, '-') +
                            '-option'
                          }
                          onClick={() => {
                            const updatedLocalOutcomes = handleClick({
                              result: value,
                              setLocalOutcomes: setLocalOutcomes,
                              localOutcomes: localOutcomes,
                            });
                            submitOutcomeResultsFunc(marketId, originId, updatedLocalOutcomes, update);
                          }}
                        >
                          {description}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }
            } else {
              return (
                <Button
                  key={id}
                  id={parentDomId + label.toLowerCase() + '-button'}
                  className="market-action-button"
                  size="mini"
                  onClick={() => {
                    const updatedLocalOutcomes = handleClick({
                      result: id,
                      setLocalOutcomes: setLocalOutcomes,
                      localOutcomes: localOutcomes,
                    });
                    submitOutcomeResultsFunc(marketId, originId, updatedLocalOutcomes, update);
                  }}
                  basic={value !== id}
                  color={color as SemanticCOLORS}
                >
                  {label}
                </Button>
              );
            }
          }
        )}
      </Button.Group>
      {OUTCOME_RESULT_OPTIONS.filter(option => option[0] === OutcomeResultsEnum.NO_RESULT).map(
        ([ id, label, color ]) => (
          <Button
            key={id}
            id={parentDomId + '--set-result--' + label.toLowerCase() + '-button'}
            className="no-result-button"
            size="mini"
            onClick={() => {
              const updatedLocalOutcomes = handleClick({
                result: id,
                setLocalOutcomes: setLocalOutcomes,
                localOutcomes: localOutcomes,
              });
              submitOutcomeResultsFunc(marketId, originId, updatedLocalOutcomes, update);
            }}
            basic={value !== id}
            color={color as SemanticCOLORS}
          >
            {label}
          </Button>
        )
      )}
    </>
  );
};
