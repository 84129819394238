import { useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import Axios from 'axios';
import { Formik } from 'formik';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Message, Button, Header, Segment } from 'semantic-ui-react';
import { object, string, date } from 'yup';

import { CashoutSettingsForm } from '../../../components/form-components/CashoutSettings';
import {
  LeaguesPhoenixFieldDropdownWithAxios,
  RegionsPhoenixFieldDropdownWithAxios
} from '../../../components/form-components/DropdownWithAxios';
import { FilterDatePicker } from '../../../components/form-components/FilterDatePicker';
import { PhoenixField, PhoenixFieldDropdown } from '../../../components/form-components/FormComponents';
import { OriginsAccordion } from '../../../components/form-components/OriginsAccordion';
import {
  BEST_OF_SETS_OPTIONS,
  TIEBREAK_OPTIONS,
  TRADER_PERM_EVENT_EDITOR,
  TRADER_PERM_ALL,
  START_DATE_PARAM,
  STATUS_UNKNOWN,
  CLOCK_STATUS_UNKNOWN,
  NOT_STARTED,
  API_EVENT_UPDATE,
  ORIGIN_ID_DEFAULT,
  API_SINGLES_BY_EVENT,
  MARKET_STATUS_ENUM,
  API_MARKETS_UPDATE
} from '../../../constants';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';
import { KEY_EVENT, KEY_MARKETS } from '../../../react-query/query';
import {
  EventOutletContextType,
  FieldTypesEnum,
  SingleByEventMarketType
} from '../../../types';
import {
  isSportWithAddScoring,
  isSportWithSetsCheck,
  prepareDataForEventEdit
} from '../../../utils';
import './EventInfo.scss';

const EventEditSchema = object().shape({
  description: string().required(),
  startDate: date().required(),
  regionId: string().required(),
  leagueId: string().required(),
});

export const EventInfo = () => {
  const queryClient = useQueryClient();
  const { event } = useOutletContext<EventOutletContextType>();
  const { originId, permissions } = useGlobalStateContext();

  const initialValues = useMemo(() => {
    return ({
      description: event.description,
      startDate: event.startDate,
      neutralVenue: event.neutralVenue,
      regionId: event.regionId,
      leagueId: event.leagueId,
      comment: event.comment,
      traderComment: event.traderComment,
      tieBreak: event.tieBreak,
      bestOfSets: event.bestOfSets,
      noAdScoring: event.noAdScoring,
      inRunningDelay: event.inRunningDelay,
      toleranceRate: event.toleranceRate,
      inRunningCalendar: event.inRunningCalendar,
      isInRunningDelayInherited: event.isInRunningDelayInherited,
      isToleranceRateInherited: event.isToleranceRateInherited,
      cashoutInherited: event.cashoutInherited,
      cashoutEnabled: event.cashoutEnabled,
      isPrematchVisible: event.isPrematchVisible,
    });
  },[
    event.bestOfSets,
    event.cashoutInherited,
    event.comment,
    event.description,
    event.inRunningCalendar,
    event.inRunningDelay,
    event.isInRunningDelayInherited,
    event.isToleranceRateInherited,
    event.leagueId,
    event.neutralVenue,
    event.noAdScoring,
    event.regionId,
    event.startDate,
    event.tieBreak,
    event.toleranceRate,
    event.traderComment,
    event.cashoutEnabled,
    event.isPrematchVisible
  ]);

  const isSportWithSets = isSportWithSetsCheck(event.sportId);
  const currentStatus = event.clock ? event.clock.status : undefined;
  const isAdSoringDisabled = new Date(event.startDate) < new Date();
  const disableEditing = permissions?.denyPermissions?.includes(TRADER_PERM_EVENT_EDITOR) ||
    ![ TRADER_PERM_EVENT_EDITOR, TRADER_PERM_ALL ].some(permission =>
      permissions?.allowPermissions?.includes(permission)
    );
  const disableTieBreak =
    (![ CLOCK_STATUS_UNKNOWN, NOT_STARTED, STATUS_UNKNOWN ].includes(currentStatus) &&
      !event.manualCreated) ||
    new Date(event.startDate) < new Date() ||
    disableEditing;
  const disableBestOfSets =
    (![ CLOCK_STATUS_UNKNOWN, NOT_STARTED, STATUS_UNKNOWN ].includes(currentStatus) &&
      !event.manualCreated) ||
    new Date(event.startDate) < new Date() ||
    disableEditing;

  async function onSubmit (values, { setSubmitting, setFieldError, resetForm }) {
    function onSuccess (data: any) {
      toast.success('Event has been updated');
      const newEvent = {
        ...values,
        cashoutEnabled: data.result.result.cashoutEnabled,
        cashoutInherited: data.result.result.cashoutInherited,
        version: data.result.result.version
      };
      resetForm({ values: { ...newEvent }});
      setSubmitting(false);
    }

    setSubmitting(true);
    try {
      const dataForRequest = prepareDataForEventEdit(initialValues, values);
      const { data: updatedEvent } = await Axios.put(API_EVENT_UPDATE, {
        event: {
          ...dataForRequest,
          id: event.id,
          version: event.version,
          cashoutInherited: values.cashoutInherited,
          cashoutEnabled: values.cashoutEnabled,
        },
        originId,
      });

      await queryClient.invalidateQueries({ queryKey: [ KEY_EVENT, event.id, originId ] });

      if (!dataForRequest.regionId && !dataForRequest.leagueId) {
        onSuccess(updatedEvent);
        return;
      }

      const { data: marketsData } = await Axios({
        url: API_SINGLES_BY_EVENT,
        params: { eventId: event.id }
      });

      const markets: SingleByEventMarketType[] = marketsData?.result?.markets;

      const notSettledMarkets = markets.filter(({ market }) =>
        ![ MARKET_STATUS_ENUM.SETTLED, MARKET_STATUS_ENUM.CLOSED ].includes(market.status));

      if (!notSettledMarkets.length) {
        onSuccess(updatedEvent);
        return;
      }

      await Axios.put(API_MARKETS_UPDATE, {
        originId,
        markets: notSettledMarkets.map(m => ({
          id: m.market.id,
          manualMode: true,
          status: MARKET_STATUS_ENUM.CLOSED
        }))
      });

      await queryClient.invalidateQueries({ queryKey: [ KEY_MARKETS, event.id, originId ] });

      onSuccess(updatedEvent);
    } catch (error) {
      setFieldError('submit', error.message);
      setSubmitting(false);
    }
  }

  return (
    <Formik
      key={event.id}
      validationSchema={EventEditSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {({
        setFieldValue,
        isValid,
        isSubmitting,
        values,
        handleSubmit,
        errors,
        dirty,
      }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <PhoenixField
                id="event-edit--description-tab--description-input"
                name="description"
                label="Description"
                fluid
                required
                disabled={disableEditing}
              />

              <Form.Field required>
                <label>Start date</label>
                <FilterDatePicker
                  id="event-edit--description-tab--start-date-input"
                  selected={values.startDate}
                  onChange={data => setFieldValue(START_DATE_PARAM, data)}
                  showTimeSelect
                  position="bottom"
                  disabled={disableEditing}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <PhoenixField
                id="event-edit--description-tab--neutral-venue-checkbox"
                type={FieldTypesEnum.CHECKBOX}
                name="neutralVenue"
                label="Neutral Venue"
                disabled={disableEditing}
              />
            </Form.Group>

            {event.manualCreated && (
              <Form.Group widths="equal">
                <RegionsPhoenixFieldDropdownWithAxios
                  id="event-edit--description-tab--region-id-dropdown"
                  name="regionId"
                  label="Region"
                  required
                  sports={event.sportId}
                  customOnChange={(newValue, setter) => {
                    if (newValue !== values.regionId) {
                      setter('leagueId', null);
                    }
                  }}
                />
                <LeaguesPhoenixFieldDropdownWithAxios
                  id="event-edit--description-tab--league-id-dropdown"
                  name="leagueId"
                  label="League"
                  required
                  regions={values.regionId}
                />
              </Form.Group>
            )}

            <Form.Group widths="equal">
              <PhoenixField
                id="event-edit--description-tab--comment-input"
                type={FieldTypesEnum.TEXTAREA}
                name="comment"
                label="Comments"
                disabled={disableEditing}
              />

              <PhoenixField
                id="event-edit--description-tab--trader-comment-input"
                type={FieldTypesEnum.TEXTAREA}
                name="traderComment"
                label="Trader comments"
                disabled={disableEditing}
              />
            </Form.Group>

            <Form.Group>
              {isSportWithSets && (
                <>
                  <PhoenixFieldDropdown
                    id="event-edit--description-tab--tie-break-dropdown"
                    name="tieBreak"
                    label="Tiebreak"
                    options={TIEBREAK_OPTIONS}
                    disabled={disableTieBreak}
                  />
                  <PhoenixFieldDropdown
                    id="event-edit--description-tab--best-of-sets-dropdown"
                    name="bestOfSets"
                    label="Best of sets"
                    options={BEST_OF_SETS_OPTIONS}
                    disabled={disableBestOfSets}
                  />
                </>
              )}
              {isSportWithAddScoring(event.sportId) && (
                <PhoenixField
                  id="event-edit--description-tab--no-ad-scoring-checkbox"
                  type={FieldTypesEnum.CHECKBOX}
                  name="noAdScoring"
                  label="No-Ad scoring"
                  disabled={isAdSoringDisabled}
                />
              )}
            </Form.Group>

            <Segment color="pink">
              <OriginsAccordion
                parentDomId="event-edit--description-tab--"
                className="event-info__origins-filter"
                multiChoice={false}
                disable={disableEditing}
                withSubOrigins={false}
                withDefaultOrigin
                icon="filter"
              />
              <Form.Group widths="equal">
                <PhoenixField
                  id="event-edit--description-tab--in-running-delay-field"
                  type={FieldTypesEnum.NUMBER}
                  name="inRunningDelay"
                  label="In running delay (sec.)"
                  disabled={!!values.isInRunningDelayInherited || disableEditing}
                />

                <PhoenixField
                  id="event-edit--description-tab--tolerance-rate-field"
                  name="toleranceRate"
                  label="Tolerance Rate, %"
                  type={FieldTypesEnum.DOUBLE}
                  min={0}
                  max={100}
                  disabled={!!values.isToleranceRateInherited || disableEditing}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <PhoenixField
                  id="event-edit--description-tab--in-running-calendar-checkbox"
                  type={FieldTypesEnum.CHECKBOX}
                  name="inRunningCalendar"
                  label="In Running Calendar"
                  disabled={disableEditing}
                />

                <PhoenixField
                  id="event-edit--description-tab--in-running-delay-inherited-checkbox"
                  type={FieldTypesEnum.CHECKBOX}
                  name="isInRunningDelayInherited"
                  label="In running delay Inherited"
                  disabled={disableEditing}
                />

                <PhoenixField
                  id="event-edit--description-tab--tolerance-rate-inherited-checkbox"
                  type={FieldTypesEnum.CHECKBOX}
                  name="isToleranceRateInherited"
                  label="Tolerance Rate Inherited"
                  disabled={disableEditing}
                />

                <PhoenixField
                  id="event-edit--description-tab--is-prematch-visible-checkbox"
                  type={FieldTypesEnum.CHECKBOX}
                  name="isPrematchVisible"
                  label="Display event on origin (for prematch)"
                  disabled={disableEditing}
                />
              </Form.Group>

              <Header as="h3">Cashout Settings</Header>
              <CashoutSettingsForm
                id="event-edit--description-tab--"
                showInherit
                enabled={event.cashoutEnabled}
                cashoutInheritedName="cashoutInherited"
                cashoutEnabledName="cashoutEnabled"
                disableAll={disableEditing}
              />
            </Segment>

            {errors.submit &&
              <Message negative>{String(errors.submit)}</Message>
            }

            <Button
              id="event-edit--description-tab--submit"
              type="submit"
              className="ui positive button"
              disabled={!isValid || disableEditing || !dirty}
              loading={isSubmitting}
              content={originId !== ORIGIN_ID_DEFAULT
                ? 'Update event'
                : 'Update event for all origins'
              }
            />
          </Form>
        </>
      )}
    </Formik>
  );
};
