import { memo, useMemo } from 'react';

import { AxiosRequestConfig as AxiosConfigType } from 'axios';
import { useAxiosRequest } from 'use-axios-request';

import { PhoenixFieldDropdown, PhoenixFieldDropdownProps } from './FormComponents';
import { API_LEAGUES_FIND, API_REGIONS_FIND, API_SPORTS_FIND } from '../../constants';
import { getOptionsWithIds } from '../../utils';
import {
  QueryStateDropdown,
  QueryStateDropdownProps
} from '../search-and-filters-components/QueryStateInputs';

const useDropdownAxiosOptions = (axiosConfig: AxiosConfigType | string | null) => {
  const { data, isFetching } = useAxiosRequest<any>(axiosConfig);
  const options = useMemo(
    () =>
      data?.result?.map(({ id, description }) => ({
        value: id,
        text: description || '',
      })) || [],
    [ data ]
  );

  return { options, isFetching };
};

export const QueryDropdownWithAxios = memo(({
  axiosConfig,
  disabled,
  ...props
}: QueryStateDropdownProps & {
  axiosConfig: AxiosConfigType | string | null;
}) => {
  const { options, isFetching } = useDropdownAxiosOptions(axiosConfig);

  return (
    <QueryStateDropdown
      disabled={disabled || axiosConfig == null}
      options={getOptionsWithIds(options, props.id)}
      loading={isFetching}
      search
      lazyLoad
      {...props}
    />
  );
});

export const PhoenixFieldDropdownWithAxios = ({
  axiosConfig,
  disabled,
  ...props
}: PhoenixFieldDropdownProps & {
  axiosConfig: AxiosConfigType | string | null;
}) => {
  const { options, isFetching } = useDropdownAxiosOptions(axiosConfig);

  return (
    <PhoenixFieldDropdown
      disabled={disabled || axiosConfig == null}
      options={getOptionsWithIds(options, props.id)}
      loading={isFetching}
      search
      lazyLoad
      {...props}
    />
  );
};

export const useSportsConfig = () => {
  return useMemo(() => ({
    url: API_SPORTS_FIND,
    params: {
      limit: 99999,
    },
  }),[]);
};

export const SportsQueryDropdownWithAxios = (props: QueryStateDropdownProps) => {
  const config = useSportsConfig();
  return (
    <QueryDropdownWithAxios
      placeholder="Sports"
      axiosConfig={config}
      {...props}
    />
  );
};

export const SportsPhoenixFieldDropdownWithAxios = (props: PhoenixFieldDropdownProps) => {
  const config = useSportsConfig();
  return (
    <PhoenixFieldDropdownWithAxios
      placeholder="Sports"
      axiosConfig={config}
      {...props}
    />
  );
};

type ParamsType = string | Array<string> | null;

const useRegionsFindAxiosConfig = (sports: ParamsType) =>
  useMemo(() =>
    sports
      ? {
          url: API_REGIONS_FIND,
          params: {
            sportIds: sports,
            limit: 99999,
          },
        }
      : null,
  [ sports ]);

export const RegionsQueryDropdownWithAxios = ({
  sports,
  ...props
}: QueryStateDropdownProps & {
  sports: ParamsType;
}) => {
  const axiosConfig = useRegionsFindAxiosConfig(sports);
  return (
    <QueryDropdownWithAxios
      placeholder="Regions"
      axiosConfig={axiosConfig}
      disabled={!sports}
      {...props}
    />
  );
};

export const RegionsPhoenixFieldDropdownWithAxios = ({
  sports,
  ...props
}: PhoenixFieldDropdownProps & {
  sports: ParamsType;
}) => {
  const axiosConfig = useRegionsFindAxiosConfig(sports);
  return (
    <PhoenixFieldDropdownWithAxios
      placeholder="Regions"
      axiosConfig={axiosConfig}
      disabled={!sports}
      {...props}
    />
  );
};

const useLeaguesFindAxiosConfig = (regions: ParamsType) =>
  useMemo(() =>
    regions
      ? {
          url: API_LEAGUES_FIND,
          params: {
            regionIds: regions,
            limit: 99999,
          },
        }
      : null,
  [ regions ]);

export const LeaguesQueryDropdownWithAxios = ({
  regions,
  ...props
}: QueryStateDropdownProps & {
  regions: ParamsType;
}) => {
  const axiosConfig = useLeaguesFindAxiosConfig(regions);
  return (
    <QueryDropdownWithAxios
      placeholder="Leagues"
      axiosConfig={axiosConfig}
      disabled={!regions}
      {...props}
    />
  );
};

export const LeaguesPhoenixFieldDropdownWithAxios = ({
  regions,
  ...props
}: PhoenixFieldDropdownProps & {
  regions: ParamsType;
}) => {
  const axiosConfig = useLeaguesFindAxiosConfig(regions);
  return (
    <PhoenixFieldDropdownWithAxios
      placeholder="Leagues"
      axiosConfig={axiosConfig}
      disabled={!regions}
      {...props}
    />
  );
};
