import React from 'react';

const CURRENCIES = {
  ARS: 'ARS$',
  AUD: 'AUD$',
  BGN: 'лв.',
  BRL: 'R$',
  CLP: 'CLP$',
  CZK: 'Kč',
  EUR: '€',
  GBP: '£',
  GEL: '₾',
  HUF: 'Ft',
  JPY: '¥',
  KES: 'KSh',
  LBP: 'ل.ل.',
  MXN: 'MXN$',
  PEN: 'S/',
  PLN: 'zł',
  RUB: '₽',
  SEK: 'kr',
  TRY: '₺',
  UGX: 'USh',
  USD: '$',
  UYU: 'UYU$',
};

export const Money = ({ children, currency }: { children: number; currency: string }) => {
  const currencySymbol = CURRENCIES[currency] || `${currency} `;
  return (
    <>
      {`${currencySymbol}${(Math.round(children * 100) / 100).toLocaleString()}`}
    </>
  );
};

// TODO make new component
export const MoneyWithUserCurrency = ({
  usd,
  children,
  currency,
}: {
  children: number;
  usd?: number;
  currency: string;
}) => {
  return (
    <>
      {usd && (
        <>
          <Money>{usd}</Money>
          {currency !== 'USD' && (
            <>
              {' '}
              (<Money currency={currency}>{children}</Money>)
            </>
          )}
        </>
      )}
      {!usd && (
        <Money currency={currency}>{children}</Money>
      )}
    </>
  );
};

Money.defaultProps = {
  currency: 'USD',
};
