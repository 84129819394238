import React from 'react';

import { Message } from 'semantic-ui-react';

import { OriginSettingsForm } from './OriginSettingsForm';
import { OriginsAccordion } from '../../components/form-components/OriginsAccordion';
import { LIMIT_MIN_STAKE } from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { uppDefaultCurrency } from '../../utils';

import './OriginSettings.scss';

export const OriginSettings = () => {
  const { originId, originsList } = useGlobalStateContext();

  const origin = React.useMemo(() => {
    if (!originsList) return null;

    const origin = originsList.find(({ id }) => id === originId);

    const sortedLimits = [ ...origin.limits ].map(limit => {
      if (limit.limitCode !== LIMIT_MIN_STAKE) return limit;

      return { ...limit, values: uppDefaultCurrency(limit.values) };
    });
    return { ...origin, limits: sortedLimits };
  }, [ originsList, originId ]);

  return (
    <section className="origin-settings">
      <h1 className="origin-settings__main-header">Origin Settings</h1>
      <OriginsAccordion
        parentDomId="origin-page--"
        className="origin-settings__origins-dropdown width-l"
        multiChoice={false}
        withSubOrigins
        withDefaultOrigin
      />
      {origin ? (
        <OriginSettingsForm origin={origin}/>
      ) : (
        <Message negative>
          <Message.Header>Origin does not exist</Message.Header>
        </Message>
      )}
    </section>
  );
};
