import { Outlet } from 'react-router-dom';
import { Container, Segment } from 'semantic-ui-react';

import { EventPathTree } from './EventPathTree/EventPathTree';
import styles from './styles/EventPaths.module.css';

export const EventPaths = () => {
  const outletContext = {
    reload: () => null,
  };

  return (
    <>
      <Segment className={styles.eventPathTree}>
        <EventPathTree />
      </Segment>
      <div className={styles.wrap}>
        <Container
          fluid
          className={styles.container}
        >
          <Outlet context={outletContext} />
        </Container>
      </div>
    </>
  );
};
