import React from 'react';

import Axios from 'axios';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { List, Form, Button, Input, Icon } from 'semantic-ui-react';
import { object, string } from 'yup';

import { API_OPPONENT_UPDATE, DESCRIPTION } from '../../constants';
import { OpponentType } from '../../types';
import { getIconNameFromOpponentType, removeEmptyFields } from '../../utils';

type Props = {
  opponent: OpponentType;
  reload: () => void;
  parentId: string;
};

type State = {
  editMode: boolean;
  opponent: OpponentType;
};

const OpponentSchema = object().shape({
  description: string().required(),
});

export class OpponentEdit extends React.Component<Props, State> {
  state = {
    editMode: false,
    opponent: this.props.opponent,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.opponent.description !== nextProps.opponent.description) {
      return {
        opponent: nextProps.opponent,
        editMode: false,
      };
    }

    return null;
  }

  toggleEdit = () => {
    this.setState(state => ({
      editMode: !state.editMode,
    }));
  };

  handleCancel = () => {
    this.setState({
      editMode: false,
    });
  };

  render() {
    const { editMode } = this.state;
    const { opponent, reload } = this.props;
    return (
      <>
        {editMode ? (
          <Formik
            validationSchema={OpponentSchema}
            initialValues={{
              description: opponent.description,
            }}
            onSubmit={(values, { setSubmitting }) => {
              const dataForRequest = removeEmptyFields({
                ...opponent,
                ...values,
              });
              Axios.put(API_OPPONENT_UPDATE, dataForRequest)
                .then(() => {
                  toast.success('Opponent has been updated');
                  this.handleCancel();
                  reload();
                  setSubmitting(false);
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.error(error);
                  setSubmitting(false);
                });
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              setFieldValue,
              values,
              isValid
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Input
                    id={this.props.parentId + 'description-input'}
                    icon={getIconNameFromOpponentType(opponent.type)}
                    iconPosition="left"
                    name="description"
                    value={values.description}
                    onChange={(_e, { value }) => setFieldValue(DESCRIPTION, value)}
                    action={
                      <>
                        <Button
                          id={this.props.parentId + 'description-submit-btn'}
                          type="submit"
                          loading={isSubmitting}
                          disabled={!isValid}
                          icon
                        >
                          <Icon name="check" />
                        </Button>
                        <Button
                          id={this.props.parentId + 'description-cancel-btn'}
                          icon
                          onClick={this.handleCancel}
                          color="red"
                        >
                          <Icon name="cancel" />
                        </Button>
                      </>
                    }
                  />
                </Form>
              );
            }}
          </Formik>
        ) : (
          <>
            <List.Icon name={getIconNameFromOpponentType(opponent.type)} />
            <List.Content>{opponent.description}</List.Content>
            <List.Icon
              id={this.props.parentId + 'edit-opp-btn'}
              name="edit"
              onClick={this.toggleEdit}
            />
          </>
        )}
      </>
    );
  }
}
