import React from 'react';

import { Loader } from 'semantic-ui-react';
import './PhoenixLoader.css';

export const PhoenixLoader = () => {
  return (
    <div className="loader">
      <Loader size={'large'} active inline="centered">
        {'Loading'}
      </Loader>
    </div>
  );
};
