import { useEffect, useState } from 'react';

import axios from 'axios';
import createError from 'axios/lib/core/createError';
import moment from 'moment';
import qs from 'qs';
import * as R from 'ramda';
import { toast } from 'react-toastify';

import {
  BEACH_VOLLEYBALL_ID,
  BETBUILDER_TYPE_ENUM,
  BETBUILDER_TYPE_FILTER,
  BETBUILDER_TYPE_OPTIONS,
  BOOK_TYPES_ENUM,
  BOOK_TYPES_FILTER,
  BOOK_TYPES_OPTIONS,
  CURRENCY_ID_USD,
  DATE_FORMAT_3_DIGIT_MONTH_TIME,
  FILTERS_FOR_FEED_EVENTS,
  FILTERS_FOR_MANUAL_EVENTS,
  IRM_STATUS_FILTER,
  IRM_STATUSES_ENUM,
  IRM_STATUSES_OPTIONS,
  LIMIT_MIN_STAKE,
  MANUAL_MODE_ENUM,
  MANUAL_MODE_OPTIONS,
  MANUAL_MODES_FILTER,
  MARKET_BETS_FILTER,
  MARKET_BETS_OPTIONS,
  MARKET_BETS_OPTIONS_TYPE_ENUM,
  MARKET_STATUS_ARRAY,
  MARKET_STATUS_ENUM,
  MARKET_STATUSES_FILTER,
  MARKET_STATUSES_OPTIONS,
  TABLE_TENNIS_SPORT_ID,
  TENNIS_SPORT_ID,
  UNKNOWN_PLAYER_FACTOR_GROUP,
  VOLLEYBALL_ID
} from './constants';
import { MarketsFilters } from './pages/EventEdit/MarketsFilters';
import { usePlayerFactorGroup } from './react-query/query';
import {
  EventType,
  IdValue,
  OpponentType,
  OriginsLimitValue,
  OutcomeSinglesByEventType,
  PlayerFactorGroup,
  SingleByEventMarketType
} from './types';

const TURN_LEFT = -1;
const NO_TURN = 0;

export const formatDateInTable = (
  startDate: string,
  dateFormat: string = DATE_FORMAT_3_DIGIT_MONTH_TIME
): string => moment(startDate).format(dateFormat);

export const formatMarketStatus = (marketStatus: string): string => {
  const market = MARKET_STATUS_ARRAY.find(item => item.id === marketStatus);

  return market ? market.description : '';
};

export const paramsSerializer = (v: object) => qs.stringify(v, { arrayFormat: 'repeat' });

export const registerAxiosInterseptors = () => {
  axios.interceptors.response.use(
    response => {
      const { config, data } = response;

      if (
        data &&
        data.error &&
        typeof data.error.code === 'number' &&
        typeof data.error.error === 'string'
      ) {
        toast.error(data.error.error);

        return Promise.reject(
          createError(data.error.error, null, response.config, response.request, response)
        );
      }

      let filterName = '';

      if (config && config.url) {
        const arrUrl = config.url.split('/');
        filterName = arrUrl[2];
      }

      if (data && typeof data === 'object') {
        const { code, error } = data;
        if (code && error) {
          toast.error(`${filterName}: can't load. Error code: ${String(code)} "${String(error)}"`, {});
          // eslint-disable-next-line
          console.log(data);

          return Promise.reject(createError(error, null, response.config, response.request, response));
        }
      }

      return response;
    },
    error => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      const { response, request, config } = error;
      let filterName = '';

      if (config && config.url) {
        const arrUrl = config.url.split('/');

        filterName = arrUrl[3];
      }

      if (response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { status } = response;

        if (status >= 500) {
          if (filterName === 'find') {
            toast.error(`${filterName}: can't load. No response from server. Error code: ${status}`, {});
          }
        } else {
          if (response.data && response.data.error) {
            toast.error(`${response.data.error}`, {});
          } else {
            toast.error(`${filterName}: can't load. Error code: ${status}`, {});
          }
        }
      } else if (request) {
        // The request was made but no response was received
        toast.error(`${filterName}: can't load. You are offline`, {});
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error(`${filterName}: wrong request to server`, {});
      }

      return Promise.reject(error);
    }
  );
};

export const parseQuery = (search: string) => qs.parse(search, { ignoreQueryPrefix: true });

export const convertNumbersToPercents = (obj: object) => {
  const keys = Object.keys(obj);

  const sum = R.pipe(R.values, R.sum)(obj);

  return keys.map(item => {
    const percent = (obj[item] / sum) * 100;
    return {
      id: item,
      percent: Math.round(percent),
      value: obj[item],
    };
  }) as Array<IdValue>;
};

// Iteratively changes property value according to transform function. Say, we can mass increase versions
export const changePropertyValue = (params: object, field: string, transform: (val: any) => any) => {
  for (const key in params) {
    if (typeof params[key] === 'object') {
      changePropertyValue(params[key], field, transform);
    } else if (key === field) {
      params[key] = transform(params[key]);
    }
  }
};

export const getIconNameFromOpponentType = (opponentType: OpponentType['type']) => {
  switch (opponentType) {
    case 'TEAM':
      return 'users';
    case 'PLAYER':
      return 'user';
    case 'HORSE':
    case 'DOG':
    default:
      return 'winner';
  }
};

export const getTextByIdFromDropdownOptions = (
  options: Array<{ value: string; text: string }>,
  id: string | null | undefined
) => {
  if (options.length === 0 || !id) return '';
  const item = options.find(({ value }) => id === String(value));
  return item ? item.text : '';
};

export const normalize = <O extends { id: string }>(
  arr: Array<O>
): {
  [key: string]: O;
} =>
  arr.reduce((obj, next) => {
    obj[next.id] = next;
    return obj;
  }, {});

export const usePlayerFactorGroups = () => {
  const { data: playerFactorGroups } = usePlayerFactorGroup({});

  return Array.isArray(playerFactorGroups) && playerFactorGroups.length !== 0
    ? normalize<PlayerFactorGroup>(playerFactorGroups)
    : null;
};

export const usePlayerFactorGroupById = (id: string) => {
  const playerFactorGroups = usePlayerFactorGroups();

  if (!playerFactorGroups) {
    return null;
  }

  return playerFactorGroups[id] || UNKNOWN_PLAYER_FACTOR_GROUP;
};

export const formatPrice = (price: number) => {
  let str = price.toFixed(3);
  if (str[str.length - 1] === '0') {
    str = str.slice(0, str.length - 1);
  }
  return str;
};

export type MarketWithPricesObjectType = {
  id: string;
  description?: string;
  outcomes?: Array<{
    id: string;
    traderPrice?: string;
    description?: string;
  }>;
};

export const calculateMargin = ({ prices }: { prices: Array<number | string> }) => {
  const margin = prices.reduce((accumulator, currentValue) =>
      Number(accumulator) + 1 / Number(currentValue), 0);
  return margin === Infinity ? '' : (Number(margin) * 100 - 100).toFixed(2);
};

export type MarketPricesStateType = Record<string, Record<string, number | string>>;

export const getUpdatedMarketsWithPricesObj = (
  marketsList: Array<MarketWithPricesObjectType>,
  price: string,
  outcomeId: string,
  marketId: string
) => {
  const editingMarket = marketsList?.find(el => el.id === marketId) || { id: marketId };
  const outcomesList = editingMarket?.outcomes || [];

  const editingOutcome = outcomesList.find(o => o.id === outcomeId) || { id: outcomeId };
  editingOutcome.traderPrice = price;

  const updatedOutcomes = outcomesList.filter(el => el.id !== outcomeId);
  updatedOutcomes.push(editingOutcome);

  const updatedList = marketsList.filter(marketObj => marketObj.id !== marketId);
  updatedList.push({ ...editingMarket, outcomes: updatedOutcomes });

  return updatedList;
};

export const isMarketOpeningEnable = (outcomes: OutcomeSinglesByEventType[]) => {
  for (const outcome of outcomes) {
    if (!outcome.currentPrice || outcome.currentPrice === 0) {
      return false;
    }
  }
  return true;
};

export default function useDebounce(value: any, delay: number) {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [ value, delay ]);

  return debouncedValue;
}

export const getFiltersToRender = (event: EventType) => {
  return event.manualCreated ? FILTERS_FOR_MANUAL_EVENTS : FILTERS_FOR_FEED_EVENTS;
};

export const getOptionsForMarketFilters = (
  markets: Array<SingleByEventMarketType>,
  filtersToRender: typeof FILTERS_FOR_MANUAL_EVENTS | typeof FILTERS_FOR_FEED_EVENTS
) => {
  const getZeroCounter = <L extends Array<{ value: string; text: string }>>(list: L) =>
    list.reduce((obj, { value }) => {
      obj[value] = 0;
      return obj;
    }, {});

  const options = {
    [MARKET_BETS_FILTER]: [],
    [MARKET_STATUSES_FILTER]: [],
    [BOOK_TYPES_FILTER]: [],
    [MANUAL_MODES_FILTER]: [],
    [IRM_STATUS_FILTER]: [],
    [BETBUILDER_TYPE_FILTER]: [],
  };

  const counts = {
    [MARKET_BETS_FILTER]: getZeroCounter(MARKET_BETS_OPTIONS),
    [MARKET_STATUSES_FILTER]: getZeroCounter(MARKET_STATUSES_OPTIONS),
    [BOOK_TYPES_FILTER]: getZeroCounter(BOOK_TYPES_OPTIONS),
    [MANUAL_MODES_FILTER]: getZeroCounter(MANUAL_MODE_OPTIONS),
    [IRM_STATUS_FILTER]: getZeroCounter(IRM_STATUSES_OPTIONS),
    [BETBUILDER_TYPE_FILTER]: getZeroCounter(BETBUILDER_TYPE_OPTIONS),
  };

  if (markets != null) {
    for (const market of markets) {
      const {
        outcomes,
        market: { status, bookType },
        marketManualMode,
        marketManualCreated,
        showInIRM,
        isBetBuilderMarket,
      } = market;

      if (outcomes?.some(outcome => outcome.betsCount > 0)) {
        counts[MARKET_BETS_FILTER][MARKET_BETS_OPTIONS_TYPE_ENUM.withBets]++;
      }

      if (isBetBuilderMarket) {
        counts[BETBUILDER_TYPE_FILTER][BETBUILDER_TYPE_ENUM.isBetbuilder]++;
      }

      if (MARKET_STATUS_ENUM[status]) {
        counts[MARKET_STATUSES_FILTER][status]++;
      }

      if (BOOK_TYPES_ENUM[bookType]) {
        counts[BOOK_TYPES_FILTER][bookType]++;
      }

      if (showInIRM) {
        counts[IRM_STATUS_FILTER][IRM_STATUSES_ENUM.showInIrm]++;
      } else {
        counts[IRM_STATUS_FILTER][IRM_STATUSES_ENUM.hideInIrm]++;
      }

      if (marketManualCreated) {
        counts[MANUAL_MODES_FILTER][MANUAL_MODE_ENUM.manualCreated]++;
      } else if (marketManualMode || outcomes?.some(outcome => outcome.manualMode)) {
        counts[MANUAL_MODES_FILTER][MANUAL_MODE_ENUM.manualTrading]++;
      }
    }

    filtersToRender.forEach(name => {
      options[name] = MarketsFilters[name].getOptions(counts);
    });
  }

  return options;
};

export const isSportWithSetsCheck = (sportID: string) => {
  return [ TABLE_TENNIS_SPORT_ID, TENNIS_SPORT_ID, VOLLEYBALL_ID, BEACH_VOLLEYBALL_ID ]
    .includes(sportID);
};

export const isSportWithTiebreak = (sportID: string) => {
  return [ TABLE_TENNIS_SPORT_ID, TENNIS_SPORT_ID, VOLLEYBALL_ID, BEACH_VOLLEYBALL_ID ]
    .includes(sportID);
};

export const isSportWithAddScoring = (sportID: string) => {
  return [ TENNIS_SPORT_ID ].includes(sportID);
};

export const removeEmptyFields = (data: any) => {
  const type = typeof data;

  if (data === null || data === undefined)
    return null;

  if (data instanceof Date)
    return data.toISOString();

  if (Array.isArray(data))
    return data.map(el => removeEmptyFields(el));

  if (type === 'object') {
    const dataKeys = Object.keys(data);
    if (dataKeys.length > 0) {
      const resultObject = Object.create({});

      dataKeys.forEach(key => {
        const value = removeEmptyFields(data[key]);
        if (value !== null) {
          resultObject[key] = value;
        }
      });

      return resultObject;
    }
    return null;
  }

  if (!data && data !== false && String(data) !== '0') {
    return null;
  }

  return data;
};

export const removeEqualsFields = (initialData: any, data: any) => {
  const type = typeof data;

  if (data === null || data === undefined)
    return null;

  if (data instanceof Date) {
    const initialDate = initialData instanceof Date
      ? initialData.toISOString()
      : new Date(initialData).toISOString();
    return data.toISOString() === initialDate
      ? null
      : data;
  }

  if (Array.isArray(data)) {
    let resultArray: any;

    /** Case for limits array in origin settings page */
    if (data[0]?.limitCode === LIMIT_MIN_STAKE) {
      resultArray = [ ...data ]
        .map((limit, index) => {
          let resultLimitsArray = [];

          const initLimitsArray = initialData[index].values;
          const limitsArray = limit.values;

          if (initLimitsArray.length !== limitsArray.length) {
            resultLimitsArray = [ ...limitsArray ];
          } else {
            initLimitsArray.forEach((initLimitItem: any, index: number) => {
              const limitItem = limitsArray[index];

              if (initLimitItem.currencyId !== limitItem.currencyId ||
                String(initLimitItem.value) !== String(limitItem.value)) {
                resultLimitsArray = [ ...limitsArray ];
              }
            });
          }

          return resultLimitsArray.length !== 0
            ? {
              limitCode: limit.limitCode,
              values: resultLimitsArray
            }
            : null;
        })
        .filter(limit => limit);

    /** Case for othersCopyEvents array in event edit page */
    } else {
      resultArray = [ ...data ]
        .map((item, index) => item === initialData[index] ? null : item)
        .filter(item => item);
    }
    return resultArray.length !== 0 ? resultArray : null;
  }

  if (type === 'object') {
    const dataKeys = Object.keys(data);
    if (dataKeys.length > 0) {
      const resultObject = Object.create({});

      dataKeys.forEach(key => {
        const value = removeEqualsFields(initialData[key], data[key]);
        if (value !== null) {
          resultObject[key] = value;
        }
      });

      const resultKeys = Object.keys(resultObject);
      return resultKeys.length !== 0 ? resultObject : null;
    }
    return null;
  }

  if (type === 'boolean' && initialData !== data) {
    return data;
  }

  if (String(initialData) !== String(data)) {
    return String(data);
  }

  return null;
};


export const prepareDataForMarketEditModal = (initialData: any, data: any) => {
  const type = typeof data;

  if (data === null || data === undefined)
    return null;

  if (data instanceof Date) {
    const initialDate = initialData instanceof Date
      ? initialData.toISOString()
      : new Date(initialData).toISOString();
    return data.toISOString() === initialDate
      ? null
      : data;
  }

  if (Array.isArray(data)) {
    if (data.length !== initialData.length) return data;

    const changedItems = data
      .map((item, index) => prepareDataForMarketEditModal(initialData[index], item))
      .filter(item => item);

    return changedItems.length !== 0 ? data : null;
  }

  if (type === 'object') {
    const dataKeys = Object.keys(data);

    if (dataKeys.length > 0) {
      const resultObject = Object.create({});

      dataKeys.forEach(key => {
        const value = prepareDataForMarketEditModal(initialData[key], data[key]);
        if (value !== null) {
          resultObject[key] = value;
        }
      });

      const resultKeys = Object.keys(resultObject);
      return resultKeys.length !== 0 ? resultObject : null;
    }
    return null;
  }

  if (type === 'boolean' && initialData !== data) {
    return data;
  }

  if (String(initialData) !== String(data)) {
    return String(data);
  }

  return null;
};

export function uppDefaultCurrency (array: Array<OriginsLimitValue>): Array<OriginsLimitValue> {
  return [ ...array ].sort(a => {
    if (a.currencyId === CURRENCY_ID_USD) {
      return TURN_LEFT;
    } else {
      return NO_TURN;
    }
  });
}

export function prepareDataForEventEdit (initialData: any, data: any) {
  const dataForRequest = removeEqualsFields(initialData, data);

  if (dataForRequest.isInRunningDelayInherited === false) {
    dataForRequest.inRunningDelay = data.inRunningDelay;
  } else if (dataForRequest.isInRunningDelayInherited === true) {
    delete dataForRequest.inRunningDelay;
  }

  if (dataForRequest.isToleranceRateInherited === false) {
    dataForRequest.toleranceRate = data.toleranceRate;
  } else if (dataForRequest.isToleranceRateInherited === true) {
    delete dataForRequest.toleranceRate;
  }

  return dataForRequest;
}

export function prepareDataForInRunningDelay (values: any) {
  const dataForRequest = { ...values };

  if (dataForRequest.isInRunningDelayInherited === true) {
    delete dataForRequest.inRunningDelay;
  }

  if (dataForRequest.isToleranceRateInherited === true) {
    delete dataForRequest.toleranceRate;
  }

  return dataForRequest;
}

export function getOptionsWithIds (
  options: Array<{[key: string]: string | number}>,
  parentDomId?: string
) {

  return options.map(option => ({
    ...option,
    id: getOptionWithId(option, parentDomId)
  }));
}
export function getOptionWithId (
  option: {[key: string]: string | number},
  parentDomId?: string
) {
  return (parentDomId || 'id') +
      '--' +
    replaceId(String(option.value)) +
      '-option';
}

export function replaceId (id: string) {
  return id.toLowerCase().replace(/\W/g, '-');
}

export function removeExtraSpaces (str: string) {
  if (!str || typeof str !== 'string' || str.length === 0) return str;
  return str.trim().replace(/\s{2,}/g, ' ');
}
