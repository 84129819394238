import React from 'react';

import { $Keys } from 'utility-types';

import { BOOK_TYPES } from '../../../../constants';
import { MarketStatusType, OutcomeSinglesByEventType } from '../../../../types';
import { calculateMargin } from '../../../../utils';
import { canEditMarketCheck, formatMargin, isDynamicMarketCheck } from '../helpers';

export const MarketRowContext = React.createContext<{
  outcomes: Array<OutcomeSinglesByEventType>;
  market: {
    description: string;
    id: string;
    status: MarketStatusType;
    isOutright: boolean;
    periodDescription: string;
    periodId: string;
    typeId: string;
    bookType: $Keys<typeof BOOK_TYPES>;
  };
  canEditMarket: boolean;
  marketBetsCount: number;
  marketBetsCountSingles: number;
  marketBetsTotal: number;
  isDynamicMarket: boolean;
  showTwoOutcomes: boolean;
  toggleShowTwoOutcomes: () => void;
  outcomesToShow: Array<OutcomeSinglesByEventType>;
  outcomesForMargin: Array<OutcomeSinglesByEventType>;
  outcomesQuantity: number;
  marginFirstPrice: string;
  marketManualMode: boolean;
  marketManualCreated: boolean;
  showInIRM: boolean;
  isBetBuilderMarket: boolean;
  isOriginMarginOverride: boolean;
  priceCollection: HTMLDivElement[];
  allPriceCollections: React.MutableRefObject<{ [key: number]: HTMLDivElement[] }>;
  addRef: (element: HTMLDivElement, array: HTMLDivElement[]) => void;
  marketIndex: number;
  marketsLength: number;
}>(null);

export const MarkerRowProvider = ({
  children,
  market,
  outcomes,
  marketManualMode,
  marketManualCreated,
  showInIRM,
  isBetBuilderMarket,
  isOriginMarginOverride,
  marketIndex,
  allRefsCollection,
  marketsLength,
}: {
  children: React.ReactNode;
  market: {
    description: string;
    id: string;
    status: MarketStatusType;
    isOutright: boolean;
    periodDescription: string;
    periodId: string;
    typeId: string;
    bookType: $Keys<typeof BOOK_TYPES>;
  };
  outcomes: Array<OutcomeSinglesByEventType>;
  marketManualMode: boolean;
  marketManualCreated: boolean;
  showInIRM: boolean;
  isBetBuilderMarket: boolean;
  isOriginMarginOverride: boolean;
  marketIndex: number;
  allRefsCollection: React.MutableRefObject<{ [_key: number]: HTMLDivElement[] }>;
  marketsLength: number;
}) => {
  const canEditMarket = canEditMarketCheck(market);

  const marketBetsCount = outcomes.reduce((prev, next) => prev + next.betsCount, 0);
  const marketBetsCountSingles = outcomes.reduce((prev, next) => prev + next.betsCountSingle, 0);
  const marketBetsTotal = outcomes.reduce((prev, next) => prev + next.betsTotal, 0);

  const isDynamicMarket = isDynamicMarketCheck(outcomes);

  const [ showTwoOutcomes, setShowTwoOutcomes ] = React.useState(isDynamicMarket);

  const outcomesToShow = showTwoOutcomes ? outcomes.slice(0, 2) : outcomes;
  const outcomesForMargin = isDynamicMarket ? outcomes.slice(0, 2) : outcomes;

  const outcomesQuantity = outcomesToShow.length;

  const marginFirstPrice = formatMargin(calculateMargin({
    prices: outcomesForMargin.map(outcome => outcome.firstPrice),
  }));

  const value = React.useMemo(() => {
    const toggleShowTwoOutcomes = () => {
      setShowTwoOutcomes(prev => !prev);
    };

    function addToRefsCollection (el, arr) {
      if (el && !arr?.includes(el)) {
        arr.push(el);
      }
    }

    return ({
      outcomes: outcomes,
      market,
      canEditMarket,
      marketBetsCount,
      marketBetsCountSingles,
      marketBetsTotal,
      isDynamicMarket,
      showTwoOutcomes,
      toggleShowTwoOutcomes,
      outcomesToShow,
      outcomesForMargin,
      outcomesQuantity,
      marginFirstPrice,
      marketManualMode,
      marketManualCreated,
      showInIRM,
      isBetBuilderMarket,
      priceCollection: allRefsCollection.current[marketIndex],
      allPriceCollections: allRefsCollection,
      addRef: addToRefsCollection,
      marketIndex,
      marketsLength,
      isOriginMarginOverride,
    });
  },[
    outcomes,
    market,
    canEditMarket,
    marketBetsCount,
    marketBetsCountSingles,
    marketBetsTotal,
    isDynamicMarket,
    showTwoOutcomes,
    setShowTwoOutcomes,
    outcomesToShow,
    outcomesForMargin,
    outcomesQuantity,
    marginFirstPrice,
    marketManualMode,
    marketManualCreated,
    showInIRM,
    isBetBuilderMarket,
    allRefsCollection,
    marketIndex,
    marketsLength,
    isOriginMarginOverride
  ]);

  return <MarketRowContext.Provider value={value}>{children}</MarketRowContext.Provider>;
};

export const useMarketRowContext = () => React.useContext(MarketRowContext);

export const useGetOutcomeByIndex = index => {
  const { outcomesToShow } = useMarketRowContext();
  return outcomesToShow[index];
};
