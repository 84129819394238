import React from 'react';

import { useParams } from 'react-router-dom';
import { Card } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { BetsByUser } from './BetsByUser';
import { PortalUserLink } from './PortalUserLink';
import { UserPerformance } from './UserPerfomance';
import { TableCellPlayerInfo } from '../../components/table-components/TableCellPlayerInfo';
import { API_PLAYER_BY_ID } from '../../constants';
import './UserPage.scss';

export const UserPage = () => {
  const { userId } = useParams();
  const { data } = useAxiosRequest<any>(
    React.useMemo(() => ({
      url: API_PLAYER_BY_ID,
      params: {
        id: userId,
      },
    }), [ userId ])
  );

  if (!data) {
    return null;
  }

  const {
    result: { currency, firstName, lastName, origin, username, id, performance, userFactorGroup },
  } = data;

  return (
    <div className="user-page-container">
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <TableCellPlayerInfo
              parentDomId="user-page--"
              noLink
              firstName={firstName}
              lastName={lastName}
              id={id}
              origin={origin.id}
              username={username}
              userFactorGroupId={userFactorGroup.id}
              floatedLabels
            />
            <PortalUserLink icon id={id}>
              Go to Portal
            </PortalUserLink>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Card.Description>
            {performance && (
              <UserPerformance
                totalBets={performance.totalBets}
                totalTurnover={performance.totalTurnover}
                totalPayout={performance.totalPayout}
                totalProfit={performance.totalProfit}
                totalTurnoverUSD={performance.totalTurnoverUSD}
                totalPayoutUSD={performance.totalPayoutUSD}
                totalProfitUSD={performance.totalProfitUSD}
                totalMargin={performance.totalMargin}
                currency={currency}
              />
            )}
          </Card.Description>
        </Card.Content>
      </Card>
      <BetsByUser userId={userId} />
    </div>
  );
};
