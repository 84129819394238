import React from 'react';

import { useNavigate } from 'react-router-dom';
import { List } from 'semantic-ui-react';

import { ToggleProvider } from './ToggleProvider';
import { EVENT_PATH_TREE_COLORS } from '../../../constants';

const Skeleton = Array.from({ length: 44 }).map((_, i) => i);

export const OpenAccordion = ({
  children,
  noContent,
  parentId,
  isFetching,
}: {
  children: Array<{
    title: React.ReactNode;
    render?: () => React.ReactNode;
    key: string;
  }>;
  noContent?: boolean;
  parentId: string;
  isFetching?: boolean;
}) => {

  if (isFetching && !children?.length) {
    return (
      <>
        {Skeleton.map((_, index) => {
          return <div key={index} className="skeleton" />;
        })}
      </>
    );
  }

  if (!isFetching && !children?.length) {
    return (
      <div>
        No items
      </div>
    );
  }

  return (
    <List>
      {children?.map(({ title, render, key }, index) =>
        noContent ? (
          <List.Item key={key}>
            <List.Content>
              <div className="noContentHeader">{title}</div>
            </List.Content>
          </List.Item>
        ) : (
          <List.Item key={key}>
            <List.Icon
              id={parentId + `item-${index + 1}--` + 'caret-btn'}
              link
              name="caret down"
            />
            <List.Content>
              <div>{title}</div>
              {render()}
            </List.Content>
          </List.Item>
        )
      )}
    </List>
  );
};

export const Accordion = ({
  children,
  label,
  linkPrefix,
  path,
  noContent,
  parentSportId,
  parentRegionId,
  selectedNodes,
  setSelectedNodes,
  parentId,
  isFetching,
}: {
  children: Array<{
    title: React.ReactNode;
    render?: () => React.ReactNode;
    key: string;
  }>;
  label: string;
  linkPrefix: string;
  path: string;
  noContent?: boolean;
  parentSportId?: string;
  parentRegionId?: string;
  selectedNodes: Array<string>;
  setSelectedNodes: (selectedNodes: Array<string>) => void;
  parentId: string;
  isFetching?: boolean;
}) => {
  const navigate = useNavigate();

  const updateSelectedValues = (value: boolean, id: string) => {
    if (!value) {
      setSelectedNodes(selectedNodes.concat([ id ]));
    } else {
      setSelectedNodes(selectedNodes.filter(currendId => currendId !== id));
    }
  };

  const onClick = () => {
    navigate(`${path}/${linkPrefix}/new`, {
      state: {
        sportId: parentSportId || '',
        regionId: parentRegionId || '',
      },
    });
  };

  return (
    <List>
      {label !== 'Sport' && (
        <List.Item>
          <List.Icon name="plus" />
          <List.Content>
            <p
              id={parentId + 'new-label'}
              className="newLink"
              style={{ color: EVENT_PATH_TREE_COLORS[linkPrefix] }}
              onClick={onClick}
            >
              New {label}
            </p>
          </List.Content>
        </List.Item>
      )}
      {isFetching && !children?.length && Skeleton.map((_, index) => {
        return (
          <div key={index} className="skeleton"/>
        );
      })}
      {!isFetching && !children?.length && (
        <div>
          No items in path
        </div>
      )}
      {!isFetching && children?.map(({
        title,
        render,
        key
      }, index) =>
        noContent ? (
          <List.Item key={key}>
            <List.Content>
              <div className="noContentHeader">{title}</div>
            </List.Content>
          </List.Item>
        ) : (
          <ToggleProvider
            key={key}
            defaultValue={selectedNodes?.includes(key)}
          >
            {({ value, toggle }) => {
              return (
                <List.Item>
                  <List.Icon
                    id={parentId + `item-${index + 1}--` + 'caret-btn'}
                    link
                    name={value ? 'caret down' : 'caret right'}
                    onClick={() => {
                      toggle();
                      updateSelectedValues(value, key);
                    }}
                  />
                  <List.Content>
                    <div>{title}</div>
                    {value && render()}
                  </List.Content>
                </List.Item>
              );
            }}
          </ToggleProvider>
        )
      )}
    </List>
  );
};
