import { $Shape } from 'utility-types';

const CLOCK_STATUSES = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  PAUSED: 'PAUSED',
  END_OF_PERIOD: 'END_OF_PERIOD',
  END_OF_EVENT: 'END_OF_EVENT',
  ADJUST: 'ADJUST',
  INTERMISSION: 'INTERMISSION',
  UNKNOWN: 'UNKNOWN',
};

const CLOCK_STATUSES_SHOW_CLOCKS: $Shape<typeof CLOCK_STATUSES> = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  PAUSED: 'PAUSED',
  END_OF_PERIOD: 'END_OF_PERIOD',
  END_OF_EVENT: 'END_OF_EVENT',
  ADJUST: 'ADJUST',
  INTERMISSION: 'INTERMISSION',
};

const CLOCK_STATUSES_PAUSED: $Shape<typeof CLOCK_STATUSES_SHOW_CLOCKS> = {
  PAUSED: 'PAUSED',
  END_OF_PERIOD: 'END_OF_PERIOD',
  END_OF_EVENT: 'END_OF_EVENT',
  ADJUST: 'ADJUST',
  INTERMISSION: 'INTERMISSION',
};

export const getIsClockDisplayed = (status: string) => status in CLOCK_STATUSES_SHOW_CLOCKS;

export const getIsClockPaused = (status: string) => status in CLOCK_STATUSES_PAUSED;

export const getRiskValuesFromRisk = risk => {
  let riskFrom;
  let riskTo;

  if (risk === 'over-25000') {
    riskFrom = 25000;
  } else if (!risk || risk === 'top-100') {
    riskFrom = 0;
  } else {
    [ riskFrom, riskTo ] = risk.split('-');
  }

  return [ riskFrom, riskTo ];
};
