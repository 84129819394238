import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { OutcomeResultLabel } from './OutcomeResultLabel';
import { EVENT_EDIT_PATH, EVENTS_PATH, OutcomeResultsEnum } from '../../constants';
import { formatDateInTable, formatPrice } from '../../utils';
import { FlexDiv } from '../FlexDiv';

export const EventOutcomeInfo = React.memo(
  ({
    sportDescription,
    leagueDescription,
    eventId,
    eventDescription,
    outcomeMarketDescription,
    outcomeMarketPeriodDescription,
    outcomeDescription,
    outcomePrice,
    outcomeId,
    inline,
    result,
    startDate,
    feedCode,
    isEachWay,
    parentDomId,
  }: {
    sportDescription?: string;
    leagueDescription?: string;
    eventDescription: string;
    eventId: string;
    startDate?: string;
    outcomeMarketDescription?: string;
    outcomeMarketPeriodDescription?: string;
    outcomeDescription?: string;
    outcomePrice?: number;
    outcomeId?: string;
    inline?: boolean;
    result?: keyof typeof OutcomeResultsEnum;
    feedCode?: string;
    isEachWay?: boolean;
    parentDomId: string;
  }) => {
    const { pathname } = useLocation();
    return (
      <FlexDiv>
        <FlexDiv.Flex>
          {startDate && (
            <>
              <Icon name="clock outline" /> {formatDateInTable(startDate)}
              <br />
            </>
          )}
          {typeof sportDescription === 'string' && typeof leagueDescription === 'string' && (
            <>
              {sportDescription} / {leagueDescription}
              {!inline && <br />}
            </>
          )}
          <Link
            id={parentDomId + 'event-link'}
            to={`${EVENT_EDIT_PATH}/${eventId}/markets`}
          >
            {eventDescription}
          </Link>{' '}
          {typeof feedCode === 'string' && `(${feedCode})`}
          {typeof eventId === 'string' && pathname === EVENTS_PATH && ` (ID:${eventId})`}
          {
            typeof outcomeId === 'string' &&
            typeof outcomeMarketDescription === 'string' &&
            typeof outcomeDescription === 'string' &&
            typeof outcomeMarketPeriodDescription === 'string' && (
              <>
                {!inline && <br />}
                <div>
                  {outcomeMarketDescription} - {outcomeMarketPeriodDescription}{' '}
                  <Link
                    id={parentDomId + 'events-markets-link'}
                    to={`${EVENT_EDIT_PATH}/${eventId}/markets?outcomeId=${outcomeId}`}
                  >
                    <strong>{outcomeDescription}</strong>
                  </Link>
                  {typeof outcomePrice === 'number' && ` @ ${formatPrice(outcomePrice)}`}
                  {isEachWay && <span>{' Each Way'}</span>}
                </div>
              </>
            )
          }
        </FlexDiv.Flex>
        {result && (
          <FlexDiv.Div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <OutcomeResultLabel size="mini" value={result} />
          </FlexDiv.Div>
        )}
      </FlexDiv>
    );
  }
);
