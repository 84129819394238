import React from 'react';

import { Label } from 'semantic-ui-react';

import { MARKET_STATUSES } from '../../../../constants';

type Props = React.ComponentProps<typeof Label> & {
  status: string;
};

export const MarketStatusLabel = React.memo<Props>(({ status, ...props }: Props) => {
  const { label, color } = MARKET_STATUSES[status] || {
    label: 'Unknown Status',
    color: 'grey',
  };

  return <Label color={color} {...props} >{label}</Label>;
});
