import { FC } from 'react';

import { DropdownComponent, InputComponent } from './SimpleComponents';
import {
  DATE_FILTER_INTERVALS_DEFAULT
} from '../../../../components/search-and-filters-components/QueryStateFilterByPeriod';
import {
  BET_TYPE_DROPDOWN_OPTIONS,
  MAX_STAKE_FILTER,
  MIN_STAKE_FILTER
} from '../../../../constants';
import { usePlayerFactorGroup } from '../../../../react-query/query';
import { BetsTableFiltersEnumNew, BetTypeTypeEnum } from '../../../../types';
import { getOptionsWithIds } from '../../../../utils';
import { SportsDropdown } from '../../../EventPaths/SportsDropdown';
import { DEFAULT_PERIOD } from '../../model/constants';
import { Action, ICompositeFilterComponent, ISportsComponent, IStakeComponent } from '../../model/types';
import cl from '../styles.module.scss';

export const PeriodComponent: FC<ICompositeFilterComponent> = ({
  parentDomId,
  action,
  value,
}) => {

  const onChange: Action = (filterName, data) => {
    action(filterName, data || DEFAULT_PERIOD);
  };

  return (
    <DropdownComponent
      id={parentDomId + 'period-dropdown'}
      value={value as string}
      action={onChange}
      selection
      name={BetsTableFiltersEnumNew.period}
      placeholder="By period"
      fluid
      options={DATE_FILTER_INTERVALS_DEFAULT.map(({ text, value }) => ({
        text,
        value,
      }))}
      clearable={Boolean(value && value !== DEFAULT_PERIOD)}
    />
  );
};

export const PlayerFactorGroupDropdown: FC<ICompositeFilterComponent> = ({
  parentDomId,
  action,
  value,
}) => {

  const { data: playerFactorGroups } = usePlayerFactorGroup({
    refetchInterval: 10000
  });
  const options = playerFactorGroups?.map(group =>
    ({ value: group.id, text: group.shortDescription })) || [];


  return (
    <DropdownComponent
      id={parentDomId + 'factor-groups-dropdown'}
      action={action}
      value={value}
      placeholder="Factor groups"
      multiple
      fluid
      name={BetsTableFiltersEnumNew.factorGroupIds}
      options={getOptionsWithIds(
        options,
        parentDomId + 'factor-groups-dropdown'
      )}
      clearable
    />
  );
};

export const BetTypeDropdowns: FC<ICompositeFilterComponent> = ({
  parentDomId,
  action,
  value,
}) => {

  const betTypeFilterOnChange = (name: BetsTableFiltersEnumNew, newValues: string []) => {
    const all_types = [
      BetTypeTypeEnum.Bet_Single,
      BetTypeTypeEnum.Bet_Multiple,
      BetTypeTypeEnum.Bet_System,
      BetTypeTypeEnum.Bet_BetBuilder,
      BetTypeTypeEnum.Bet_Trader
    ];

    if (newValues?.includes(BetTypeTypeEnum.Bet_Any) || all_types.every(v => newValues.includes(v))) {
      action(name, [ BetTypeTypeEnum.Bet_Any ]);
    } else {
      action(name, newValues);
    }
  };

  return (
    <DropdownComponent
      id={parentDomId + 'bet-type-dropdown'}
      placeholder="Bet Type"
      name={BetsTableFiltersEnumNew.betType}
      fluid
      multiple
      selection
      options={getOptionsWithIds(
        BET_TYPE_DROPDOWN_OPTIONS,
        parentDomId + 'bet-type-dropdown')
      }
      clearable
      closeOnChange
      value={value}
      action={betTypeFilterOnChange}
    />
  );
};

const checkIsAmountValid = v => v === '' || !isNaN(v);

export const StakeFilter: FC<IStakeComponent> = ({
  parentDomId,
  action,
  value,
}) => {

  function onChange (name: BetsTableFiltersEnumNew, val: string) {
    const isValid = checkIsAmountValid(val);
    if (!isValid) return;
    const newValue = {
      ...value,
      [name]: val
    };
    action(BetsTableFiltersEnumNew.stake, newValue);
  }

  return (
    <div className={cl.stakeFilterWrap}>
      <div className={cl.stakeFilterHeader}>
        stake $
      </div>
      <div className={cl.stakeFilterContainer}>
        <InputComponent
          id={parentDomId + 'min-stake-input'}
          action={onChange}
          value={value[MIN_STAKE_FILTER]}
          fluid
          placeholder="min"
          name={MIN_STAKE_FILTER}
        />
        <span>-</span>
        <InputComponent
          id={parentDomId + 'max-stake-input'}
          action={onChange}
          value={value[MAX_STAKE_FILTER]}
          fluid
          placeholder="max"
          name={MAX_STAKE_FILTER}
        />
      </div>
    </div>
  );
};

export const SportsDropdownFilter: FC<ISportsComponent> = ({
  parentDomId,
  action,
  value,
}) => {

  const onChange = (data: string[]) => {
    action(BetsTableFiltersEnumNew.sports, data);
  };

  return (
    <SportsDropdown
      id={parentDomId + 'sport-dropdown'}
      value={value}
      onChange={onChange}
      placeholder="Sports"
      fluid
      multiple
    />
  );
};
