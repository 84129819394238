import React from 'react';

import { useOutletContext } from 'react-router-dom';
import { Checkbox, Table } from 'semantic-ui-react';

import { MarkerRowProvider } from './MarketRowContext';
import { MarketRows } from './MarketRows';
import { TableSkeleton } from '../../../../components/UI/TableSkeleton';
import { TRADER_PERM_ALL, TRADER_PERM_EVENT_EDITOR } from '../../../../constants';
import { useGlobalStateContext } from '../../../../hooks-and-global-states/global-context';
import { useQueryState } from '../../../../hooks-and-global-states/history-hooks';
import { EventOutletContextType, SingleByEventMarketType } from '../../../../types';
import { getFiltersToRender } from '../../../../utils';
import { MarketsFilters } from '../../MarketsFilters';
import './MarketsTable.scss';

export const MarketsTable = ({
  openSidebar,
  selectedMarkets,
  setSelectedMarkets,
  marketsArray,
}: {
  openSidebar: (arg0: React.ReactNode) => void;
  selectedMarkets?: Array<SingleByEventMarketType>;
  setSelectedMarkets?: (selectedMarkets: Array<SingleByEventMarketType>) => void;
  marketsArray: Array<SingleByEventMarketType>;
}) => {
  const { queryState } = useQueryState();
  const { permissions } = useGlobalStateContext();
  const { event } = useOutletContext<EventOutletContextType>();

  const enableEventEditing =
    !permissions?.denyPermissions?.includes(TRADER_PERM_EVENT_EDITOR) &&
    [ TRADER_PERM_EVENT_EDITOR, TRADER_PERM_ALL ].some(permission =>
      permissions?.allowPermissions?.includes(permission)
    );

  const [ sorting, setSorting ] = React.useState<{
    column: 'liability' | 'bets' | null | void;
    direction: 'ascending' | 'descending' | null | void;
  }>({
    column: null,
    direction: null,
  });

  const sortBy = React.useCallback(column => {
    setSorting(state => ({
      column,
      direction: state.direction === 'ascending' ? 'descending' : 'ascending',
    }));
  },[ setSorting ]);

  const sortByLiability = React.useCallback(() => sortBy('liability'), [ sortBy ]);

  const sortByBets = React.useCallback(() => sortBy('bets'), [ sortBy ]);

  const markets = React.useMemo(() => {
    if (!marketsArray || marketsArray.length === 0) {
      return [];
    }

    let markets = [ ...marketsArray ];

    if (sorting.column === 'liability') {
      const sortByOutcomesLiabilityRisk = (a, b) =>
        Math.min(...a.outcomes.map(({ liabilityRiskSingle }) => liabilityRiskSingle)) -
        Math.min(...b.outcomes.map(({ liabilityRiskSingle }) => liabilityRiskSingle));

      markets.sort((a, b) =>
        sorting.direction === 'ascending'
          ? sortByOutcomesLiabilityRisk(a, b)
          : sortByOutcomesLiabilityRisk(b, a)
      );
    } else if (sorting.column === 'bets') {
      const sortByOutcomesBetsTotal = (a, b) =>
        a.outcomes.reduce((prev, next) => prev + next.betsTotal, 0) -
        b.outcomes.reduce((prev, next) => prev + next.betsTotal, 0);

      markets.sort((a, b) =>
        sorting.direction === 'ascending' ? sortByOutcomesBetsTotal(a, b) : sortByOutcomesBetsTotal(b, a)
      );
    }

    const filtersToRender = getFiltersToRender(event);

    filtersToRender.forEach(name => {
      if (queryState[name]) {
        markets = MarketsFilters[name].getter(markets, queryState);
      }
    });

    return markets;
  }, [ queryState, marketsArray, sorting, event ]);

  const toggleSelection = React.useCallback(() => (
    selectedMarkets.length > 0
      ? setSelectedMarkets([])
      : setSelectedMarkets(markets)
  ),[ selectedMarkets, setSelectedMarkets, markets ]);

  const selectMarket = React.useCallback(selectedMarket => {
    let marketsToSelect;
    if (selectedMarkets.find(({ market }) => selectedMarket.id === market.id)) {
      marketsToSelect = selectedMarkets.filter(({ market }) => selectedMarket.id !== market.id);
    } else {
      const market = markets.find(({ market }) => selectedMarket.id === market.id);
      marketsToSelect = market ? [ ...selectedMarkets, market ] : selectedMarkets;
    }

    setSelectedMarkets(marketsToSelect);
  },[ markets, setSelectedMarkets, selectedMarkets ]);

  const allRefsCollection = React.useRef({});

  return (
    <Table compact celled sortable>
      <Table.Header>
        <Table.Row className="market-table__header-row">
          <Table.HeaderCell>
            {enableEventEditing && (
              <Checkbox
                id="event-edit-markets-tab-markets-table-top-checkbox"
                checked={selectedMarkets.length > 0}
                onChange={toggleSelection}
              />
            )}
          </Table.HeaderCell>
          <Table.HeaderCell>Outcome</Table.HeaderCell>
          <Table.HeaderCell>First Price</Table.HeaderCell>
          <Table.HeaderCell>Current Price</Table.HeaderCell>
          <Table.HeaderCell>Result</Table.HeaderCell>
          <Table.HeaderCell>Liability</Table.HeaderCell>
          <Table.HeaderCell
            sorted={sorting.column === 'liability' && sorting.direction ? sorting.direction : null}
            onClick={sortByLiability}
          >
            Liability <br className="br"/>Singles
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sorting.column === 'bets' &&sorting.direction ? sorting.direction : null}
            onClick={sortByBets}
          >
            Stake Singles/#/ <br className="br"/>Avg. Singles
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {!marketsArray ? (
          <TableSkeleton
            rows={10}
            columns={8}
            rowHeight="input"
          />
        ) : (
          markets.map(
            ({
              market,
              outcomes,
              marketManualMode,
              marketManualCreated,
              showInIRM,
              isBetBuilderMarket,
              isOriginMarginOverride,
            }, index) => {
              allRefsCollection.current[index] = [];
              return (
                <MarkerRowProvider
                  key={market.id}
                  outcomes={outcomes}
                  market={market}
                  marketManualMode={marketManualMode}
                  marketManualCreated={marketManualCreated}
                  showInIRM={showInIRM}
                  isBetBuilderMarket={isBetBuilderMarket}
                  marketIndex={index}
                  allRefsCollection={allRefsCollection}
                  marketsLength={markets.length}
                  isOriginMarginOverride={isOriginMarginOverride}
                >
                  <MarketRows
                    event={event}
                    queryOutcomeId={queryState.outcomeId}
                    openSidebar={openSidebar}
                    marketSelected={selectedMarkets?.some(m => m.market.id === market.id)}
                    selectMarket={selectMarket}
                  />
                </MarkerRowProvider>
              );
            }
          )
        )}
      </Table.Body>
    </Table>
  );
};
