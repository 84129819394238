import React from 'react';

import { Button, Grid, Modal } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { getHeadPeriodObj, getPeriodsTree } from './helpers';
import { API_SCORE_UPDATE, TREE_LEVELS } from '../../../constants';
import { PeriodsScoresTreeNodeType, ScoreOpponentForPeriod } from '../../../types';
import useDebounce from '../../../utils';
import './ScoreForPeriodRow.css';

export const ScoreForPeriodRow = ({
  eventId,
  nodeObj,
  nodeStatusesState,
  setNodeStatusesState,
  treeObjState,
  setTreeObjState,
  opponentsInfo,
  treeLevel,
}: {
  eventId: string;
  nodeObj: PeriodsScoresTreeNodeType;
  nodeStatusesState: object;
  setNodeStatusesState: (nodeStatusesState: object) => void;
  treeObjState: PeriodsScoresTreeNodeType;
  setTreeObjState: (treeObj: PeriodsScoresTreeNodeType) => void;
  opponentsInfo: {
    homeOpponent: ScoreOpponentForPeriod;
    awayOpponent: ScoreOpponentForPeriod;
  };
  treeLevel: number;
}) => {
  const periodScores = nodeObj.node;

  const periodName = periodScores.period.split('/')[0].replace('Live', '');
  const homeScore = periodScores.scores[0].homeScore;
  const awayScore = periodScores.scores[0].awayScore;
  const periodId = periodScores.periodId;

  const [ approveEndMatchModal, setApproveEndMatchModal ] = React.useState({
    isOpen: false,
    opponentId: undefined,
  });

  const [ updatingScoreState, setUpdatingScoreState ] = React.useState({
    direction: '',
    opponentId: undefined,
  });

  let iconName: string;

  const isNodeOpened = nodeStatusesState[nodeObj.node.periodId];
  if (isNodeOpened) {
    iconName = 'angle down';
  } else {
    iconName = 'angle right';
  }

  const nodeVisibilityControl = React.useCallback(() => {
    const updatedDict = { ...nodeStatusesState };
    updatedDict[periodId] = !nodeStatusesState[periodId];
    setNodeStatusesState(updatedDict);
  }, [ nodeStatusesState, periodId, setNodeStatusesState ]);

  const onSuccess = React.useCallback(
    data => {
      if (data) {
        const result = data.result;
        const periodsList = result.periodScores;
        const updatedTree = getPeriodsTree(
          getHeadPeriodObj(periodsList).periodId,
          periodsList,
          treeObjState
        );

        setTreeObjState(updatedTree as PeriodsScoresTreeNodeType);
      }
      if (approveEndMatchModal.isOpen) {
        setApproveEndMatchModal({
          isOpen: false,
          opponentId: undefined,
        });
      }
    },
    [ treeObjState, setTreeObjState, approveEndMatchModal, setApproveEndMatchModal ]
  );

  const { update, isFetching } = useAxiosRequest(null, {
    onSuccess,
  });

  const debouncedScoreData = useDebounce(updatingScoreState, 500);

  React.useEffect(() => {
    if (debouncedScoreData.direction) {
      update({
        method: 'PUT',
        url: API_SCORE_UPDATE,
        data: {
          eventId: eventId,
          periodId: nodeObj.node.periodId,
          opponentId: debouncedScoreData.opponentId,
          scoreType: periodScores.scores[0].typeId,
          direction: debouncedScoreData.direction,
        },
      });
      setUpdatingScoreState({
        direction: '',
        opponentId: undefined,
      });
    }
    // eslint-disable-next-line
  }, [debouncedScoreData, eventId, update]);

  const getRowClassname = () => {
    if (treeLevel === TREE_LEVELS.HEADER_LEVEL) {
      return 'scoreboard-header-row';
    } else if (treeLevel === TREE_LEVELS.SECOND_LEVEL) {
      return isNodeOpened ? 'opened_second_level_period_raw' : 'second_level_period_raw';
    } else {
      return 'third_level_period_raw';
    }
  };

  const isActiveThirdLevelPeriod = treeLevel === TREE_LEVELS.THIRD_LEVEL && nodeObj.node.isActive;

  return (
    <Grid.Row key={periodId} className={getRowClassname()}>
      <Grid.Column
        width={6}
        textAlign={'center'}
        className={treeLevel === TREE_LEVELS.HEADER_LEVEL ? 'match_header_points' : ''}
      >
        {(homeScore.canDecrement || homeScore.canIncrement) && (
          <Button
            id="irm--score-button-1"
            size="mini"
            className="score_button"
            icon="minus"
            disabled={!homeScore.canDecrement}
            loading={isFetching}
            onClick={() => {
              setUpdatingScoreState({
                direction: 'down',
                opponentId: opponentsInfo.homeOpponent.id,
              });
            }}
          />
        )}
        <span style={{ color: isActiveThirdLevelPeriod ? '#46A949' : 'black' }}>{homeScore.value}</span>
        {(homeScore.canDecrement || homeScore.canIncrement) && (
          <Button
            id="irm--score-button-2"
            size="mini"
            className="score_button"
            icon="add"
            disabled={!homeScore.canIncrement}
            loading={isFetching}
            onClick={() => {
              homeScore.canEndMatch
                ? setApproveEndMatchModal({
                    isOpen: true,
                    opponentId: opponentsInfo.homeOpponent.id,
                  })
                : setUpdatingScoreState({
                    direction: 'up',
                    opponentId: opponentsInfo.homeOpponent.id,
                  });
            }}
          />
        )}
      </Grid.Column>
      <Grid.Column
        width={4}
        textAlign="center"
        className={treeLevel === TREE_LEVELS.HEADER_LEVEL ? 'match-period-description' : ''}
      >
        {nodeObj.children !== undefined && !(treeLevel === TREE_LEVELS.HEADER_LEVEL) && (
          <Button
            id="irm--score-button-3"
            onClick={nodeVisibilityControl}
            size="mini"
            className={isNodeOpened ? 'opened_node_button' : 'closed_node_button'}
            icon={iconName}
          />
        )}
        <span
          style={{
            color: isActiveThirdLevelPeriod ? '#46A949' : 'black',
          }}
        >
          {periodName}
        </span>
        {nodeObj.children !== undefined && !(treeLevel === TREE_LEVELS.HEADER_LEVEL) && (
          <Button
            id="irm--score-button-4"
            size="mini"
            icon={iconName}
            style={{ visibility: 'hidden' }}
          />
        )}
      </Grid.Column>
      <Grid.Column
        width={6}
        textAlign="center"
        className={treeLevel === TREE_LEVELS.HEADER_LEVEL ? 'match_header_points' : ''}
      >
        {(awayScore.canDecrement || awayScore.canIncrement) && (
          <Button
            id="irm--score-button-5"
            size="mini"
            className="score_button"
            icon="minus"
            disabled={!awayScore.canDecrement}
            loading={isFetching}
            onClick={() => {
              setUpdatingScoreState({
                direction: 'down',
                opponentId: opponentsInfo.awayOpponent.id,
              });
            }}
          />
        )}
        <span
          style={{
            color: isActiveThirdLevelPeriod ? '#46A949' : 'black',
          }}
        >
          {awayScore.value}
        </span>
        {(awayScore.canDecrement || awayScore.canIncrement) && (
          <Button
            id="irm--score-button-6"
            size="mini"
            className="score_button"
            icon="add"
            disabled={!awayScore.canIncrement}
            loading={isFetching}
            onClick={() => {
              awayScore.canEndMatch
                ? setApproveEndMatchModal({
                    isOpen: true,
                    opponentId: opponentsInfo.awayOpponent.id,
                  })
                : setUpdatingScoreState({
                    direction: 'up',
                    opponentId: opponentsInfo.awayOpponent.id,
                  });
            }}
          />
        )}
      </Grid.Column>
      <Modal
        size="tiny"
        open={approveEndMatchModal.isOpen}
        onClose={() => {
          setApproveEndMatchModal({
            isOpen: false,
            opponentId: undefined,
          });
        }}
      >
        <Modal.Header>{'This action will end the event. Are you sure?'}</Modal.Header>
        <Modal.Actions>
          <Button
            id="irm--score-button-7"
            content="Cancel"
            icon="checkmark"
            onClick={() =>
              setApproveEndMatchModal({
                isOpen: false,
                opponentId: undefined,
              })
            }
            negative
          />
          <Button
            id="irm--score-button-8"
            content="Confirm"
            labelPosition="right"
            icon="checkmark"
            onClick={() =>
              setUpdatingScoreState({
                direction: 'up',
                opponentId: approveEndMatchModal.opponentId,
              })
            }
            positive
          />
        </Modal.Actions>
      </Modal>
    </Grid.Row>
  );
};
