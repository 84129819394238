import React from 'react';

import { CLOCK_STATUS_UNKNOWN, STATUS_UNKNOWN } from '../../constants';
import { EventType } from '../../types';

export const EventPeriodStatusBlock = ({ event }:{ event: EventType }) => {
  const clock = event.clock;

  if (clock && ![ STATUS_UNKNOWN, CLOCK_STATUS_UNKNOWN ].includes(clock.status)) {
    return <div style={{ fontWeight: 'bold' }}>{`${clock.period.description} - ${clock.status}`}</div>;
  }

  return <div />;
};
