import React from 'react';

import { Header, Label, SemanticCOLORS } from 'semantic-ui-react';

import { OUTCOME_RESULTS } from '../../constants';

export const OutcomeResultLabel = ({
  value,
  voidReasonLabel,
  simple,
  ...props
}: {
  value: string;
  voidReasonLabel?: {
    description: string;
    value: string;
    id: string;
  } | null;
  simple?: boolean
}) => {
  const { label, color } = OUTCOME_RESULTS[value] || OUTCOME_RESULTS.NO_RESULT;

  if (simple) {
    return (
      <Header
        as='h4'
        color={color as SemanticCOLORS}
        style={{ display: 'inline', fontSize: 'inherit' }}
      >
        {label}
        {voidReasonLabel ? ` (${voidReasonLabel.description})` : ''}
      </Header>
    );
  }

  return (
    <Label color={color as SemanticCOLORS} {...props}>
      {label}
      {voidReasonLabel ? ` (${voidReasonLabel.description})` : ''}
    </Label>
  );
};
