import { ReactNode, SyntheticEvent } from 'react';

import { toast } from 'react-toastify';
import { Dropdown, DropdownItemProps, Grid, Header, List } from 'semantic-ui-react';

import { BetTypeDropdown } from './BetTypeDropdown';
import { BookTypeDropdown } from './BookTypeDropdown';
import { useTraiderVoidReasons } from './helpers';
import {
  QueryDropdownWithAxios,
  SportsQueryDropdownWithAxios
} from '../../components/form-components/DropdownWithAxios';
import { OriginsAccordion } from '../../components/form-components/OriginsAccordion';
import { ClearFiltersButton } from '../../components/search-and-filters-components/ClearFiltersButton';
import {
  PlayerFactorGroupsFilter
} from '../../components/search-and-filters-components/PlayerFactorGroupsFilter';
import {
  QueryStateFilterByAmount
} from '../../components/search-and-filters-components/QueryStateFilterByAmount';
import {
  QueryStateFilterByPeriod
} from '../../components/search-and-filters-components/QueryStateFilterByPeriod';
import {
  QueryStateHideTestAccountsFilter
} from '../../components/search-and-filters-components/QueryStateHideTestAccountsFilter';
import {
  QueryStateCheckbox,
  QueryStateDropdown,
  QueryStateInput
} from '../../components/search-and-filters-components/QueryStateInputs';
import {
  API_MARKET_TYPE_FIND,
  API_PERIOD_FIND,
  BET_ACCEPTED_FILTER,
  BET_ACCEPTED_STATUS_DROPDOWN_OPTIONS,
  BET_ID_FILTER,
  BET_REJECT_REASONS_DROPDOWN_OPTIONS,
  BET_REJECTED_REASON_FILTER,
  BET_STATUS_DROPDOWN_OPTIONS,
  BET_STATUSES_FILTER,
  BET_TYPE_DROPDOWN_OPTIONS,
  BET_TYPE_FILTER,
  BetStatusesEnum,
  BOOK_TYPE_FILTER,
  EVENT_ID_FILTER,
  FACTOR_GROUP_FILTER,
  FILTER_INTERVALS,
  HIDE_TEST_ACCOUNTS_FILTER,
  MARKET_PERIOD_FILTER,
  MARKET_TYPE_FILTER,
  MAX_STAKE_FILTER,
  MIN_STAKE_FILTER,
  ORIGIN_IDS_FILTER,
  PERIOD_FILTER,
  SHOW_ONLY_OUTRIGHTS_FILTER,
  SPORT_FILTER,
  STAKE_FILTER,
  USER_ID_FILTER
} from '../../constants';
import { useQueryState } from '../../hooks-and-global-states/history-hooks';
import {
  useBetsSettle,
  useBetsVoid
} from '../../react-query/mutations';
import { BetsTableFiltersEnum, BetType } from '../../types';
import { getOptionsWithIds, getOptionWithId, removeEmptyFields } from '../../utils';

type FiltersType = Record<
  BetsTableFiltersEnum,
  {
    render: (filterParams?: {
      sportId?: string;
      eventId?: string;
      statusFilterOnChange?: (name: any, data: any) => void
      parentDomId?: string,
      defaultPeriod?: string,
      intervalsOptionsType?: string,
      },
    ) => ReactNode
  }
>

const FILTERS: FiltersType = {
  [USER_ID_FILTER]: {
    render: ({ parentDomId }) => (
      <QueryStateInput
        id={parentDomId + 'account-id-input'}
        fluid
        name={USER_ID_FILTER}
        placeholder="Account Id"
      />
    ),
  },
  [EVENT_ID_FILTER]: {
    render: ({ parentDomId }) => (
      <QueryStateInput
        id={parentDomId + 'event-id-input'}
        fluid
        name={EVENT_ID_FILTER}
        placeholder="Event Id"
      />
    ),
  },
  [BET_TYPE_FILTER]: {
    render: ({ parentDomId }) => (
      <BetTypeDropdown
        id={parentDomId + 'bet-type-dropdown'}
        placeholder="Bet Type"
        options={BET_TYPE_DROPDOWN_OPTIONS}
        name={BET_TYPE_FILTER}
      />
    ),
  },
  [BET_ACCEPTED_FILTER]: {
    render: ({ parentDomId }) => (
      <QueryStateDropdown
        id={parentDomId + 'accepted-dropdown'}
        placeholder="Accepted"
        fluid
        options={BET_ACCEPTED_STATUS_DROPDOWN_OPTIONS}
        name={BET_ACCEPTED_FILTER}
      />
    ),
  },
  [BOOK_TYPE_FILTER]: {
    render: ({ parentDomId }) => (
      <BookTypeDropdown id={parentDomId + 'book-type-dropdown'} selection fluid clearable />
    ),
  },
  [BET_STATUSES_FILTER]: {
    render: ({ statusFilterOnChange, parentDomId }) => (
      <QueryStateDropdown
        id={parentDomId + 'bet-status-dropdown'}
        placeholder="Status"
        multiple
        fluid
        name={BET_STATUSES_FILTER}
        options={getOptionsWithIds(BET_STATUS_DROPDOWN_OPTIONS, parentDomId + 'bet-status-dropdown')}
        onChange={statusFilterOnChange}
      />
    ),
  },
  [STAKE_FILTER]: {
    render: ({ parentDomId }) => (
      <QueryStateFilterByAmount
        id={parentDomId}
        minName={MIN_STAKE_FILTER}
        maxName={MAX_STAKE_FILTER}
        label="stake $"
      />
    ),
  },
  [PERIOD_FILTER]: {
    render: ({
      parentDomId,
      defaultPeriod = FILTER_INTERVALS.month.value,
      intervalsOptionsType
    }) => (
      <QueryStateFilterByPeriod
        id={parentDomId + 'period-dropdown'}
        defaultValue={defaultPeriod}
        intervalsOptionsType={intervalsOptionsType}
      />
    ),
  },
  [SPORT_FILTER]: {
    render: ({ parentDomId }) => (
      <SportsQueryDropdownWithAxios
        id={parentDomId + 'sport-dropdown'}
        fluid
        name="sports"
        multiple
      />
    ),
  },
  [MARKET_TYPE_FILTER]: {
    render: ({ eventId, parentDomId }) =>
      typeof eventId === 'string' && (
        <QueryDropdownWithAxios
          id={parentDomId + 'market-type-dropdown'}
          placeholder="Market Type"
          fluid
          axiosConfig={{
            url: API_MARKET_TYPE_FIND,
            params: { eventId },
          }}
          name={MARKET_TYPE_FILTER}
        />
      ),
  },
  [MARKET_PERIOD_FILTER]: {
    render: ({ sportId, parentDomId }) =>
      typeof sportId === 'string' && (
        <QueryDropdownWithAxios
          id={parentDomId + 'market-period-dropdown'}
          placeholder="Period"
          fluid
          axiosConfig={{
            url: API_PERIOD_FIND,
            params: { sportEventPathId: sportId },
          }}
          name={MARKET_PERIOD_FILTER}
          search
        />
      ),
  },
  [BET_REJECTED_REASON_FILTER]: {
    render: ({ parentDomId }) => (
      <QueryStateDropdown
        id={parentDomId + 'rejected-reason-dropdown'}
        placeholder="Rejected reason"
        multiple
        fluid
        name={BET_REJECTED_REASON_FILTER}
        options={getOptionsWithIds(
          BET_REJECT_REASONS_DROPDOWN_OPTIONS,
          parentDomId + 'rejected-reason-dropdown'
        )}
      />
    ),
  },
  [HIDE_TEST_ACCOUNTS_FILTER]: {
    render: ({ parentDomId }) => <QueryStateHideTestAccountsFilter parentDomId={parentDomId} />,
  },
  [SHOW_ONLY_OUTRIGHTS_FILTER]: {
    render: ({ parentDomId }) => (
      <QueryStateCheckbox
        id={parentDomId + 'only-outrights-checkbox'}
        label="Show only outrights"
        name={SHOW_ONLY_OUTRIGHTS_FILTER}
      />
    ),
  },
  [ORIGIN_IDS_FILTER]: {
    render: ({ parentDomId }) => <OriginsAccordion parentDomId={parentDomId} />,
  },
  [BET_ID_FILTER]: {
    render: ({ parentDomId }) => (
      <QueryStateInput
        id={parentDomId + 'bet-id-input'}
        fluid
        name={BET_ID_FILTER}
        placeholder="Bet Id"
      />
    ),
  },
  [FACTOR_GROUP_FILTER]: {
    render: ({ parentDomId }) => (
      <PlayerFactorGroupsFilter
        parentDomId={parentDomId}
      />
    ),
  },
};

const DEFAULT_FILTER_PARAMS = {};

type TFiltersForBetsTable = {
  refreshBets: () => void;
  selectedBets: Array<BetType>;
  setSelectedBets: (bets: Array<BetType>) => void;
  filterParams?: {
    sportId?: string;
    eventId?: string;
  };
  filters?: Array<keyof typeof BetsTableFiltersEnum>;
  parentDomId: string;
  defaultPeriod: string;
  intervalsOptionsType?: string;
}

export const FiltersForBetsTable = ({
  refreshBets,
  selectedBets,
  setSelectedBets,
  filterParams = DEFAULT_FILTER_PARAMS,
  filters,
  parentDomId,
  defaultPeriod,
  intervalsOptionsType,
}: TFiltersForBetsTable) => {
  const { queryState, setQueryState, setQueryStateWithParams } = useQueryState();
  const voidReasonList = useTraiderVoidReasons();

  const mutBetsSettle = useBetsSettle({
    onSuccess: onSuccess
  });

  const mutVoidBets = useBetsVoid({
    onSuccess: onSuccess,
    onError: () => toast.error('Request error')
  });

  const liveMode = queryState.liveMode == null || !!queryState.liveMode;

  function voidBets (
    _e: SyntheticEvent,
    { value }: DropdownItemProps
  ) {
    const dataForRequest = removeEmptyFields({
      bets: [ ...selectedBets.map(({ id }) => ({ id: id, reason: value })) ],
    });
    mutVoidBets.mutate(dataForRequest);
  }

  function onSuccess () {
    toast.success('Success');
    setSelectedBets([]);
    refreshBets();
  }

  function statusFilterOnChange (
    name: string,
    data: any
  ) {
    const defaultChange = data.includes(BetStatusesEnum.BET_STATUS_FAIL) &&
      !!queryState.betRejectReasons;

    if (defaultChange) {
      setQueryState(name, data);
    } else {
      setQueryStateWithParams({
        [name]: data,
        betRejectReasons: [],
      });
    }
  }

  function settleBets () {
    const idsForRequest = selectedBets.map(({ id }) => id);
    mutBetsSettle.mutate({ ids: idsForRequest });
  }

  return (
    <Grid.Column width="3">
      <Header>Filters</Header>
      <List>
        <List.Item>
          <ClearFiltersButton id={parentDomId + 'bets-filters-clear-btn'} />
        </List.Item>
        {filters.map(filter => (
          <List.Item key={filter}>
            {FILTERS[filter].render({
              ...filterParams,
              statusFilterOnChange,
              parentDomId,
              defaultPeriod,
              intervalsOptionsType,
            })}
          </List.Item>
        ))}
        {!liveMode && (
          <List.Item>
            <Dropdown
              id={parentDomId + 'void-reason-dropdown'}
              labeled
              fluid
              button
              className="icon"
              loading={mutVoidBets.isPending || mutBetsSettle.isPending}
              disabled={liveMode || !selectedBets?.length}
              text={`Action (${selectedBets?.length || '-'} selected bets)`}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  id={parentDomId + 'void-reason-dropdown--settle'}
                  onClick={settleBets}
                >
                  Settle
                </Dropdown.Item>
                <Dropdown.Divider />
                {voidReasonList.map((item: {[key: string]: string}) => (
                  <Dropdown.Item
                    key={item.id}
                    onClick={voidBets}
                    value={item.value}
                    id={getOptionWithId(item, parentDomId + 'void-reason-dropdown')}
                  >
                    {item.description}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </List.Item>
        )}
      </List>
    </Grid.Column>
  );
};
