import React from 'react';

import cx from 'classnames';
import { Checkbox, Table } from 'semantic-ui-react';

import { BETS_COLUMNS } from './Columns';
import { BetsColumnLabelType, BetType } from '../../../types';

type TBetsTableRow = {
  bet: BetType;
  columns: Array<BetsColumnLabelType>;
  refresh: (arg0: void) => void;
  voidReasonList: Array<{
    id: string;
    description: string;
    value: string;
  }>;
  className: string | null;
  isBetSelected: boolean;
  selectBets: (bet: BetType) => void;
  liveMode: boolean;
  showCopyButtons?: {
    userId?: boolean;
    betId?: boolean;
    userName?: boolean;
  };
  parentDomId: string;
}

export const BetsTableRow = React.memo(({
  bet,
  columns,
  refresh,
  voidReasonList,
  className,
  isBetSelected,
  selectBets,
  liveMode,
  showCopyButtons,
  parentDomId,
}: TBetsTableRow) => (
  <Table.Row className={
    cx(
      className,
      { 'firstBetRow': bet.firstBets }
    )}
  >
    {!liveMode && (
      <Table.Cell>
        <Checkbox
          id={parentDomId + 'bet-outcome-checkbox'}
          className="bets-table-row__checkbox"
          checked={isBetSelected}
          onChange={() => selectBets(bet)}
        />
      </Table.Cell>
    )}
    {columns.map(column => {
      return (
        <Table.Cell key={column + bet.id}>
          {BETS_COLUMNS[column].render(
            bet, { refresh, voidReasonList, showCopyButtons, parentDomId }
          )}
        </Table.Cell>
      );
    })}
  </Table.Row>
));
