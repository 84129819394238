import React from 'react';

import { Dropdown } from 'semantic-ui-react';

import { AxiosRequest } from '../../components/fetch-components/AxiosRequest';
import { API_REGIONS_FIND } from '../../constants';
import { replaceId } from '../../utils';

export const RegionsDropdown = ({
  value,
  onChange,
  sportIds,
  id,
}: {
  value: string;
  onChange: (arg0: string) => void;
  sportIds: string | null;
  id: string;
}) => {
  const config = React.useMemo(() => {
    if (sportIds !== '')
    return ({
      url: API_REGIONS_FIND,
      params: {
        description: '',
        sportIds,
        limit: 99999,
      }
    });
    return null;
  },[ sportIds ]);

  return (
    <AxiosRequest
      config={config}
      cache="reload"
      noLoading
    >
      {({ data, isFetching }) => {
        const options = data?.result?.map((region: any) => ({
          text: region.description,
          value: region.id,
          key: region.id,
          id: id + '--' + replaceId(region.id) + '-option'
        })) || [];
        return (
          <Dropdown
            id={id}
            fluid
            search
            selection
            scrolling
            options={options}
            onChange={(_e, { value }) => onChange(String(value))}
            value={value}
            selectOnBlur={false}
            loading={isFetching}
            disabled={sportIds === ''}
          />
        );
      }}
    </AxiosRequest>
  );
};
