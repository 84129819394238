import React from 'react';

import { HeaderMenu } from './HeaderMenu';
import { ErrorBoundary } from '../ErrorBoundary';
import { Sidebar } from '../sidebar-components/Sidebar';

export const Layout = ({
  username,
  logout,
  enableUtilities,
  closeSidebar,
  sidebarContent,
  children,
}: {
  username: string;
  logout: () => void;
  enableUtilities: boolean;
  closeSidebar: () => void;
  sidebarContent: any;
  children: React.ReactNode;
}) => {
  return (
    <>
      <HeaderMenu
        username={username}
        logout={logout}
        enableUtilities={enableUtilities}
      />
      <Sidebar
        closeSidebar={closeSidebar}
        sidebarVisible={!!sidebarContent}
        sidebarContent={sidebarContent}
      />
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </>
  );
};
