import React from 'react';

import moment from 'moment';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Grid, Modal, Loader } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { ControlPanel } from './ControlPanel';
import {
  getActiveRowId,
  getHeadPeriodObj,
  getMarket,
  getPeriodsTree,
  getWidths
} from './helpers';
import { IrmMarkets } from './IrmMarkets';
import { IrmScoreboard } from './IrmScoreboard';
import { MarketsEditor } from './MarketsEditor';
import {
  API_CLOCK_UPDATE,
  API_PERIOD_REVERT,
  API_SCORE_FIND,
  API_SINGLES_BY_EVENT,
  HORIZONTAL_POSITION_SIZE,
  PADDING_SIZE
} from '../../../constants';
import { useGlobalStateContext } from '../../../hooks-and-global-states/global-context';
import {
  EventOutletContextType,
  EventType,
  ModalStateType,
  PeriodsScoresTreeNodeType,
  ScoreForPeriodsType,
  ScoreOpponentForPeriod,
  SingleByEventMarketType,
  SingleByEventResponse
} from '../../../types';
import {
  calculateMargin,
  MarketPricesStateType,
  MarketWithPricesObjectType,
  removeEmptyFields
} from '../../../utils';
import { useEventListener } from '../helpers';

import './InRunningManagerContainer.css';

export const IrmContext = React.createContext([]);

export const IRM = ({
  openSidebar,
}: {
  openSidebar: (node: React.ReactNode) => void;
}) => {
  const { event, eventId } = useOutletContext<EventOutletContextType>();
  const { originId, originIdAllParam } = useGlobalStateContext();
  const [ treeObjState, setTreeObjState ] = React.useState(undefined);
  const [ nodeStatusesState, setNodeStatusesState ] = React.useState({
    length: 0,
  });

  const [ activePeriodState ] = React.useContext(IrmContext);

  const scoresConfig = React.useMemo(
    () => ({
      url: API_SCORE_FIND,
      params: { eventId: eventId, originId: originId },
    }),
    [ eventId, originId ]
  );

  const updatePeriodsTree = React.useCallback(result => {
    const periodsList = result.periodScores;
    let headPeriodId;
    if (periodsList.length !== 0) {
      headPeriodId = getHeadPeriodObj(periodsList).periodId;
    }
    setTreeObjState(getPeriodsTree(headPeriodId, periodsList));
  }, []);

  const updateNodeStatusesState = React.useCallback(
    result => {
      const updatedStatuses = {
        length: result.periodScores.length,
      };
      //We need to open head period in tree. In backend this period is at the
      // beginning of the periods list
      let isHead = true;
      for (const period of result.periodScores) {
        if (period.periodId in nodeStatusesState) {
          updatedStatuses[period.periodId] = nodeStatusesState[period.periodId];
        } else {
          updatedStatuses[period.periodId] = isHead;
        }
        isHead = false;
      }
      return updatedStatuses;
    },
    [ nodeStatusesState ]
  );

  const onSuccess = React.useCallback(
    data => {
      const result = data.result;
      updatePeriodsTree(result);
      if (result.periodScores.length !== nodeStatusesState.length) {
        setNodeStatusesState(updateNodeStatusesState(result));
      }
    },
    [ nodeStatusesState, updateNodeStatusesState, updatePeriodsTree ]
  );

  const { data: scoresData } = useAxiosRequest<{
    result: ScoreForPeriodsType;
  }>(scoresConfig, {
    pollInterval: 3000,
    onSuccess: onSuccess,
  });

  const config = React.useMemo(
    () => ({
      url: API_SINGLES_BY_EVENT,
      params: {
        eventId: event.id,
        originId: originIdAllParam,
      },
    }),
    [ event.id, originIdAllParam ]
  );

  const { data, refresh: marketsRefresh } = useAxiosRequest<{
    result: SingleByEventResponse;
  }>(config, { pollInterval: 5000 });

  const markets = data && data.result ? data.result.markets : null;

  return (
    <>
      {!scoresData || !markets ? (
        <Loader active inline="centered" />
      ) : (
        <InRunningManagerUi
          eventId={eventId}
          event={event}
          markets={markets}
          marketsRefresh={marketsRefresh}
          originId={originId}
          treeObjState={treeObjState}
          setTreeObjState={setTreeObjState}
          opponentsInfo={{
            awayOpponent: scoresData.result.awayOpponent,
            homeOpponent: scoresData.result.homeOpponent,
          }}
          activeRowId={getActiveRowId(scoresData.result, nodeStatusesState, activePeriodState)}
          nodeStatusesState={nodeStatusesState}
          setNodeStatusesState={setNodeStatusesState}
          openSidebar={openSidebar}
        />
      )}
    </>
  );
};

const InRunningManagerUi = ({
  eventId,
  event,
  markets,
  marketsRefresh,
  originId,
  treeObjState,
  setTreeObjState,
  opponentsInfo,
  activeRowId,
  nodeStatusesState,
  setNodeStatusesState,
  openSidebar,
}: {
  eventId: string;
  event: EventType;
  markets: Array<SingleByEventMarketType>;
  marketsRefresh: () => void;
  originId: string;
  treeObjState: PeriodsScoresTreeNodeType;
  setTreeObjState: (treeObjState: PeriodsScoresTreeNodeType) => void;
  opponentsInfo: {
    homeOpponent: ScoreOpponentForPeriod;
    awayOpponent: ScoreOpponentForPeriod;
  };
  activeRowId: string;
  nodeStatusesState: object;
  setNodeStatusesState: (nodeStatusesState: object) => void;
  openSidebar: (node: React.ReactNode) => void;
}) => {
  const [ activePeriodState, setActivePeriodState ] = React.useState({
    // For selected period in table
    selectedPeriod: activeRowId,
    // For current period in lifetime event
    liveActivePeriod: activeRowId,
  });

  const [ modalState, setModalState ] = React.useState({
    showModal: false,
  });

  const [ scoreboardState, setScoreboardState ] = React.useState({
    hide: false,
  });

  const [ editedPricesState, setEditedPricesState ] = React.useState([]);

  const [ eventMarginState, setEventMarginState ] = React.useState(event.marginValue);

  const [ useMarginState, setUseMarginState ] = React.useState(event.isAutoMargin);

  const [ outcomesListState, setOutcomesListState ] = React.useState([]);

  const [ approveEndMatchModal, setApproveEndMatchModal ] = React.useState(false);
  const [ revertPeriodModal, setRevertPeriodModal ] = React.useState(false);

  const widths = getWidths(scoreboardState);

  const [ widthState, setWidthState ] = React.useState({
    scoreboardWidth: widths[0],
    marketsTableWidth: widths[1],
  });

  const getMarketPricesObj = React.useCallback(() => {
    const marketPricesObj = {};
    for (const market of markets) {
      const marketId = market.market.id;
      marketPricesObj[marketId] = {};
      for (const outcome of market.outcomes) {
        marketPricesObj[marketId][outcome.outcome.id] = outcome.currentPrice;
      }
    }
    return marketPricesObj;
  }, [ markets ]);

  // State for storing market outcomes prices. All markets info except this
  // getting regularly with polling (MarketsContext)
  const [ marketPricesState, setMarketPricesState ] = React.useState(getMarketPricesObj());

  const addNewMarketsForMarketPrices = React.useCallback(() => {
    const marketsForAdding = {};
    for (const market of markets) {
      const marketId = market.market.id;
      if (!(marketId in marketPricesState)) {
        marketsForAdding[marketId] = {};
        for (const outcome of market.outcomes) {
          marketsForAdding[marketId][outcome.outcome.id] = outcome.currentPrice;
        }
      }
    }
    setMarketPricesState({
      ...marketPricesState,
      ...marketsForAdding,
    });
  }, [ markets, marketPricesState, setMarketPricesState ]);

  if (markets && markets.length !== Object.keys(marketPricesState).length) {
    // if new markets were added, we need to add them in prices state
    addNewMarketsForMarketPrices();
  }

  const getOutcomesList = () => {
    const outcomesList = [];
    if (markets) {
      for (const market of markets) {
        if (market.market.status === 'SETTLED' || !market.showInIRM) {
          continue;
        }
        for (const outcome of market.outcomes) {
          outcomesList.push(outcome.outcome.id);
        }
      }
    }
    return outcomesList;
  };

  React.useEffect(() => {
    setOutcomesListState(getOutcomesList());
    // eslint-disable-next-line
  }, [markets]);

  const [ selectedOutcomeState, setSelectedOutcomeState ] = React.useState(undefined);

  const getMargin = React.useCallback(
    marketId => {
      const prices = [];
      for (const outcomeId in marketPricesState[marketId]) {
        prices.push(marketPricesState[marketId][outcomeId]);
      }
      return calculateMargin({
        prices: prices,
      });
    },
    [ marketPricesState ]
  );

  const selectOutcome = React.useCallback(
    newOutcomeId => {
      if (selectedOutcomeState && newOutcomeId) {
        const previousMarket = getMarket(selectedOutcomeState, markets);
        const newMarket = getMarket(newOutcomeId, markets);
        if (
          //check if auto calculation of margin is disabled
          !useMarginState &&
          //selecting if market changed
          newMarket.market.id !== previousMarket.market.id &&
          //check previous market in editing
          !!editedPricesState.find(editedMarket => editedMarket.id === previousMarket.market.id) &&
          //previous market has 2 outcomes
          previousMarket.outcomes.length === 2 &&
          //margin <101%
          Number(getMargin(previousMarket.market.id)) < 1
          // TODO check

          // eslint-disable-next-line
        ) {
        }
      }
      setSelectedOutcomeState(newOutcomeId);
    },
    // eslint-disable-next-line
    [selectedOutcomeState, setSelectedOutcomeState, markets, useMarginState, editedPricesState]
  );

  // This keyboard shortcuts are using for switching between market prices \
  // on IRM tab

  const listenerCallback = React.useCallback(
    e => {
      // Switching on the previous market price (up on the page)
      if (e.code === 'Tab' && e.shiftKey) {
        e.preventDefault();
        if (outcomesListState.length > 0) {
          if (!selectedOutcomeState) {
            selectOutcome(outcomesListState[outcomesListState.length - 1]);
          } else {
            const selectedPriceIndex = outcomesListState.indexOf(selectedOutcomeState);
            if (selectedPriceIndex === 0) {
              selectOutcome(outcomesListState[outcomesListState.length - 1]);
            } else {
              selectOutcome(outcomesListState[selectedPriceIndex - 1]);
            }
          }
        }
        // Switching on the next market price (down on the page)
      } else if (e.code === 'Tab') {
        e.preventDefault();
        if (outcomesListState.length > 0) {
          if (!selectedOutcomeState) {
            selectOutcome(outcomesListState[0]);
          } else {
            const selectedPriceIndex = outcomesListState.findIndex(
              outcomeID => outcomeID === selectedOutcomeState
            );
            if (selectedPriceIndex === outcomesListState.length - 1) {
              selectOutcome(outcomesListState[0]);
            } else {
              selectOutcome(outcomesListState[selectedPriceIndex + 1]);
            }
          }
        }
      } else if (e.shiftKey && e.code === 'ArrowUp') {
        e.preventDefault();
        if (outcomesListState.length > 0) {
          selectOutcome(outcomesListState[0]);
        }
      }
    },
    [ selectOutcome, selectedOutcomeState, outcomesListState ]
  );

  useEventListener('keydown', listenerCallback);

  const { update: endEventUpdate, isFetching: endEventIsFetching } = useAxiosRequest(null, {
    onSuccess: () => {
      setApproveEndMatchModal(false);
      toast.success('Event was completely ended');
    },
  });

  const endEvent = () => {
    const clock = event.clock;
    const dataForRequest = removeEmptyFields({
      clockId: clock.id,
      startTime: moment().subtract(12, 'hours').format(),
      offset: clock.offset,
      status: 'END_OF_EVENT',
      periodId: clock.period.id,
    });

    endEventUpdate({
      method: 'PUT',
      url: API_CLOCK_UPDATE,
      data: dataForRequest,
    });
  };

  const { update: revertPeriodUpdate, isFetching: revertPeriodIsFetching } = useAxiosRequest(null, {
    onSuccess: () => {
      setRevertPeriodModal(false);
      toast.success('You have successfully reverted back to previous period');
    },
  });

  const revertPeriod = () => {
    revertPeriodUpdate({
      method: 'PUT',
      url: API_PERIOD_REVERT,
      data: {
        eventId: event.id,
      },
    });
  };

  React.useMemo(() => {
    const widths = getWidths(scoreboardState);
    setWidthState({
      scoreboardWidth: widths[0],
      marketsTableWidth: widths[1],
    });
    //eslint-disable-next-line
  }, [window.innerWidth]);

  return (
    <IrmContext.Provider value={[ activePeriodState, setActivePeriodState ]}>
      {window.innerWidth > HORIZONTAL_POSITION_SIZE ? (
        <Grid
          style={{
            width: widthState.scoreboardWidth + widthState.marketsTableWidth + PADDING_SIZE * 4,
          }}
        >
          {!scoreboardState.hide && (
            <Grid.Column
              className="scoreboard-block"
              style={{ width: widthState.scoreboardWidth }}
            >
              <IrmScoreboard
                opponentsInfo={opponentsInfo}
                treeObjState={treeObjState}
                setTreeObjState={setTreeObjState}
                nodeStatusesState={nodeStatusesState}
                setNodeStatusesState={setNodeStatusesState}
                setApproveEndMatchModal={setApproveEndMatchModal}
                setRevertPeriodModal={setRevertPeriodModal}
                eventId={eventId}
                revertPeriodIsFetching={revertPeriodIsFetching}
                endEventIsFetching={endEventIsFetching}
              />
            </Grid.Column>
          )}
          <Grid.Column
            className="markets-table-block"
            style={{ width: widthState.marketsTableWidth }}
          >
            <MarketsBlock
              modalState={modalState}
              markets={markets}
              marketsRefresh={marketsRefresh}
              setModalState={setModalState}
              scoreboardState={scoreboardState}
              setScoreboardState={setScoreboardState}
              eventMarginState={eventMarginState}
              setEventMarginState={setEventMarginState}
              useMarginState={useMarginState}
              setUseMarginState={setUseMarginState}
              editedPricesState={editedPricesState}
              setEditedPricesState={setEditedPricesState}
              originId={originId}
              eventId={eventId}
              marketPricesState={marketPricesState}
              setMarketPricesState={setMarketPricesState}
              event={event}
              selectedOutcomeState={selectedOutcomeState}
              selectOutcome={selectOutcome}
              openSidebar={openSidebar}
            />
          </Grid.Column>
        </Grid>
      ) : (
        <Grid>
          {!scoreboardState.hide && (
            <Grid.Row className="scoreboard-block">
              <div
                style={{
                  minWidth: widthState.scoreboardWidth,
                  maxWidth: widthState.scoreboardWidth,
                }}
                className="scoreboard-div"
              >
                <IrmScoreboard
                  opponentsInfo={opponentsInfo}
                  treeObjState={treeObjState}
                  setTreeObjState={setTreeObjState}
                  nodeStatusesState={nodeStatusesState}
                  setNodeStatusesState={setNodeStatusesState}
                  setApproveEndMatchModal={setApproveEndMatchModal}
                  setRevertPeriodModal={setRevertPeriodModal}
                  eventId={eventId}
                  revertPeriodIsFetching={revertPeriodIsFetching}
                  endEventIsFetching={endEventIsFetching}
                />
              </div>
            </Grid.Row>
          )}
          <Grid.Row className="markets-table-block">
            <div
              style={{
                minWidth: widthState.marketsTableWidth,
                maxWidth: widthState.marketsTableWidth,
              }}
              className="markets-block-div"
            >
              <MarketsBlock
                modalState={modalState}
                markets={markets}
                marketsRefresh={marketsRefresh}
                setModalState={setModalState}
                scoreboardState={scoreboardState}
                setScoreboardState={setScoreboardState}
                eventMarginState={eventMarginState}
                setEventMarginState={setEventMarginState}
                useMarginState={useMarginState}
                setUseMarginState={setUseMarginState}
                editedPricesState={editedPricesState}
                setEditedPricesState={setEditedPricesState}
                originId={originId}
                eventId={eventId}
                marketPricesState={marketPricesState}
                setMarketPricesState={setMarketPricesState}
                event={event}
                selectedOutcomeState={selectedOutcomeState}
                selectOutcome={selectOutcome}
                openSidebar={openSidebar}
              />
            </div>
          </Grid.Row>
        </Grid>
      )}
      <MarketsEditor
        markets={markets}
        modalState={modalState}
        setModalState={setModalState}
        eventId={eventId}
        sportId={event.sportId}
        originId={originId}
      />
      <Modal
        size="tiny"
        open={approveEndMatchModal}
        onClose={() => {
          setApproveEndMatchModal(false);
        }}
      >
        <Modal.Header>{'This action will end the event. Are you sure?'}</Modal.Header>
        <Modal.Actions>
          <Button
            content="Cancel"
            icon="checkmark"
            onClick={() => setApproveEndMatchModal(false)}
            negative
          />
          <Button
            content="Confirm"
            labelPosition="right"
            icon="checkmark"
            onClick={endEvent}
            positive
          />
        </Modal.Actions>
      </Modal>
      <Modal
        size="tiny"
        open={revertPeriodModal}
        onClose={() => {setRevertPeriodModal(false); }}
      >
        <Modal.Header>
          {'Are you sure to revert back to previous period? It will reset current period'}
        </Modal.Header>
        <Modal.Actions>
          <Button
            content="Cancel"
            icon="checkmark"
            onClick={() => setRevertPeriodModal(false)}
            negative
          />
          <Button
            content="Confirm"
            labelPosition="right"
            icon="checkmark"
            onClick={() => revertPeriod()}
            positive
          />
        </Modal.Actions>
      </Modal>
    </IrmContext.Provider>
  );
};

const MarketsBlock = ({
  modalState,
  markets,
  marketsRefresh,
  setModalState,
  scoreboardState,
  setScoreboardState,
  eventMarginState,
  setEventMarginState,
  useMarginState,
  setUseMarginState,
  editedPricesState,
  setEditedPricesState,
  originId,
  eventId,
  marketPricesState,
  setMarketPricesState,
  event,
  selectedOutcomeState,
  selectOutcome,
  openSidebar,
}: {
  modalState: ModalStateType;
  markets: Array<SingleByEventMarketType>;
  marketsRefresh: () => void;
  setModalState: (modalState: ModalStateType) => void;
  scoreboardState: { hide: boolean };
  setScoreboardState: (scoreboardState: { hide: boolean }) => void;
  eventMarginState: number;
  setEventMarginState: (eventMarginState: number) => void;
  useMarginState: boolean;
  setUseMarginState: (useMarginState: boolean) => void;
  editedPricesState?: Array<MarketWithPricesObjectType>;
  setEditedPricesState?: (editedPricesState: Array<MarketWithPricesObjectType>) => void;
  originId: string;
  eventId: string;
  marketPricesState: MarketPricesStateType;
  setMarketPricesState: (item: MarketPricesStateType) => void;
  event: EventType;
  selectedOutcomeState?: string;
  selectOutcome?: (item: string) => void;
  openSidebar: (node: React.ReactNode) => void;
}) => {
  return (
    <Grid className="markets-block">
      <ControlPanel
        marketsArray={markets}
        marketsRefresh={marketsRefresh}
        modalState={modalState}
        setModalState={setModalState}
        scoreboardState={scoreboardState}
        setScoreboardState={setScoreboardState}
        eventMarginState={eventMarginState}
        setEventMarginState={setEventMarginState}
        useMarginState={useMarginState}
        setUseMarginState={setUseMarginState}
        editedPricesState={editedPricesState}
        setEditedPricesState={setEditedPricesState}
        originId={originId}
        eventId={eventId}
        eventVersion={event.version}
        marketPricesState={marketPricesState}
      />
      <Grid.Row>
        <IrmMarkets
          event={event}
          className="IrmMarketsTable"
          editedPricesState={editedPricesState}
          setEditedPricesState={setEditedPricesState}
          eventMarginState={eventMarginState}
          useMarginState={useMarginState}
          selectedOutcomeState={selectedOutcomeState}
          selectOutcome={selectOutcome}
          marketsArray={markets}
          marketsRefresh={marketsRefresh}
          marketPricesState={marketPricesState}
          setMarketPricesState={setMarketPricesState}
          openSidebar={openSidebar}
        />
      </Grid.Row>
    </Grid>
  );
};
