import React from 'react';

import { Segment } from 'semantic-ui-react';

export const ErrorMessage = ({ children }: { children: React.ReactNode }) => {
  return (
    <Segment inverted color="red">
      {children}
    </Segment>
  );
};
