import React from 'react';

import { useAxiosRequest } from 'use-axios-request';

import { ErrorMessage } from '../../components/UI/ErrorMessage';
import { API_LEAGUES_FIND_BY_ID, API_REGIONS_FIND_BY_ID, API_SPORTS_FIND_BY_ID } from '../../constants';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';

const REQUEST_URLS = {
  sports: API_SPORTS_FIND_BY_ID,
  regions: API_REGIONS_FIND_BY_ID,
  leagues: API_LEAGUES_FIND_BY_ID,
};

export const EditEventPathTreeRequest = ({
  url,
  id,
  children,
}: {
  url: 'sports' | 'regions' | 'leagues';
  children: (arg0: { eventPath: null | any; isFetching: boolean }) => React.ReactNode;
  id: string;
}) => {
  const { originId } = useGlobalStateContext();
  const { error, isFetching, data } = useAxiosRequest<any>(
    React.useMemo(() => ({
      url: REQUEST_URLS[url],
      params: {
        eventPathIds: id,
        originId
      }
    }),[ url, originId, id ])
  );

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  if (!isFetching && !data?.result?.[0]) {
    return (
      <ErrorMessage>
        Event path ({url} {id}) does not exist
      </ErrorMessage>
    );
  }

  return (
    <>
      {children({
        eventPath: isFetching ? null : data.result[0],
        isFetching,
      })}
    </>
  );
};
