import React from 'react';

import moment from 'moment';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Table, Header, Label } from 'semantic-ui-react';
import { useAxiosRequest } from 'use-axios-request';

import { EventOutcomeInfo } from '../../components/table-components/EventOutcomeInfo';
import { ErrorMessage } from '../../components/UI/ErrorMessage';
import { TableSkeleton } from '../../components/UI/TableSkeleton';
import {
  API_EVENTS_FIND,
  ASC,
  MANUAL_MODE_ARRAY,
  MARKET_STATUS_ENUM,
  MARKET_STATUSES_FILTER, START_DATE_PARAM
} from '../../constants';
import { EventListTablRowType } from '../../types';
import { paramsSerializer } from '../../utils';

const UNSETTLED_MARKET_STATUSES_ENUM = [
  MARKET_STATUS_ENUM.OPEN,
  MARKET_STATUS_ENUM.SUSPENDED,
  MARKET_STATUS_ENUM.CLOSED
];

const COMMON = {
  manualMode: MANUAL_MODE_ARRAY.map(({ id }) => id),
  sortBy: START_DATE_PARAM,
  sortDir: ASC,
  startDateFrom: moment().subtract(3, 'years').format(),
};

const MANUAL_EVENTS_AXIOS_CONFIG = {
  url: API_EVENTS_FIND,
  params: {
    ...COMMON,
    limit: 10,
    marketStatus: UNSETTLED_MARKET_STATUSES_ENUM,
  },
  paramsSerializer: (v: any) => qs.stringify(v, { arrayFormat: 'repeat' }),
};

const MANUAL_EVENTS_URL_PARAMS = {
  ...COMMON,
  [MARKET_STATUSES_FILTER]: UNSETTLED_MARKET_STATUSES_ENUM,
};

export const ManualEvents = React.memo(() => {
  const { data, isFetching, error } = useAxiosRequest<any>(MANUAL_EVENTS_AXIOS_CONFIG);

  return (
    <>
      <Header>
        <Link to={`/events?${paramsSerializer(MANUAL_EVENTS_URL_PARAMS)}`}>
          Earliest 10 Manual Events with Unsettled markets
        </Link>
      </Header>
      {error ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Event Info</Table.HeaderCell>
              <Table.HeaderCell>Manual Mode</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {isFetching ? (
              <TableSkeleton
                rows={10}
                columns={3}
                rowHeight={3}
              />
            ) : (
              (data.result as Array<EventListTablRowType>).map(
                ({
                  id,
                  sportDescription,
                  leagueDescription,
                  description,
                  manualMode,
                  startDate,
                  feedCode,
                }) => {
                  return (
                    <Table.Row key={id}>
                      <Table.Cell>{id}</Table.Cell>
                      <Table.Cell>
                        <EventOutcomeInfo
                          parentDomId={`manual-events--event-${id}--`}
                          sportDescription={sportDescription}
                          leagueDescription={leagueDescription}
                          eventId={id}
                          eventDescription={description}
                          startDate={startDate}
                          feedCode={feedCode}
                        />
                      </Table.Cell>

                      <Table.Cell>
                        {manualMode.map(m => {
                          const mode = MANUAL_MODE_ARRAY.find(({ id }) => id === m);
                          return (
                            mode && (
                              <Label key={m} size="tiny">
                                {mode.description}
                              </Label>
                            )
                          );
                        })}
                      </Table.Cell>
                    </Table.Row>
                  );
                }
              )
            )}
          </Table.Body>
        </Table>
      )}
    </>
  );
});
