import { Component, ReactNode } from 'react';

import Raven from 'raven-js';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import './ErrorBoundary.scss';

export class ErrorBoundary extends Component<
  { children: ReactNode },
  { error: Error | null | undefined; locationHref: string }
> {
  state = {
    error: null,
    locationHref: '',
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    if (process.env.NODE_ENV === 'production') {
      Raven.captureException(error, { extra: errorInfo });
    }

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line
      console.log(errorInfo);
    }
  }

  componentDidMount() {
    if (this.state.locationHref !== location.href) {
      this.setState({
        error: null,
        locationHref: location.href,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.locationHref !== location.href) {
      this.setState({
        error: null,
        locationHref: location.href,
      });
    }
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <Segment placeholder className="error-boundary__wrap">
          <Header icon>
            <Icon name="warning circle" />
          </Header>
          <div className="error-boundary__body">
            Something went wrong while displaying this page. Please reload or visit a different page to
            continue.
            <Button
              primary
              className="error-boundary__reload-button"
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </Button>
          </div>
        </Segment>
      );
    }

    return this.props.children;
  }
}
