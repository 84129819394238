import { memo } from 'react';

import { Table } from 'semantic-ui-react';

import { Money } from '../../../../components/table-components/Money';
import { ITotalsRow } from '../../model/types';

export const TotalsRow = memo<ITotalsRow>(({
  totalBets,
  totalRevenue,
  totalProfit,
  totalPayout,
  totalMargin
}) => {
  return (
    <Table.Row active>
      <Table.Cell className="number-table-cell">Period Total</Table.Cell>
      <Table.Cell className="number-table-cell">{totalBets}</Table.Cell>
      <Table.Cell className="number-table-cell">
        <Money>{totalRevenue}</Money>
      </Table.Cell>
      <Table.Cell className="number-table-cell">
        <Money>{totalPayout}</Money>
      </Table.Cell>
      <Table.Cell className="number-table-cell">
        <Money>{totalProfit}</Money>
      </Table.Cell>
      <Table.Cell className="number-table-cell">{totalMargin}%</Table.Cell>
    </Table.Row>
  );
});
