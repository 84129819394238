import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { isEqual } from 'lodash';
import { Accordion, Button, Checkbox, Dropdown, Icon, Input, List, Menu } from 'semantic-ui-react';

import { createOriginsBlock, getOriginsDescriptions } from './utils';
import { useGlobalStateContext } from '../../hooks-and-global-states/global-context';
import { useOutsideClick } from '../../hooks-and-global-states/hooks';
import { OriginType } from '../../types';
import { Loading } from '../UI/Loading';

import './OriginsAccordion.scss';

type OriginsAccordionNew = {
  // horizontal?: boolean;
  // multiChoice?: boolean;
  disable?: boolean;
  // withSubOrigins?: boolean;
  withDefaultOrigin?: boolean;
  className?: string;
  icon?: string;
  parentDomId: string;
  originsParentState: string[] | string;
  originAction: (origins: string[]) => void;
  type: 'multi-origins' | 'simple-dropdown';
  highlightChangedOrigin?: boolean;
  fullWidth?: boolean;
};

type Component = Omit<OriginsAccordionNew, 'type'> & {
  originsData: OriginType[]
}

export const OriginsAccordion: FC<OriginsAccordionNew> = memo(({
  // horizontal = false,
  // multiChoice = true,
  disable,
  // withSubOrigins = true,
  withDefaultOrigin,
  className,
  icon,
  parentDomId,
  originAction,
  originsParentState,
  type = 'multi-origins',
  highlightChangedOrigin,
  fullWidth,
}) => {
  const { originsList } = useGlobalStateContext();

  if (!originsList?.length) return <Loading />;

  if (type === 'multi-origins') {
    return (
      <MultiOriginComponent
        originsData={originsList}
        // horizontal={horizontal}
        // multiChoice={multiChoice}
        // disable={disable}
        // withSubOrigins={withSubOrigins}
        // withDefaultOrigin={withDefaultOrigin}
        className={className}
        // icon={icon}
        parentDomId={parentDomId}
        originAction={originAction}
        originsParentState={originsParentState}
      />
    );
  }

  if (type === 'simple-dropdown') {
    return (
      <SimpleDropdown
        originsData={originsList}
        // horizontal={horizontal}
        // multiChoice={multiChoice}
        disable={disable}
        // withSubOrigins={withSubOrigins}
        withDefaultOrigin={withDefaultOrigin}
        className={className}
        icon={icon}
        parentDomId={parentDomId}
        originAction={originAction}
        originsParentState={originsParentState}
        highlightChangedOrigin={highlightChangedOrigin}
        fullWidth={fullWidth}
      />
    );
  }
});

const SimpleDropdown: FC<Component> = ({
  originsData,
  disable,
  withDefaultOrigin,
  className,
  icon,
  parentDomId,
  originAction,
  originsParentState,
  highlightChangedOrigin,
  fullWidth,
}) => {
  const filterOrigins = useMemo(() => (
    createOriginsBlock(originsData, withDefaultOrigin)
  ),[ originsData, withDefaultOrigin ]);

  const singleChoiceDropdownOptions = useMemo(() => {
    return filterOrigins.map((collection, index) => ({
      key: index,
      text: `${collection.description || '-'} (${collection?.list[0]?.id || '-'})`,
      value: collection?.list[0]?.id || index,
      content: (
        <div key={collection.description + collection?.list[0]?.id || index}>
          {highlightChangedOrigin && <div className="origins-accordion__circle" />}
          <p className="dropdown-checkbox">
            {collection.description || '-'} ({collection?.list[0]?.id || '-'})
          </p>
        </div>
      ),
    }));
  }, [ filterOrigins, highlightChangedOrigin ]);

  function onChangeSingleChoiceDropdown(_e: any, data: any) {
    const parentOriginId = String(data.value);
    originAction([ parentOriginId ]);
  }

  return (
    <div
      className={cx(
        'origins-accordion-wrapper',
        !fullWidth && 'origins-accordion-wrapper_compact',
        className
      )}
    >
      <Dropdown
        id={parentDomId + 'origin-dropdown'}
        className={`origins-accordion__input ${icon ? 'icon' : ''} searching-dropdown`}
        selection
        fluid
        onChange={onChangeSingleChoiceDropdown}
        value={originsParentState}
        options={singleChoiceDropdownOptions}
        disabled={disable}
        icon={icon}
        labeled={!!icon}
        button={!!icon}
        search
      />
    </div>
  );
};

const MultiOriginComponent: FC<Component> = ({
  originsData,
  className,
  parentDomId,
  originAction,
  originsParentState,
}) => {
  const accRef = useRef();
  useOutsideClick(accRef, closeAccordion);

  const filterOrigins = createOriginsBlock(originsData, false);

  const [ localActiveOrigins, setLocalActiveOrigins ] = useState(getOriginsInitialState);
  const [ isTopAccordionOpen, setIsTopAccordionOpen ] = useState(false);
  const [ accordionActiveIndexes, setAccordionActiveIndexes ] = useState([]);

  const isAllOriginsIncludes = originsData.every(el => localActiveOrigins?.includes(el.id));
  const originsDescriptions = getOriginsDescriptions(
    localActiveOrigins as string[],
    originsData,
    isAllOriginsIncludes
  );
  const isRequestBtnDisable =
    isEqual(localActiveOrigins, originsParentState) || !localActiveOrigins?.length;
  const wrapClasses = cx(
    'origins-accordion-wrapper',
    className
  );

  useEffect(() => {
    if (
      !isEqual(localActiveOrigins, originsParentState) &&
      originsParentState.length !== 0
    ) {
      setLocalActiveOrigins(originsParentState);
    }
  // eslint-disable-next-line
  }, [ originsParentState ]);

  useEffect(() => {
    if (
      isAllOriginsIncludes &&
      !isEqual(localActiveOrigins, originsParentState)
    ) {
      originAction([]);
    }
  // eslint-disable-next-line
  }, [ isAllOriginsIncludes ]);

  function showOriginsList () {
    setIsTopAccordionOpen(prev => !prev);
  }

  function onChangeShowAllOriginsCheckbox () {
    if (isAllOriginsIncludes) {
      setLocalActiveOrigins([]);
    } else {
      const allOriginsList = originsData.map(el => el.id);
      setLocalActiveOrigins(allOriginsList);
      originAction([]);
    }
  }

  function saveOriginsToGlobalState () {
    originAction(localActiveOrigins as string[]);
  }

  function closeAccordion () {
    setIsTopAccordionOpen(false);
    setAccordionActiveIndexes([]);
  }

  function getOriginsInitialState () {
    if (!originsParentState.length) {
      return originsData.map(el => el.id);
    } else {
      return originsParentState;
    }
  }

  return (
    <div className={wrapClasses}>
      <Input className="origins-accordion__input" disabled value={originsDescriptions} />
      <List horizontal>
        <List.Item>
          <div ref={accRef}>
            <Accordion className="origins-top-accordion" exclusive={false}>
              <Accordion.Title className="origins-top-accordion__title" active={isTopAccordionOpen}>
                <Icon
                  id={parentDomId + 'show-origins-list-btn'}
                  name="dropdown"
                  onClick={showOriginsList}
                />
                <Checkbox
                  id={parentDomId + 'show-all-origins-checkbox'}
                  label="Show all origins"
                  checked={isAllOriginsIncludes}
                  onChange={onChangeShowAllOriginsCheckbox}
                />
                <div className="origins-top-accordion__title-button-wrapper">
                  {!isAllOriginsIncludes && (
                    <Button
                      id={parentDomId + 'save-origins-btn'}
                      icon
                      size="small"
                      disabled={isRequestBtnDisable}
                      onClick={saveOriginsToGlobalState}
                      className="origins-top-accordion__title-button"
                    >
                      <Icon name="save" />
                    </Button>
                  )}
                </div>
              </Accordion.Title>

              <Accordion.Content className="origins-accordion" active={isTopAccordionOpen}>
                <Accordion.Accordion exclusive={false} as={Menu} vertical>
                  {filterOrigins.map((collection, index) => (
                    <AccordionsCollection
                      key={collection.description + collection?.list[0]?.id || index}
                      parentDomId={parentDomId + 'origin-' + collection?.list[0]?.id + '--'}
                      collection={collection}
                      originGroupIndex={index}
                      localActiveOrigins={localActiveOrigins as string[]}
                      accordionActiveIndexes={accordionActiveIndexes}
                      setAccordionActiveIndexes={setAccordionActiveIndexes}
                      setLocalActiveOrigins={setLocalActiveOrigins}
                      // multiChoice={multiChoice}
                      // withSubOrigins={withSubOrigins}
                      originsParentState={originsParentState as string[]}
                    />
                  ))}
                </Accordion.Accordion>
              </Accordion.Content>
            </Accordion>
          </div>
        </List.Item>
      </List>
    </div>
  );
};

interface IAccordionCollection {
  collection: {
    description: string,
      parentId: string,
      list: OriginType[]
  };
  originGroupIndex: number;
  localActiveOrigins: string[];
  accordionActiveIndexes: number[];
  setAccordionActiveIndexes: (arr: (prev: string[]) => any | string[]) => void;
  setLocalActiveOrigins: (arr: (prev: string[]) => any | string[]) => void;
  // multiChoice?: boolean;
  withSubOrigins?: boolean;
  parentDomId: string;
  originsParentState: string[];
}

const AccordionsCollection: FC<IAccordionCollection> = ({
  collection,
  originGroupIndex,
  localActiveOrigins,
  accordionActiveIndexes,
  setAccordionActiveIndexes,
  setLocalActiveOrigins,
  parentDomId,
  originsParentState,
}) => {
  const categoriesIds = collection?.list?.map(origin => origin.id);
  const parentOriginId = collection?.list[0]?.id;
  const collectionLabel = `${collection.description || '-'} (${parentOriginId || '-'})`;

  const isAllCategoriesIdsIncludes = categoriesIds.every(el => localActiveOrigins?.includes(el));
  const isAllCategoriesIdsIncludesInQueryState = categoriesIds.every(el =>
    originsParentState.includes(el) || !originsParentState
  );
  const isOriginsGroupChecked = isAllCategoriesIdsIncludes;
  const isOneItemIncludesInQueryState = categoriesIds?.some(el => originsParentState.includes(el));

  const LayoutComponent =  Menu.Item;

  const className = cx({
    'origins-top-accordion__item_active' : isAllCategoriesIdsIncludesInQueryState,
    'origins-top-accordion__item_active-light' : isOneItemIncludesInQueryState &&
      !isAllCategoriesIdsIncludesInQueryState,
  });

  function onClickAccordionSection (e: any, data: any) {
    e.stopPropagation();
    if (accordionActiveIndexes?.includes(data.index)) {
      setAccordionActiveIndexes(prev => prev.filter(el => el !== data.index));
    } else {
      setAccordionActiveIndexes(prev => [ ...prev, data.index ]);
    }
  }

  function changeOrigin (_e: any, data: any) {
    if (localActiveOrigins?.includes(data.value)) {
      setLocalActiveOrigins(prev => prev.filter(el => el !== data.value).sort());
    } else {
      setLocalActiveOrigins(prev => [ ...prev, data.value ].sort());
    }
  }

  function addOriginsGroup () {
    if (isAllCategoriesIdsIncludes) {
      setLocalActiveOrigins(prev => prev.filter(el => categoriesIds.indexOf(el) < 0).sort());
    } else {
      setLocalActiveOrigins( prev =>
        prev
          .concat(categoriesIds)
          .filter((value, index, array) => array.indexOf(value) === index)
          .sort()
      );
    }
  }

  return (
    <LayoutComponent className={className}>
      <Accordion.Title
        active={accordionActiveIndexes?.includes(originGroupIndex)}
        index={originGroupIndex}
        className="origins-accordion__title"
      >
        {collection?.list?.length > 1 && (
          <Button
            id={parentDomId + 'show-sub-origins-btn'}
            index={originGroupIndex}
            onClick={onClickAccordionSection}
            icon="dropdown"
            compact
            circular
          />
        )}
        <Checkbox
          id={parentDomId + 'sub-origins-collection-checkbox'}
          className="dropdown-checkbox"
          label={collectionLabel}
          checked={isOriginsGroupChecked}
          onChange={addOriginsGroup}
        />
      </Accordion.Title>

      <Accordion.Content active={accordionActiveIndexes?.includes(originGroupIndex)}>
        {!!collection?.list?.length &&
          collection.list.map(origin => {
            const isOriginIncludesInLocalState = localActiveOrigins?.includes(origin.id);
            const checkboxLabel = `${origin.description || '-'} (${origin.id || '-'})`;
            return (
              <div key={origin.id} className="origins-accordion__item">
                <Checkbox
                  key={origin.id}
                  id={parentDomId + `sub-origin-${origin.id}-checkbox`}
                  label={checkboxLabel}
                  checked={isOriginIncludesInLocalState}
                  value={origin.id}
                  onChange={changeOrigin}
                />
              </div>
            );
          })}
      </Accordion.Content>
    </LayoutComponent>
  );
};
