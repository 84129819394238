import React from 'react';

export class ReloadTickerProvider extends React.Component<
  {
    children: (arg0: { reloadTicker: number; reload: () => void }) => React.ReactNode;
  },
  {
    reloadTicker: number;
  }
> {
  state = {
    reloadTicker: 0,
  };

  reload = () => {
    this.setState(prevState => ({
      reloadTicker: prevState.reloadTicker + 1,
    }));
  };

  render() {
    return this.props.children({
      reloadTicker: this.state.reloadTicker,
      reload: this.reload,
    });
  }
}
