import React, { FC, Fragment, SyntheticEvent, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Dropdown,
  DropdownItemProps,
  Header,
  Icon,
  Popup,
  SemanticCOLORS
} from 'semantic-ui-react';

import cl from './styles.module.scss';
import { MoneyWithUserCurrency } from '../../../components/table-components/Money';
import { OutcomeResultLabel } from '../../../components/table-components/OutcomeResultLabel';
import {
  TableCellGroupInfoNew,
  TableCellPlayerInfoNew
} from '../../../components/table-components/TableCellPlayerInfo';
import { TextWithCopyButton } from '../../../components/table-components/TextWithCopyButton';
import {
  BET_REJECT_REASONS,
  BET_STATUSES,
  BetsSortByEnum,
  BetStatusesEnum,
  EVENT_EDIT_PATH,
  OutcomeResultsEnum
} from '../../../constants';
import { DateTime } from '../../../hooks-and-global-states/time-hooks';
import { useBetsSettle, useBetsVoid } from '../../../react-query/mutations';
import { BetType, IOutcomeInfo } from '../../../types';
import { formatPrice, getOptionWithId, removeEmptyFields } from '../../../utils';
import { useBetsActions, useBetsStore } from '../lib/reducer';
import { BetsColumnEnum } from '../model/constants';
import { IBetsColumn } from '../model/types';

export const BET_CELL_CREATOR: Record<BetsColumnEnum, IBetsColumn> = {
  [BetsColumnEnum.Type]: {
    classes: '',
    columnSortBy: null,
    render: (bet, { showCopyButtons }) => {
      function getBetType(bet: BetType) {
        const { type, traderName } = bet;

        if (type === 'System') {
          return <>Multiple (System)</>;
        }

        if (type === 'Single' && traderName) {
          return (
            <>
              <div>{'Single'}</div>
              <div>{`Trader: ${bet.traderName}`}</div>
            </>
          );
        }

        return <>{bet.type}</>;
      }

      const BetType = () => getBetType(bet);
      return (
        <>
          {showCopyButtons?.betId && <TextWithCopyButton value={bet.id} type="betId" />}
          <BetType />
          {bet.firstBets && <p style={{ color: 'purple' }}>First</p>}
        </>
      );
    },
  },
  [BetsColumnEnum.Date]: {
    classes: '',
    columnSortBy: BetsSortByEnum.BET_SORT_DATE,
    render: bet => (
      <div className="bets__date-cell">
        <DateTime format="DD-MM-YYYY">{bet.date}</DateTime>
        <br />
        <DateTime format="HH:mm:ss">{bet.date}</DateTime>
      </div>
    ),
  },
  [BetsColumnEnum.Player]: {
    classes: 'player-cell',
    columnSortBy: null,
    render: (bet, { showCopyButtons, parentDomId }) => (
      <TableCellPlayerInfoNew
        firstName={bet.user.firstName}
        lastName={bet.user.lastName}
        username={bet.user.username}
        id={bet.user.id}
        showCopyButtons={showCopyButtons}
        parentDomId={parentDomId}
      />
    ),
  },
  [BetsColumnEnum.Group]: {
    classes: '',
    columnSortBy: null,
    render: (bet: BetType) => (
      <TableCellGroupInfoNew
        origin={bet.user.origin.id}
        userFactorGroupId={bet.user.factorGroup && bet.user.factorGroup.id}
      />
    ),
  },
  [BetsColumnEnum.Outcomes]: {
    classes: '',
    columnSortBy: null,
    render: (bet, { parentDomId, withFilters, activeColumnsList }) => (
      <OutcomeCell
        parentDomId={parentDomId}
        bet={bet}
        withFilters={withFilters}
        activeColumnsList={activeColumnsList}
      />
    ),
  },
  [BetsColumnEnum.StakeUSD]: {
    classes: '',
    columnSortBy: BetsSortByEnum.BET_SORT_STAKE,
    render: (bet: BetType) => <div>{bet.stakeUSD}</div>,
  },
  [BetsColumnEnum.Stake]: {
    classes: '',
    columnSortBy: BetsSortByEnum.BET_SORT_STAKE_LOCAL,
    render: bet => (
      <MoneyWithUserCurrency currency={bet.user.currency}>
        {bet.stake}
      </MoneyWithUserCurrency>
    ),
  },
  [BetsColumnEnum.Price]: {
    classes: '',
    columnSortBy: BetsSortByEnum.BET_SORT_PRICE,
    render: bet => {
      function getPriceFromOutcomes (outcomes: BetType['outcomes']) {
        let price = 1;
        for (const outcome of outcomes) {
          if (outcome.result !== OutcomeResultsEnum.VOID) {
            price = price * outcome.price;
          }
        }
        return price;
      }
      return (
        <>
          {formatPrice(getPriceFromOutcomes(bet.outcomes))}
        </>
      );
    },
  },
  [BetsColumnEnum.WinningUSD]: {
    classes: '',
    columnSortBy: BetsSortByEnum.BET_SORT_PAYOUT,
    render: (bet: BetType) => <div>{bet.payoutUSD}</div>,
  },
  [BetsColumnEnum.Winning]: {
    classes: '',
    columnSortBy: BetsSortByEnum.BET_SORT_PAYOUT_LOCAL,
    render: bet => (
      <MoneyWithUserCurrency currency={bet.user.currency}>{bet.payout}</MoneyWithUserCurrency>
    ),
  },
  [BetsColumnEnum.Status]: {
    classes: '',
    columnSortBy: null,
    render: (bet, { voidReasonList }) => {
      const { label, color } = BET_STATUSES[bet.status] || {
        label: 'unknown',
        color: 'grey',
      };

      return (
        <div className="bets__status-cell">
          <Header
            className="status-cell--title"
            as="h5"
            color={color as SemanticCOLORS}
          >
            {label}
          </Header>
          {bet.status === BetStatusesEnum.BET_STATUS_FAIL && (
            <>
              <Popup
                trigger={
                  <div className={cl.rejectReason}>
                    ({BET_REJECT_REASONS[bet.rejectReason] ||
                    BET_REJECT_REASONS['BET_REASON_OTHER']})
                  </div>
                }
                content={bet.rejectReasonDescription}
                hideOnScroll
              />
            </>
          )}
          {bet.status === BetStatusesEnum.BET_STATUS_TRADER_VOID && (
            <>
              <span>
                ({
                  voidReasonList
                    .find(v => v.value === bet.voidReason)
                    ?.description || 'Unknown reason'
                })
              </span>
              <span className="status-cell--trader">
                {bet.traderVoidName}
              </span>
            </>
          )}
        </div>
      );
    },
  },
  [BetsColumnEnum.Actions]: {
    classes: '',
    columnSortBy: null,
    render: (bet, { voidReasonList, parentDomId }) => {
      if (
        bet.status !== BetStatusesEnum.BET_STATUS_TRADER_VOID &&
        bet.status !== BetStatusesEnum.BET_STATUS_PORTAL_MANUAL_PAYOUT &&
        !bet.isSettledByPortal &&
        bet.status !== BetStatusesEnum.BET_STATUS_FAIL &&
        bet.status !== BetStatusesEnum.BET_STATUS_VOID
      )
        return (
          <VoidBetButtonNew
            betId={bet.id}
            voidReasonList={voidReasonList}
            parentDomId={parentDomId + 'void-bet--'}
          />
        );

      if (bet.isSettledByPortal || bet.status === BetStatusesEnum.BET_STATUS_PORTAL_MANUAL_PAYOUT)
        return <Header as="h4" color="purple" content="Portal" />;
    },
  },
};


const OutcomeCell = ({
  bet,
  parentDomId,
  withFilters,
  activeColumnsList,
}: {
  bet: BetType;
  parentDomId: string;
  withFilters: boolean;
  activeColumnsList: BetsColumnEnum[],
}) => {
  const [ short, setShort ] = useState<boolean>(true);
  const showMoreBtn = bet.outcomes.length > 3;
  const outcomesToShow = short ? bet?.outcomes?.slice(0, 3) : bet?.outcomes;

  return (
    <div>
      <div>
        {outcomesToShow.map(outcome => (
          <Fragment key={'short-link' + outcome.id}>
            <EventOutcomeInfoNew
              sportDescription={outcome.sport.description}
              leagueDescription={outcome.league.description}
              eventId={outcome.event.id}
              eventDescription={outcome.event.description}
              outcomeId={outcome.id}
              outcomeMarketDescription={outcome.market.description}
              outcomeMarketPeriodDescription={outcome.market.periodDescription}
              outcomeDescription={outcome.description}
              outcomePrice={outcome.price}
              isEachWay={bet.isEachWay}
              result={outcome.result}
              parentDomId={parentDomId}
              withFilters={withFilters}
              activeColumnsList={activeColumnsList}
            />
          </Fragment>
        ))}
      </div>
      <div>
        {showMoreBtn && (
          <button
            className={cl.showMoreBtn}
            onClick={() => setShort(p => !p)}
          >
            {short ? 'Show more' : 'Show less'}
            <Icon
              name={short ? 'angle down' : 'angle up'}
            />
          </button>
        )}
      </div>
    </div>
  );
};

const EventOutcomeInfoNew: FC<IOutcomeInfo> = ({
  sportDescription,
  leagueDescription,
  eventId,
  eventDescription,
  outcomeMarketDescription,
  outcomeMarketPeriodDescription,
  outcomeDescription,
  outcomePrice,
  outcomeId,
  result,
  isEachWay,
  parentDomId,
  withFilters,
  activeColumnsList,
}) => {
  const columnsEquity =
    (withFilters ? 3 : 0) +
    (activeColumnsList.includes(BetsColumnEnum.Player)
      ? activeColumnsList.length + 1
      : activeColumnsList.length);

  const cls = cx(
    cl.outcomeWrap,
    `max-width-outcomes_${columnsEquity}`
  );
  return (
    <div className={cls}>
      <>
        <OutcomeResultLabel size="mini" value={result} simple />{' '}
        <Link id={parentDomId + 'event-link'} to={`${EVENT_EDIT_PATH}/${eventId}/markets`}>
          {eventDescription}
        </Link>
      </>{' '}
      |{' '}
      <>
        <>
          {outcomeMarketDescription} - {outcomeMarketPeriodDescription}{' '}
        </>
        <Link
          id={parentDomId + 'events-markets-link'}
          to={`${EVENT_EDIT_PATH}/${eventId}/markets?outcomeId=${outcomeId}`}
        >
          <strong>{outcomeDescription}</strong>
        </Link>
        {outcomePrice && <> {` @ ${formatPrice(outcomePrice)}`}</>}
        {isEachWay && <>{' Each Way'}</>}
      </>{' '}
      |{' '}
      <>
        {sportDescription} / {leagueDescription}
      </>
    </div>
  );
};

export const VoidBetButtonNew = ({
  betId,
  voidReasonList,
  parentDomId,
}: {
  betId: string;
  voidReasonList: Array<{
    id: string;
    description: string;
    value: string;
  }>;
  parentDomId: string;
}) => {
  const queryClient = useQueryClient();
  const { betsParams } = useBetsStore();
  const { changeSelectedBets } = useBetsActions();

  const mutBetsSettle = useBetsSettle({
    onSuccess: onSuccess
  });

  const mutVoidBets = useBetsVoid({
    onSuccess: onSuccess,
    onError: () => toast.error('Request error')
  });

  function voidBets (
    _e: SyntheticEvent,
    { value }: DropdownItemProps
  ) {
    const dataForRequest = removeEmptyFields({
      bets: [{ id: betId, reason: value }],
    });
    mutVoidBets.mutate(dataForRequest);
  }

  async function onSuccess () {
    toast.success('Success');
    changeSelectedBets([]);
    await queryClient.invalidateQueries({ queryKey: [ ...betsParams ] });
  }

  return (
    <>
      <Dropdown
        id={parentDomId + 'void-reason-dropdown'}
        button
        className={cx('icon', 'bets__row-void-button')}
        loading={mutVoidBets.isPending || mutBetsSettle.isPending}
        text="Void"
      >
        <Dropdown.Menu>
          {voidReasonList.map((item: Record<string, string>) => (
            <Dropdown.Item
              key={item.id}
              onClick={voidBets}
              value={item.value}
              id={getOptionWithId(item, parentDomId + 'void-reason-dropdown')}
            >
              {item.description}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
