import React from 'react';

import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

import { ASC, DESC } from '../constants';
import { QueryStateType } from '../types';
import { parseQuery } from '../utils';

const LOCATION_CHANGE = 'location change';
const COMMIT_PENDING_STATE = 'commit pending state';
const STATE_UPDATE_WITH_PARAMS = 'state update with params';
const STATE_UPDATE = 'state update';

/** Pages where query state not main state */
const NEW_PAGES = [
  '/new-bets'
];

const QueryStateContext = React.createContext<{
  pendingQueryState: QueryStateType,
  queryState: QueryStateType,
  setQueryState: (
    name: string,
    value: string | number | boolean | Array<string> | Date,
    debounce?: boolean
  ) => void,
  setQueryStateWithParams: (obj: QueryStateType, debounce?: boolean) => void
}>({
  pendingQueryState: {},
  queryState: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setQueryState: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setQueryStateWithParams: () => {}
});

export const serialize = (v: object, prepend = true) =>
  qs.stringify(v, { addQueryPrefix: prepend, arrayFormat: 'repeat' });

type State = {
  pathname: string;
  search: string;
  queryState: QueryStateType;
  pendingQueryState: QueryStateType;
};

type Action =
  | {
      type: 'location change';
      payload: any;
    }
  | {
      type: 'state update';
      payload: {
        name: string;
        value: string | number | Array<string>;
        debounce: boolean;
      };
    }
  | {
      type: 'state update with params';
      payload: {
        params: { [key: string]: string | number | Array<string> };
        debounce?: boolean;
      };
    }
  | {
      type: 'commit pending state';
    };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (action.payload.pathname !== state.pathname) {
        const parsedState = parseQuery(action.payload.search);
        return {
          pathname: action.payload.pathname,
          queryState: parsedState,
          pendingQueryState: parsedState,
          search: action.payload.search,
        };
      }

      if (action.payload.search !== state.search && action.payload.search) {
        const parsedState = parseQuery(action.payload.search);
        return {
          pathname: state.pathname,
          queryState: parsedState,
          pendingQueryState: parsedState,
          search: action.payload.search,
        };
      }

      return state;
    }

    case STATE_UPDATE: {
      const { name, value, debounce } = action.payload;

      if (state.pendingQueryState[name] === value) {
        return state;
      }

      const nextState = {
        ...state.pendingQueryState,
        [name]: value,
      };

      if (
        value === '' ||
        Array.isArray(value) && !value.length
      ) {
        delete nextState[name];
      }

      if (+state.queryState.page > 1 && name !== 'page') {
        delete nextState.page;
      }

      return {
        pathname: state.pathname,
        queryState: debounce ? state.queryState : nextState,
        pendingQueryState: nextState,
        search: debounce ? state.search : serialize(nextState),
      };
    }

    case STATE_UPDATE_WITH_PARAMS: {
      const { params, debounce } = action.payload;

      const outdatedPendingQueryState = Object.keys(params)
        .some(name => state.pendingQueryState[name] !== params[name]);

      if (!outdatedPendingQueryState) {
        return state;
      }

      const nextState = {
        ...state.pendingQueryState,
        ...params,
      };

      Object.keys(nextState).forEach(name => {
        if (
          nextState[name] === '' || (
          Array.isArray(nextState[name]) && !nextState[name].length
        )) {
          delete nextState[name];
        }
        if (+state.queryState.page > 1 && name !== 'page') {
          delete nextState.page;
        }
      });

      return {
        pathname: state.pathname,
        queryState: debounce ? state.queryState : nextState,
        pendingQueryState: nextState,
        search: debounce ? state.search : serialize(nextState),
      };
    }

    case COMMIT_PENDING_STATE: {
      return {
        pathname: state.pathname,
        queryState: state.pendingQueryState,
        pendingQueryState: state.pendingQueryState,
        search: serialize(state.pendingQueryState),
      };
    }
    default:
      return state;
  }
};

const getInitialQuery = (searchStr: string) => {
  const queryObj = parseQuery(searchStr);
  if(Object.keys(queryObj).includes('liveMode')){
    return {
      ...queryObj,
      liveMode: queryObj['liveMode'] === 'true'
    };
  }else{
    return queryObj;
  }
};

export const QueryState = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialQueryState = getInitialQuery(location.search);

  const initialState = React.useMemo(() => ({
    pathname: location.pathname,
    search: location.search,
    queryState: initialQueryState,
    pendingQueryState: initialQueryState,
  }),[ initialQueryState, location.pathname, location.search ]);

  const [ state, dispatch ] = React.useReducer(reducer, initialState);

  React.useMemo(() => {
    dispatch({
      type: LOCATION_CHANGE,
      payload: location,
    });
  }, [ location ]);

  React.useEffect(() => {
    if (state.queryState !== state.pendingQueryState) {
      const timeoutId = setTimeout(() => {
        dispatch({
          type: COMMIT_PENDING_STATE,
        });
      }, 300);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [ state.queryState, state.pendingQueryState, location ]);

  React.useEffect(() => {
    if (NEW_PAGES.includes(location.pathname)) {
      return;
    }
    if (location.pathname !== state.pathname || location.search !== state.search) {
      navigate(`${state.pathname}${state.search}`);
    }
  }, [ state.pathname, state.search, location, navigate ]);

  const value = React.useMemo(() => ({
    pendingQueryState: state.pendingQueryState,
    queryState: state.queryState,
    setQueryState: (name: string, value: string | number | Array<string>, debounce = false) => {
      dispatch({
        type: STATE_UPDATE,
        payload: {
          name,
          value,
          debounce,
        },
      });
    },
    setQueryStateWithParams: (
      params: { [key: string]: string | number | Array<string> }, debounce = false
    ) => {
      dispatch({
        type: STATE_UPDATE_WITH_PARAMS,
        payload: {
          params,
          debounce,
        },
      });
    },
  }),[ state.pendingQueryState, state.queryState ]);

  return <QueryStateContext.Provider value={value as any}>{children}</QueryStateContext.Provider>;
};

export const useQueryState = () => {
  return React.useContext(QueryStateContext);
};

export const useSortQuery = ({
  sortBy: defaultSortBy,
  sortDir: defaultSortDir = DESC,
}: {
  sortBy: string;
  sortDir?: 'DESC' | 'ASC';
}) => {
  const { queryState, setQueryStateWithParams } = useQueryState();
  const { sortBy = defaultSortBy, sortDir = defaultSortDir } = queryState;

  const setSort = React.useCallback((name: string) => {
    setQueryStateWithParams({
      sortBy: name,
      sortDir: sortBy === name && sortDir === DESC ? ASC : DESC,
    });
  },[ setQueryStateWithParams, sortBy, sortDir ]);

  return {
    sortBy,
    sortDir,
    sortDirForSemanticTable:
      sortDir === 'DESC' ? ('descending' as const) : ('ascending' as const),
    setSort,
  };
};

export function useIsQueryStateClear () {
  const { queryState } = useQueryState();
  let length = 0;

  //eslint-disable-next-line
  for (let key in queryState) {
    length++;
  }

  return !length;
}
