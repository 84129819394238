import React, { ChangeEvent, FocusEvent, KeyboardEvent, SyntheticEvent } from 'react';

import cx from 'classnames';
import { toast } from 'react-toastify';
import { Button, Input } from 'semantic-ui-react';

import { useGetOutcomeByIndex, useMarketRowContext } from './MarketRowContext';
import {
  CHANGE_CURRENT_AND_LOCAL_PRICES_INDEX_ACTION,
  CHANGE_CURRENT_PRICE_INDEX_ACTION,
  CHANGE_LOCAL_PRICE_INDEX_ACTION,
  KEY_ENTER,
  KEY_TAB,
  RESET_DELTA_INDEX_ACTION
} from '../../../../constants';
import { useGlobalStateContext } from '../../../../hooks-and-global-states/global-context';
import { useMarketUpdate, usePriceCreate } from '../../../../react-query/mutations';
import { PriceLocalMarketsType } from '../../../../types';
import { getNewPrice } from '../helpers';
import './UpdatePriceForm.scss';

let timeout: ReturnType<typeof setTimeout>;

export const UpdatePriceForm = ({
  index,
  enableEventEditing,
  pricesForRequest,
  setPricesForRequest,
  parentDomId,
}:{
  index: number;
  enableEventEditing: boolean;
  pricesForRequest: Array<PriceLocalMarketsType>,
  setPricesForRequest: (prev: { payload: { index: number }; type: string }) => void;
  parentDomId: string;
}) => {
  const isNewOutcome = pricesForRequest[index] === undefined;
  if (isNewOutcome) return null;

  return (
    <UpdatePriceFormComponent
      index={index}
      enableEventEditing={enableEventEditing}
      pricesForRequest={pricesForRequest}
      setPricesForRequest={setPricesForRequest}
      parentDomId={parentDomId}
    />
  );
};

const UpdatePriceFormComponent = ({
  index,
  enableEventEditing,
  pricesForRequest,
  setPricesForRequest,
  parentDomId,
}:{
  index: number;
  enableEventEditing: boolean;
  pricesForRequest: Array<PriceLocalMarketsType>;
  setPricesForRequest: (prev: { payload: { index: number }; type: string }) => void;
  parentDomId: string;
}) => {
  const {
    canEditMarket: canEditPrice,
    priceCollection,
    addRef,
    market: { id: marketId },
    marketIndex,
    allPriceCollections,
    marketsLength,
  } = useMarketRowContext();

  const {
    outcome: { id: outcomeId },
    currentPrice: globalCurrentPrice,
    manualMode
  } = useGetOutcomeByIndex(index);

  const {
    currentPrice,
    feedPrice,
    localPrice,
    delta,
    correct
  } = pricesForRequest[index];

  const { originId } = useGlobalStateContext();

  const mutPriceCreate = usePriceCreate({
    onSuccess: () => onSuccess(),
  });

  const mutMarketUpdate = useMarketUpdate({
    onSuccess: () => onSuccess(true),
  });

  const [ feedBtnHold, setFeedBtnHold ] = React.useState(false);

  const isLocalAndCurrentEqual = Number(currentPrice) === Number(localPrice);
  const isFeedBtnVisible = !!manualMode && !!feedPrice && canEditPrice && enableEventEditing;

  /** Update current price after request */
  React.useEffect(() => {
    if (!isLocalAndCurrentEqual) {
      setPricesForRequest({
        type: CHANGE_CURRENT_PRICE_INDEX_ACTION,
        payload: {
          index,
          currentPrice: globalCurrentPrice,
        }
      });
    } else {
      setPricesForRequest({
        type: CHANGE_CURRENT_AND_LOCAL_PRICES_INDEX_ACTION,
        payload: {
          index,
          currentPrice: globalCurrentPrice,
          localPrice: globalCurrentPrice,
        }
      });
    }

    /** Reset delta after 5 seconds */
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setPricesForRequest({
        type: RESET_DELTA_INDEX_ACTION,
        payload: { index }
      });
    }, 5000);

    setFeedBtnHold(false);
    // eslint-disable-next-line
  },[ globalCurrentPrice ]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  },[]);

  function inputPriceOnChange (e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setPricesForRequest({
      type: CHANGE_LOCAL_PRICE_INDEX_ACTION,
      payload: {
        index,
        localPrice: e.target.value,
      }
    });
  }

  function inputPriceOnKeyDown (e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === KEY_TAB || e.key === KEY_ENTER) e.preventDefault();

    const nextCondition = (e.key === KEY_TAB && !e.shiftKey) || e.key === KEY_ENTER;
    const prevCondition = e.shiftKey && e.key === KEY_TAB;
    const saveCondition = (e.ctrlKey || e.metaKey) && e.key === 's';
    const isLastItem = index + 1 === priceCollection?.length;
    const isFirstItem = index === 0;
    const isSuperFirst = isFirstItem && marketIndex === 0;
    const isSuperLast = isLastItem && marketIndex + 1 === marketsLength;

      /** Go to next price. Keys: 'Enter' or 'Tab' */
    if (nextCondition && isLastItem && !isSuperLast) {
      const nextPrice = getNewPrice(allPriceCollections, marketIndex);
      if (nextPrice) nextPrice.focus();
    } else if (nextCondition && !isLastItem) {
      (priceCollection[index + 1].firstElementChild.firstElementChild as HTMLElement)?.focus();

      /** Go to previous price. Keys: 'Shift' + 'Tab' */
    } else if (prevCondition && isFirstItem && !isSuperFirst) {
      const prevPrice = getNewPrice(allPriceCollections, marketIndex, false);
      if (prevPrice) prevPrice.focus();

    } else if (prevCondition && !isFirstItem) {
      (priceCollection[index - 1]
        .firstElementChild.firstElementChild as HTMLElement)?.focus();

      /** Save changes, make request. Keys: 'ctrl (cmd)' + 's' */
    } else if (saveCondition) {
      e.preventDefault();
      if (correct && !isLocalAndCurrentEqual) {
        mutPriceCreate.mutate({
          outcomeId,
          price: localPrice,
        });
      }
    }
  }

  function inputPriceOnFocus (e: FocusEvent<HTMLInputElement>) {
    e.target.select();
  }

  /** Return Feed Price */
  function btnFeedOnClick (e: SyntheticEvent<HTMLButtonElement>) {
    e.preventDefault();

    setFeedBtnHold(true);

    mutMarketUpdate.mutate({
      originId,
      id: marketId,
      outcomes: [{
        id: outcomeId,
        manualMode: false,
      }]
    });
  }

  function onSuccess (feedBtn?: boolean) {
    toast.success('Price were saved');

    if (feedBtn) {
      setFeedBtnHold(false);
    } else {
      (priceCollection[index].firstElementChild.firstElementChild as HTMLElement)?.blur();
    }

    setPricesForRequest({
      type: CHANGE_CURRENT_AND_LOCAL_PRICES_INDEX_ACTION,
      payload: {
        index,
        currentPrice: feedBtn ? feedPrice : localPrice,
        localPrice: feedBtn ? feedPrice : localPrice,
      },
    });
  }

  return (
    <div
      className="price-cell"
      ref={el => addRef(el, priceCollection)}
    >
      <Input
        id={parentDomId + 'change-price-input'}
        size="small"
        className={cx(
          'input-price', {
          'input-price_blue': !isLocalAndCurrentEqual,
          'input-price_red': delta < 0,
          'input-price_green': delta > 0,
        })}
        value={localPrice}
        disabled={!canEditPrice || !enableEventEditing}
        onChange={inputPriceOnChange}
        onKeyDown={inputPriceOnKeyDown}
        onFocus={inputPriceOnFocus}
        error={!correct}
      />
      {isFeedBtnVisible && (
        <Button
          id={parentDomId + 'feed-price-btn'}
          className="btn-feed-price"
          size="small"
          content={`Feed: ${feedPrice}`}
          onClick={btnFeedOnClick}
          loading={feedBtnHold}
        />
      )}
    </div>
  );
};
